import React, { useEffect, useState } from 'react';
import styles from './ShowcaseCard.module.scss';
import { dataCardResponseType } from '../../../type';
import { useLocation, useNavigate } from 'react-router-dom';
import headerImage from '../../../images/default-header-showcases.png';
import logoImage from '../../../images/default-logo.png';
import { useMediaQuery } from 'react-responsive';
import { FavoriteShowcasesSvg } from 'src/components/UI/svg-icon/FavoriteShowcasesSvg';
import { fetchPost } from 'src/common/proxy-1C-fetch-auth';
import { toastError } from 'src/common/toastError.helper';

interface IProps {
  showCase: dataCardResponseType;
}
const ShowcaseCard = ({ showCase }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const location = useLocation();
  const isCatalogPath = location.pathname.startsWith('/catalog');
  const isFavoritesPath = location.pathname.indexOf('/favorites') !== 0;
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const logoOrHeaderImage = {
    'Шапка витрины': headerImage,
    'Лого витрины': logoImage,
  };

  useEffect(() => {
    if (showCase?.favorite) {
      setIsFavorite(showCase?.favorite);
      return;
    }
    if (isFavoritesPath) {
      setIsFavorite(true);
    }
  }, []);

  const handleFavoriteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    changeFavorite();
  };

  const chooseImageForLogoAndCover = (showCase, logoOrCover: 'Шапка витрины' | 'Лого витрины') => {
    let image = showCase?.files?.find(item => item.category === logoOrCover);
    return image ? image.location : logoOrHeaderImage[logoOrCover];
  };

  const changeFavorite = async () => {
    try {
      if (!isFavorite) {
        const response = await fetchPost(`/users/favourites/`, 'POST', {
          category: 'showcases',
          itemId: showCase.id,
        });
        if (toastError(response)) return;
      } else {
        const response = await fetchPost(`/users/favourites/${showCase.id}`, 'DELETE', {
          category: 'showcases',
        });
        if (toastError(response)) return;
      }

      setIsFavorite(!isFavorite);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      key={showCase?.id}
      className={styles.container}
      onClick={() => navigate(`${isCatalogPath ? '/catalog' : ''}/showcases/${showCase?.id}`)}
    >
      <div
        className={styles.backgroundImageContainer}
        style={{
          backgroundImage: `url(${chooseImageForLogoAndCover(showCase, 'Шапка витрины')})`,
        }}
      />
      {isCatalogPath && (
        <div className={styles.favoriteButton} onClick={e => handleFavoriteClick(e)}>
          <FavoriteShowcasesSvg width={isMobile ? '24px' : '32px'} color={isFavorite ? 'currentColor' : 'white'} />
        </div>
      )}
      <div className={styles.contentContainer}>
        <p className={styles.titleContentText}>{showCase?.title || 'Нет данных'}</p>
        <p className={styles.subTitleContentText}>{showCase?.description || 'Нет данных'}</p>
        <div className={styles.border} />
        <p className={styles.addressContentText}>{showCase?.quarry?.address?.address || 'Нет данных'}</p>
      </div>
      <div
        className={styles.logoContainer}
        style={{
          backgroundImage: `url(${chooseImageForLogoAndCover(showCase, 'Лого витрины')})`,
        }}
      />
    </div>
  );
};

export default ShowcaseCard;
