import React from 'react';
import styles from '../../GSMCardAdd.module.scss';
import AddGSMSvg from '../../../../../../components/UI/svg-icon/AddGSMSvg';
import CustomTooltip from '../../../../../../components/newUI/CustomTooltip/CustomTooltip';
import InfoSvg from '../../../../../../components/UI/svg-icon/InfoSvg';
import BackGroundGSMCardSvg from '../../../../../../components/UI/svg-icon/BackGroundGSMCardSvg';
import { useMediaQuery } from 'react-responsive';
interface IProps {
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
  drivers: { value: number; label: string }[];
  checkAvailableDrivers: boolean;
}
const ListCardAdd = ({ setOpenModalOfAddGSMCard, drivers, checkAvailableDrivers }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div
      onClick={() => checkAvailableDrivers && drivers.length && setOpenModalOfAddGSMCard(true)}
      className={styles.listContainer}
    >
      <div className={styles.svgAndTextContainer}>
        <AddGSMSvg width={isMobile ? '18' : '52'} height={isMobile ? '12' : '36'} />
        <div className={styles.listRedContainer}>
          <p
            style={{
              textDecoration: checkAvailableDrivers ? 'unset' : 'underline',
            }}
            className={checkAvailableDrivers ? styles.listText : styles.listRedText}
          >
            {checkAvailableDrivers ? 'Добавить топливную карту' : 'Добавьте водителя,'}
          </p>
          {!checkAvailableDrivers && (
            <>
              <span
                style={{
                  color: drivers.length ? '#6C779C' : '#DC2626',
                }}
                className={drivers.length ? styles.listText : styles.listRedText}
              >
                &nbsp;чтобы добавить топливную карту
              </span>
            </>
          )}
        </div>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <CustomTooltip
          svg={<InfoSvg color={checkAvailableDrivers ? '#6C779C' : '#DC2626'} />}
          text={'Топливных карт не может быть больше, чем водителей'}
          widthTooltip={isMobile ? '250px' : '400px'}
          widthSvg={'16px'}
          positionTooltipRight={isMobile && '-13px'}
          positionTooltipBottom={'23px'}
          MobileSideTooltip={'left'}
          SideTooltip={'up'}
        />
      </div>
      <div className={styles.backGroundList}>
        {!isMobile && (
          <p style={{ marginBottom: '-23px' }}>
            <BackGroundGSMCardSvg width={'165px'} height={'188px'} />
          </p>
        )}
      </div>
    </div>
  );
};

export default ListCardAdd;
