import React from 'react';
import { ImageContainer } from '../../../components/ImageContainer';
import { ButtonClose } from '../../../components/UI/buttons/buttonClose';
import { removeImage } from '../../../common/removeImage';

const UploadImageContainer = ({ setImages, images, openPopupFunc, disabled = false }) => {
  return (
    <>
      {images.map(({ id, value, name, size, isSize, date, isDate, type }) => (
        <div className="image-container" key={id}>
          <ImageContainer
            id={id}
            value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
            name={name}
            size={size}
            isSize={isSize}
            date={date}
            isDate={isDate}
            type={type}
            openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupFunc(value)}
          >
            <ButtonClose
              id={id}
              onClick={({ target }) => !disabled && removeImage(target, images, setImages)}
              hidden=""
            />
          </ImageContainer>
        </div>
      ))}
    </>
  );
};

export default UploadImageContainer;
