import React from 'react';
import styles from './GSMStatisticsPlot.module.scss';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { plotOptions } from '../../GSMconstants';
import { GSMPieChart } from './GSMPieChart/GSMPieChart';
import { getDayOfWeek } from '../../GSMHelpers';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);

export const GSMStatisticsPlot = ({
  type,
  labels,
  data,
}: {
  type: 'sum' | 'pie' | 'liters';
  labels: string[];
  data: number[];
}) => {
  const newLabels = labels.map(label => {
    const newLabel = new Date(label);
    return getDayOfWeek(newLabel) + ', ' + newLabel.getDate();
  });
  const dataSettings = {
    labels: newLabels,
    datasets: [
      {
        data: data,
        borderColor: '#F4753A',
        pointBackgroundColor: '#F4753A',
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 1,
      },
    ],
  };
  return (
    <div className={styles.container}>
      {type !== 'pie' ? (
        <Line data={dataSettings} options={plotOptions} />
      ) : (
        <GSMPieChart labels={labels} data={data} />
      )}
    </div>
  );
};
