import React, { useEffect } from 'react';
import {
    optionDefaultForTypeMaterialSelect,
    optionsSelectMaterial
} from '../../../constans';
import styles from './TheTypeOfMaterial.module.scss';
import { ICheckBox, IMaterialButtons, ITypeInputsOfMaterial } from '../../../type';
import CustomInput from '../../../../../components/newUI/CustomInput/CustomInput';
import { CheckBoxCustom } from '../../../../../components/UI/checkBox/CheckBoxCustom';
import { useMediaQuery } from 'react-responsive';
import { CustomSelect } from '../../../../../components/UI/select/CustomSelect';
import { useLocation } from 'react-router-dom';

interface IProps {
    ownNameCheckBox:ICheckBox
    setOwnNameCheckBox:React.Dispatch<React.SetStateAction<ICheckBox>>
    materialButtons:IMaterialButtons[]
    setMaterialButtons:React.Dispatch<React.SetStateAction<IMaterialButtons[]>>
    valueOfInputsOfMaterial:ITypeInputsOfMaterial
    setValueOfInputsOfMaterial:React.Dispatch<React.SetStateAction<ITypeInputsOfMaterial>>
    valueMaterial:string
    setValueMaterial:React.Dispatch<React.SetStateAction<string>>
    routeFromIndividualMaterialPage:boolean
    edit:boolean
}
const TheTypeOfMaterial = ({
                               materialButtons,setMaterialButtons,
                               setValueOfInputsOfMaterial,valueOfInputsOfMaterial,valueMaterial,setValueMaterial,
                               ownNameCheckBox,setOwnNameCheckBox,routeFromIndividualMaterialPage,edit }:IProps) => {
    const pickCheckOwnName = id => {
        setOwnNameCheckBox(prevState => ({ id: id, active: !prevState.active }));
};

    const isMobile = useMediaQuery({ maxWidth: 768 });
    const { pathname } = useLocation();
    const defaultValueForSelect = () => {
        if (edit) {
            return optionDefaultForTypeMaterialSelect[pathname.split('/')[pathname.split('/').length - 3]];
        }
        if (routeFromIndividualMaterialPage){
            return optionDefaultForTypeMaterialSelect[pathname.split('/')[pathname.split('/').length - 2]];
        }
        return '';
    };
    const changeMaterialButtonsActiveState = (buttonTitle: string,isActive:boolean):void => {
        const newData = [ ...materialButtons ];
        newData.forEach(button => {
            if (isActive) {
                button.active = button.title === buttonTitle;
            }else {
                button.active=false;
            }
        });
        setMaterialButtons(newData);
    };
    const handleValueMaterial=(value)=>{
        setValueMaterial(value);
    };
    return (
        <div className={styles.container}>
            {isMobile?
                <CustomSelect
                    isDisabled={edit}
                    defaultValue={defaultValueForSelect()}
                    error={false}
                    placeholder={'Выберите из списка'}
                    noOptionsMessage={''}
                    isSearchable={false}
                    isClearable={false}
                    options={optionsSelectMaterial}
                    setValue={value=>handleValueMaterial(value.value)}
                />
                :
                <div className={styles.materialsContainer}>
                    {materialButtons.map((item)=>(
                        <div key={item?.title} className={item.disabled?styles.titleAndSvgContainerDisabled:styles.titleAndSvgContainer}
                             onClick={()=> {
                                 changeMaterialButtonsActiveState(item.title,true);
                                 handleValueMaterial(item.value);
                             }}
                        >
                            <p style={{ color: item.active ? '#FF641D' : '#9BA1B4' }} className={styles.svgContainer}>{item.svg}</p>
                            <p style={{ color: item.active ? '#FF641D' : '#1B1B1B' }} className={styles.titleContainer}>{item.title}</p>
                        </div>
                    ))}
                </div>
            }
                <div className={styles.inputNameContainer}>
                    {valueMaterial &&
                        <CheckBoxCustom
                            children={'Свое наименование'}
                            error={false}
                            check={ownNameCheckBox.active}
                            pick={pickCheckOwnName}
                            name={'ownName'}
                            id={'ownName'}
                        />
                    }
                    {ownNameCheckBox.active &&
                        <CustomInput
                            clearButton={true}
                            width={'100%'}
                            type={''}
                            value={valueOfInputsOfMaterial[valueMaterial].name}
                            placeholder={'Введите значение'}
                            setValue={(value)=>setValueOfInputsOfMaterial(prevState => ({ ...prevState,[valueMaterial]:
                                    { ...prevState[valueMaterial],name:value } }))}
                            disabled={false}/>
                    }
                </div>
        </div>

    );
};

export default TheTypeOfMaterial;