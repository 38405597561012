import React, { useState } from 'react';
import styles from './CurrentFlight.module.scss';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import QRCode from 'react-qr-code';
import { Drawer } from 'antd';
import { formPrice } from '../../../../../common/form-price.helper';
import ButtonFilled from '../../../../../components/newUI/ButtonFilled/ButtonFilled';
interface IProps {
  currentFlight: any;
}
export const CurrentFlight = ({ currentFlight }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isQr, setIsQr] = useState(false);
  const truckTopIsCurrentFlight = currentFlight ? '52.14px' : '98.14px';
  const truckTopMobile = isMobile ? truckTopIsCurrentFlight : '42.21px';

  const handleCancelModal = () => {
    setIsQr(false);
  };

  const navigate = useNavigate();

  const navigateToActiveFlight = () => {
    if (currentFlight) {
      localStorage.setItem('flightId', currentFlight?.id);
      navigate(`/flights/${currentFlight?.id}`);
    }
  };

  return (
    <div className={`${styles.container} ${currentFlight ? styles.containerButton : styles.noneContainer}`}>
      <Drawer
        height={'450px'}
        placement={'bottom'}
        title={'Сканировать QR-код'}
        onClose={handleCancelModal}
        open={isQr}
        footer={null}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <QRCode value={currentFlight?.id} bgColor={'#FFFFFF'} fgColor={'#000000'} size={256} />
          <ButtonFilled margin={'10px 0 0 0'} onClick={() => setIsQr(false)} text="Готово" width={'100%'} />
        </div>
      </Drawer>
      <div className={styles.headerContainer}>
        <p className="textH6 textH6__medium">Текущий рейс</p>
      </div>
      <div className={styles.ContentContainer} onClick={navigateToActiveFlight}>
        {currentFlight ? (
          <div className={styles.textContentContainer}>
            <span className={styles.flightText}>Рейс: {currentFlight?.flightName || 'Нет данных'}</span>
            <span
              className={styles.contentText}
            >{`${currentFlight?.car?.carBrand?.brand || 'Нет данных'} ${currentFlight?.car?.stateNumber || ''}`}</span>
            <div className={styles.contentSubcontainer}>
              <span className={styles.contentTitle}>Расстояние</span>
              <span className={styles.contentText}>{currentFlight?.distance} км</span>
            </div>
            <div className={styles.contentSubcontainer}>
              <span className={styles.contentTitle}>Время</span>
              <span className={styles.contentText}>{currentFlight?.arrivalTime}</span>
            </div>
          </div>
        ) : (
          <p className={styles.emptyText}>
            Тут будет отображаться <br />
            информация {!isMobile ? <br /> : <></>}
            по текущему рейсу
          </p>
        )}
        <img className={styles.truck} style={{ top: truckTopMobile }} src="/image/truck.png" alt="sss" />
      </div>
      {currentFlight ? (
        <div className={styles.bottomContainer}>
          <span className={styles.bottomText}> Оплата</span>
          <span className={styles.contentPriceText}>
            {currentFlight?.driversSalary
              ? formPrice(currentFlight?.driversSalary * currentFlight?.distance, 'RUB')
              : formPrice(0, 'RUB')}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
