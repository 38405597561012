import { toast } from 'react-toastify';
import React from 'react';

export const toastError = (response: any) => {
  if (response?.error) {
    const errorMessages = response?.message?.map(message => {
      return (
        <p>
          {message}
          <br />
          <br />
        </p>
      );
    });
    toast.error(<div>{errorMessages}</div>);
    return true;
  }
  return false;
};
