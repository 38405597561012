export const formatPhoneNumber = (phoneNumber): string => {
  let arrayOfNumberPhone = phoneNumber.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (arrayOfNumberPhone) {
    let initialCode = arrayOfNumberPhone[1] ? '+7 ' : '';
    return [
      initialCode,
      '',
      arrayOfNumberPhone[2],
      '',
      arrayOfNumberPhone[3],
      '',
      arrayOfNumberPhone[4],
      '',
      arrayOfNumberPhone[5],
    ].join(' ');
  }
  return 'некорректный номер телефона';
};
