import React from 'react';
import styles from './QuarryLink.module.scss';
import { ArrowRightSvg } from 'src/components/UI/svg-icon/ArrowRightSvg';

type TQuarryLink = {
  onClick: () => void;
  quarryName: string;
};

export const QuarryLink = ({ onClick, quarryName }: TQuarryLink) => {
  return (
    <div
      className={styles.quarryLink}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <p className={styles.infoTextValueMobile} onClick={onClick}>
        {quarryName}
      </p>
      <ArrowRightSvg />
    </div>
  );
};
