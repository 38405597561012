// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReplenishmentsPage_container__cQwUh {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n  margin-top: 16px;\n}\n\n.ReplenishmentsPage_topContainer__-fSbk {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.ReplenishmentsPage_title__zO3LC {\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n  color: var(--color-gray900);\n}\n\n@media (max-width: 768px) {\n  .ReplenishmentsPage_container__cQwUh {\n    gap: 24px;\n  }\n  .ReplenishmentsPage_topContainer__-fSbk {\n    flex-direction: column;\n    gap: 16px;\n    align-items: flex-start;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/GSMNewPageCarrier/ReplenishmentsPage/ReplenishmentsPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;AACF;;AAEA;EACE;IACE,SAAA;EACF;EACA;IACE,sBAAA;IACA,SAAA;IACA,uBAAA;EACF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n  margin-top: 16px;\n}\n\n.topContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n  color: var(--color-gray900);\n}\n\n@media (max-width: 768px) {\n  .container {\n    gap: 24px;\n  }\n  .topContainer {\n    flex-direction: column;\n    gap: 16px;\n    align-items: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReplenishmentsPage_container__cQwUh",
	"topContainer": "ReplenishmentsPage_topContainer__-fSbk",
	"title": "ReplenishmentsPage_title__zO3LC"
};
export default ___CSS_LOADER_EXPORT___;
