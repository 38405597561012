import React from 'react';
import { fontSizeSelect } from './constans';
interface IProps {
  title: string;
  fontSize?: '32px' | '24px';
}
const TitleMain = ({ title, fontSize = '32px' }: IProps) => {
  return <div className={fontSizeSelect[fontSize]}>{title}</div>;
};

export default TitleMain;
