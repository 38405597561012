import React from 'react';
import styles from './Filters.module.scss';
import { IFilters } from '../../type';
import { InputSearch } from '../../../../components/UI/inputs/InputSearch';
import { CustomSelect } from '../../../../components/UI/select/CustomSelect';
import { optionsDateAdded, optionsGrid } from '../../constans';
import { useMediaQuery } from 'react-responsive';

interface IProps {
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
}

const Filters = ({ filters, setFilters }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div className={styles.container}>
      <InputSearch
        height={isMobile && '40px'}
        placeholder="Поиск"
        onInput={({ currentTarget: { value: search } }) => setFilters(prevState => ({ ...prevState, search }))}
        value={filters.search}
      />
      <div className={styles.twoFiltersString}>
        <div style={{ width: isMobile ? '100%' : '242px' }}>
          <CustomSelect
            height40px={isMobile}
            defaultValue={optionsGrid[0]}
            isDisabled={false}
            error={false}
            options={optionsGrid}
            setValue={e => setFilters(prevState => ({ ...prevState, grid: e.value }))}
            placeholder={''}
            isClearable={false}
            isSearchable={false}
            noOptionsMessage={'Нет вариантов'}
          />
        </div>
        <div style={{ width: isMobile ? '100%' : '242px' }}>
          <CustomSelect
            height40px={isMobile}
            defaultValue={optionsDateAdded[0]}
            isDisabled={false}
            error={false}
            options={optionsDateAdded}
            setValue={e => setFilters(prevState => ({ ...prevState, dateAdded: e.value }))}
            placeholder={''}
            isClearable={false}
            isSearchable={false}
            noOptionsMessage={'Нет вариантов'}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;