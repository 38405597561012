import { Modal } from 'antd';
import React from 'react';
import styles from './ModalNew.module.scss';
import { useMediaQuery } from 'react-responsive';
import DrawerMobile from '../newUI/DrawerMobile/DrawerMobile';
import FrameModalSvg from '../UI/svg-icon/FrameModalSvg';

interface IProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<boolean>;
  children: any;
  width?: string;
  afterClose?: () => void;
}

export const ModalNew = ({ openModal, setOpenModal, children, width = '480px', afterClose }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      {isMobile ? (
        <DrawerMobile
          afterClose={afterClose}
          closeButton={false}
          heightDrawer={'fit-content'}
          open={openModal}
          close={() => setOpenModal(false)}
        >
          {children}
        </DrawerMobile>
      ) : (
        <Modal
          destroyOnClose={true}
          afterClose={afterClose}
          width={width}
          zIndex={9500}
          footer={null}
          closeIcon={false}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          className={styles.modal}
          centered
        >
          {children}
          <div className={styles.closeButton}>
            <FrameModalSvg onClick={() => setOpenModal(false)} />
          </div>
        </Modal>
      )}
    </>
  );
};
