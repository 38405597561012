import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import styles from './ShowcaseDescriptionView.module.scss';
import { useMediaQuery } from 'react-responsive';
import useClickOutside from 'src/CustomHooks/useClickOutside';
import { ShowcaseDescriptionButtons } from './ShowcaseDescriptionButtons/ShowcaseDescriptionButtons';
import { useLocation } from 'react-router-dom';
import defaultLogoImage from '../../images/default-logo.png';

type TShowcaseDescriptionView = {
  logoImage: string;
  carrierName: string;
  carrierDescription: string;
};

export const ShowcaseDescriptionView = ({ logoImage, carrierName, carrierDescription }: TShowcaseDescriptionView) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.desktopContainer}>
        <div className={styles.logoContainer}>
          <div
            className={styles.logoImage}
            style={{
              backgroundImage: `url(${logoImage ? logoImage : defaultLogoImage})`,
            }}
          />
        </div>
        <div className={styles.titleContainer}>
          <div className={(isMobile ? 'textH4 textH4_medium ' : 'textH3 textH3_medium ') + styles.carrierName}>
            {carrierName}
          </div>
          {!isMobile && <div className={styles.carrierDescription}>{carrierDescription}</div>}
        </div>
      </div>
      {isMobile && (
        <div className={styles.mobileContainer}>
          <div className={styles.carrierDescription}>{carrierDescription}</div>
        </div>
      )}
    </div>
  );
};
