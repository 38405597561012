import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './FooterLk.module.scss';
import NewLogoForModalAuth from '../../UI/svg-icon/NewLogoForModalAuth';
import TelegramSvg from '../../UI/svg-icon/TelegramSvg';
import FooterLogoSvg from '../../UI/svg-icon/FooterLogoSvg';
import FooterLogoTextSvg from '../../UI/svg-icon/FooterLogoTextSvg';
import FooterLogoTextSvgForBright from '../../UI/svg-icon/FooterLogoTextSvgForBright';

const FooterLk = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContentContainer}>
        <div className={styles.firstContainerFooter}>
          <div
            onClick={() => {
              navigate('/');
            }}
            style={{ cursor: 'pointer' }}
          >
            <FooterLogoSvg />
          </div>
          <div
            onClick={() => {
              navigate('/');
            }}
            style={{ cursor: 'pointer' }}
          >
            <FooterLogoTextSvgForBright />
          </div>
        </div>
        <div className={styles.secondContainerFooter}>
          <div className={styles.secondContainerContentFooter}>
            <div className={styles.stringSecondContainer}>
              <div>
                <a
                  style={{ color: '#6C779C', textDecoration: 'underline' }}
                  target={'_blank'}
                  href="https://solber.ru/"
                >
                  solber.ru
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.thirdContainerFooter}>
        <div className={styles.bottomContainer}>
          <p
            onClick={() => {
              navigate('/privacyPolicy');
            }}
            style={{ cursor: 'pointer' }}
            className={styles.secondContainerFooter}
          >
            Политика конфиденциальности
          </p>
          <p
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/userAgreement');
            }}
            className={styles.secondContainerFooter}
          >
            Пользовательское соглашение
          </p>
        </div>
        <div className={styles.thirdStringFirstContainer}>© 2024 ООО НТК «СОЛБЕР».Все права защищены.</div>
      </div>
    </div>
  );
};

export default FooterLk;
