import React from 'react';

export const ShowcasesIconSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5 7H16.5V5L14.5 3H10.5L8.5 5V7H4.5C3.4 7 2.5 7.9 2.5 9V14C2.5 14.75 2.9 15.38 3.5 15.73V19C3.5 20.11 4.39 21 5.5 21H19.5C20.61 21 21.5 20.11 21.5 19V15.72C22.09 15.37 22.5 14.73 22.5 14V9C22.5 7.9 21.6 7 20.5 7ZM10.5 5H14.5V7H10.5V5ZM4.5 9H20.5V14H15.5V11H9.5V14H4.5V9ZM13.5 15H11.5V13H13.5V15ZM19.5 19H5.5V16H9.5V17H15.5V16H19.5V19Z"
        fill="currentColor"
      />
    </svg>
  );
};
