// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowcaseCards_cardContainer__eFv1s {\n  display: grid;\n  gap: 21px;\n  grid-template-columns: 1fr 1fr 1fr;\n}\n\n@media (max-width: 768px) {\n  .ShowcaseCards_cardContainer__eFv1s {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/ShowcasesPage/components/ShowcaseCards/ShowcaseCards.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,kCAAA;AACF;;AAEA;EACE;IACE,aAAA;IACA,sBAAA;IACA,SAAA;EACF;AACF","sourcesContent":[".cardContainer {\n  display: grid;\n  gap: 21px;\n  grid-template-columns: 1fr 1fr 1fr;\n}\n\n@media (max-width: 768px) {\n  .cardContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "ShowcaseCards_cardContainer__eFv1s"
};
export default ___CSS_LOADER_EXPORT___;
