import { TCatalogMaterialCategory, TFilter, TFilterItem, TFilterValues, TMaterialsValues } from './catalogTypes';
import crushedStone from './images/crushed-stone.png';
import secondaryCrushedStone from './images/secondary-crushed-stone.png';
import sand from './images/sand.png';
import PGS from './images/PGS.png';
import SHPS from './images/SHPS.png';
import gravel from './images/gravel.png';
import mobileCrushedStone from './images/mobile-crushed-stone.png';
import mobileSecondaryCrushedStone from './images/mobile-secondary-crushed-stone.png';
import mobileSand from './images/mobile-sand.png';
import mobilePGS from './images/mobile-PGS.png';
import mobileSHPS from './images/mobile-SHPS.png';
import mobileGravel from './images/mobile-gravel.png';

export const materialsKindValues: TMaterialsValues = {
  Щебень: [
    { value: 'Гранитный', label: 'Гранитный' },
    { value: 'Гравийный', label: 'Гравийный' },
    { value: 'Известняковый', label: 'Известняковый' },
  ],
  'Щебень вторичный': [
    { value: 'Отсев дробления', label: 'Отсев дробления' },
    { value: 'Бетонный', label: 'Бетонный' },
    { value: 'Кирпичный бой', label: 'Кирпичный бой' },
  ],
  Песок: [
    { value: 'Пескогрунт', label: 'Пескогрунт' },
    { value: 'Карьерный', label: 'Карьерный' },
    { value: 'Сеяный', label: 'Сеяный' },
    { value: 'Мытый', label: 'Мытый' },
    { value: 'Намывной', label: 'Намывной' },
    { value: 'Речной', label: 'Речной' },
    { value: 'Кварцевый', label: 'Кварцевый' },
  ],
  ЩПС: [
    { value: 'с1 (самая крупная фракция 40 мм)', label: 'с1 (самая крупная фракция 40 мм)' },
    { value: 'с2 (самая крупная фракция 20 мм)', label: 'с2 (самая крупная фракция 20 мм)' },
    { value: 'с3 (размер зерен 120 мм и меньше)', label: 'с3 (размер зерен 120 мм и меньше)' },
    { value: 'с4 (самая крупная фракция 80мм)', label: 'с4 (самая крупная фракция 80мм)' },
    { value: 'с5 (самая крупная фракция 40мм)', label: 'с5 (самая крупная фракция 40мм)' },
    { value: 'с6 (самая крупная фракция 20 мм)', label: 'с6 (самая крупная фракция 20 мм)' },
    { value: 'с7 (самая крупная фракция 10 мм)', label: 'с7 (самая крупная фракция 10 мм)' },
    { value: 'с8 (самая крупная фракция 5 мм)', label: 'с8 (самая крупная фракция 5 мм)' },
    { value: 'с9 (самая крупная фракция 80 мм)', label: 'с9 (самая крупная фракция 80 мм)' },
    { value: 'с10 (самая крупная фракция 40 мм)', label: 'с10 (самая крупная фракция 40 мм)' },
    { value: 'с11 (самая крупная фракция 20 мм)', label: 'с11 (самая крупная фракция 20 мм)' },
  ],
  ПГС: [
    { value: 'Натуральная ПГС', label: 'Натуральная ПГС' },
    { value: 'Обогащенная ПГС', label: 'Обогащенная ПГС' },
  ],
  Гравий: [],
};

export const materialsFractionValues: TMaterialsValues = {
  Щебень: [
    { value: '0-4 мм', label: '0-4 мм' },
    { value: '4-8 мм', label: '4-8 мм' },
    { value: '4-16 мм', label: '4-16 мм' },
    { value: '8-11,2 мм', label: '8-11,2 мм' },
    { value: '8-16 мм', label: '8-16 мм' },
    { value: '11,2-16 мм', label: '11,2-16 мм' },
    { value: '16-22,4 мм', label: '16-22,4 мм' },
    { value: '16-31,5 мм', label: '16-31,5 мм' },
    { value: '22,4-31,5 мм', label: '22,4-31,5 мм' },
    { value: '31,5-45 мм', label: '31,5-45 мм' },
    { value: '25-60 мм', label: '25-60 мм' },
    { value: '31,5-63 мм', label: '31,5-63 мм' },
    { value: '5-10 мм', label: '5-10 мм' },
    { value: '5-20 мм', label: '5-20 мм' },
    { value: '20-40 мм', label: '20-40 мм' },
    { value: '40-70 мм', label: '40-70 мм' },
    { value: '70-100 мм', label: '70-100 мм' },
  ],

  'Отсев дробления': [
    { value: '0-10 мм', label: '0-10 мм' },
    { value: '0-100 мм', label: '0-100 мм' },
  ],

  Бетонный: [
    { value: '5-20 мм', label: '5-20 мм' },
    { value: '20-40 мм', label: '20-40 мм' },
    { value: '40-70 мм', label: '40-70 мм' },
  ],

  'Кирпичный бой': [{ value: '0-100 мм', label: '0-100 мм' }],

  ПГС: [
    { value: '0-10 мм', label: '0-10 мм' },
    { value: '5-20 мм', label: '5-20 мм' },
    { value: '10-20 мм', label: '10-20 мм' },
    { value: '20-40 мм', label: '20-40 мм' },
    { value: '40-70 мм', label: '40-70 мм' },
  ],
  Гравий: [
    { value: '0-4 мм', label: '0-4 мм' },
    { value: '4-8 мм', label: '4-8 мм' },
    { value: '4-16 мм', label: '4-16 мм' },
    { value: '8-11,2 мм', label: '8-11,2 мм' },
    { value: '8-16 мм', label: '8-16 мм' },
    { value: '11,2-16 мм', label: '11,2-16 мм' },
    { value: '16-22,4 мм', label: '16-22,4 мм' },
    { value: '16-31,5 мм', label: '16-31,5 мм' },
    { value: '22,4-31,5 мм', label: '22,4-31,5 мм' },
    { value: '31,5-45 мм', label: '31,5-45 мм' },
    { value: '25-60 мм', label: '25-60 мм' },
    { value: '31,5-63 мм', label: '31,5-63 мм' },
    { value: '0-100 мм', label: '0-100 мм' },
    { value: '5-10 мм', label: '5-10 мм' },
    { value: '5-20 мм', label: '5-20 мм' },
    { value: '20-40 мм', label: '20-40 мм' },
    { value: '40-70 мм', label: '40-70 мм' },
    { value: '70-100 мм', label: '70-100 мм' },
  ],
};

export const catalogMaterialCategories: TCatalogMaterialCategory[] = [
  {
    name: 'rubble',
    title: 'Щебень',
    subtitle: 'Материал \nдля строительства',
    image: crushedStone,
    mobileImage: mobileCrushedStone,
    textColor: 'var(--color-orange400)',
    subtextColor: 'var(--color-gray900)',
  },
  {
    name: 'secondary-crushed-stone',
    title: 'Щебень вторичный',
    image: secondaryCrushedStone,
    mobileImage: mobileSecondaryCrushedStone,
    textColor: 'white',
  },
  {
    name: 'sand',
    title: 'Песок',
    subtitle: 'Материал \nдля строительства',
    image: sand,
    mobileImage: mobileSand,
    textColor: 'white',
    subtextColor: 'var(--color-orange10)',
  },
  {
    name: 'psc',
    title: 'ЩПС',
    subtitle: 'Щебеночно-песчаная смесь',
    image: SHPS,
    mobileImage: mobileSHPS,
    textColor: 'var(--color-gray900)',
  },
  {
    name: 'asg',
    title: 'ПГС',
    subtitle: 'Песчано-гравийная смесь',
    image: PGS,
    mobileImage: mobilePGS,
    textColor: 'white',
  },
  {
    name: 'gravel',
    title: 'Гравий',
    image: gravel,
    mobileImage: mobileGravel,
    textColor: 'white',
  },
];

export const defaultFilterValues: TFilterValues = {
  objectPlace: [],
  materialType: {},
  materialFraction: [],
  priceRange: { from: '', to: '' },
};

export const defaultFilter: TFilter = {
  search: '',
  objectPlace: null,
  materialType: [],
  materialFraction: [],
  priceRange: { from: '', to: '' },
};

export const sortValues: TFilterItem[] = [
  { value: 'new', label: 'Новые' },
  { value: 'lower-price', label: 'По возрастанию цены' },
  { value: 'higher-price', label: 'По убыванию цены' },
  { value: 'higher-volume', label: 'С большим объемом' },
  { value: 'lower-volume', label: 'С меньшим объемом' },
];
