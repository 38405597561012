import React, { useState } from 'react';
import styles from './GSMCardPageCard.module.scss';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { WalletGSMSvg } from 'src/components/UI/svg-icon/WalletGSMSvg';
import { formCardNumber } from 'src/common/formCardNumber.helper';
import { ModalNew } from 'src/components/ModalNew/ModalNew';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import { useMediaQuery } from 'react-responsive';
import { Switch } from 'antd';

export const GSMCardPageCard = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const onChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <span className={styles.cardNumber}>{formCardNumber('3455456277103507')}</span>
        <div className={styles.activeContainer}>
          <p className={styles.activeText}>Активна</p>
          <Switch className={styles.switcher} checked={isChecked} onChange={onChange} />
        </div>
      </div>
      <div className={styles.priceAndCardHolderContainer}>
        <div className={styles.priceContainer}>
          <div style={{ paddingTop: '4px' }}>
            <WalletGSMSvg />
          </div>
          <span className={styles.price}>{`0 ₽`}</span>
        </div>
        <div className={styles.cardHolderContainer}>
          <p className={styles.cardHolderTitle}>Владелец</p>
          <p className={styles.cardHolderName}>Перевозчиков Перевоз Перевозович</p>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <ButtonNotFilled text="Пополнить" height={'40px'} width={'100%'} onClick={() => {}} />
        <ButtonFilled text="Заправиться" height={'40px'} width={'100%'} onClick={() => setOpenModal(true)} />
      </div>
      {isMobile && <div className={styles.closeCardButton}>Закрыть карту</div>}
      {openModal && (
        <ModalNew
          openModal={openModal}
          setOpenModal={setOpenModal}
          children={
            <div className={styles.modalContainer}>
              <div className={styles.modalTitle}>Подтвердите переход</div>
              <div className={styles.modalSubtitle}>
                Нажимая кнопку “Продолжить” вы подтверждаете переход в другой сервис
              </div>
              <div className={styles.modalButtonsContainer}>
                <ButtonNotFilled
                  text="Отмена"
                  sizeText={isMobile ? 'small' : 'big'}
                  onClick={() => setOpenModal(false)}
                />
                <ButtonFilled text="Продолжить" sizeText={isMobile ? 'small' : 'big'} />
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};
