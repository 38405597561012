// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowcaseDescriptionButtons_buttonsContainer__IW7Q- {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n}\n\n@media (max-width: 768px) {\n  .ShowcaseDescriptionButtons_buttonsContainer__IW7Q- {\n    flex-direction: column;\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/ShowcasesPage/components/ShowcaseDescription/ShowcaseDescriptionButtons/ShowcaseDescriptionButtons.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE;IACE,sBAAA;IACA,WAAA;EACF;AACF","sourcesContent":[".buttonsContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n}\n\n@media (max-width: 768px) {\n  .buttonsContainer {\n    flex-direction: column;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": "ShowcaseDescriptionButtons_buttonsContainer__IW7Q-"
};
export default ___CSS_LOADER_EXPORT___;
