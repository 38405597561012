import { Button, Form, Input, Modal, Select, Space } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import styles from './AddPlanModal.module.scss';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import UnionRefuseSvg from '../../../../components/UI/svg-icon/UnionRefuseSvg';
import { fetchPost } from '../../../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import DisplaySumVolume from '../DisplaySumVolume/DisplaySumVolume';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';
import { useMediaQuery } from 'react-responsive';
import { IItemData, ISelect } from '../../types';
import VolumePerFlight from '../VolumePerFlight/VolumePerFlight';
import { toastError } from 'src/common/toastError.helper';

interface IProps {
  infoData: IItemData;
  openAddPlan: boolean;
  setOpenAddPlan: Dispatch<SetStateAction<boolean>>;
  getData: any;
  driversList: ISelect[];
  carsList: ISelect[];
}
const AddPlanModal = ({ setOpenAddPlan, openAddPlan, infoData, getData, carsList, driversList }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [form] = Form.useForm();
  const [volumeSum, setVolumeSum] = useState<any>([]);
  const [focusSelect, setFocusSelect] = useState<boolean>();
  useEffect(() => {
    const rooDiv = document.getElementById('root');
    if (openAddPlan) {
      rooDiv.style.overflow = 'hidden';
      rooDiv.style.height = '100vh';
      rooDiv.style.position = 'relative';
    } else {
      rooDiv.style.overflow = 'unset';
    }
  }, [openAddPlan]);

  const refuseAddPlan = () => {
    setOpenAddPlan(false);
  };
  const onFinish = async (values: any) => {
    try {
      const postEditPlanning = await fetchPost('/dispatching/add-flights-to-plan', 'POST', {
        flightDetails: values.users.map(item => {
          return {
            ...item,
            flightPlanVolume: Number(item?.flightPlanVolume),
            flightsNumber: Number(item?.flightsNumber),
          };
        }),
        carrierOrderId: infoData?.id,
      });

      if (toastError(postEditPlanning)) {
        setOpenAddPlan(false);
        return;
      }
      setOpenAddPlan(false);
      getData();
      toast.success('Вы успешно добавили планирование');
    } catch (e) {
      setOpenAddPlan(false);
      console.log(e);
    }
  };
  return (
    <>
      {isMobile ? (
        /*-------------Мобильная версия-----------------------------*/
        <div className={openAddPlan ? 'DriverPagePopup active' : 'DriverPagePopup'}>
          <div onClick={() => refuseAddPlan()} className="DriverPagePopup__white"></div>
          <div className="DriverPagePopup__line"></div>
          <div className="DriverPagePopup__wrapper" style={{ height: '100%', top: '80px' }}>
            <div className={styles.mobileContainerDrawer}>
              <div className={styles.mobileTitleAndSubtitleContainer}>
                <div className={styles.drawerTriangleContainer}>
                  <div className={styles.drawerTriangle} />
                </div>
                <div className={styles.mobileIconAndTitleContainer}>
                  <div className={styles.mobileTitle}>Добавить планирование</div>
                  <div className={styles.mobileSubtitle}>
                    Распределение объема по дате, ТС, количеству рейсов и назначение водителя на заявку
                  </div>
                </div>
                <div className={styles.mobileCloseIconDrawer} onClick={refuseAddPlan}>
                  <div className={styles.mobileCloseIconBlockDrawer}>
                    <CloseSvg />
                  </div>
                </div>
              </div>
              <Form
                onFieldsChange={() => {
                  const data = form.getFieldsValue();
                  setVolumeSum(data.users);
                }}
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                autoComplete="off"
                form={form}
                style={{ maxWidth: '100%' }}
              >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <div className={styles.mobileTableContainer}>
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <div key={key} style={{ marginBottom: `12px` }} className={styles.mobileTableContent}>
                            <Form.Item style={{ marginBottom: '3px', maxWidth: '100%' }}>
                              <div className={styles.mobileTextContainer}>
                                <div>
                                  <p className={styles.mobileTextTitleOfInput}>Водитель:</p>
                                </div>
                                <div className={styles.mobileCloseIcon} onClick={() => remove(name)}>
                                  <UnionRefuseSvg />
                                </div>
                              </div>
                            </Form.Item>
                            <Form.Item
                              style={{ marginBottom: '12px' }}
                              {...restField}
                              name={[name, 'driverId']}
                              rules={[{ required: true, message: '' }]}
                            >
                              <Select
                                onFocus={() => setFocusSelect(true)}
                                placeholder={'Водитель'}
                                style={{ width: '100%' }}
                                options={driversList}
                              />
                            </Form.Item>
                            <p className={styles.mobileTextTitleOfInput}>Тс:</p>
                            <Form.Item
                              style={{ marginBottom: '12px' }}
                              {...restField}
                              name={[name, 'carId']}
                              rules={[{ required: true, message: '' }]}
                            >
                              <Select placeholder={'ТС'} style={{ width: '100%' }} options={carsList} />
                            </Form.Item>
                            <p className={styles.mobileTextTitleOfInput}>Объем материала:</p>
                            <VolumePerFlight infoData={infoData} data={volumeSum} name={name} carsList={carsList} />
                            <p className={styles.mobileTextTitleOfInput}>Кол-во рейсов:</p>
                            <Form.Item
                              style={{ marginBottom: '12px' }}
                              {...restField}
                              name={[name, 'flightsNumber']}
                              rules={[{ required: true, message: '' }]}
                            >
                              <Input placeholder="Кол-во рейсов" className={styles.mobileInput} />
                            </Form.Item>
                            <p className={styles.mobileTextTitleOfInput}>Общий объем</p>
                            <DisplaySumVolume carsList={carsList} data={volumeSum} name={name} infoData={infoData} />
                          </div>
                        );
                      })}
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined rev={undefined} />}
                        className={styles.addPlanButton}
                      >
                        {fields.length ? 'Добавить еще планирование' : 'Добавить планирование'}
                      </Button>
                    </div>
                  )}
                </Form.List>
                <Form.Item>
                  <div className={styles.mobileBottomButtons}>
                    <ButtonFilled
                      disabled={volumeSum?.length < 1}
                      width={`100%`}
                      text={'Сохранить'}
                      htmlType={'submit'}
                    />
                    <ButtonNotFilled width={`100%`} onClick={refuseAddPlan} text={'Отмена'} />
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        /*-------------ПК версия-----------------------------*/
        <Modal
          zIndex={9500}
          style={{ minWidth: '1080px' }}
          width={1090}
          footer={null}
          closeIcon={false}
          open={openAddPlan}
          onCancel={refuseAddPlan}
          className={styles.modal}
        >
          <div className={styles.titleAndSubtitleContainer}>
            <div className={styles.title}>Добавить планирование</div>
            <div className={styles.subtitle}>
              Распределение объема по дате, ТС, количеству рейсов и назначение водителя на заявку
            </div>
          </div>
          <Form
            onFieldsChange={() => {
              const data = form.getFieldsValue();
              setVolumeSum(data.users);
            }}
            name="dynamic_form_nest_item"
            onFinish={onFinish}
            style={{ width: 1042 }}
            autoComplete="off"
            form={form}
          >
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <div className={styles.tableContainer}>
                  <div className={styles.mainNamesOfTableContainer}>
                    <p style={{ marginRight: '227px' }}>Водитель</p>
                    <p style={{ marginRight: '183px' }}>Назначенное ТС</p>
                    <p style={{ marginRight: '58px' }}>Объем на рейс</p>
                    <p style={{ marginRight: '58px' }}>Кол-во рейсов</p>
                    <p>Общий объем</p>
                  </div>
                  {fields.map(({ key, name, ...restField }) => {
                    return (
                      <Space key={key} className={styles.tableContent}>
                        <Form.Item
                          style={{ marginBottom: '0px' }}
                          {...restField}
                          name={[name, 'driverId']}
                          rules={[{ required: true, message: '' }]}
                        >
                          <Select placeholder={'Водитель'} style={{ width: '256px' }} options={driversList} />
                        </Form.Item>
                        <Form.Item
                          style={{ marginBottom: '0px' }}
                          {...restField}
                          name={[name, 'carId']}
                          rules={[{ required: true, message: '' }]}
                        >
                          <Select placeholder={'ТС'} style={{ width: '256px' }} options={carsList} />
                        </Form.Item>
                        <VolumePerFlight infoData={infoData} data={volumeSum} name={name} carsList={carsList} />
                        <Form.Item
                          style={{ marginBottom: '0px' }}
                          {...restField}
                          name={[name, 'flightsNumber']}
                          rules={[{ required: true, message: '' }]}
                        >
                          <Input placeholder="Кол-во рейсов" className={styles.modalInput} />
                        </Form.Item>
                        <DisplaySumVolume data={volumeSum} name={name} infoData={infoData} carsList={carsList} />
                        <Form.Item className={styles.modalRemoveItem}>
                          <div style={{ width: '24px', fontSize: '16px' }} onClick={() => remove(name)}>
                            <UnionRefuseSvg />
                          </div>
                        </Form.Item>
                      </Space>
                    );
                  })}
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined rev={undefined} />}
                    className={styles.addPlanButton}
                  >
                    {fields.length ? 'Добавить еще планирование' : 'Добавить планирование'}
                  </Button>
                </div>
              )}
            </Form.List>
            <Form.Item>
              <div className={styles.bottomButtons}>
                <ButtonNotFilled onClick={refuseAddPlan} text={'Отмена'} />
                <ButtonFilled disabled={volumeSum?.length < 1} text={'Сохранить'} htmlType={'submit'} />
              </div>
            </Form.Item>
          </Form>
          <div className={styles.closeButton}>
            <FrameModalSvg onClick={refuseAddPlan} />
          </div>
        </Modal>
      )}
    </>
  );
};
export default AddPlanModal;
