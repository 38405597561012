// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PriceFilterInput_wrapper__LLG5J {\n  height: 40px !important;\n}\n\n.PriceFilterInput_inputText__MSwA6 {\n  height: 40px !important;\n  display: flex !important;\n}\n\n.PriceFilterInput_inputForm__OL5DZ {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 0 16px !important;\n  font-size: 14px !important;\n  line-height: 20px !important;\n  font-weight: 400 !important;\n}\n\n.PriceFilterInput_inputForm__OL5DZ::placeholder {\n  color: var(--color-gray400) !important;\n}", "",{"version":3,"sources":["webpack://./src/Pages/Catalog/components/PriceFilterInput/PriceFilterInput.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,uBAAA;EACA,wBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,0BAAA;EACA,0BAAA;EACA,4BAAA;EACA,2BAAA;AACF;;AAEA;EACE,sCAAA;AACF","sourcesContent":[".wrapper {\n  height: 40px !important;\n}\n\n.inputText {\n  height: 40px !important;\n  display: flex !important;\n}\n\n.inputForm {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 0 16px !important;\n  font-size: 14px !important;\n  line-height: 20px !important;\n  font-weight: 400 !important;\n}\n\n.inputForm::placeholder {\n  color: var(--color-gray400) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PriceFilterInput_wrapper__LLG5J",
	"inputText": "PriceFilterInput_inputText__MSwA6",
	"inputForm": "PriceFilterInput_inputForm__OL5DZ"
};
export default ___CSS_LOADER_EXPORT___;
