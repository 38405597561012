// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CalendarArrows_container__k\\+0\\+T {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 24px;\n  height: 24px;\n  border: 1px solid var(--color-gray200);\n  border-radius: 24px;\n  background: white;\n  color: var(--color-gray600);\n}", "",{"version":3,"sources":["webpack://./src/Pages/GSMNewPage/components/CalendarArrows/CalendarArrows.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,sCAAA;EACA,mBAAA;EACA,iBAAA;EACA,2BAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 24px;\n  height: 24px;\n  border: 1px solid var(--color-gray200);\n  border-radius: 24px;\n  background: white;\n  color: var(--color-gray600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CalendarArrows_container__k+0+T"
};
export default ___CSS_LOADER_EXPORT___;
