import React from 'react';

export const SortArrowsSvg = ({ width = '24px', color = '#1C274C' }: { width?: string; color?: string }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 18L16 6M16 6L20 10.125M16 6L12 10.125"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 6L8 18M8 18L12 13.875M8 18L4 13.875"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
