import { FavoriteSvg } from '../../components/UI/svg-icon/FavoriteSvg';
import CompareSvg from '../../components/UI/svg-icon/CompareSvg';
import { ShareSvg } from '../../components/UI/svg-icon/ShareSvg';
import React from 'react';
import { FavoriteShowcasesSvg } from '../../components/UI/svg-icon/FavoriteShowcasesSvg';
import { formVolume } from '../DispatchingPages/Constans/Constans';
import { ICurrentMaterialData, IOrderInputs } from './type';
import { formPrice } from '../../common/form-price.helper';

export const headerButtonsArray = (favorite, compare) => {
  return [
    {
      text: 'В избранное',
      svg: favorite ? <FavoriteShowcasesSvg color="currentColor" width={'20px'} /> : <FavoriteSvg width={'20px'} />,
    },
    { text: compare ? 'В сравнении' : 'Сравнить', svg: <CompareSvg active={compare} width={'20px'} /> },
    { text: 'Поделиться', svg: <ShareSvg width={'20px'} /> },
  ];
};
export const InitStateCurrentMaterialData = {
  breed: '',
  sellUnit: '',
  description: '',
  mainProperty: '',
  id: null,
  kind: '',
  minimalPricePerCubicMeter: null,
  minimalPricePerTon: null,
  otherProducts: [{ fraction: '', id: null }],
  properties: [{ title: '', value: '' }],
  showcase: { title: '', id: 0 },
  volume: null,
  volumeInOtherUnit: null,
  selectedUnit: '',
};
export const translateMainInfoToRussianFromBackend = {
  filtrationCoefficient: 'Коэффициент фильтрации',
  class: 'Класс',
  type: 'Тип',
  hghContent: 'Содержание ПГЧ, %',
  sandPercent: '% Песка',
  gravelPercent: '% Гравия',
  coarseness: 'Модуль крупности',
  group: 'Группа песка',
  kind: 'Вид',
  category: 'Категория',
  sparseness: 'Лещадность, %',
  frostResistance: 'Морозостойкость',
  fraction: 'Фракция',
  mark: 'Прочность',
  bulkCoefficient: 'Насыпная плотность, т/м3',
  rest: 'Объем',
  price: 'Цена',
};
export const initStateUnitMeasurement = [
  { title: 'тн', value: 'тонн', active: false },
  { title: 'м³', value: 'м³', active: false },
];
export const minimalPriceByUnit = (unit: string, data: ICurrentMaterialData) => {
  const obj = {
    тонн: Number(data.minimalPricePerTon),
    'м³': Number(data.minimalPricePerCubicMeter),
  };
  return obj[unit];
};
export const infoArrayOrderCard = (dataCard: ICurrentMaterialData) => [
  { title: 'Наименование:', value: `${dataCard?.breed} ${dataCard?.kind ? dataCard?.kind : ''}` },
  { title: 'Фракция:', value: dataCard?.mainProperty },
  { title: 'Карьер:', value: dataCard?.showcase.title },
  {
    title: 'Объем:',
    value: `${formVolume(
      dataCard?.sellUnit === dataCard?.selectedUnit ? dataCard?.volume : dataCard?.volumeInOtherUnit,
    )} ${sellUnitView[dataCard?.selectedUnit]}`,
  },
  {
    title: 'Цена:',
    value: `от ${formPrice(minimalPriceByUnit(dataCard.selectedUnit, dataCard), 'RUB', 'ru')}/${sellUnitView[dataCard?.selectedUnit]}`,
  },
];
export const cartOrder = (dataOrder: ICurrentMaterialData, orderInputs: IOrderInputs) => [
  {
    title: `${dataOrder?.breed} ${dataOrder?.kind ? dataOrder?.kind : ''}:`,
    value: `${formPrice(minimalPriceByUnit(dataOrder.selectedUnit, dataOrder), 'RUB', 'ru')}`,
  },
  {
    title: 'Итоговая цена',
    value: `${formPrice(minimalPriceByUnit(dataOrder?.selectedUnit, dataOrder) * Number(orderInputs?.volume.replaceAll(',', '.')), 'RUB', 'ru')}`,
  },
];
export const initStateOrderInputs = {
  payment: '',
  comment: '',
  volume: '',
  objectId: 0,
  address: '',
  delivery: {
    startDate: '',
    finishDate: '',
    dayVolume: '',
    daysOfWeek: [],
  },
};
export const optionsPayment = [
  { value: 'Солбер Кошелек', label: 'Солбер Кошелек' },
  { value: 'Расчетный счет', label: 'Расчетный счет' },
  { value: 'Кредитный лимит ', label: 'Кредитный лимит ' },
];
export const optionsDays = [
  { value: 'Пн', label: 'Пн' },
  { value: 'Вт', label: 'Вт' },
  { value: 'Ср', label: 'Ср' },
  { value: 'Чт', label: 'Чт' },
  { value: 'Пт', label: 'Пт' },
  { value: 'Сб', label: 'Сб' },
  { value: 'Вс', label: 'Вс' },
];
export const convertTextFromFraction = {
  C1: 'самая крупная фракция 40 мм',
  C2: 'самая крупная фракция 20 мм',
  C3: 'размер зерен 120 мм и меньше',
  C4: 'самая крупная фракция 80мм',
  C5: 'самая крупная фракция 40мм',
  C6: 'самая крупная фракция 20 мм',
  C7: 'самая крупная фракция 10 мм',
  C8: 'самая крупная фракция 5 мм',
  C9: 'самая крупная фракция 80 мм',
  C10: 'самая крупная фракция 40 мм',
  C11: 'самая крупная фракция 20 мм',
};
export const sellUnitView = {
  тонн: 'тн',
  'м³': 'м³',
};
export const priceByUnit = (currentMaterialData: ICurrentMaterialData) => {
  return {
    тн: currentMaterialData?.minimalPricePerTon,
    'м³': currentMaterialData?.minimalPricePerCubicMeter,
  };
};
export const initStateShareInfo = {
  logoImage: '',
  carrierName: '',
  link: '',
};
