import styles from './AddBlockCard.module.scss';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';
import React, { useEffect, useState } from 'react';
import { CloseCircleNewSvg } from 'src/components/UI/svg-icon/CloseCircleNewSvg';
import { addBlockCardTitles, hoursArray, initInputsData, typesOfInput } from '../../constants';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { IFormattedSelectorsAdminPage, ISelectValue, TInputInfo } from '../../types';
import CustomDatePicker from 'src/components/newUI/CustomDatePicker/CustomDatePicker';
import dayjs from 'dayjs';
import { clearObject } from 'src/common/ClearObject.helper';
import { fetchGet, fetchPost } from 'src/common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import { toastError } from '../../../../../common/toastError.helper';
import { convertTimeAndDateToBackend } from '../../utils';

interface IChangeInputsProps {
  name: string;
  open?: boolean;
  changeOpen?: boolean;
  time?: string;
  date?: string;
  selectValue?: ISelectValue;
}
export const AddBlockCard = () => {
  const [inputsInfo, setInputsInfo] = useState<TInputInfo[]>(initInputsData);
  const [sendBlock, setSendBlock] = useState<boolean>(false);
  const datePlaceholder = dayjs(new Date()).format('DD.MM.YYYY').toString();
  const addBlockButtonDisable: boolean = !!(
    sendBlock ||
    !inputsInfo[0].value ||
    (inputsInfo[2].value !== 'all' && !inputsInfo[3].value) ||
    !inputsInfo[4].date ||
    !inputsInfo[5].date
  );
  const selectDisable = (input: TInputInfo): boolean => {
    return inputsInfo[2].value === 'all' && input.name === 'object';
  };
  const changeInputs = ({
    name,
    changeOpen = false,
    open = false,
    time = '',
    date = '',
    selectValue = undefined,
  }: IChangeInputsProps) => {
    setInputsInfo(prevState =>
      prevState.map(item => {
        if (item?.name === name) {
          return clearObject({
            ...item,
            value: selectValue || item?.value || undefined,
            time: time || item?.time || undefined,
            open: changeOpen && open,
            date: date || item?.date || undefined,
          });
        }
        return item;
      }),
    );
  };
  const customTimePickerButton = (name: string) => {
    return (
      <ButtonFilled
        text="Готово"
        onClick={() => changeInputs({ name, changeOpen: true, open: false })}
        width="100%"
        sizeText="small"
      />
    );
  };
  const changeFieldOfSelector = (
    nameObject: string,
    fieldOfObject: 'options' | 'value' | 'time',
    valueField: string | ISelectValue[],
    clearValue: boolean,
  ) => {
    setInputsInfo(prevInputsInfo =>
      prevInputsInfo.map(input => {
        if (input.name === nameObject && !clearValue) {
          return { ...input, [fieldOfObject]: valueField as string };
        }
        if (input.name === nameObject && clearValue) {
          return { ...input, value: '', options: valueField as ISelectValue[] };
        }
        return input;
      }),
    );
  };
  const getDataQuarry = async () => {
    try {
      const responseQuarries = await fetchGet('/admin/quarries/list', {});
      const formattedQuarriesArray: IFormattedSelectorsAdminPage[] = responseQuarries.map(quarry => ({
        value: String(quarry.id),
        label: quarry.title,
      }));
      changeFieldOfSelector('quarry', 'options', formattedQuarriesArray, false);
    } catch (e) {
      console.log(e);
    }
  };
  const getDataReasonsOfBlock = async () => {
    try {
      const responseReasonsOfBlock = await fetchGet('/admin/quarries/block-reasons', {});
      const formattedReasonsOfBlockArray: IFormattedSelectorsAdminPage[] = responseReasonsOfBlock.reasons.map(
        reason => ({
          value: String(reason.id),
          label: reason.description,
        }),
      );
      changeFieldOfSelector('reason', 'options', formattedReasonsOfBlockArray, false);
    } catch (e) {
      console.log(e);
    }
  };

  const getDataBlockedFor = async () => {
    let responseBlockedFor;
    try {
      if (inputsInfo[2].value === 'object') {
        responseBlockedFor = await fetchGet('/admin/objects/list', {});
      }
      if (inputsInfo[2].value !== 'object') {
        responseBlockedFor = await fetchGet('/admin/companies/list', { type: inputsInfo[2].value });
      }
      const formattedBlockedForArray: IFormattedSelectorsAdminPage[] = responseBlockedFor?.map(object => ({
        value: String(object.id),
        label: object.title,
      }));
      changeFieldOfSelector('object', 'options', formattedBlockedForArray, true);
    } catch (e) {
      console.log(e);
    }
  };
  const getDataMaterialsSelectors = async () => {
    try {
      const responseMaterials = await fetchGet('/admin/quarries/list/materials', {
        quarryId: inputsInfo[0].value,
      });
      const formattedMaterialsArray: IFormattedSelectorsAdminPage[] = responseMaterials?.map(material => ({
        value: String(material.id),
        label: material.title,
      }));
      changeFieldOfSelector('material', 'options', formattedMaterialsArray, true);
    } catch (e) {
      console.log(e);
    }
  };

  const postDataQuarry = async () => {
    const requestOption = {
      quarryId: Number(inputsInfo[0].value),
      materialId: inputsInfo[1].value ? Number(inputsInfo[1].value) : undefined,
      blockType: inputsInfo[2].value,
      blockTypeId: inputsInfo[3].value ? Number(inputsInfo[3].value) : undefined,
      blockFrom: convertTimeAndDateToBackend(inputsInfo[4].date, inputsInfo[4].time),
      blockTo: convertTimeAndDateToBackend(inputsInfo[5].date, inputsInfo[5].time),
      blockReasonId: inputsInfo[6].value ? Number(inputsInfo[6].value) : undefined,
    };
    setSendBlock(true);
    try {
      const responseQuarry = await fetchPost('/admin/quarries/block', 'POST', clearObject(requestOption));
      if (toastError(responseQuarry)) return;
      setInputsInfo(initInputsData);
      getDataReasonsOfBlock();
      getDataQuarry();
      toast.success(`Карьер успешно заблокирован`);
    } catch (e) {
      console.log(e);
    } finally {
      setSendBlock(false);
    }
  };
  useEffect(() => {
    getDataReasonsOfBlock();
    getDataQuarry();
  }, []);
  useEffect(() => {
    inputsInfo[0].value && getDataMaterialsSelectors();
  }, [inputsInfo[0].value]);
  useEffect(() => {
    inputsInfo[2].value !== 'all' ? getDataBlockedFor() : changeFieldOfSelector('object', 'options', [], false);
  }, [inputsInfo[2].value]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.mainTitle}>Добавление блокировки для карьера</div>
        <ButtonSimple
          text="Сбросить"
          svg={<CloseCircleNewSvg color={'currentColor'} />}
          onClick={() => {
            setInputsInfo(initInputsData);
            getDataReasonsOfBlock();
            getDataQuarry();
          }}
        />
      </div>
      <div className={styles.inputsContainer}>
        {inputsInfo.map((input, index) => {
          const isDateFrom = input?.name === 'dateFrom';
          const timePlaceholder = isDateFrom ? '00:00' : '23:59';
          const timeName = isDateFrom ? 'dateFrom' : 'dateTo';
          return (
            <div className={styles.inputContainer} key={index}>
              <div className={styles.title}>{addBlockCardTitles[index]}</div>
              {input?.type === typesOfInput.SELECT ? (
                <SelectLk
                  disabled={selectDisable(input)}
                  isSearch={input.isSearch}
                  heightSelectWindow={'195px'}
                  options={input.options}
                  value={input?.value}
                  placeholder={input.placeholder}
                  setValue={value => {
                    changeFieldOfSelector(input.name, 'value', value, false);
                  }}
                />
              ) : (
                <div className={styles.datetime}>
                  <CustomDatePicker
                    value={input?.date ? dayjs(input?.date, 'DD.MM.YYYY') : null}
                    setValue={date =>
                      changeInputs({
                        name: input?.name,
                        date: date?.format('DD.MM.YYYY').toString(),
                      })
                    }
                    width="160px"
                    height="48px"
                    paddingRight="16px"
                    paddingLeft="4px"
                    placeholder={datePlaceholder}
                  />
                  <SelectLk
                    heightSelectWindow={'195px'}
                    value={input.time}
                    setValue={value => {
                      changeFieldOfSelector(input.name, 'time', value, false);
                    }}
                    placeholder={'00:00'}
                    width={'119px'}
                    options={hoursArray()}
                  />
                </div>
              )}
            </div>
          );
        })}
        <ButtonFilled
          text="Добавить"
          width="289px"
          disabled={addBlockButtonDisable}
          onClick={() => {
            postDataQuarry();
          }}
        />
      </div>
    </div>
  );
};
