import React from 'react';
import styles from './GSMNewPage.module.scss';
import { PageWrapper } from 'src/components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import { GSMNewCard } from './GSMNewCard/GSMNewCard';
import { CalendarSvg } from 'src/components/UI/svg-icon/CalendarSvg';
import { GSMOperationsHistoryTable } from './GSMOperationsHistoryTable/GSMOperationsHistoryTable';
import { mockGSMOperationsHistoryTable } from './GSMconstants';
import { GSMStatisticsCard } from './GSMStatisticsCard/GSMStatisticsCard';

export const GSMNewPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <PageWrapper style={{ backgroundColor: `${isMobile ? 'white' : 'var(--color-gray50)'}` }}>
      <div className={styles.container}>
        <div className={styles.titleAndSubtitleContainer}>
          <span className={styles.title}>ГСМ</span>
          <span className={styles.subtitle}>Ваши топливные карты и детализация по ним</span>
        </div>
        <div className={styles.priceAndPlotContainer}>
          <GSMNewCard />
          <GSMStatisticsCard />
        </div>
        <div className={styles.operationsHistoryContainer}>
          <div className={styles.operationsHistoryHeader}>
            <span className={styles.subheader}>История операций</span>
            <div className={styles.calendarContainer}>
              <span className={styles.calendarText}>Октябрь</span>
              <CalendarSvg width={isMobile ? '16px' : '20px'} />
            </div>
          </div>
          <GSMOperationsHistoryTable operations={mockGSMOperationsHistoryTable} />
        </div>
      </div>
    </PageWrapper>
  );
};
