import React, { useEffect, useState } from 'react';
import styles from './DistributionAndSubmitRequest.module.scss';
import UnionSvg from '../../../../components/UI/svg-icon/UnionSvg';
import { Drawer, Input, Modal } from 'antd';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import UnionRefuseSvg from '../../../../components/UI/svg-icon/UnionRefuseSvg';
import { toast } from 'react-toastify';
import { fetchPost } from '../../../../common/proxy-1C-fetch-auth';
import { CustomSelect } from 'src/components/UI/select/CustomSelect';
import { reasonList } from './constans';
import { formVolume } from '../../Constans/Constans';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import AlertSvg from '../../../../components/UI/svg-icon/AlertSvg';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';
import { IItemPageValueReason } from '../../types';
import { toastError } from 'src/common/toastError.helper';
const DistributionAndSubmitRequest = ({ data, getData }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [valueReason, setValueReason] = useState<IItemPageValueReason>({ value: '', label: '' });
  const [commentValue, setCommentValue] = useState<string>('');
  const [isRefuseModalOpen, setIsRefuseModalOpen] = useState(false);
  const [isAgreeModalOpen, setIsAgreeModalOpen] = useState(false);
  const [openAgreeDrawer, setOpenAgreeDrawer] = useState(false);
  const [openRefuseDrawer, setOpenRefuseDrawer] = useState(false);
  const [focusedSelectDrawer, setFocusedSelectDrawer] = useState(false);
  useEffect(() => {
    const rooDiv = document.getElementById('root');
    if (openRefuseDrawer || openAgreeDrawer) {
      rooDiv.style.overflow = 'hidden';
      rooDiv.style.height = '100vh';
      rooDiv.style.position = 'relative';
    } else {
      rooDiv.style.overflow = 'unset';
    }
  }, [openAgreeDrawer, openRefuseDrawer]);
  const sendRejection = async () => {
    try {
      const response = await fetchPost(`/dispatching/reject-the-application/${data?.id}`, 'PATCH', {
        comment: valueReason.value === 'Другая проблема (введите комментарий)' ? commentValue : valueReason.value,
      });
      if (toastError(response)) {
        setOpenRefuseDrawer(false);
        setIsRefuseModalOpen(false);
        return;
      }
      setOpenRefuseDrawer(false);
      setIsRefuseModalOpen(false);
      toast.success('Вы успешно отказались от заявки');
      getData();
    } catch (e) {
      console.log(e);
    }
  };
  const sendAgree = async () => {
    try {
      const response = await fetchPost(`/dispatching/confirm/${data?.id}`, 'PATCH', {});
      if (toastError(response)) {
        setOpenAgreeDrawer(false);
        setIsAgreeModalOpen(false);
        return;
      }
      setOpenAgreeDrawer(false);
      setIsAgreeModalOpen(false);
      toast.success('Вы успешно подтвердили заявку');
      getData();
    } catch (e) {
      console.log(e);
    }
  };
  const onMenuClose = () => setFocusedSelectDrawer(false);
  const onMenuOpen = () => setFocusedSelectDrawer(true);
  const openRefuseModal = () => {
    setIsRefuseModalOpen(true);
  };
  const closeRefuseModal = () => {
    setIsRefuseModalOpen(false);
  };
  const openAgreeModal = () => {
    setIsAgreeModalOpen(true);
  };
  const closeAgreeModal = () => {
    setIsAgreeModalOpen(false);
  };
  const showAgreeDrawer = () => {
    setOpenAgreeDrawer(true);
  };

  const onAgreeCloseDrawer = () => {
    setOpenAgreeDrawer(false);
  };
  const showRefuseDrawer = () => {
    setOpenRefuseDrawer(true);
  };

  const onRefuseCloseDrawer = () => {
    setOpenRefuseDrawer(false);
  };

  const correctVisionOfUnit = (unit: string): string => {
    if (unit === 'тонн') {
      return 'тн';
    } else {
      return 'м³';
    }
  };
  const validationRefuseButton = (): boolean => {
    if (valueReason?.value === 'Другая проблема (введите комментарий)' && commentValue) {
      return false;
    } else return !(valueReason?.value && valueReason?.value !== 'Другая проблема (введите комментарий)');
  };
  const changeHeightDrawer = (): number => {
    if (valueReason?.value === 'Другая проблема (введите комментарий)' && !focusedSelectDrawer) {
      return 448;
    } else if (focusedSelectDrawer) {
      return 680;
    } else {
      return 380;
    }
  };
  useEffect(() => {
    setCommentValue('');
  }, [valueReason]);
  return (
    <div className={styles.container} onClick={e => e.stopPropagation()}>
      <div className={styles.valueContainer}>
        <p className={styles.textFrom}>
          {data?.distributedVolume === null ? '0' : formVolume(data?.distributedVolume)}
        </p>
        <p className={styles.textTo}>/</p>
        <p className={styles.textTo}>{formVolume(data?.volume)}</p>
        <p className={styles.textUnit}>{correctVisionOfUnit(data?.unit)}</p>
      </div>
      {isMobile && (
        <div className={data?.status === 'Новая' && !data?.rejectComment && styles.mobileContentOfNomenclature}>
          <p className={styles.mobileTextContentOfNomenclature}>{data?.content}</p>
        </div>
      )}
      {data?.status === 'Новая' && !data?.rejectComment && (
        <div className={styles.clickableIconContainer}>
          <div className={styles.blockIconContainer} onClick={isMobile ? showAgreeDrawer : openAgreeModal}>
            {isMobile ? (
              <>
                <div className={styles.mobileIcon}>
                  <UnionSvg />
                </div>
                <span className={styles.mobileTextIcon}>Принять</span>
              </>
            ) : (
              <UnionSvg />
            )}
          </div>
          <div className={styles.blockIconContainer} onClick={isMobile ? showRefuseDrawer : openRefuseModal}>
            {isMobile ? (
              <>
                <div className={styles.mobileIcon}>
                  <UnionRefuseSvg />
                </div>
                <span className={styles.mobileTextIcon}>Отклонить</span>
              </>
            ) : (
              <UnionRefuseSvg />
            )}
          </div>
        </div>
      )}
      {!isMobile && (
        <>
          {data?.rejectComment && data?.status === 'Отклонена' && (
            <CustomTooltip
              SideTooltip={'left'}
              svg={<AlertSvg />}
              text={data?.rejectComment}
              widthTooltip={`250px`}
              widthSvg={'24px'}
            />
          )}
        </>
      )}

      {isMobile ? (
        /*-----------------------------МОБИЛ. DRAWER----------------------------------------*/
        <>
          <Drawer
            onClose={onRefuseCloseDrawer}
            open={openRefuseDrawer}
            placement={`bottom`}
            closeIcon={false}
            zIndex={9500}
            height={changeHeightDrawer()}
            style={{ borderRadius: '12px 12px 0px 0px' }}
          >
            <div className={styles.mobileContainerDrawer}>
              <div className={styles.drawerTriangleContainer}>
                <div className={styles.drawerTriangle} />
              </div>
              <div className={styles.mobileCloseIconContainerDrawer}>
                <div className={styles.mobileCloseIconDrawer} onClick={onRefuseCloseDrawer}>
                  <CloseSvg />
                </div>
              </div>
              <div className={styles.mobileTitleAndSubtitleTextDrawerContainer}>
                <div className={styles.mobileTitleTextDrawer}>Отказаться от заявки</div>
                <div className={styles.mobileSubTitleTextDrawer}>
                  Выберите причину, почему вы хотите отказаться от заказа&nbsp;
                  <span className={styles.mobileOrderTextDrawer}>{data?.orderNumber}?</span>
                </div>
              </div>
              <div className={styles.mobileSelectDrawerContainer}>
                <CustomSelect
                  minMenuHeight={300}
                  menuShouldScrollIntoView={false}
                  onMenuClose={onMenuClose}
                  onMenuOpen={onMenuOpen}
                  error={false}
                  value={valueReason}
                  options={reasonList}
                  placeholder={'Выберите из списка'}
                  noOptionsMessage={'Нет вариантов'}
                  setValue={value => setValueReason(value)}
                  isSearchable={false}
                  isClearable={false}
                />
                {valueReason.value === 'Другая проблема (введите комментарий)' && (
                  <Input
                    placeholder={'Напишите логичную причину для отказа в этом поле...'}
                    onChange={e => setCommentValue(e.target.value)}
                    disabled={false}
                    style={{ width: `100%`, height: `56px`, marginTop: '8px' }}
                  />
                )}
              </div>
              <div className={styles.mobileButtonsDrawer}>
                <ButtonFilled
                  text={'Да, хочу отказаться'}
                  width={'100%'}
                  onClick={sendRejection}
                  disabled={validationRefuseButton()}
                />
                <ButtonNotFilled text={'Нет, вернуться'} width={'100%'} onClick={onRefuseCloseDrawer} />
              </div>
            </div>
          </Drawer>
          <Drawer
            onClose={onAgreeCloseDrawer}
            open={openAgreeDrawer}
            placement={`bottom`}
            closeIcon={false}
            zIndex={9500}
            height={305}
            style={{ borderRadius: '12px 12px 0px 0px' }}
          >
            <div className={styles.mobileContainerDrawer}>
              <div className={styles.drawerTriangleContainer}>
                <div className={styles.drawerTriangle} />
              </div>
              <div className={styles.mobileCloseIconContainerDrawer}>
                <div className={styles.mobileCloseIconDrawer} onClick={onAgreeCloseDrawer}>
                  <CloseSvg />
                </div>
              </div>
              <div className={styles.mobileTitleAndSubtitleTextDrawerContainer}>
                <div className={styles.mobileTitleTextDrawer}>Подтвердить заявку</div>
                <div className={styles.mobileSubTitleTextDrawer}>
                  Вы действительно хотите принять заказ{' '}
                  <span className={styles.mobileOrderTextDrawer}>{data?.orderNumber}</span>?
                </div>
              </div>
              <div className={styles.mobileButtonsDrawer}>
                <ButtonFilled text={'Да, принять'} width={'100%'} onClick={sendAgree} />
                <ButtonNotFilled text={'Нет, вернуться'} width={'100%'} onClick={onAgreeCloseDrawer} />
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        /*-----------------------------------ПК МОДАЛКИ------------------------------------------*/
        <>
          <Modal
            className={styles.modal}
            zIndex={9500}
            width={464}
            footer={null}
            open={isRefuseModalOpen}
            onCancel={closeRefuseModal}
            closeIcon={false}
          >
            <div className={styles.containerModal}>
              <div className={styles.titleAndSubtitleTextModalContainer}>
                <div className={styles.titleTextModal}>Отказаться от заявки</div>
                <div style={{ marginBottom: '12px' }} className={styles.subTitleTextModal}>
                  Выберите причину, почему вы хотите отказаться от заказа&nbsp;
                  <span className={styles.orderTextModal}>{data?.orderNumber}?</span>
                </div>
              </div>
              <div style={{ marginBottom: '32px' }}>
                <CustomSelect
                  error={false}
                  value={valueReason}
                  options={reasonList}
                  placeholder={'Выберите из списка'}
                  noOptionsMessage={'Нет вариантов'}
                  setValue={value => setValueReason(value)}
                  isSearchable={false}
                  isClearable={false}
                />
                {valueReason.value === 'Другая проблема (введите комментарий)' && (
                  <Input
                    placeholder={'Напишите логичную причину для отказа в этом поле...'}
                    onChange={e => setCommentValue(e.target.value)}
                    disabled={false}
                    style={{ width: 384, height: 56, marginTop: '12px' }}
                  />
                )}
              </div>
              <div className={styles.buttonsModal}>
                <ButtonNotFilled text={'Нет, вернуться'} width={'100%'} onClick={closeRefuseModal} />
                <ButtonFilled
                  text={'Да, хочу отказаться'}
                  width={'100%'}
                  onClick={sendRejection}
                  disabled={validationRefuseButton()}
                />
              </div>
            </div>
            <div className={styles.closeButton}>
              <FrameModalSvg onClick={closeRefuseModal} />
            </div>
          </Modal>
          <Modal
            zIndex={9500}
            width={464}
            footer={null}
            open={isAgreeModalOpen}
            onCancel={closeAgreeModal}
            closeIcon={false}
            className={styles.modal}
          >
            <div className={styles.containerModal}>
              <div className={styles.titleAndSubtitleTextModalContainer}>
                <div className={styles.titleTextModal}>Подтвердить заявку</div>
                <div style={{ marginBottom: '32px' }} className={styles.subTitleTextModal}>
                  Вы действительно хотите принять заказ{' '}
                  <span className={styles.orderTextModal}>{data?.orderNumber}</span>?
                </div>
              </div>
              <div className={styles.buttonsModal}>
                <ButtonNotFilled text={'Нет, вернуться'} width={'100%'} onClick={closeAgreeModal} />
                <ButtonFilled text={'Да, принять'} width={'100%'} onClick={sendAgree} />
              </div>
            </div>
            <div className={styles.closeButton}>
              <FrameModalSvg onClick={closeAgreeModal} />
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default DistributionAndSubmitRequest;
