import React, { useEffect, useState } from 'react';
import styles from './ModalOfLimitRequest.module.scss';
import { ModalNew } from '../../../../components/ModalNew/ModalNew';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import InfoSvg from '../../../../components/UI/svg-icon/InfoSvg';
import { WalletGSMSvg } from '../../../../components/UI/svg-icon/WalletGSMSvg';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import SuccessSvgVer2 from '../../../../components/UI/svg-icon/SuccessSvgVer2';
import CustomInput from '../../../../components/newUI/CustomInput/CustomInput';
import { regForNumberWithComma } from '../../../ShowcasesPage/constans';
import { Progress } from 'antd';

interface IProps {
  openModalOfAddGSMCard: boolean;
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
}

const ModalOfLimitRequest = ({ openModalOfAddGSMCard, setOpenModalOfAddGSMCard }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [successRequest, setSuccessRequest] = useState<boolean>(false);
  const [valueOfRequest, setValueOfRequest] = useState<string>('');

  useEffect(() => {
    if (Number(valueOfRequest.replace(',', '.')) >= 100000) {
      setValueOfRequest('100000');
    }
  }, [valueOfRequest]);
  return (
    <ModalNew
      afterClose={() => setSuccessRequest(false)}
      setOpenModal={setOpenModalOfAddGSMCard}
      openModal={openModalOfAddGSMCard}
    >
      {successRequest ? (
        <div className={styles.successContainer}>
          <div className={styles.titleSuccessContainer}>
            {isMobile ? (
              <div className={styles.closeButton} onClick={() => setOpenModalOfAddGSMCard(false)}>
                <CloseSvg />
              </div>
            ) : (
              <p className={styles.title}>Запрос лимита</p>
            )}
          </div>
          <div className={styles.successContentContainer}>
            <SuccessSvgVer2 />
            <div className={styles.successTextContentContainer}>
              <p className={styles.successTextTitleContent}>Запрос успешно отправлен</p>
              <p className={styles.successTextSubTitleContent}>Средства будут зачислены в течение 24 часов</p>
            </div>
          </div>
          <ButtonFilled width={'100%'} text={'Готово'} onClick={() => setOpenModalOfAddGSMCard(false)} />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.mobileCloseButtonAndTitle}>
            <p className={styles.title}>Запрос лимита</p>
            {isMobile && (
              <div className={styles.closeButton} onClick={() => setOpenModalOfAddGSMCard(false)}>
                <CloseSvg />
              </div>
            )}
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.availableLimitContainer}>
              <p className={styles.availableLimitText}>Доступный для распределения лимит</p>
              <div style={{ marginRight: '9px' }}>
                <CustomTooltip
                  svg={<InfoSvg color={'#1C274C'} />}
                  text={'нужен текст'}
                  widthTooltip={'250px'}
                  SideTooltip={'left'}
                  MobileSideTooltip={'left'}
                />
              </div>
            </div>
            <div className={styles.moneyContainer}>
              <WalletGSMSvg />
              <p className={styles.moneyText}>100 000 ₽</p>
            </div>
            <div className={styles.progressLineContainer}>
              <Progress
                trailColor={'white'}
                showInfo={false}
                percent={(Number(valueOfRequest) * 100) / 100000}
                size={['100%', 8]}
                strokeColor="#FFEFE6"
              />
            </div>
            <div>
              <CustomInput
                height={isMobile ? '40px' : '48px'}
                disabled={false}
                value={valueOfRequest}
                placeholder={'0'}
                suffix={'₽'}
                setValue={e => {
                  if (regForNumberWithComma.test(e) || e === '') {
                    setValueOfRequest(e);
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <ButtonNotFilled width={'100%'} text={'Отмена'} onClick={() => setOpenModalOfAddGSMCard(false)} />
            <ButtonFilled width={'100%'} text={'Запросить'} onClick={() => setSuccessRequest(true)} />
          </div>
        </div>
      )}
    </ModalNew>
  );
};

export default ModalOfLimitRequest;
