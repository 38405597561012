import React from 'react';
import styles from './CurrentLimit.module.scss';
import { WalletGSMSvg } from '../../../../components/UI/svg-icon/WalletGSMSvg';
import { formPrice } from '../../../../common/form-price.helper';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { useNavigate } from 'react-router-dom';
import { Progress } from 'antd';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import { HelpNewSvg } from '../../../../components/UI/svg-icon/HelpNewSvg';
import QuestionOutlineSvg from '../../../../components/UI/svg-icon/QuestionOutlineSvg';
import AddGSMSvg from '../../../../components/UI/svg-icon/AddGSMSvg';
import AlertSvg from '../../../../components/UI/svg-icon/AlertSvg';
import InfoSvg from '../../../../components/UI/svg-icon/InfoSvg';
interface IProps {
  limit: {
    distributedLimit: number;
    limit: number;
  };
}

const CurrentLimit = ({ limit }: IProps) => {
  const navigate = useNavigate();
  const percentLimit = (limit?.distributedLimit * 100) / limit?.limit;
  return (
    <div className={styles.container}>
      <div className={styles.containerLimit}>
        <div className={styles.mainBlockContainer}>
          <p className={styles.titleMainBlock}>Текущий лимит ГСМ</p>
          <div>
            <CustomTooltip
              svg={<InfoSvg color={'#6C779C'} />}
              text={'Лимит равен сумме возможности оплаты'}
              widthTooltip={'310px'}
              widthSvg={'16px'}
              positionTooltipRight={'-13px'}
              positionTooltipBottom={'23px'}
              MobileSideTooltip={'left'}
              SideTooltip={'left'}
              styleWindowTooltip={{ fontSize: '12px' }}
            />
          </div>
        </div>
        <div className={styles.walletContainer}>
          <div className={styles.moneyContainer}>
            <WalletGSMSvg />
            <p className={styles.moneyText}>{formPrice(limit?.limit || 0, 'RUB', 'ru')}</p>
          </div>
          <div className={styles.progressLineContainer}>
            <Progress
              trailColor={'white'}
              showInfo={false}
              percent={percentLimit}
              size={['100%', 6]}
              strokeColor="#FFEFE6"
            />
          </div>
          <div className={styles.distributionContainer}>
            Распределено {formPrice(limit?.distributedLimit || 0, 'RUB', 'ru')}
          </div>
        </div>
        <ButtonNotFilled
          disabled={false}
          text={'Пополнить карты'}
          width={'100%'}
          sizeText={'small'}
          height="40px"
          onClick={() => navigate('/gsm/replenishments')}
        />
      </div>
    </div>
  );
};

export default CurrentLimit;
