import React from 'react';

type Props = {
    isLabel: boolean;
    margin: string;
    value: string;
    placeholder: string;
    setValue: React.Dispatch<string>;
    disabled: boolean;
    padding?: string,
    height?: string;
    [key: string]: any;
}
export const InputTextArea = ({
                                  isLabel,
                                  margin,
                                  value,
                                  placeholder,
                                  setValue,
                                  disabled,
                                  height='',
                                  padding='24px 0 0 16px',
                                  ...props }: Props) => {
    return (
        <div className={'InputTextArea form-item'
        } style={{ marginTop: margin, height: height, padding: padding }}>
            <textarea {...props}
                   className={
                       disabled ? 'InputTextArea__input form-input disabled' : 'InputTextArea__input form-input'
                   }
                   disabled={disabled}
                   value={value || ''}
                   onChange={({ target: { value } }) => setValue(String(value))}
            />
            <div></div>
            {isLabel && <label className={value ? 'InputTextArea__label form-label valid' : 'InputTextArea__label form-label'} >{placeholder}</label>}
        </div>
    );
};