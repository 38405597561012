import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import styles from './FormReplenishmentCard.module.scss';
import { useNavigate } from 'react-router-dom';
import { IDataCards, IInputsFormReplenishment, TOptionsForSelectCard } from '../../type';
import { useMediaQuery } from 'react-responsive';
import { formPrice } from 'src/common/form-price.helper';
import CustomInput from 'src/components/newUI/CustomInput/CustomInput';
import { regForNumberWithComma } from 'src/Pages/ShowcasesPage/constans';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { InputVerification } from 'src/components/UI/inputs/InputVerification';
import SuccessSvgVer2 from 'src/components/UI/svg-icon/SuccessSvgVer2';
import { SelectCard } from '../components/SelectCard/SelectCard';
import { formCardNumber } from 'src/common/formCardNumber.helper';
import { fetchPost } from 'src/common/proxy-1C-fetch-auth';
import { toastError } from 'src/common/toastError.helper';
import { clearObject } from 'src/common/ClearObject.helper';
import { Context } from '../../../../context/context';
import { formatPhoneNumber } from 'src/common/formatPhoneNumber.helper';
interface IProps {
  codeValue: string;
  setCodeValue: React.Dispatch<string>;
  step: 'transfer' | 'smsAuth' | 'success';
  setStep: React.Dispatch<'transfer' | 'smsAuth' | 'success'>;
  inputsFormReplenishment: IInputsFormReplenishment;
  setInputsFormReplenishment: Dispatch<React.SetStateAction<IInputsFormReplenishment>>;
  optionsFormOfReplenishmentsCard: { value: string; label: string }[];
  optionsForSelectCard: TOptionsForSelectCard;
  currentCard: IDataCards;
}
const FormReplenishmentCard = ({
  codeValue,
  setCodeValue,
  step,
  setStep,
  inputsFormReplenishment,
  setInputsFormReplenishment,
  optionsFormOfReplenishmentsCard,
  optionsForSelectCard,
  currentCard,
}: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { user } = useContext(Context);
  const [sort, setSort] = useState<{ value: string; label: string }>({ value: 'own', label: 'С общего баланса' });
  const [focus, setFocus] = useState<boolean>(false);
  const [errorSending, setErrorSending] = useState<boolean>(false);
  const [isLoadingTransferButton, setIsLoadingTransferButton] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const navigate = useNavigate();
  const refCode = useRef<HTMLInputElement | null>();
  const currentSelectedBalance = optionsForSelectCard?.find(item => item?.value === sort?.value)?.money;
  const comparingSelectedBalanceAndInputValue =
    Number(inputsFormReplenishment.sum.replace(',', '.')) > Number(currentSelectedBalance);
  const limitForInput = e => (regForNumberWithComma.test(e) || e === '') && Number(e.replace(',', '.')) <= 9999999.99;
  const disableButtonByInputSum = !(inputsFormReplenishment?.sum === '0' || inputsFormReplenishment?.sum === '0,');
  const calculateMoneyForButtonTransfer = String(
    +Number(inputsFormReplenishment.sum.replace(',', '.')).toFixed(2),
  ).replace('.', ',');
  const disableTransferButton =
    !disableButtonByInputSum ||
    !inputsFormReplenishment?.sum.length ||
    comparingSelectedBalanceAndInputValue ||
    isLoadingTransferButton;
  const inputVerificationChange = ({ target: { value } }) => {
    setCodeValue(value.replace(/\D/gu, '').slice(0, 5));
  };
  const sendSms = async () => {
    setIsLoadingTransferButton(true);
    try {
      const responseSendSms = await fetchPost('/limits/send-sms', 'POST', {});
      if (toastError(responseSendSms)) return;
      setStep('smsAuth');
      setTimer(59);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingTransferButton(false);
    }
  };
  const transfer = async () => {
    try {
      const responseTransfer = await fetchPost(
        `/limits/${sort?.value === 'own' ? 'set' : 'transfer'}`,
        'POST',
        clearObject({
          cardId: Number(currentCard?.id),
          limit: Number(inputsFormReplenishment.sum.replace(',', '.')),
          oldCardId: sort?.value === 'own' ? undefined : Number(sort?.value),
          code: Number(codeValue),
        }),
      );
      if (toastError(responseTransfer)) return;
      setStep('success');
    } catch (e) {
      console.log(e);
    }
  };
  const convertVisualOfTimerCount = (): string => {
    if (timer < 10) {
      return `00:0${timer}`;
    } else {
      return `00:${timer}`;
    }
  };
  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);
  useEffect(() => {
    if (codeValue.length === 5) {
      transfer();
    }
  }, [codeValue]);
  useEffect(() => {
    setCodeValue('');
    if (step === 'transfer') {
      setInputsFormReplenishment(prevState => ({ ...prevState, sum: '' }));
    }
  }, [step]);
  return (
    <>
      {step === 'transfer' && (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.transferContainer}>
              <div className={styles.blockTransfer}>
                <p className={styles.contentText}>Откуда перевести</p>
                <div className={styles.inputContainer}>
                  <SelectCard
                    sortNames={optionsFormOfReplenishmentsCard}
                    sortOptions={optionsForSelectCard}
                    sort={sort}
                    setSort={setSort}
                  />
                </div>
                <div className={styles.money}>{formPrice(currentSelectedBalance, 'RUB', 'ru')}</div>
              </div>
              <div className={styles.blockTransfer}>
                <p className={styles.contentText}>{`На карту ${formCardNumber(currentCard?.cardNumber)}`}</p>
                <div className={styles.inputContainer}>
                  <CustomInput
                    error={comparingSelectedBalanceAndInputValue}
                    height={'48px'}
                    disabled={false}
                    value={inputsFormReplenishment.sum}
                    placeholder={'0'}
                    suffix={'₽'}
                    setValue={e => {
                      if (limitForInput(e)) {
                        setInputsFormReplenishment(prevState => ({ ...prevState, sum: e }));
                      }
                    }}
                  />
                  {comparingSelectedBalanceAndInputValue && (
                    <p className={styles.errorText}>Сумма превышает текущий баланс</p>
                  )}
                </div>
                <div className={styles.money}>{formPrice(currentCard?.limit?.current || 0, 'RUB', 'ru')}</div>
              </div>
            </div>
          </div>
          <ButtonFilled
            disabled={disableTransferButton}
            width={isMobile ? '100%' : '520px'}
            text={`Перевести ${calculateMoneyForButtonTransfer} ₽`}
            onClick={() => sendSms()}
          />
        </div>
      )}
      {step === 'smsAuth' && (
        <div className={styles.smsContainer}>
          <div className={styles.smsAuthContainer}>
            <p className={styles.smsAuthTitle}>Для подтверждения операции введите код из смс</p>
            <div className={styles.smsAuthSubTitle} style={{ display: 'inline' }}>
              Мы отправили код подтверждения на номер&ensp;{' '}
              <span className={styles.smsAuthNumber}>{formatPhoneNumber(user.phone)}</span>
            </div>
            <InputVerification
              style={{ height: '80px', padding: '16px' }}
              placeholder="00000"
              errorSending={errorSending}
              setErrorSending={setErrorSending}
              onInput={inputVerificationChange}
              value={codeValue}
              refNew={refCode}
              focus={focus}
              setFocus={setFocus}
              autoFocus={true}
            />
            {errorSending && <div className={styles.descriptionError}>Вы ввели неверный код. Попробуйте еще раз.</div>}
            <div style={{ marginTop: '24px' }}>
              {timer > 0 ? (
                <div className={styles.newCodeCounter}>
                  Получить новый код можно <br />
                  через {convertVisualOfTimerCount()}
                </div>
              ) : (
                <ButtonFilled
                  text={'Отправить код повторно'}
                  width={'100%'}
                  onClick={() => sendSms()}
                  disabled={isLoadingTransferButton}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {step === 'success' && (
        <div className={styles.successContainer}>
          <div className={styles.successContent}>
            <SuccessSvgVer2 />
            <div className={styles.successContentText}>
              <p className={styles.successThickText}>Карта успешно пополнена</p>
              <p className={styles.successSoftText}>Средства будут зачислены в течение 24 часов</p>
            </div>
          </div>
          <ButtonFilled width={isMobile ? '100%' : '520px'} text={'Готово'} onClick={() => navigate(-1)} />
        </div>
      )}
    </>
  );
};

export default FormReplenishmentCard;
