import mobileDriverTour from '../../../../assets/img/TourImages/mobileDriverTour.png'
import mobileDispatchingTour from '../../../../assets/img/TourImages/mobileDispatchingTour.png'
import mobileFlightTour from '../../../../assets/img/TourImages/mobileFlightTour.png'
import mobileCarTour from '../../../../assets/img/TourImages/mobileCarTour.png'
import mobileDocumentTour from '../../../../assets/img/TourImages/mobileDocumentTour.png'
import mobileProfileTour from '../../../../assets/img/TourImages/mobileProfileTour.png'
import mobileEmploerTour from '../../../../assets/img/TourImages/mobileEmploerTour.png'
import mobilePaymentProcessTour from '../../../../assets/img/TourImages/mobilePaymentProcessTour.png'
import mobileHelpTour from '../../../../assets/img/TourImages/mobileHelpTour.png'

export const arrayOfMobileTourContent=[{
    title:'Диспетчеризация',discription:'Распределяйте груз между рейсами и транспортными средствами, назначайте водителей. Отказывайтесь от остатков в случае необходимости и редактируйте планирование. Просматривайте общий список выполненных объемов в подразделе «Выполненные заявки».',
    src:mobileDispatchingTour,
},{
    title:'Рейсы',discription:'Отслеживайте статус выполнения рейсов водителями, просматривайте полную информацию по рейсам, переназначайте водителей и транспортное средство, отказывайтесь от рейсов до выезда водителей.',
    src:mobileFlightTour,
},{
    title:'Водители',discription:'Добавляйте и удаляйте водителей, чтобы назначать их на рейсы. Укажите их личные и паспортные данные, а также прикрепите водительское удостоверение и паспорт для регистрации.\n' +
        'После добавления водитель получит уведомление о регистрации и сможет войти в свой личный кабинет. В случае удаления водителя его номер телефона будет удален из системы, а доступ к личному кабинету - потерян.\n',
    src:mobileDriverTour,
},{
    title:'Транспортные средства',discription:'Добавляйте и удаляйте транспортные средства, распределяйте их между рейсами. Укажите номер и марку автомобиля, добавьте файл СТС и договор лизинга при наличии.',
    src:mobileCarTour,
},{
    title:'Сотрудники',discription:'Добавляйте и удаляйте сотрудников и назначайте им роли менеджера, бухгалтера и администратора. У каждого сотрудника доступен свой функционал личного кабинета, необходимый для работы.\n' +
        'После добавления сотрудника он получит уведомление о регистрации и сможет войти в личный кабинет. При удалении сотрудник потеряет доступ к кабинету, его номер также удалится из системы.',
    src:mobileEmploerTour,
},{
    title:'Документы',discription:'Формируйте договоры поставки или перевозки и заверяйте их электронной подписью. Просматривайте уже сформированные договоры, а также спецификации к ним.\n' +
        'В подразделе «Реестры» вы можете формировать электронный список реестров и отправлять его на проверку.', src:mobileDocumentTour,
},{
    title:'Взаиморасчеты',discription:'Просматривайте количество оплаченных и неоплаченных рейсов, следите за суммой оплаты по оригиналам и сканам транспортных накладных.', src:mobilePaymentProcessTour,
},{
    title:'Профиль',discription:'Добавляйте и просматривайте данные, редактируйте их при необходимости. \n' +
        'Завершите регистрацию: заполните личные и юридические данные, прикрепите учредительные документы. Затем ваш профиль отправится на проверку, после прохождения которой вы сможете работать в личном кабинете.\n', src:mobileProfileTour,
},{
    title:'Помощь',discription:'Изучайте ответы на наиболее часто задаваемые вопросы.', src:mobileHelpTour,
}]