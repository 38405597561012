import React from 'react';
import styles from './PublicShowcaseHeader.module.scss';
import { Container } from 'src/components/Container';
import { Link } from 'react-router-dom';
import { PublicShowcaseLogo } from './svg/PublicShowcaseLogo';
import { PublicShowcaseLogoText } from './svg/PublicShowcaseLogoText';

export const PublicShowcaseHeader = () => {
  const logoStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '14px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  };
  return (
    <div className="Header">
      <Container>
        <div className="Header__wrapper">
          <div style={logoStyle as React.CSSProperties}>
            <PublicShowcaseLogo />
            <PublicShowcaseLogoText />
          </div>
        </div>
      </Container>
    </div>
  );
};
