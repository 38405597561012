import React from 'react';
import { Link } from 'react-router-dom';
import { RequestItem } from '../../RequestItem';
import { StatusItem } from '../StatusItem';
import { FlightListProps } from '../../../common/flight-list-props.type';

export const FlightList = ({ flightItems, more, setMore, filter, isCompleted}: FlightListProps) => {
  return (
    <>
      {flightItems.map((item: any) => (
          <Link key={item?.id} to={`/flights/${item?.id}`} onClick={() => localStorage.setItem('flightId', item.id)}>
            <RequestItem
                itemFlight={item}
                price={item?.driversSalary * item?.distance}
                number={item?.flightName?.trim()}
                numberTn={item?.numberTn}
                date={
                  isCompleted ? new Date(item.dateTn)?.toLocaleDateString() : new Date(item.date)?.toLocaleDateString()
                }
                nameLoading={item?.supplier?.title}
                addressLoading={item?.quarry?.address?.address || 'Нет данных'}
                nameUnloading={item?.client?.title}
                addressUnloading={item?.object?.address?.address || 'Нет данных'}
                volume={isCompleted ? item?.unloadingVolume || 'Нет данных' : item?.volumePlan}
                measurement={item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit}
                cargo={item?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument || ''}
                disabled={item?.status || ''}
                isCompleted={isCompleted}
                status={<StatusItem status={item?.status} />}
            />
          </Link>
      ))}
    </>
  );
};
