import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import styles from './SpecificationPage.module.scss';
import CustomTable from '../../components/newUI/CustomTable/CustomTable';
import { columnsForOtherDocuments, columnsForTableOfSpecifications, optionsOfActivityOfSpecifications } from './utils';
import { Context } from '../../context/context';
import MobileCardTableSpecifications from './Components/MobileCardTableSpecifications/MobileCardTableSpecifications';
import { DataResponseDocuments } from '../DocumentsPages/type';
import { tabNames } from '../DocumentsPages/DocumentsPage';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import axios from 'axios';

interface IProps {
  loading: boolean;
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  dataResponse: DataResponseDocuments[];
  setDataResponse: Dispatch<React.SetStateAction<DataResponseDocuments[]>>;
  activityOfSpecifications: { value: string; label: string } | undefined | null;
  setActivityOfSpecifications: React.Dispatch<
    React.SetStateAction<{ value: string; label: string } | undefined | null>
  >;
}
export const SpecificationsPage = ({
  dataResponse = [],
  setDataResponse,
  loading,
  setLoading,
  activityOfSpecifications,
  setActivityOfSpecifications,
}: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { user } = useContext(Context);
  const tab = localStorage.getItem('tabOfDocumentsPage');
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  /*Удаление колонки "статус" если мы ищем закрытые договоры*/
  const columnsForTableOfSpecificationsByStatus =
    activityOfSpecifications.value === 'closed'
      ? columnsForTableOfSpecifications[user.companyType].filter(item => item.key !== 'status')
      : columnsForTableOfSpecifications[user.companyType];

  const downloadFile = async (link: string, name: string, mimeType: string) => {
    try {
      const response = await axios.get(link, {
        responseType: 'blob',
      });
      const urlBlob = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
      const anchor = document.createElement('a');
      anchor.href = urlBlob;
      anchor.setAttribute('download', name);
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      console.log(error);
    }
  };

  const tabColumns =
    tab === tabNames.OTHERDOCUMENTS
      ? columnsForOtherDocuments({ downloadFile })[tabNames.OTHERDOCUMENTS]
      : columnsForTableOfSpecificationsByStatus;

  const getData = async (isPagination: boolean) => {
    if (tab !== tabNames.CONTRACTSANDSPECIFICATIONS && tab !== tabNames.OTHERDOCUMENTS) return;
    setLoading(true);
    try {
      if (tab === tabNames.CONTRACTSANDSPECIFICATIONS) {
        const response = await fetchGet('/information/contracts', {
          status: activityOfSpecifications.value,
        });
        const responseTableData = response?.response?.map(item => {
          return {
            ...item,
            status: item?.status?.title,
          };
        });
        setDataResponse(responseTableData);
        setLoading(false);
        return;
      }

      const response = await fetchGet('/documents/other', {
        limit: 10,
        offset: isPagination ? offset : 0,
      });
      setTotalCount(response?.response?.count);
      const responseTableData = response?.response?.rows;
      setLoading(false);
      if (isPagination && offset !== 0) {
        return setDataResponse(prevState => [...prevState, ...responseTableData]);
      }
      return setDataResponse(responseTableData);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    setOffset(0);
    getData(false);
  }, [activityOfSpecifications, tab]);
  useEffect(() => {
    if (totalCount > offset) {
      getData(true);
    }
  }, [offset]);
  return (
    <>
      {tab === tabNames.CONTRACTSANDSPECIFICATIONS && (
        <div style={{ width: isMobile ? '100%' : '298px' }} className={styles.customSelectContainer}>
          <CustomSelect
            defaultValue={optionsOfActivityOfSpecifications[0]}
            error={false}
            placeholder={''}
            noOptionsMessage={''}
            isSearchable={false}
            isClearable={false}
            options={optionsOfActivityOfSpecifications}
            setValue={value => setActivityOfSpecifications(value)}
          />
        </div>
      )}
      {isMobile ? (
        <MobileCardTableSpecifications
          tab={tab}
          activityOfSpecifications={activityOfSpecifications}
          dataResponse={dataResponse}
          loading={loading}
          downloadFile={downloadFile}
        />
      ) : (
        <div className={styles.customTableContainer}>
          <CustomTable
            loading={loading}
            link={true}
            linkTextBeforeId={'specifications'}
            columns={tabColumns}
            data={dataResponse}
            isDisabledOnClick={tab === tabNames.OTHERDOCUMENTS}
          />
        </div>
      )}
    </>
  );
};
