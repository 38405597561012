import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import { Modal, Tabs } from 'antd';
// @ts-ignore
import styles from './ShipmentsPage.module.scss';
import AwaitingShipment from './AwaitingShipment/AwaitingShipment';
import Shipped from './Shipped/Shipped';
import { Html5Qrcode } from 'html5-qrcode';
import { ButtonTransparent } from '../../components/UI/buttons/ButtonTransparent';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import TextBoxSearchOutlineSvg from '../../components/UI/svg-icon/textBoxSearchOutlineSvg';
import CustomInputValueAndCheckBox from '../../components/newUI/CustomInputValueAndCheckBox/CustomInputValueAndCheckBox';
import { clearObject } from '../../common/ClearObject.helper';
import { IDataResponseAwaitingShipped, IDataResponseShipped, IFilter } from './type';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import { toastError } from 'src/common/toastError.helper';

export const ShipmentsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [tab, setTab] = useState<string>('1');
  const [dataCount, setDataCount] = useState<string>();
  const [currentQrRegisty, setCurrentQrRegisty] = useState<any>('');
  const [awaitingShipmentIds, setAwaitingShipmentIds] = useState<any>();
  const [shipmentIds, setShipmentIds] = useState<any>();
  const [isEnabled, setEnabled] = useState<boolean>(false);
  const [qrMessage, setQrMessage] = useState<string>('');
  const [isQr, setIsQr] = useState<boolean>(false);
  const [updatePage, setUpdatePage] = useState<boolean>(false);

  const [filter, setFilter] = useState(null);
  useEffect(() => {
    const config = { fps: 60, qrbox: { width: 200, height: 200 } };

    const html5QrCode = new Html5Qrcode('qrCodeContainer');

    const qrScanerStop = () => {
      if (html5QrCode && html5QrCode.isScanning) {
        html5QrCode
          .stop()
          .then(ignore => console.log('Scaner stop'))
          .catch(err => console.log('Scaner error'));
      }
    };

    const qrCodeSuccess = decodedText => {
      setQrMessage(decodedText);
      setEnabled(false);
    };

    if (isEnabled) {
      // @ts-ignore
      html5QrCode.start({ facingMode: 'environment' }, config, qrCodeSuccess);
      setQrMessage('');
    } else {
      qrScanerStop();
    }

    return () => {
      qrScanerStop();
    };
  }, [isEnabled]);
  const callBackDataCount = (count: string): void => {
    setDataCount(count);
  };
  useEffect(() => {
    setUpdatePage(!updatePage);
  }, [isQr]);

  const callBackDataAwaitingShipment = (flights: IDataResponseAwaitingShipped[]): void => {
    setAwaitingShipmentIds(
      flights.map(flight => {
        return Number(flight?.actualLoading?.id);
      }),
    );
  };

  const callBackDataShipment = (flights: IDataResponseShipped[]): void => {
    setShipmentIds(
      flights.map(flight => {
        return Number(flight?.flight?.id);
      }),
    );
  };
  const callBackDataFilter = (filter: IFilter): void => {
    setFilter(filter);
  };
  const handleCancelModal = (): void => {
    setIsQr(false);
  };
  const getCurrentQrRegisty = async () => {
    try {
      const response = await fetchGet(`/shipments/${qrMessage}`, {});
      setCurrentQrRegisty(response?.response);
      if (toastError(response)) return; 
      setIsQr(true);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (qrMessage !== '') {
      getCurrentQrRegisty();
    }
  }, [qrMessage]);
  const dowmloadRegistry = async ids => {
    const individualids = new Set(
      ids.map(id => {
        return id;
      }),
    );
    try {
      const response = await fetchPost(`/attorneys/generate-registry `, 'POST', {
        ids: Array.from(individualids),
      });
      if (toastError(response)) return;

      const link = document.createElement('a');
      link.download = response?.response?.name;

      link.href = response?.response?.file;

      link.click();

      return;
    } catch (e) {
      console.log(e);
    }
  };
  const downloadRegistryShipped = async () => {
    try {
      const response = await fetchPost(`/reports/generate-shipment-registry `, 'POST', {ids:Array.from(new Set(shipmentIds))});
      if (toastError(response)) return;

      const link = document.createElement('a');
      link.download = 'Реестр выполненных отгрузок';

      link.href = response?.response?.file;

      link.click();

      return;
    } catch (e) {
      console.log(e);
    }
  };
  const tabs: {
    key: string;
    label: string;
    children: JSX.Element;
  }[] = [
    {
      key: '1',
      label: 'Ожидают погрузки',
      children: (
        <>
          <div className={styles.blockRegister}>
            {isMobile ? (
              <div></div>
            ) : (
              <p onClick={() => dowmloadRegistry(awaitingShipmentIds)} className={styles.textRegister}>
                Сформировать реестр доверенностей
              </p>
            )}
          </div>
          <AwaitingShipment
            updatePage={updatePage}
            callBackDataAwaitingShipment={callBackDataAwaitingShipment}
            callBackDataCount={callBackDataCount}
            tab={tab}
            isQr={isQr}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Отгруженные',
      children: (
        <>
          <div className={styles.blockRegister}>
            {isMobile ? (
              <div></div>
            ) : (
              <p className={styles.textRegister} onClick={() => downloadRegistryShipped()}>
                Сформировать документ
              </p>
            )}
          </div>
          <Shipped
            updatePage={updatePage}
            callBackDataCount={callBackDataCount}
            callBackDataFilter={callBackDataFilter}
            callBackDataShipment={callBackDataShipment}
            tab={tab}
          />
        </>
      ),
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
  };

  return (
    <PageWrapper>
      <div className={'shipments-page'}>
        <div className={styles.titleAndSubtitleContainer}>
          <TitleMain title={'Отгрузки'} />
          {tab === '1' ? (
            <SubTitleMain subTitle={`У вас ${dataCount} рейсов ожидают погрузки`} />
          ) : (
            <SubTitleMain subTitle={`За все время вы отгрузили ${dataCount} рейса`} />
          )}
        </div>
        {isMobile ? (
          <div className={styles.scaner}>
            <div id="qrCodeContainer" />
            <ButtonTransparent
              style={{ marginTop: '16px', marginBottom: '16px' }}
              modifier={''}
              color={'orange'}
              disabled={false}
              svg=""
              isSvg={false}
              onClick={() => setEnabled(!isEnabled)}
              children={!isEnabled ? 'Сканировать QR код' : 'Закрыть сканер'}
            />
          </div>
        ) : (
          <div id="qrCodeContainer"></div>
        )}
        {isMobile ? (
          <div className={styles.mobileBlockRegister}>
            {tab === '1' ? (
              <p className={styles.mobileTextRegister} onClick={() => dowmloadRegistry(awaitingShipmentIds)}>
                Сформировать реестр доверенностей
              </p>
            ) : (
              <p className={styles.mobileTextRegister} onClick={() => dowmloadRegistry(shipmentIds)}>
                Сформировать документ
              </p>
            )}
          </div>
        ) : (
          <div></div>
        )}
        {isMobile ? (
          <div className={styles.mobileTabContainer}>
            <Tabs style={{ marginTop: '-12px' }} defaultActiveKey={tab} items={tabs} onChange={onChange} />
          </div>
        ) : (
          <Tabs defaultActiveKey={tab} items={tabs} onChange={onChange} />
        )}
        <Modal
          style={{
            maxWidth: '100vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'normal',
            justifyContent: 'flex-end',
            height: '90vh',
            paddingBottom: '0px',
          }}
          title={'Сканировать QR-код'}
          onCancel={handleCancelModal}
          open={isQr}
          footer={null}
        >
          <div>
            {currentQrRegisty?.status === 'active' ? (
              <div className={styles.qrScanContainer}>
                <div className={styles.firstQrTextContainer}>
                  <div>
                    <TextBoxSearchOutlineSvg />
                  </div>
                  <div className={styles.qrTextBorder}>
                    <p className={styles.text}>{currentQrRegisty?.flightName}</p>
                  </div>
                </div>
                <div className={styles.firstQrTextContainer} style={{ paddingTop: '12px' }}>
                  <div>
                    <p className={styles.qrTopText}>Транспорт:</p>
                  </div>
                  <div style={{ maxWidth: '150px' }}>
                    <p style={{ textAlign: 'start' }} className={styles.text}>
                      {currentQrRegisty?.car?.carBrand?.brand}
                      {currentQrRegisty?.car?.stateNumber}
                    </p>
                    <p style={{ textAlign: 'start' }} className={styles.qrBottomText}>
                      {currentQrRegisty?.driver?.fullName}
                    </p>
                  </div>
                </div>
                <div className={styles.firstQrTextContainer} style={{ paddingTop: '12px' }}>
                  <div>
                    <p className={styles.qrTopText}>Загрузка:</p>
                  </div>
                  <div style={{ maxWidth: '150px', marginRight: '40px' }}>
                    <p style={{ textAlign: 'start' }} className={styles.text}>
                      {currentQrRegisty?.organization}
                    </p>
                    <p style={{ textAlign: 'start' }} className={styles.qrBottomText}>
                      {currentQrRegisty?.supplier?.title}
                    </p>
                  </div>
                </div>

                <div className={styles.firstQrTextContainer} style={{ display: 'block', paddingTop: '12px' }}>
                  <div>
                    <p
                      className={styles.qrBottomText}
                      style={{
                        color: ' #373D50',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                      }}
                    >
                      {currentQrRegisty?.nomenclature?.nameByDocument}
                    </p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px' }}>
                    <div>
                      <p className={styles.qrTopText}>Запланированный Объем:</p>
                    </div>
                    <div>
                      <p style={{ textAlign: 'start' }} className={styles.text}>
                        {currentQrRegisty?.volumePlan}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.firstQrTextContainer}
                  style={{ display: 'block', borderBottom: '0px', paddingTop: '12px' }}
                >
                  <div>
                    <p className={styles.qrTopText}>Фактическая погрузка с ед. измерения:</p>
                  </div>
                  <div style={{ paddingTop: '12px' }}>
                    <CustomInputValueAndCheckBox
                      setTab={setTab}
                      setIsQr={setIsQr}
                      id={currentQrRegisty?.id}
                      unit={currentQrRegisty?.unitDownload}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.qrScanContainer}>
                <div className={styles.firstQrTextContainer} style={{ paddingTop: '12px' }}>
                  <div className={styles.textBorderBlue}>
                    <p className={styles.textBlue}>{currentQrRegisty?.flightName}</p>
                  </div>
                  <div className={styles.qrTextBorder}>
                    <p className={styles.text}>{currentQrRegisty?.flightName}</p>
                  </div>
                </div>
                <div className={styles.firstQrTextContainer} style={{ paddingTop: '12px' }}>
                  <div>
                    <p className={styles.qrTopText}>Транспорт:</p>
                  </div>
                  <div style={{ maxWidth: '150px' }}>
                    <p style={{ textAlign: 'start' }} className={styles.text}>
                      {currentQrRegisty?.car?.carBrand?.brand}
                      {currentQrRegisty?.car?.stateNumber}
                    </p>
                    <p style={{ textAlign: 'start' }} className={styles.qrBottomText}>
                      {currentQrRegisty?.driver?.fullName}
                    </p>
                  </div>
                </div>
                <div className={styles.firstQrTextContainer} style={{ paddingTop: '12px' }}>
                  <div>
                    <p className={styles.qrTopText}>Загрузка:</p>
                  </div>
                  <div style={{ maxWidth: '150px', marginRight: '40px' }}>
                    <p style={{ textAlign: 'start' }} className={styles.text}>
                      {currentQrRegisty?.organization}
                    </p>
                    <p style={{ textAlign: 'start' }} className={styles.qrBottomText}>
                      {currentQrRegisty?.supplier?.title}
                    </p>
                  </div>
                </div>

                <div
                  className={styles.firstQrTextContainer}
                  style={{ display: 'block', paddingTop: '12px', borderBottom: '0px' }}
                >
                  <div>
                    <p
                      className={styles.qrBottomText}
                      style={{
                        color: ' #373D50',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                      }}
                    >
                      {currentQrRegisty?.nomenclature?.nameByDocument}
                    </p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px' }}>
                    <div>
                      <p className={styles.qrTopText}>Запланированный Объем:</p>
                    </div>
                    <div>
                      <p style={{ textAlign: 'start' }} className={styles.text}>
                        {currentQrRegisty?.volumePlan}
                      </p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px' }}>
                    <div>
                      <p className={styles.qrTopText}>Фактический Объем:</p>
                    </div>
                    <div>
                      <p style={{ textAlign: 'start' }} className={styles.text}>
                        {currentQrRegisty?.supplierFilledVolume}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </PageWrapper>
  );
};
