import React from 'react';
import styles from './ShowedFilters.module.scss';
import { CloseCircleNewSvg } from 'src/components/UI/svg-icon/CloseCircleNewSvg';
import { TFilterItem } from '../../catalogTypes';
import { formatNumber } from '../../utils';
import { useMediaQuery } from 'react-responsive';

type TShowedFilters = {
  combinedFilter: TFilterItem[];
  deleteFilter: (filterlabel: string, filterValue: string) => void;
  clearFilters: () => void;
};

export const ShowedFilters = ({ combinedFilter, deleteFilter, clearFilters }: TShowedFilters) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      {combinedFilter.map(item => {
        const value = item.value;
        const label = item.label;
        return (
          <>
            <div key={item.value} className={styles.container}>
              <p className={styles.text}>{label}</p>
              <div className={styles.button} onClick={() => deleteFilter(label, value)}>
                <CloseCircleNewSvg color={'var(--color-gray800)'} />
              </div>
            </div>
          </>
        );
      })}
      <div className={styles.clearButton} onClick={() => clearFilters()}>
        <p>{isMobile ? 'Очистить' : 'Очистить все фильтры'}</p>
      </div>
    </>
  );
};
