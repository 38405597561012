import React, { useContext } from 'react';
import { useState } from 'react';
import NotificationActiveSvg from '../../components/UI/svg-icon/NotificationActiveSvg';
import { Context } from '../../context/context';
import NotificationSvg from '../../components/UI/svg-icon/NotificationSvg';
import styles from '../Notices.module.scss';
import { CloseSvg } from '../../components/UI/svg-icon/CloseSvg';
import { fetchPost } from '../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import { declinationWord } from '../../common/declinationWord.helper';
import { toastError } from 'src/common/toastError.helper';

const Notices = () => {
  const { notices, setNotices } = useContext(Context);
  const [showNotices, setShowNotices] = useState(false);

  const userLs = JSON.parse(localStorage.getItem('user'));

  const objDeclension = {
    driver: ['новый рейс', 'новых рейса', 'новых рейсов'],
    supplier: ['новая отгрузка', 'новые отгрузки', 'новых отгрузок'],
    carrier: ['новя заявка', 'новые заявки', 'новых заявок'],
  };
  const sendMarkAsRead = async () => {
    try {
      if (notices.length) {
        const response = await fetchPost('/notices', 'PATCH', {
          noticeIds: notices.map(notice => notice.id),
        });
        if (toastError(response)) return;
        setNotices([]);
      }
      setShowNotices(false);
    } catch (e) {
      setShowNotices(false);
      console.log(e);
    }
  };
  return (
    <div style={{ position: 'relative', zIndex: 1000 }}>
      <div style={{ cursor: 'pointer' }} onClick={() => setShowNotices(!showNotices)}>
        {notices?.length ? <NotificationActiveSvg /> : <NotificationSvg />}
      </div>
      {showNotices && (
        <div>
          {notices?.length ? (
            <div className={styles.noticesCard}>
              <div className={styles.noticesCardHeaderContainer}>
                <p className={styles.noticesCardTitle}>Уведомления</p>
                <div className={styles.noticesCardCloseSvg} onClick={sendMarkAsRead}>
                  <CloseSvg />
                </div>
              </div>
              <div className={styles.noticesCardContentContainer}>
                <p className={styles.noticesCardDate}>{new Date().toLocaleString().split(',')[0]}</p>
                <p>
                  У Вас {notices?.length} {declinationWord(notices?.length, objDeclension[userLs?.type])}!
                </p>
              </div>
            </div>
          ) : (
            <div className={styles.noticesCard}>
              <div className={styles.noticesCardHeaderContainer}>
                <p className={styles.noticesCardTitle}>Уведомления</p>
                <div className={styles.noticesCardCloseSvg} onClick={sendMarkAsRead}>
                  <CloseSvg />
                </div>
              </div>
              <div className={styles.noticesCardNoContentContainer}>
                <p>У вас нет новых уведомлений</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notices;
