import { User } from './user.type';

export const localStorageRead = (): User => {
  const localStorageString = localStorage.getItem('user');
  return localStorageString
    ? JSON.parse(localStorageString as string)
    : {
        agreement: false,
        verified: false,
        id: null,
        block: false,
        blockDate: '',
        blockNumber: 0,
        isLogin: false,
        notification: true,
        companyId: null,
        type: '',
        phone: '',
        companyType: null,
        isInn: false,
        inn: '',
        isChangeInput: false,
        isPhone: false,
        apiKey: null,
        active: false,
        activeProfile: false,
        error: false,
        synchronize: false,
        preLoaded: false,
        deleteDriver: true,
      };
};

export const localStorageWrite = (user: User) => localStorage.setItem('user', JSON.stringify(user));
