import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../CustomHooks/storeHooks';
import { PageWrapper } from '../../../components/PageWrapper';
import MaterialTypes from './components/MaterialTypes/MaterialTypes';
import MaterialFields from './components/MaterialFields/MaterialFields';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import { IMaterialField, IRequestCreateMaterialObject } from '../type';
import styles from './EditAndAddMaterialPage.module.scss';
import {
  clearCurrentMaterialProperties,
  clearStateValue,
  createMaterial,
  deleteCurrentMaterial,
  editMaterial,
  getAllMaterials,
  getCurrentMaterialForEdit,
  handleChangeActiveMaterial,
  selectActiveMaterialTypeId,
  selectCurrentMaterialProperties,
  selectCurrentMaterialsGroup,
} from '../services/material/materialSlice';
import { fetchPostFile } from '../../../common/proxy-1C-fetch-auth';
import { createSendFileFD } from '../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Image } from '../../../common/image.type';
import { useMediaQuery } from 'react-responsive';
import { openPopUp } from '../../../common/open-close-popup.function';
import DeleteOutlineBlueSvg from '../../../components/UI/svg-icon/DeleteOutlineBlueSvg';
import ButtonNotFilled from '../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { IMaterialsGroup } from '../services/types';
import { Basket } from '../../../components/UI/svg-icon/Basket';

const EditAndAddMaterialPage = ({ edit = false }: { edit?: boolean }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [passportMaterial, setPassportMaterial] = useState<(File | Image)[]>([]);
  const [photoMaterial, setPhotoMaterial] = useState<(File | Image)[]>([]);
  const currentMaterialProperties = useAppSelector(selectCurrentMaterialProperties);
  const activeMaterialType = useAppSelector(selectActiveMaterialTypeId);
  const currentShowcaseId = localStorage.getItem('currentShowcaseId');
  const materialsGroup: IMaterialsGroup = useAppSelector(selectCurrentMaterialsGroup);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const materialId = pathname.split('/')[pathname.split('/').length - 2];
  const currentQuarryId = localStorage.getItem('currentQuarryId');
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const isEmptyProperty = currentMaterialProperties.some(property => property.isRequired && !property.value);
  const isFieldsEmpty = !currentMaterialProperties?.length || !passportMaterial?.length || isEmptyProperty;
  const handleCreateMaterial = async () => {
    const requestFieldObj: IRequestCreateMaterialObject = {
      quarryId: Number(currentQuarryId),
      materialId: activeMaterialType,
      properties: currentMaterialProperties.map((field: IMaterialField) => {
        return {
          propertyId: field.id,
          value: field.value || '',
        };
      }),
    };
    const editReqObj = {
      id: materialId,
      data: requestFieldObj,
    };
    if (!edit) {
      dispatch(createMaterial(requestFieldObj)).then(async res => {
        const fileUploadPhoto: any = photoMaterial.filter(file => !file.hasOwnProperty('location'));
        const fileUploadPassport: any = passportMaterial.filter(file => !file.hasOwnProperty('location'));
        const formDataPhoto = createSendFileFD(
          fileUploadPhoto,
          String(res.payload.response.id),
          'materials',
          'Фото материала',
        );
        const formDataPassport = createSendFileFD(
          fileUploadPassport,
          String(res.payload.response.id),
          'materials',
          'Паспорт',
        );
        await fetchPostFile('/gateway/files', 'POST', formDataPhoto);
        await fetchPostFile('/gateway/files', 'POST', formDataPassport);
        if (!res?.payload?.message) navigate(-1);
      });
    }
    if (edit) {
      dispatch(editMaterial(editReqObj)).then(async res => {
        const fileUploadPhoto: any = photoMaterial.filter(file => !file.hasOwnProperty('location'));
        const fileUploadPassport: any = passportMaterial.filter(file => !file.hasOwnProperty('location'));
        const formDataPhoto = createSendFileFD(fileUploadPhoto, String(materialId), 'materials', 'Фото материала');
        const formDataPassport = createSendFileFD(fileUploadPassport, String(materialId), 'materials', 'Паспорт');
        fileUploadPhoto.length && (await fetchPostFile('/gateway/files', 'POST', formDataPhoto));
        fileUploadPassport.length && (await fetchPostFile('/gateway/files', 'POST', formDataPassport));
        if (!res?.payload?.message) navigate(-1);
      });
    }
  };

  useEffect(() => {
    if (edit) {
      dispatch(getCurrentMaterialForEdit(Number(materialId))).then((res: any) => {
        dispatch(handleChangeActiveMaterial(res.payload.response.materialTypeId));
        res.payload.response.files.forEach(file => {
          if (file.category === 'Паспорт') {
            setPassportMaterial(prevState => [...prevState, file]);
            return;
          }
          setPhotoMaterial(prevState => [...prevState, file]);
        });
      });
    }
    dispatch(getAllMaterials());
    return () => {
      dispatch(handleChangeActiveMaterial(0));
      dispatch(clearCurrentMaterialProperties(''));
    };
  }, []);
  useEffect(() => {
    if (edit && isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    if (!edit) {
      setPassportMaterial([]);
      setPhotoMaterial([]);
    }
  }, [activeMaterialType]);
  return (
    <PageWrapper>
      <div className="button-back" onClick={() => navigate(-1)}>
        <div className="button-back__svg">
          <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
              fill="currentColor"
            />
          </svg>
        </div>
        Вернуться назад
      </div>
      <div className={styles.headerContainer}>
        <h1 className={styles.headerTitle}>{edit ? 'Редактирование материала' : 'Добавление материала'}</h1>
        {!isMobile && (
          <div className={styles.headerBtnContainer}>
            {edit && (
              <ButtonNotFilled
                onClick={() => {
                  dispatch(deleteCurrentMaterial(Number(materialId))).then(res => {
                    materialsGroup?.materials?.length > 1 ? navigate(-1) : navigate(-2);
                  });
                }}
                width={'137px'}
                sizeText="small"
                text={'Удалить'}
                svg={<Basket />}
              />
            )}
            <ButtonFilled
              disabled={isFieldsEmpty}
              text={'Сохранить'}
              sizeText="small"
              width={'137px'}
              onClick={() => {
                handleCreateMaterial();
              }}
            />
          </div>
        )}
      </div>
      <MaterialTypes
        edit={edit}
        setActiveMaterialType={value => dispatch(handleChangeActiveMaterial(value))}
        activeMaterialType={activeMaterialType}
      />
      <MaterialFields
        passportMaterial={passportMaterial}
        setPassportMaterial={setPassportMaterial}
        photoMaterial={photoMaterial}
        setPhotoMaterial={setPhotoMaterial}
      />
      {isMobile && (
        <div className={styles.mobileBtnContainer}>
          {edit && (
            <ButtonNotFilled
              onClick={() => {
                dispatch(deleteCurrentMaterial(Number(materialId))).then(res => {
                  materialsGroup?.materials?.length > 1 ? navigate(-1) : navigate(-2);
                });
              }}
              width={'100%'}
              sizeText="small"
              text={'Удалить'}
              svg={<Basket />}
            />
          )}
          <ButtonFilled
            text={'Сохранить'}
            sizeText="small"
            width={'100%'}
            onClick={() => {
              handleCreateMaterial();
            }}
          />
        </div>
      )}
    </PageWrapper>
  );
};

export default EditAndAddMaterialPage;
