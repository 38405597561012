import React, { useContext, useEffect, useState } from 'react';
import styles from './NewAuthPhone.module.scss';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { Context } from '../../context/context';
import { useMediaQuery } from 'react-responsive';
import AuthLineSvg from '../../components/UI/svg-icon/AuthLineSvg';
import { AUTHSUBSERVER } from '../../common/sub-server.constant';
import { localStorageWrite } from '../../common/user/local-storage.helper';
import FooterAuth from './footerAuth/FooterAuth';
import AuthLineMobileSvg from '../../components/UI/svg-icon/AuthLineMobileSvg';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import LogoMobileSvg from '../../components/UI/svg-icon/LogoMobileSvg';
const NewAuthPhone = () => {
  const [active, setActive] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { setUser, user } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [timer, setTimer] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('user');
  }, []);
  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);
  const sendPhone = async () => {
    const requestOptionsExist: any = {
      method: 'GET',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    const requestOptions: any = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone: `+${user.phone}` }),
    };
    try {
      const responseExist = await fetch(`${AUTHSUBSERVER}/api/auth/exist?phone=%2B${user.phone}`, requestOptionsExist);
      let responseIdentificate;
      if (responseExist.status === 204) {
        responseIdentificate = await fetch(`${AUTHSUBSERVER}/api/auth/identificate`, requestOptions);
        if (responseIdentificate?.status === 204) {
          setUser({ ...user, isInn: true, isPhone: true, agreement: true, isFirstLogin: false });
          localStorageWrite(user);
          navigate('/authSms');
        }
      }
      if (responseIdentificate?.status === 400) {
        const data = await responseIdentificate?.json();
        let convertDate = Number(data?.message.slice(9).split('.')[0]) + 1;
        setTimer(convertDate);
        return;
      }

      if (responseExist?.status === 404) {
        toast.error('Данный номер ещё не зарегистрирован');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const convertVisualOfTimerCount = (): string => {
    if (timer < 10) {
      return `00:0${timer}`;
    } else {
      return `00:${timer}`;
    }
  };

  return (
    <div>
      {isMobile ? (
        <div className={styles.mainContainer}>
          <div className={styles.container}>
            <div className={styles.headerLogo}>
              <div className={styles.headerLogoContent}>
                <LogoMobileSvg />
                <p className={styles.textLogo}>
                  Платформа нерудных
                  <br />
                  материалов
                </p>
              </div>
            </div>
            <div className={styles.phoneContainer}>
              <div className={styles.svgContainer}>
                <AuthLineMobileSvg />
              </div>
              <div className={styles.headerTextContainer}>
                <h1 className={styles.titleTextContainer}>Войти</h1>
                <p className={styles.descriptionInputContainer}>
                  Чтобы войти , введите свой номер телефона.
                  <br />
                  На указанный номер телефона поступит sms-сообщение с кодом для ввода и авторизации
                </p>
              </div>
              <div className={styles.inputContainer}>
                <MyPhoneInput
                  onEnterDisabled={timer !== 0}
                  disabled={false}
                  setIsError={setError}
                  isError={error}
                  isActive={active}
                  setIsActive={setActive}
                  style={{ marginTop: isMobile ? '24px' : '40px', backgroundColor: '#FFF' }}
                  value={user.phone}
                  onChange={value => {
                    setUser({ ...user, phone: value });
                  }}
                  isFunction={sendPhone}
                  btnHelp={true}
                />
                {timer > 0 && (
                  <div className={styles.codeCounter}>
                    Получить новый код <br />
                    можно через {convertVisualOfTimerCount()}
                  </div>
                )}

                <div style={{ marginTop: isMobile ? '16px' : '24px', zIndex: '20' }}>
                  <ButtonFilled
                    text={'Войти'}
                    width={'100%'}
                    onClick={() => sendPhone()}
                    disabled={timer > 0 || !user.phone || user.phone === '+7'}
                  />
                </div>
                <div className={styles.registerTextContainer}>
                  Нет аккаунта?
                  <p
                    className={styles.registerText}
                    onClick={() => {
                      setUser({ ...user, phone: '' });
                      navigate('/registerRole');
                    }}
                  >
                    &nbsp;Зарегистрироваться
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        /*Компьютерная версия*/
        <div className={styles.mainContainer}>
          <div className={styles.container}>
            <div className={styles.phoneContainer}>
              <div className={styles.svgContainer}>
                <AuthLineSvg />
              </div>
              <div className={styles.headerTextContainer}>
                <h1 className={styles.titleTextContainer}>Войти</h1>
                <p className={styles.descriptionInputContainer}>
                  Чтобы войти , введите свой номер телефона.
                  <br />
                  На указанный номер телефона поступит sms-сообщение с кодом для ввода и авторизации
                </p>
              </div>
              <div className={styles.inputContainer}>
                <MyPhoneInput
                  onEnterDisabled={timer !== 0}
                  disabled={false}
                  setIsError={setError}
                  isError={error}
                  isActive={active}
                  setIsActive={setActive}
                  style={{ marginTop: isMobile ? '24px' : '40px', backgroundColor: '#FFF' }}
                  value={user.phone}
                  onChange={value => {
                    setUser({ ...user, phone: value });
                  }}
                  isFunction={sendPhone}
                  btnHelp={true}
                />
                {timer > 0 && (
                  <div className={styles.codeCounter}>
                    Получить новый код <br />
                    можно через {convertVisualOfTimerCount()}
                  </div>
                )}
                <div style={{ marginTop: isMobile ? '16px' : '24px', zIndex: 20 }}>
                  <ButtonFilled
                    text={'Войти'}
                    width={'100%'}
                    onClick={() => sendPhone()}
                    disabled={timer > 0 || !user.phone || user.phone === '+7'}
                  />
                </div>

                <div className={styles.registerTextContainer}>
                  Нет аккаунта?
                  <p
                    className={styles.registerText}
                    onClick={() => {
                      setUser({ ...user, phone: '' });
                      navigate('/registerRole');
                    }}
                  >
                    &nbsp;Зарегистрироваться
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterAuth />
        </div>
      )}
    </div>
  );
};

export default NewAuthPhone;
