import React, { useContext, useRef } from 'react';
import { ProfileErrorSvg } from '../UI/svg-icon/ProfileErrorSvg';
import { ProfileSvg } from '../UI/svg-icon/ProfileSvg';
import { ProfileSvgActive } from '../UI/svg-icon/ProfileSvgActive';
import { HelpSvg } from '../UI/svg-icon/HelpSvg';
import { HelpSvgActive } from '../UI/svg-icon/HelpSvgActive';

import stylesTour from './Tour.module.scss';
// @ts-ignore
import styles from './NewAsideNavSupplier.module.css';
import NewNavItem from '../newUI/NewNavItem/NewNavItem';
import { FlightSvg } from '../UI/svg-icon/FlightSvg';
import { FlightSvgActive } from '../UI/svg-icon/FlightSvgActive';
import MainSvg from '../UI/svg-icon/MainSvg';
import MainSvgActive from '../UI/svg-icon/MainSvgActive';
import { Tour, TourProps } from 'antd';
import { Context } from '../../context/context';
import MainTourImg from '../../assets/img/TourImages/MainTourImg.png';
import FlightDriverTourImg from '../../assets/img/TourImages/FlightDriverTourImg.png';
import ProfileTourImg from '../../assets/img/TourImages/ProfileTourImg.png';
import NavIdeaItem from './components/NavIdeaItem/NavIdeaItem';/*
import { GsmSvg } from '../UI/svg-icon/GsmSvg';
import { GsmSvgActive } from '../UI/svg-icon/GsmSvgActive';*/

const NewAsideNavDriver = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  /*const ref5 = useRef(null);*/
  const { setStartTour, startTour,user } = useContext(Context);

  const steps: TourProps['steps'] = [
    {
      title: <p className={stylesTour.title}>Главная</p>,
      description:
        'Отслеживайте размер своей заработной платы за выполненные рейсы за день, месяц или период. ' +
        'Просматривайте информацию по активному рейсу в удобном формате.',
      placement: 'right',
      type: 'default',
      cover: <img alt="tour.png" src={MainTourImg} />,
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnFirstNext },
      target: () => ref1.current,
    },
    {
      title: <p className={stylesTour.title}>Рейсы</p>,
      placement: 'right',
      cover: <img alt="tour.png" src={FlightDriverTourImg} />,
      description:
        'Отслеживайте количество активных и выполненных рейсов и просматривайте информацию о них. ' +
        'Проставляйте различные статусы по мере выполнения рейса: ' +
        '«Выехал на загрузку», «Начал загрузку», «Загрузился», «Начал выгрузку», «Выгрузился», «Выполнен». ' +
        'Добавляйте транспортную накладную в карточке рейса.' +
        ' В этом же разделе вам доступен индивидуальный QR-код, который необходимо показать на месте загрузки вместо бумажной доверенности.',
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      target: () => ref2.current,
    },
    {
      title: <p className={stylesTour.title}>Профиль</p>,
      placement: 'right',
      cover: <img alt="tour.png" src={ProfileTourImg} />,
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description: 'Просматривайте свои личные данные, а также информацию о паспорте и водительском удостоверении.',
      target: () => ref3.current,
    },
    {
      title: <p className={stylesTour.title}>Помощь</p>,
      placement: 'right',
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description: 'Изучайте ответы на самые часто задаваемые вопросы.',
      target: () => ref4.current,
    },
  ];
  const navLink = [
    {
      name: 'Главная',
      ref: ref1,
      link: '/main',
      type: ['driver'],
      notActiveProfile: true,
      svg: <MainSvg />,
      svgActive: <MainSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Рейсы',
      ref: ref2,
      link: '/flights',
      type: ['driver'],
      notActiveProfile: true,
      svg: <FlightSvg />,
      svgActive: <FlightSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },/*
    {
      name: 'ГСМ',
      ref: ref5,
      link: '/gsm',
      type: ['driver'],
      notActiveProfile: true,
      svg: <GsmSvg />,
      svgActive: <GsmSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },*/
    {
      name: 'Профиль',
      link: '/profile',
      ref: ref3,
      type: ['driver'],
      notActiveProfile: true,
      svg: <ProfileSvg />,
      svgActive: <ProfileSvgActive />,
      svgProfileError: <ProfileErrorSvg />,
      errorProfile: true,
    },
    {
      name: 'Помощь',
      link: '/help',
      ref: ref4,
      type: ['driver'],
      notActiveProfile: false,
      svg: <HelpSvg />,
      svgActive: <HelpSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
  ];
  return (
    <div className={styles.AsideBox} style={{ zIndex: startTour ? '1000' : '9000' }}>
      {navLink.map(({ name, link, ref, type, notActiveProfile, svg, svgActive, svgProfileError, errorProfile }) => (
        <div ref={ref}>
          <NewNavItem
            ref={ref}
            disabled={null}
            typeAccess={type}
            key={name}
            name={name}
            link={link}
            notActiveProfile={notActiveProfile}
            svg={svg}
            svgActive={svgActive}
            svgProfileError={svgProfileError}
            errorProfile={errorProfile}
          />
        </div>
      ))}
      <Tour
        open={startTour}
        gap={{ offset: [-6, -2], radius: 12 }}
        onClose={() => setStartTour(false)}
        steps={steps}
        indicatorsRender={(current, total) => <span></span>}
      />

      {user?.verified&&
          <NavIdeaItem/>
      }
    </div>
  );
};

export default NewAsideNavDriver;
