import React, { useContext } from 'react';
import styles from './MobileCardTableSpecifications.module.scss';
import { DataResponseDocuments } from '../../../DocumentsPages/type';
// @ts-ignore
import LoadingGif from '../../../../assets/img/Loader-Solber.gif';
import { EmptyList } from '../../../../components/EmptyList';
import { Context } from '../../../../context/context';
import { useNavigate } from 'react-router-dom';
import QuestionOutlineSvgLowerWidth from '../../../../components/UI/svg-icon/QuestionOutlineSvgLowerWidth';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import { statusColor, statusTextTooltip } from '../../utils';
import { DownloadDocumentSvg } from 'src/components/UI/svg-icon/DownloadDocumentSvg';
import { tabNames } from 'src/Pages/DocumentsPages/DocumentsPage';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';
interface IProps {
  dataResponse: DataResponseDocuments[];
  activityOfSpecifications: { value: string; label: string } | undefined | null;
  loading: boolean;
  tab: string;
  downloadFile: (link: string, name: string, mimeType: string) => void;
}
const MobileCardTableSpecifications = ({
  dataResponse,
  activityOfSpecifications,
  loading,
  tab,
  downloadFile,
}: IProps) => {
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const Spin = () => {
    return (
      <div style={{ marginTop: '90px' }}>
        <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
      </div>
    );
  };

  const handleDownloadClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: DataResponseDocuments) => {
    const { location, name, mimeType } = item?.files;
    e.stopPropagation();
    downloadFile(location, name, mimeType);
  };
  return (
    <>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Spin />
        </div>
      ) : (
        <>
          {dataResponse?.length ? (
            <div className={styles.containerMain}>
              {dataResponse.map(item => (
                <div
                  key={item?.id}
                  className={styles.containerCard}
                  onClick={() => {
                    if (tab !== tabNames.OTHERDOCUMENTS) navigate(`/document/specifications/${item?.id}`);
                  }}
                >
                  {activityOfSpecifications.value === 'open' || tab === tabNames.OTHERDOCUMENTS ? (
                    <div className={styles.statusAndButton}>
                      <p className={statusColor[item?.status]}>
                        {item?.status}
                        <div>
                          {tab !== tabNames.OTHERDOCUMENTS && (
                            <CustomTooltip
                              SideTooltip={'up'}
                              svg={<QuestionOutlineSvgLowerWidth />}
                              positionTooltipBottom={'23px'}
                              text={statusTextTooltip[item?.status]}
                              widthTooltip={'fitContent'}
                              widthSvg={'16px'}
                              styleWindowTooltip={{ whiteSpace: 'nowrap' }}
                            />
                          )}
                        </div>
                      </p>
                      {tab === tabNames.OTHERDOCUMENTS && item?.files?.location && (
                        <ButtonSimple
                          isText={false}
                          text={''}
                          onClick={e => handleDownloadClick(e, item)}
                          svg={<DownloadDocumentSvg />}
                        />
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={styles.stringContainer}>
                    <p className={styles.titleText}>{tab === tabNames.OTHERDOCUMENTS ? 'Номер:' : 'Номер договора:'}</p>
                    <p className={styles.infoText}>{item?.title || item?.number}</p>
                  </div>
                  {user.companyType === 'carrier' ? (
                    <div className={styles.stringContainer}>
                      <p className={styles.titleText}>
                        {tab === tabNames.OTHERDOCUMENTS ? 'Тип документа:' : 'Вид договора:'}
                      </p>
                      <p className={styles.infoText}>{item?.contractType || item?.type}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={styles.stringContainer}>
                    <p className={styles.titleText}>Дата начала:</p>
                    <p className={styles.infoText}>
                      {item?.date ? item?.date?.slice(0, 10).split('-').reverse().join('.') : '—'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <EmptyList title={'Документов не найдено'} subTitle={'В данном разделе пока нет документов'} />
          )}
        </>
      )}
    </>
  );
};

export default MobileCardTableSpecifications;
