// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackButton_backButtonAndTextContainer__0RyJU {\n  cursor: pointer;\n  display: flex;\n  gap: 12px;\n  align-items: center;\n  color: #6C779C;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n\n.BackButton_backButton__mI1a6 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 12px;\n}\n\n.BackButton_textBackButton__YXDda {\n  margin-top: 2px;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/newUI/BackButton/BackButton.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,cAAA;EACA,uBAAA;EAAA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF","sourcesContent":[".backButtonAndTextContainer{\n  cursor: pointer;\n  display: flex;\n  gap: 12px;\n  align-items: center;\n  color: #6C779C;\n  width: fit-content;\n}\n\n.backButton{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 12px;\n}\n\n.textBackButton{\n  margin-top: 2px;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButtonAndTextContainer": "BackButton_backButtonAndTextContainer__0RyJU",
	"backButton": "BackButton_backButton__mI1a6",
	"textBackButton": "BackButton_textBackButton__YXDda"
};
export default ___CSS_LOADER_EXPORT___;
