import React from 'react';
import styles from './ShowcaseMainInfoField.module.scss';

export const ShowcaseMainInfoField = ({ name, value }: { name: string; value: string }) => {
  return (
    <div className={styles.subtitle}>
      <p className={styles.subtitleText}>{name}</p>
      <p className={styles.subtitleText + ' ' + styles.subtitleTextInfo}>{value}</p>
    </div>
  );
};
