// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowedFilters_container__4A28A {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: 40px;\n  gap: 16px;\n  padding: 8px 16px;\n  background-color: var(--color-orange10);\n  border-radius: 8px;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n\n.ShowedFilters_text__VbarI {\n  text-wrap: nowrap;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  color: var(--color-gray800);\n}\n\n.ShowedFilters_button__bqYKo {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.ShowedFilters_clearButton__SwQdV {\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 700;\n  color: var(--color-orange400);\n  text-wrap: nowrap;\n  padding: 4px 10px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/Pages/Catalog/components/ShowedFilters/ShowedFilters.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,SAAA;EACA,iBAAA;EACA,uCAAA;EACA,kBAAA;EACA,uBAAA;EAAA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,6BAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: 40px;\n  gap: 16px;\n  padding: 8px 16px;\n  background-color: var(--color-orange10);\n  border-radius: 8px;\n  width: fit-content;\n}\n\n.text {\n  text-wrap: nowrap;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  color: var(--color-gray800);\n}\n\n.button {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.clearButton {\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 700;\n  color: var(--color-orange400);\n  text-wrap: nowrap;\n  padding: 4px 10px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ShowedFilters_container__4A28A",
	"text": "ShowedFilters_text__VbarI",
	"button": "ShowedFilters_button__bqYKo",
	"clearButton": "ShowedFilters_clearButton__SwQdV"
};
export default ___CSS_LOADER_EXPORT___;
