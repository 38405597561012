import React from 'react';
interface IProps {
    width?:string
    height?:string
}
const BackGroundGsmCardSvg = ({ width='100%',height='100%' }:IProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 165 188" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Road">
                <g clipPath="url(#clip0_27813_74332)">
                    <g id="Vector">
                        <path d="M88.2214 19.0078V53.4677H86.9828V19.0078C86.9828 8.41103 78.3541 -0.217735 67.7574 -0.217735H41.5958V-1.45631H67.7711C79.0422 -1.45631 88.2214 7.72293 88.2214 19.0078Z" fill="url(#paint0_linear_27813_74332)"/>
                        <path d="M85.3307 19.0078V53.4677H84.0921V19.0078C84.0921 10.0074 76.7707 2.68604 67.7704 2.68604H41.6089V1.44746H67.7704C77.4451 1.4337 85.3307 9.31932 85.3307 19.0078Z" fill="url(#paint1_linear_27813_74332)"/>
                        <path d="M82.4405 19.0078V53.4677H81.2019V19.0078C81.2019 11.6038 75.1742 5.57605 67.7702 5.57605H41.6087V4.33748H67.7702C75.8485 4.33748 82.4405 10.9157 82.4405 19.0078Z" fill="url(#paint2_linear_27813_74332)"/>
                        <path d="M79.5512 19.0077V53.4677H78.3127V19.0077C78.3127 13.2002 73.5785 8.46606 67.771 8.46606H41.6095V7.22748H67.771C74.2666 7.22748 79.5512 12.5121 79.5512 19.0077Z" fill="url(#paint3_linear_27813_74332)"/>
                        <path d="M76.6471 19.0077V53.4677H75.4085V19.0077C75.4085 14.7966 71.9818 11.3561 67.7569 11.3561H41.5954V10.1175H67.7706C72.6699 10.1175 76.6471 14.1085 76.6471 19.0077Z" fill="url(#paint4_linear_27813_74332)"/>
                        <path d="M73.7569 19.0077V53.4677H72.5183V19.0077C72.5183 16.393 70.3852 14.2599 67.7704 14.2599H41.6089V13.0213H67.7704C71.0733 13.0075 73.7569 15.7049 73.7569 19.0077Z" fill="url(#paint5_linear_27813_74332)"/>
                        <path d="M47.4714 54.7048C36.8747 54.7048 28.2459 63.3336 28.2459 73.9303C28.2459 84.527 36.8609 93.1558 47.4714 93.1558H97.9778C101.281 93.1558 103.964 95.8394 103.964 99.1422C103.964 102.445 101.281 105.142 97.9778 105.142V103.904C100.593 103.904 102.726 101.771 102.726 99.156C102.726 96.5412 100.593 94.4081 97.9778 94.4081H47.4714C36.1866 94.4081 27.0073 85.2289 27.0073 73.9441C27.0073 62.6593 36.1866 53.48 47.4714 53.48H94.2483V54.7186H47.4714V54.7048Z" fill="url(#paint6_linear_27813_74332)"/>
                        <path d="M47.4722 57.6093C38.4719 57.6093 31.1505 64.9306 31.1505 73.931C31.1505 82.9313 38.4719 90.2527 47.4722 90.2527H97.9786C102.878 90.2527 106.869 94.2437 106.869 99.1429C106.869 104.042 102.878 108.033 97.9786 108.033L97.9786 106.795C102.19 106.795 105.63 103.368 105.63 99.1429C105.63 94.9317 102.204 91.4913 97.9786 91.4913H47.4722C37.7838 91.4913 29.9119 83.6057 29.9119 73.931C29.9119 64.2425 37.7975 56.3707 47.4722 56.3707H94.2491V57.6093H47.4722Z" fill="url(#paint7_linear_27813_74332)"/>
                        <path d="M47.4724 60.4995C40.0685 60.4995 34.0407 66.5272 34.0407 73.9312C34.0407 81.3351 40.0685 87.3629 47.4724 87.3629H97.9788C104.474 87.3629 109.759 92.6475 109.759 99.1431C109.759 105.639 104.474 110.923 97.9788 110.923L97.9788 109.685C103.786 109.685 108.521 104.951 108.521 99.1431C108.521 93.3356 103.786 88.6014 97.9788 88.6014H47.4724C39.3804 88.6014 32.8021 82.0232 32.8021 73.9312C32.8021 65.8391 39.3804 59.2609 47.4724 59.2609H94.2493V60.4995H47.4724Z" fill="url(#paint8_linear_27813_74332)"/>
                        <path d="M47.4717 63.3892C41.6641 63.3892 36.93 68.1234 36.93 73.9309C36.93 79.7385 41.6641 84.4726 47.4717 84.4726H97.9781C106.07 84.4726 112.648 91.0508 112.648 99.1428C112.648 107.235 106.07 113.813 97.9781 113.813V112.575C105.382 112.575 111.41 106.547 111.41 99.1428C111.41 91.7389 105.382 85.7112 97.9781 85.7112H47.4717C40.976 85.7112 35.6914 80.4266 35.6914 73.9309C35.6914 67.4353 40.976 62.1507 47.4717 62.1507H94.2486V63.3892H47.4717Z" fill="url(#paint9_linear_27813_74332)"/>
                        <path d="M47.4733 66.2799C43.2621 66.2799 39.8216 69.7067 39.8216 73.9316C39.8216 78.1427 43.2483 81.5832 47.4733 81.5832H97.9797C107.668 81.5832 115.54 89.4688 115.54 99.1435C115.54 108.832 107.654 116.704 97.9797 116.704L97.9797 115.465C106.98 115.465 114.301 108.144 114.301 99.1435C114.301 90.1432 106.98 82.8218 97.9797 82.8218H47.4733C42.574 82.8218 38.583 78.8308 38.583 73.9316C38.583 69.0323 42.574 65.0414 47.4733 65.0414H94.2502V66.2799H47.4733Z" fill="url(#paint10_linear_27813_74332)"/>
                        <path d="M47.4722 69.1826C44.8574 69.1826 42.7243 71.3158 42.7243 73.9305C42.7243 76.5453 44.8574 78.6784 47.4722 78.6784H97.9786C109.263 78.6784 118.443 87.8576 118.443 99.1425C118.443 110.427 109.263 119.607 97.9786 119.607L97.9786 118.368C108.575 118.368 117.204 109.739 117.204 99.1425C117.204 88.5457 108.575 79.917 97.9786 79.917H47.4722C44.1693 79.917 41.4857 77.2334 41.4857 73.9305C41.4857 70.6276 44.1693 67.9303 47.4722 67.9303H94.2492V69.1689H47.4722V69.1826Z" fill="url(#paint11_linear_27813_74332)"/>
                        <path d="M82.2348 -15.5897C82.2348 -4.99297 90.8635 3.63578 101.46 3.63578H144.896V4.87436H101.46C90.1754 4.87436 80.9962 -4.30487 80.9962 -15.5897C80.9962 -16.209 81.0236 -26.7277 81.0787 -27.3332H82.3172C82.2622 -26.7277 82.2348 -16.209 82.2348 -15.5897Z" fill="url(#paint12_linear_27813_74332)"/>
                        <path d="M85.1248 -15.5905C85.1248 -6.59016 92.4462 0.731209 101.446 0.731209H144.896V1.96979H101.46C91.7718 1.96979 83.9 -5.91582 83.9 -15.5905C83.9 -16.2098 83.9274 -26.7285 83.9962 -27.334H85.2485C85.166 -26.7285 85.1248 -16.2098 85.1248 -15.5905Z" fill="url(#paint13_linear_27813_74332)"/>
                        <path d="M88.0148 -15.5911C88.0148 -8.18721 94.0426 -2.15947 101.446 -2.15947H144.896V-0.920891H101.46C93.3682 -0.920891 86.79 -7.49911 86.79 -15.5911C86.79 -16.2104 86.8312 -26.7291 86.9137 -27.3347H88.1661C88.0697 -26.7291 88.0148 -16.2242 88.0148 -15.5911Z" fill="url(#paint14_linear_27813_74332)"/>
                        <path d="M90.9048 -15.5914C90.9048 -9.7838 95.6389 -5.04968 101.446 -5.04968H144.896V-3.81111H101.46C94.9646 -3.81111 89.68 -9.0957 89.68 -15.5914C89.68 -16.2106 89.7349 -26.7293 89.8313 -27.3349H91.0974C90.9735 -26.7431 90.9048 -16.2244 90.9048 -15.5914Z" fill="url(#paint15_linear_27813_74332)"/>
                        <path d="M93.8086 -15.5897C93.8086 -11.3785 97.2353 -7.93805 101.46 -7.93805H144.896V-6.69947H101.46C96.561 -6.69947 92.57 -10.6904 92.57 -15.5897C92.57 -16.2227 92.6387 -26.7414 92.7626 -27.3332H94.0424C93.8911 -26.7414 93.8086 -16.2227 93.8086 -15.5897Z" fill="url(#paint16_linear_27813_74332)"/>
                        <path d="M96.6986 -15.591C96.6986 -12.9762 98.8317 -10.8431 101.446 -10.8431H144.896V-9.6045H101.46C98.1574 -9.6045 95.4738 -12.2881 95.4738 -15.591C95.4738 -16.2378 95.5838 -26.7565 95.7627 -27.3345H97.0838C96.8361 -26.7702 96.6986 -16.2515 96.6986 -15.591Z" fill="url(#paint17_linear_27813_74332)"/>
                        <path d="M41.6099 -31.2238V25.7773C41.6099 28.3921 43.743 30.5252 46.3578 30.5252C48.9726 30.5252 51.1057 28.3921 51.1057 25.7773V14.2586H52.3443V25.7773C52.3443 29.0802 49.6607 31.7638 46.344 31.7638C43.0412 31.7638 40.3576 29.0802 40.3576 25.7773V-31.2238H41.6099Z" fill="url(#paint18_linear_27813_74332)"/>
                        <path d="M30.0361 -28.7337V25.7773C30.0361 34.7777 37.3575 42.099 46.3578 42.099C55.3582 42.099 62.6795 34.7777 62.6795 25.7773V14.2173H63.9181V25.7773C63.9181 35.4658 56.0325 43.3376 46.3578 43.3376C36.6694 43.3376 28.7975 35.452 28.7975 25.7773V-28.7337H30.0361Z" fill="url(#paint19_linear_27813_74332)"/>
                        <path d="M27.1317 -28.8523V25.7768C27.1317 36.3735 35.7605 45.0022 46.3572 45.0022C56.9539 45.0022 65.5827 36.3735 65.5827 25.7768V14.258H66.8213V25.7768C66.8213 37.0616 57.642 46.2408 46.3572 46.2408C35.0724 46.2408 25.8932 37.0616 25.8932 25.7768V-28.8523H27.1317Z" fill="url(#paint20_linear_27813_74332)"/>
                        <path d="M38.706 25.7772C38.706 29.9884 42.1328 33.4289 46.3577 33.4289C50.5688 33.4289 54.0093 30.0021 54.0093 25.7772V14.2584H55.2479V25.7772C55.2479 30.6765 51.2569 34.6674 46.3577 34.6674C41.4584 34.6674 37.4674 30.6765 37.4674 25.7772V-31.3423H38.6923V25.7772H38.706Z" fill="url(#paint21_linear_27813_74332)"/>
                        <path d="M35.8157 25.7769C35.8157 31.5845 40.5499 36.3186 46.3574 36.3186C52.165 36.3186 56.8991 31.5845 56.8991 25.7769V14.2582H58.1377V25.7769C58.1377 32.2726 52.8531 37.5572 46.3574 37.5572C39.8618 37.5572 34.5772 32.2726 34.5772 25.7769V-28.6152H35.8157V25.7769Z" fill="url(#paint22_linear_27813_74332)"/>
                        <path d="M32.9264 25.7776C32.9264 33.1815 38.9541 39.2093 46.3581 39.2093C53.762 39.2093 59.7898 33.1815 59.7898 25.7776V14.2588H61.0283V25.7776C61.0283 33.8697 54.4501 40.4479 46.3581 40.4479C38.266 40.4479 31.6878 33.8697 31.6878 25.7776V-29.208H32.9264V25.7776Z" fill="url(#paint23_linear_27813_74332)"/>
                        <path d="M19.1156 -43.4484V-6.16721C19.1156 -2.86434 21.7992 -0.180752 25.1021 -0.180752H26.0654V-1.41933H25.1021C22.4873 -1.41933 20.3542 -3.55244 20.3542 -6.16721V-43.4484C20.3542 -45.7466 19.9689 -47.9486 19.267 -49.9991H17.9596C18.7028 -47.9486 19.1156 -45.7466 19.1156 -43.4484Z" fill="url(#paint24_linear_27813_74332)"/>
                        <path d="M16.2106 -43.4481V-6.16687C16.2106 -1.26761 20.2016 2.72336 25.1009 2.72336H26.0642V1.48478H25.1009C20.8897 1.48478 17.4492 -1.94194 17.4492 -6.16687V-43.4481C17.4492 -45.7601 16.9951 -47.9757 16.1694 -49.9987H14.8207C15.7152 -47.9895 16.2106 -45.7738 16.2106 -43.4481Z" fill="url(#paint25_linear_27813_74332)"/>
                        <path d="M13.3228 -43.4488V-6.16759C13.3228 0.328056 18.6074 5.61265 25.103 5.61265H26.0664V4.37407H25.103C19.2955 4.37407 14.5614 -0.360043 14.5614 -6.16759V-43.4488C14.5614 -45.8021 13.9971 -48.0315 13.0063 -49.9995H11.6025C12.7035 -48.059 13.3228 -45.8158 13.3228 -43.4488Z" fill="url(#paint26_linear_27813_74332)"/>
                        <path d="M10.4316 -43.4481V-6.16693C10.4316 1.9251 17.0098 8.50333 25.1019 8.50333H26.0652V7.26475H25.1019C17.6979 7.26475 11.6702 1.23701 11.6702 -6.16693V-43.4481C11.6702 -45.8702 10.927 -48.1272 9.6747 -49.9988H8.13336C9.56461 -48.196 10.4316 -45.9253 10.4316 -43.4481Z" fill="url(#paint27_linear_27813_74332)"/>
                        <path d="M7.54143 -43.4493V-6.16812C7.54143 3.52031 15.427 11.3922 25.1017 11.3922H26.065V10.1536H25.1017C16.1014 10.1536 8.78001 2.83221 8.78001 -6.16812V-43.4493C8.78001 -46.0503 7.65153 -48.3761 5.87623 -50H3.81193C6.02761 -48.6651 7.54143 -46.2292 7.54143 -43.4493Z" fill="url(#paint28_linear_27813_74332)"/>
                        <path d="M4.63779 -43.4471V-6.16593C4.63779 5.11889 13.817 14.2981 25.1018 14.2981H26.0652V13.0595H25.1018C14.5051 13.0595 5.87636 4.43079 5.87636 -6.16593V-43.4471C5.87636 -46.7087 3.24783 -49.3785 0 -49.4336V-48.195C2.57349 -48.1262 4.63779 -46.0206 4.63779 -43.4471Z" fill="url(#paint29_linear_27813_74332)"/>
                        <path d="M133.52 144.424H135.984C137.222 144.424 137.896 144.424 138.282 144.465V144.424C141.585 144.424 144.282 147.107 144.282 150.41V167.447C144.282 170.75 141.598 173.448 138.282 173.448C134.979 173.448 132.295 170.764 132.295 167.447V129.753C132.295 126.45 134.979 123.753 138.282 123.753H185.153V124.992H138.282C135.667 124.992 133.534 127.125 133.534 129.74V167.447C133.534 170.062 135.667 172.195 138.282 172.195C140.897 172.195 143.03 170.062 143.03 167.447V150.424C143.03 147.809 140.897 145.676 138.282 145.676H137.869C136.74 145.676 133.534 145.676 133.534 145.676" fill="url(#paint30_linear_27813_74332)"/>
                        <path d="M130.63 129.753V167.448C130.63 171.659 134.057 175.099 138.282 175.099C142.507 175.099 145.933 171.672 145.933 167.448V150.424C145.933 146.213 142.507 142.772 138.282 142.772H137.869C136.74 142.772 133.699 142.772 133.699 142.772H133.534V141.534H135.997C137.236 141.534 137.91 141.534 138.296 141.575V141.534C143.195 141.534 147.186 145.525 147.186 150.424V167.448C147.186 172.347 143.195 176.338 138.296 176.338C133.396 176.338 129.405 172.347 129.405 167.448V129.753C129.405 124.854 133.396 120.863 138.296 120.863H185.153V122.102H138.282C134.057 122.116 130.63 125.542 130.63 129.753Z" fill="url(#paint31_linear_27813_74332)"/>
                        <path d="M127.74 129.752V167.446C127.74 173.253 132.474 177.988 138.282 177.988C144.089 177.988 148.823 173.253 148.823 167.446V150.422C148.823 144.615 144.089 139.881 138.282 139.881H137.869C136.727 139.881 133.616 139.881 133.616 139.881H133.534V138.642H135.956C137.222 138.642 137.91 138.642 138.296 138.683V138.642C144.791 138.642 150.076 143.927 150.076 150.422V167.446C150.076 173.942 144.791 179.226 138.296 179.226C131.8 179.226 126.515 173.942 126.515 167.446V129.752C126.515 123.256 131.8 117.972 138.296 117.972H185.153V119.21H138.282C132.46 119.21 127.74 123.944 127.74 129.752Z" fill="url(#paint32_linear_27813_74332)"/>
                        <path d="M124.851 129.753V167.447C124.851 174.851 130.878 180.879 138.282 180.879C145.686 180.879 151.714 174.851 151.714 167.447V150.423C151.714 143.02 145.686 136.992 138.282 136.992H137.869C136.727 136.992 133.631 136.992 133.631 136.992H133.534V135.753L135.956 135.739C137.223 135.739 137.911 135.739 138.296 135.781V135.739C146.388 135.739 152.966 142.318 152.966 150.41V167.447C152.966 175.539 146.388 182.117 138.296 182.117C130.204 182.117 123.626 175.539 123.626 167.447V129.753C123.626 121.661 130.204 115.083 138.296 115.083H185.154V116.321H138.282C130.865 116.321 124.851 122.349 124.851 129.753Z" fill="url(#paint33_linear_27813_74332)"/>
                        <path d="M121.946 129.752V167.446C121.946 176.447 129.268 183.768 138.268 183.768C147.268 183.768 154.603 176.447 154.603 167.446V150.423C154.603 141.422 147.282 134.101 138.282 134.101H137.869C136.727 134.101 133.616 134.101 133.616 134.101H133.534V132.862H135.956C137.222 132.862 137.91 132.862 138.296 132.904V132.862C147.984 132.862 155.856 140.748 155.856 150.423V167.446C155.856 177.135 147.97 185.007 138.296 185.007C128.621 185.007 120.735 177.121 120.735 167.446V129.752C120.735 120.064 128.621 112.192 138.296 112.192H185.153V113.431H138.282C129.281 113.431 121.946 120.752 121.946 129.752Z" fill="url(#paint34_linear_27813_74332)"/>
                        <path d="M119.056 129.752V167.446C119.056 178.043 127.685 186.672 138.282 186.672C148.878 186.672 157.493 178.043 157.493 167.446V150.423C157.493 139.826 148.865 131.197 138.268 131.197H137.855C136.685 131.197 133.52 131.211 133.52 131.211V129.972L137.511 129.959C137.855 129.959 138.089 129.959 138.268 129.972V129.959C149.553 129.959 158.732 139.138 158.732 150.423V167.446C158.732 178.731 149.553 187.91 138.268 187.91C126.983 187.91 117.804 178.731 117.804 167.446V129.752C117.804 118.467 126.983 109.288 138.268 109.288H185.153V110.527H138.282C127.685 110.54 119.056 119.155 119.056 129.752Z" fill="url(#paint35_linear_27813_74332)"/>
                        <path d="M135.177 31.1345V82.0538C135.177 84.6686 137.31 86.8017 139.925 86.8017C142.54 86.8017 144.673 84.6686 144.673 82.0538V45.7203H145.912V82.0676C145.912 85.3704 143.228 88.054 139.911 88.054C136.608 88.054 133.925 85.3704 133.925 82.0676V31.1483C133.925 20.5516 125.296 11.9228 114.699 11.9228C104.103 11.9228 95.4739 20.5516 95.4739 31.1483V78.9298H94.2353V31.1483C94.2353 19.8635 103.415 10.6842 114.699 10.6842C125.998 10.6704 135.177 19.8497 135.177 31.1345Z" fill="url(#paint36_linear_27813_74332)"/>
                        <path d="M147.577 44.6531V82.041C147.577 86.266 144.136 89.6927 139.925 89.6927C135.7 89.6927 132.274 86.2522 132.274 82.041V31.1217C132.274 21.4471 124.402 13.5615 114.713 13.5615C105.039 13.5615 97.153 21.4333 97.153 31.1217V78.9033H97.9787C98.0475 78.9033 98.1163 78.9068 98.1851 78.9102C98.2539 78.9136 98.3227 78.9171 98.3916 78.9171V31.1217C98.3916 22.1214 105.713 14.8 114.713 14.8C123.714 14.8 131.035 22.1214 131.035 31.1217V82.041C131.035 86.9403 135.026 90.9313 139.925 90.9313C144.824 90.9313 148.815 86.9403 148.815 82.041V44.2974L147.577 44.6531Z" fill="url(#paint37_linear_27813_74332)"/>
                        <path d="M150.467 43.1116V82.0537C150.467 87.8613 145.733 92.5954 139.925 92.5954C134.118 92.5954 129.384 87.8613 129.384 82.0537V31.1345C129.384 23.0424 122.805 16.4642 114.713 16.4642C106.621 16.4642 100.043 23.0424 100.043 31.1345V79.0123C100.456 79.0536 100.869 79.1087 101.282 79.1775V31.1345C101.282 23.7305 107.309 17.7028 114.713 17.7028C122.117 17.7028 128.145 23.7305 128.145 31.1345V82.0537C128.145 88.5494 133.43 93.834 139.925 93.834C146.421 93.834 151.705 88.5494 151.705 82.0537V42.4002L150.467 43.1116Z" fill="url(#paint38_linear_27813_74332)"/>
                        <path d="M153.357 41.0959V82.0534C153.357 89.4573 147.329 95.485 139.925 95.485C132.521 95.485 126.494 89.4573 126.494 82.0534V31.1341C126.494 24.6384 121.209 19.3538 114.713 19.3538C108.218 19.3538 102.933 24.6384 102.933 31.1341H102.947V79.5074C103.36 79.6175 103.772 79.7413 104.185 79.8652V31.1203C104.185 25.3127 108.919 20.5786 114.727 20.5786C120.535 20.5786 125.269 25.3127 125.269 31.1203V82.0396C125.269 90.1316 131.847 96.7099 139.939 96.7099C148.031 96.7099 154.609 90.1316 154.609 82.0396V40.1473L153.357 41.0959Z" fill="url(#paint39_linear_27813_74332)"/>
                        <path d="M156.247 37.7759V82.0546C156.247 91.0549 148.925 98.3763 139.925 98.3763C130.925 98.3763 123.603 91.0549 123.603 82.0546V31.1353C123.603 26.2361 119.612 22.2451 114.713 22.2451C109.814 22.2451 105.823 26.2361 105.823 31.1353V80.4857C106.236 80.6509 106.649 80.8436 107.061 81.05V31.1353C107.061 26.9104 110.502 23.4837 114.713 23.4837C118.938 23.4837 122.365 26.9241 122.365 31.1353V82.0546C122.365 91.7293 130.237 99.6149 139.925 99.6149C149.6 99.6149 157.485 91.743 157.485 82.0546V36.1158L156.247 37.7759Z" fill="url(#paint40_linear_27813_74332)"/>
                        <path d="M159.165 31.8472V82.0526C159.165 92.6494 150.536 101.278 139.939 101.278C129.342 101.278 120.714 92.6494 120.714 82.0526V31.1334C120.714 27.8167 118.016 25.1331 114.713 25.1331C111.411 25.1331 108.727 27.8305 108.727 31.1334H108.741V81.9701C109.167 82.2315 109.58 82.5205 109.979 82.8095V31.1334C109.979 28.5186 112.112 26.3855 114.727 26.3855C117.342 26.3855 119.475 28.5186 119.475 31.1334V82.0526C119.475 93.3375 128.654 102.517 139.939 102.517C151.224 102.517 160.403 93.3375 160.403 82.0526V26.2743L159.165 31.8472Z" fill="url(#paint41_linear_27813_74332)"/>
                        <path d="M151.711 -23.1714V26.3969C151.711 32.8925 146.427 38.1771 139.931 38.1771H134.816V36.9386H139.931C145.738 36.9386 150.473 32.2044 150.473 26.3969V-23.1714H151.711Z" fill="url(#paint42_linear_27813_74332)"/>
                        <path d="M153.364 -23.1714V26.3976C153.364 33.8015 147.336 39.8292 139.932 39.8292H134.817V41.0678H139.932C148.024 41.0678 154.602 34.4896 154.602 26.3976V-23.1714H153.364Z" fill="url(#paint43_linear_27813_74332)"/>
                        <path d="M156.254 -23.1714V26.3964C156.254 35.3967 148.933 42.7181 139.932 42.7181H134.818V43.9566H139.932C149.607 43.9566 157.492 36.0848 157.492 26.3964V-23.1714H156.254Z" fill="url(#paint44_linear_27813_74332)"/>
                        <path d="M159.158 -23.1714V26.3986C159.158 36.9953 150.529 45.624 139.932 45.624H134.817V46.8626H139.932C151.217 46.8626 160.396 37.6834 160.396 26.3986V-23.1714H159.158Z" fill="url(#paint45_linear_27813_74332)"/>
                        <path d="M144.68 -23.1714V26.3973C144.68 29.012 142.547 31.1452 139.932 31.1452H134.817V32.3837H139.932C143.235 32.3837 145.918 29.7001 145.918 26.3973V-23.1714H144.68Z" fill="url(#paint46_linear_27813_74332)"/>
                        <path d="M147.585 -23.1714V26.3976C147.585 30.6225 144.144 34.0493 139.933 34.0493H134.818V35.2878H139.933C144.832 35.2878 148.823 31.2969 148.823 26.3976V-23.1714H147.585Z" fill="url(#paint47_linear_27813_74332)"/>
                        <path d="M91.9085 105.147C81.2978 105.147 72.6577 113.787 72.6577 124.398C72.6577 135.009 81.284 143.649 91.9085 143.649H117.95V144.903H91.9085C80.6088 144.903 71.4175 135.712 71.4175 124.412C71.4175 113.112 80.6088 103.921 91.9085 103.921H97.9871V105.161H91.9085V105.147Z" fill="url(#paint48_linear_27813_74332)"/>
                        <path d="M91.9093 108.056C82.8971 108.056 75.5661 115.387 75.5661 124.399C75.5661 133.411 82.8971 140.742 91.9093 140.742H117.951C117.951 140.742 117.951 137.077 117.951 141.982V140.742C117.951 136.525 117.951 141.982 117.951 141.982H91.9093C82.2081 141.982 74.3259 134.086 74.3259 124.399C74.3259 114.698 82.2219 106.815 91.9093 106.815H97.9879V108.056H91.9093Z" fill="url(#paint49_linear_27813_74332)"/>
                        <path d="M91.9095 110.95C84.4958 110.95 78.4601 116.985 78.4601 124.399C78.4601 131.813 84.4958 137.848 91.9095 137.848H117.951V139.089H91.9095C83.8068 139.089 77.2199 132.502 77.2199 124.399C77.2199 116.296 83.8068 109.709 91.9095 109.709H97.9881V110.95H91.9095Z" fill="url(#paint50_linear_27813_74332)"/>
                        <path d="M91.9088 113.843C86.0936 113.843 81.3532 118.584 81.3532 124.399C81.3532 130.214 86.0936 134.954 91.9088 134.954H117.95V136.195H91.9088C85.4046 136.195 80.113 130.903 80.113 124.399C80.113 117.895 85.4046 112.603 91.9088 112.603H97.9874V113.843H91.9088Z" fill="url(#paint51_linear_27813_74332)"/>
                        <path d="M91.9104 116.738C87.6936 116.738 84.2486 120.169 84.2486 124.399C84.2486 128.616 87.6799 132.061 91.9104 132.061H117.952C117.952 141.762 117.952 133.301 117.952 133.301H91.9104C87.0046 133.301 83.0084 129.305 83.0084 124.399C83.0084 119.494 87.0046 115.497 91.9104 115.497H97.989V116.738H91.9104Z" fill="url(#paint52_linear_27813_74332)"/>
                        <path d="M91.9093 119.644C89.2911 119.644 87.1552 121.78 87.1552 124.398C87.1552 127.017 89.2911 129.153 91.9093 129.153H117.951V130.393C117.951 141.004 117.952 130.211 117.951 130.393L91.9093 130.393C88.6021 130.393 85.9149 127.706 85.9149 124.398C85.9149 121.091 88.6021 118.39 91.9093 118.39H97.9879V119.63H91.9093V119.644Z" fill="url(#paint53_linear_27813_74332)"/>
                    </g>
                </g>
            </g>
            <defs>
                <linearGradient id="paint0_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint1_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint2_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint3_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint4_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint5_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint6_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint7_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint8_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint9_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint10_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint11_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint12_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint13_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint14_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint15_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint16_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint17_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint18_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint19_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint20_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint21_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint22_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint23_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint24_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint25_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint26_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint27_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint28_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint29_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint30_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint31_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint32_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint33_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint34_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint35_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint36_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint37_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint38_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint39_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint40_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint41_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint42_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint43_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint44_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint45_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint46_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint47_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint48_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint49_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint50_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint51_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint52_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <linearGradient id="paint53_linear_27813_74332" x1="2.41789e-06" y1="-7.5" x2="156" y2="180" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#EBF1FF"/>
                    <stop offset="1" stopColor="#CDD3E6"/>
                </linearGradient>
                <clipPath id="clip0_27813_74332">
                    <path d="M0 0H153C159.627 0 165 5.37258 165 12V188H0V0Z" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default BackGroundGsmCardSvg;