import React from 'react';
import { ImageContainer } from '../../../components/ImageContainer';
import { ButtonClose } from '../../../components/UI/buttons/buttonClose';
import { removeImage } from '../../../common/removeImage';

const ImagesContainer = ({ setImages, images, openPopupFunc, deleteFile, disabled = false }) => {
  return (
    <div>
      {images.map(({ id, location, name, size, isSize, date, isDate, mimeType, communicationCategory }) => (
        <div className="image-container" key={id}>
          <ImageContainer
            id={id}
            value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location}
            name={name}
            size={size}
            isSize={isSize}
            date={date}
            isDate={isDate}
            type={mimeType}
            openPopUp={() => (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupFunc(location)}
          >
            <ButtonClose
              id={id}
              onClick={({ target }) => {
                !disabled && deleteFile(id, name, communicationCategory);
                !disabled && removeImage(target, images, setImages);
              }}
              hidden=""
            />
          </ImageContainer>
        </div>
      ))}
    </div>
  );
};

export default ImagesContainer;
