// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Filters_container__gV3Br {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.Filters_twoFiltersString__HdFBi {\n  display: flex;\n  gap: 16px;\n}\n\n@media (max-width: 768px) {\n  .Filters_container__gV3Br {\n    gap: 24px;\n  }\n  .Filters_twoFiltersString__HdFBi {\n    flex-direction: column;\n    gap: 8px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/GSMNewPageCarrier/components/Filters/Filters.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE;IACE,SAAA;EACF;EACA;IACE,sBAAA;IACA,QAAA;EACF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.twoFiltersString {\n  display: flex;\n  gap: 16px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    gap: 24px;\n  }\n  .twoFiltersString {\n    flex-direction: column;\n    gap: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Filters_container__gV3Br",
	"twoFiltersString": "Filters_twoFiltersString__HdFBi"
};
export default ___CSS_LOADER_EXPORT___;
