import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import { Modal, Tabs } from 'antd';
// @ts-ignore
import styles from './ShipmentsPage.module.scss';
import AwaitingShipment from './AwaitingShipment/AwaitingShipment';
import Shipped from './Shipped/Shipped';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import TextBoxSearchOutlineSvg from '../../components/UI/svg-icon/textBoxSearchOutlineSvg';
import CustomInputValueAndCheckBox from '../../components/newUI/CustomInputValueAndCheckBox/CustomInputValueAndCheckBox';
import { IDataResponseAwaitingShipped, IDataResponseShipped, IFilter } from './type';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import { toastError } from 'src/common/toastError.helper';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';

export const ShipmentsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [tab, setTab] = useState<string>('1');
  const [dataCount, setDataCount] = useState<string>();
  const [currentQrRegisty, setCurrentQrRegisty] = useState<any>('');
  const [awaitingShipmentIds, setAwaitingShipmentIds] = useState<any>();
  const [shipmentIds, setShipmentIds] = useState<any>();
  const [qrMessage, setQrMessage] = useState<string>('');
  const [isQr, setIsQr] = useState<boolean>(false);
  const [updatePage, setUpdatePage] = useState<boolean>(false);

  const [filter, setFilter] = useState(null);

  const callBackDataCount = (count: string): void => {
    setDataCount(count);
  };
  useEffect(() => {
    setUpdatePage(!updatePage);
  }, [isQr]);

  const callBackDataAwaitingShipment = (flights: IDataResponseAwaitingShipped[]): void => {
    setAwaitingShipmentIds(
      flights.map(flight => {
        return Number(flight?.actualLoading?.id);
      }),
    );
  };

  const callBackDataShipment = (flights: IDataResponseShipped[]): void => {
    setShipmentIds(
      flights.map(flight => {
        return Number(flight?.flight?.id);
      }),
    );
  };
  const callBackDataFilter = (filter: IFilter): void => {
    setFilter(filter);
  };
  const handleCancelModal = (): void => {
    setIsQr(false);
  };
  const getCurrentQrRegisty = async () => {
    try {
      const response = await fetchGet(`/shipments/${qrMessage}`, {});
      setCurrentQrRegisty(response?.response);
      if (toastError(response)) return;
      setIsQr(true);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (qrMessage !== '') {
      getCurrentQrRegisty();
    }
  }, [qrMessage]);
  const dowmloadRegistry = async ids => {
    const individualids = new Set(
      ids.map(id => {
        return id;
      }),
    );
    try {
      const response = await fetchPost(`/attorneys/generate-registry `, 'POST', {
        ids: Array.from(individualids),
      });
      if (toastError(response)) return;

      const link = document.createElement('a');
      link.download = response?.response?.name;

      link.href = response?.response?.file;

      link.click();

      return;
    } catch (e) {
      console.log(e);
    }
  };
  const downloadRegistryShipped = async () => {
    try {
      const response = await fetchPost(`/reports/generate-shipment-registry `, 'POST', {
        ids: Array.from(new Set(shipmentIds)),
      });
      if (toastError(response)) return;

      const link = document.createElement('a');
      link.download = 'Реестр выполненных отгрузок';

      link.href = response?.response?.file;

      link.click();

      return;
    } catch (e) {
      console.log(e);
    }
  };
  const tabs: {
    key: string;
    label: string;
    children: JSX.Element;
  }[] = [
    {
      key: '1',
      label: 'Ожидают погрузки',
      children: (
        <>
          <div className={styles.blockRegister}>
            {isMobile ? (
              <div></div>
            ) : (
              <ButtonSimple
                text={'Сформировать реестр доверенностей'}
                onClick={() => dowmloadRegistry(awaitingShipmentIds)}
              />
            )}
          </div>
          <AwaitingShipment
            updatePage={updatePage}
            callBackDataAwaitingShipment={callBackDataAwaitingShipment}
            callBackDataCount={callBackDataCount}
            tab={tab}
            isQr={isQr}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Отгруженные',
      children: (
        <>
          <div className={styles.blockRegister}>
            {!isMobile && <ButtonSimple text={'Сформировать документ'} onClick={() => downloadRegistryShipped()} />}
          </div>
          <Shipped
            updatePage={updatePage}
            callBackDataCount={callBackDataCount}
            callBackDataFilter={callBackDataFilter}
            callBackDataShipment={callBackDataShipment}
            tab={tab}
          />
        </>
      ),
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
  };

  return (
    <PageWrapper>
      <div className={'shipments-page'}>
        <div className={styles.titleAndSubtitleContainer}>
          <TitleMain title={'Отгрузки'} />
          {tab === '1' ? (
            <SubTitleMain subTitle={`У вас ${dataCount} рейсов ожидают погрузки`} />
          ) : (
            <SubTitleMain subTitle={`За все время вы отгрузили ${dataCount} рейса`} />
          )}
        </div>
        {isMobile ? (
          <div className={styles.mobileBlockRegister}>
            {tab === '1' ? (
              <ButtonSimple
                text={'Сформировать реестр доверенностей'}
                onClick={() => dowmloadRegistry(awaitingShipmentIds)}
              />
            ) : (
              <ButtonSimple text={'Сформировать документ'} onClick={() => downloadRegistryShipped()} />
            )}
          </div>
        ) : (
          <div></div>
        )}
        {isMobile ? (
          <div className={styles.mobileTabContainer}>
            <Tabs style={{ marginTop: '-12px' }} defaultActiveKey={tab} items={tabs} onChange={onChange} />
          </div>
        ) : (
          <Tabs defaultActiveKey={tab} items={tabs} onChange={onChange} />
        )}
        <Modal
          style={{
            maxWidth: '100vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'normal',
            justifyContent: 'flex-end',
            height: '90vh',
            paddingBottom: '0px',
          }}
          title={'Сканировать QR-код'}
          onCancel={handleCancelModal}
          open={isQr}
          footer={null}
        >
          <div>
            {currentQrRegisty?.status === 'active' ? (
              <div className={styles.qrScanContainer}>
                <div className={styles.firstQrTextContainer}>
                  <div>
                    <TextBoxSearchOutlineSvg />
                  </div>
                  <div className={styles.qrTextBorder}>
                    <p className={styles.text}>{currentQrRegisty?.flightName}</p>
                  </div>
                </div>
                <div className={styles.firstQrTextContainer} style={{ paddingTop: '12px' }}>
                  <div>
                    <p className={styles.qrTopText}>Транспорт:</p>
                  </div>
                  <div style={{ maxWidth: '150px' }}>
                    <p style={{ textAlign: 'start' }} className={styles.text}>
                      {currentQrRegisty?.car?.carBrand?.brand}
                      {currentQrRegisty?.car?.stateNumber}
                    </p>
                    <p style={{ textAlign: 'start' }} className={styles.qrBottomText}>
                      {currentQrRegisty?.driver?.fullName}
                    </p>
                  </div>
                </div>
                <div className={styles.firstQrTextContainer} style={{ paddingTop: '12px' }}>
                  <div>
                    <p className={styles.qrTopText}>Загрузка:</p>
                  </div>
                  <div style={{ maxWidth: '150px', marginRight: '40px' }}>
                    <p style={{ textAlign: 'start' }} className={styles.text}>
                      {currentQrRegisty?.organization}
                    </p>
                    <p style={{ textAlign: 'start' }} className={styles.qrBottomText}>
                      {currentQrRegisty?.supplier?.title}
                    </p>
                  </div>
                </div>

                <div className={styles.firstQrTextContainer} style={{ display: 'block', paddingTop: '12px' }}>
                  <div>
                    <p
                      className={styles.qrBottomText}
                      style={{
                        color: ' #373D50',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                      }}
                    >
                      {currentQrRegisty?.nomenclature?.nameByDocument}
                    </p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px' }}>
                    <div>
                      <p className={styles.qrTopText}>Запланированный Объем:</p>
                    </div>
                    <div>
                      <p style={{ textAlign: 'start' }} className={styles.text}>
                        {currentQrRegisty?.volumePlan}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.firstQrTextContainer}
                  style={{ display: 'block', borderBottom: '0px', paddingTop: '12px' }}
                >
                  <div>
                    <p className={styles.qrTopText}>Фактическая погрузка с ед. измерения:</p>
                  </div>
                  <div style={{ paddingTop: '12px' }}>
                    <CustomInputValueAndCheckBox
                      setTab={setTab}
                      setIsQr={setIsQr}
                      id={currentQrRegisty?.id}
                      unit={currentQrRegisty?.unitDownload}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.qrScanContainer}>
                <div className={styles.firstQrTextContainer} style={{ paddingTop: '12px' }}>
                  <div className={styles.textBorderBlue}>
                    <p className={styles.textBlue}>{currentQrRegisty?.flightName}</p>
                  </div>
                  <div className={styles.qrTextBorder}>
                    <p className={styles.text}>{currentQrRegisty?.flightName}</p>
                  </div>
                </div>
                <div className={styles.firstQrTextContainer} style={{ paddingTop: '12px' }}>
                  <div>
                    <p className={styles.qrTopText}>Транспорт:</p>
                  </div>
                  <div style={{ maxWidth: '150px' }}>
                    <p style={{ textAlign: 'start' }} className={styles.text}>
                      {currentQrRegisty?.car?.carBrand?.brand}
                      {currentQrRegisty?.car?.stateNumber}
                    </p>
                    <p style={{ textAlign: 'start' }} className={styles.qrBottomText}>
                      {currentQrRegisty?.driver?.fullName}
                    </p>
                  </div>
                </div>
                <div className={styles.firstQrTextContainer} style={{ paddingTop: '12px' }}>
                  <div>
                    <p className={styles.qrTopText}>Загрузка:</p>
                  </div>
                  <div style={{ maxWidth: '150px', marginRight: '40px' }}>
                    <p style={{ textAlign: 'start' }} className={styles.text}>
                      {currentQrRegisty?.organization}
                    </p>
                    <p style={{ textAlign: 'start' }} className={styles.qrBottomText}>
                      {currentQrRegisty?.supplier?.title}
                    </p>
                  </div>
                </div>

                <div
                  className={styles.firstQrTextContainer}
                  style={{ display: 'block', paddingTop: '12px', borderBottom: '0px' }}
                >
                  <div>
                    <p
                      className={styles.qrBottomText}
                      style={{
                        color: ' #373D50',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                      }}
                    >
                      {currentQrRegisty?.nomenclature?.nameByDocument}
                    </p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px' }}>
                    <div>
                      <p className={styles.qrTopText}>Запланированный Объем:</p>
                    </div>
                    <div>
                      <p style={{ textAlign: 'start' }} className={styles.text}>
                        {currentQrRegisty?.volumePlan}
                      </p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px' }}>
                    <div>
                      <p className={styles.qrTopText}>Фактический Объем:</p>
                    </div>
                    <div>
                      <p style={{ textAlign: 'start' }} className={styles.text}>
                        {currentQrRegisty?.supplierFilledVolume}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </PageWrapper>
  );
};
