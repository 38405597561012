import React from 'react';
import styles from '../../GSMCardAdd.module.scss';
import BackGroundGSMCardSvg from '../../../../../../components/UI/svg-icon/BackGroundGSMCardSvg';
import CustomTooltip from '../../../../../../components/newUI/CustomTooltip/CustomTooltip';
import InfoSvg from '../../../../../../components/UI/svg-icon/InfoSvg';
import AddGSMSvg from '../../../../../../components/UI/svg-icon/AddGSMSvg';
import { useMediaQuery } from 'react-responsive';
interface IProps {
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
  drivers: { value: number; label: string }[];
  checkAvailableDrivers: boolean;
}
const GridCardAdd = ({ setOpenModalOfAddGSMCard, drivers, checkAvailableDrivers }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div
      className={styles.container}
      onClick={() => checkAvailableDrivers && drivers.length && setOpenModalOfAddGSMCard(true)}
    >
      <div className={styles.tooltipContainer} onClick={event => event.stopPropagation()}>
        <CustomTooltip
          svg={<InfoSvg color={checkAvailableDrivers ? '#6C779C' : '#DC2626'} />}
          text={'Топливных карт не может быть больше, чем водителей'}
          widthTooltip={isMobile ? '250px' : '400px'}
          widthSvg={'16px'}
          positionTooltipLeft={'-13px'}
          positionTooltipBottom={'23px'}
          MobileSideTooltip={'right'}
        />
      </div>
      <AddGSMSvg />
      <div>
        <p
          style={{
            textDecoration: checkAvailableDrivers ? 'unset' : 'underline',
          }}
          className={checkAvailableDrivers ? styles.mainTextCard : styles.redMainTextCard}
        >
          {checkAvailableDrivers ? 'Добавить топливную' : 'Добавьте водителя,'}
        </p>
        <p className={checkAvailableDrivers ? styles.mainTextCard : styles.redMainTextCard}>
          {checkAvailableDrivers ? 'карту' : 'чтобы добавить топливную карту'}
        </p>
      </div>
      <div className={styles.backGround}>
        <BackGroundGSMCardSvg width={isMobile ? '134px' : '165px'} height={isMobile ? '153px' : '188px'} />
      </div>
    </div>
  );
};

export default GridCardAdd;
