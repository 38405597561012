import React from 'react';
import { loadingImages } from '../common/loading-image.function';
import { ClipSvg } from './UI/svg-icon/ClipSvg';

export const ProfilePageUploading = ({ name, desc, setImages, images, count, error = false, disabled = false }) => {
  return (
    <div className="ProfilePageUploading">
      <input
        disabled={disabled}
        id="InputUploading"
        type={'file'}
        accept={'image/, .png,.jpg,.jpeg,.pdf,.heic'}
        onChange={({ target }: any) => loadingImages(setImages, images, target, count)}
      />
      <label className={`ProfilePageUploading__label${error ? '__error' : ''}`} htmlFor="InputUploading">
        <div className="ProfilePageUploading__title">
          <div className="ProfilePageUploading__svg">
            <ClipSvg />
          </div>
          <div className={`ProfilePageUploading__text${error ? '__error' : ''}`}>
            {name && <span>{name}</span>}
            {desc && <span>{desc}</span>}
          </div>
        </div>
      </label>
    </div>
  );
};
