import React from 'react';

export const CalendarSvg = ({ width = '20px' }: { width?: string }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.6665 9.99967C1.6665 6.85698 1.6665 5.28563 2.64281 4.30932C3.61913 3.33301 5.19047 3.33301 8.33317 3.33301H11.6665C14.8092 3.33301 16.3805 3.33301 17.3569 4.30932C18.3332 5.28563 18.3332 6.85698 18.3332 9.99967V11.6663C18.3332 14.809 18.3332 16.3804 17.3569 17.3567C16.3805 18.333 14.8092 18.333 11.6665 18.333H8.33317C5.19047 18.333 3.61913 18.333 2.64281 17.3567C1.6665 16.3804 1.6665 14.809 1.6665 11.6663V9.99967Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path d="M5.8335 3.33301V2.08301" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      <path d="M14.1665 3.33301V2.08301" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      <path d="M2.0835 7.5H17.9168" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      <path
        d="M15.0002 14.1667C15.0002 14.6269 14.6271 15 14.1668 15C13.7066 15 13.3335 14.6269 13.3335 14.1667C13.3335 13.7064 13.7066 13.3333 14.1668 13.3333C14.6271 13.3333 15.0002 13.7064 15.0002 14.1667Z"
        fill="currentColor"
      />
      <path
        d="M15.0002 10.8333C15.0002 11.2936 14.6271 11.6667 14.1668 11.6667C13.7066 11.6667 13.3335 11.2936 13.3335 10.8333C13.3335 10.3731 13.7066 10 14.1668 10C14.6271 10 15.0002 10.3731 15.0002 10.8333Z"
        fill="currentColor"
      />
      <path
        d="M10.8332 14.1667C10.8332 14.6269 10.4601 15 9.99984 15C9.5396 15 9.1665 14.6269 9.1665 14.1667C9.1665 13.7064 9.5396 13.3333 9.99984 13.3333C10.4601 13.3333 10.8332 13.7064 10.8332 14.1667Z"
        fill="currentColor"
      />
      <path
        d="M10.8332 10.8333C10.8332 11.2936 10.4601 11.6667 9.99984 11.6667C9.5396 11.6667 9.1665 11.2936 9.1665 10.8333C9.1665 10.3731 9.5396 10 9.99984 10C10.4601 10 10.8332 10.3731 10.8332 10.8333Z"
        fill="currentColor"
      />
      <path
        d="M6.66667 14.1667C6.66667 14.6269 6.29357 15 5.83333 15C5.3731 15 5 14.6269 5 14.1667C5 13.7064 5.3731 13.3333 5.83333 13.3333C6.29357 13.3333 6.66667 13.7064 6.66667 14.1667Z"
        fill="currentColor"
      />
      <path
        d="M6.66667 10.8333C6.66667 11.2936 6.29357 11.6667 5.83333 11.6667C5.3731 11.6667 5 11.2936 5 10.8333C5 10.3731 5.3731 10 5.83333 10C6.29357 10 6.66667 10.3731 6.66667 10.8333Z"
        fill="currentColor"
      />
    </svg>
  );
};
