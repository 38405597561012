import React, { useState } from 'react';
import styles from './GSMNewCard.module.scss';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { WalletGSMSvg } from 'src/components/UI/svg-icon/WalletGSMSvg';
import { formCardNumber } from 'src/common/formCardNumber.helper';
import { ModalNew } from 'src/components/ModalNew/ModalNew';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../../components/UI/svg-icon/CloseSvg';

export const GSMNewCard = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <span className={styles.cardNumber}>{formCardNumber('3455456277103507')}</span>
        <div className={styles.statusContainer}>Активна</div>
      </div>
      <div className={styles.priceContainer}>
        <div style={{ paddingTop: '4px' }}>
          <WalletGSMSvg />
        </div>
        <span className={styles.price}>{`0 ₽`}</span>
      </div>
      <ButtonFilled text={'Заправиться'} height={'40px'} width={'100%'} onClick={() => setOpenModal(true)} />
      {openModal && (
        <ModalNew
          openModal={openModal}
          setOpenModal={setOpenModal}
          children={
            <div className={styles.modalContainer}>
              <div className={styles.mobileCloseButtonAndTitle}>
                <p className={styles.modalTitle}>Подтвердите переход</p>
                {isMobile && (
                  <div className={styles.closeButton} onClick={() => setOpenModal(false)}>
                    <CloseSvg />
                  </div>
                )}
              </div>
              <div className={styles.modalSubtitle}>
                Нажимая кнопку “Продолжить” вы подтверждаете переход в другой сервис
              </div>
              <div className={styles.modalButtonsContainer}>
                <ButtonNotFilled
                  text="Отмена"
                  sizeText={isMobile ? 'small' : 'big'}
                  onClick={() => setOpenModal(false)}
                />
                <ButtonFilled text="Продолжить" sizeText={isMobile ? 'small' : 'big'} />
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};
