// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowcaseMainInfo_mainInfoContainer__8SmhX {\n  display: flex;\n  flex-direction: row;\n  gap: 24px;\n}\n\n.ShowcaseMainInfo_columnContainer__l7C-s {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 603px;\n}\n\n.ShowcaseMainInfo_line__PGgda {\n  border-right: 1px solid var(--color-gray200);\n}\n\n.ShowcaseMainInfo_list__lBR3R {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-row-gap: 16px;\n}\n\n@media (max-width: 768px) {\n  .ShowcaseMainInfo_mainInfoContainer__8SmhX {\n    flex-direction: column;\n    gap: 16px;\n    padding: 2px 0;\n  }\n  .ShowcaseMainInfo_columnContainer__l7C-s {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/ShowcasesPage/components/ShowcaseMainInfo/ShowcaseMainInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;AACF;;AAEA;EACE,4CAAA;AACF;;AAEA;EACE,aAAA;EACA,0BAAA;EACA,kBAAA;AACF;;AAEA;EACE;IACE,sBAAA;IACA,SAAA;IACA,cAAA;EACF;EAEA;IACE,WAAA;EAAF;AACF","sourcesContent":[".mainInfoContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 24px;\n}\n\n.columnContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 603px;\n}\n\n.line {\n  border-right: 1px solid var(--color-gray200);\n}\n\n.list {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-row-gap: 16px;\n}\n\n@media (max-width: 768px) {\n  .mainInfoContainer {\n    flex-direction: column;\n    gap: 16px;\n    padding: 2px 0;\n  }\n\n  .columnContainer {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainInfoContainer": "ShowcaseMainInfo_mainInfoContainer__8SmhX",
	"columnContainer": "ShowcaseMainInfo_columnContainer__l7C-s",
	"line": "ShowcaseMainInfo_line__PGgda",
	"list": "ShowcaseMainInfo_list__lBR3R"
};
export default ___CSS_LOADER_EXPORT___;
