import React from 'react';

export const GsmSvgActive = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="gas">
              <path id="Vector" d="M18 10C17.7348 10 17.4804 9.89464 17.2929 9.70711C17.1054 9.51957 17 9.26522 17 9C17 8.73478 17.1054 8.48043 17.2929 8.29289C17.4804 8.10536 17.7348 8 18 8C18.2652 8 18.5196 8.10536 18.7071 8.29289C18.8946 8.48043 19 8.73478 19 9C19 9.26522 18.8946 9.51957 18.7071 9.70711C18.5196 9.89464 18.2652 10 18 10ZM12 10H6V5H12M19.77 7.23L19.78 7.22L16.06 3.5L15 4.56L17.11 6.67C16.17 7 15.5 7.93 15.5 9C15.5 9.3283 15.5647 9.65339 15.6903 9.95671C15.8159 10.26 16.0001 10.5356 16.2322 10.7678C16.7011 11.2366 17.337 11.5 18 11.5C18.36 11.5 18.69 11.42 19 11.29V18.5C19 18.7652 18.8946 19.0196 18.7071 19.2071C18.5196 19.3946 18.2652 19.5 18 19.5C17.7348 19.5 17.4804 19.3946 17.2929 19.2071C17.1054 19.0196 17 18.7652 17 18.5V14C17 12.89 16.1 12 15 12H14V5C14 3.89 13.1 3 12 3H6C4.89 3 4 3.89 4 5V21H14V13.5H15.5V18.5C15.5 19.163 15.7634 19.7989 16.2322 20.2678C16.7011 20.7366 17.337 21 18 21C18.3283 21 18.6534 20.9353 18.9567 20.8097C19.26 20.6841 19.5356 20.4999 19.7678 20.2678C19.9999 20.0356 20.1841 19.76 20.3097 19.4567C20.4353 19.1534 20.5 18.8283 20.5 18.5V9C20.5 8.31 20.22 7.68 19.77 7.23Z" fill="currentColor"/>
          </g>
      </svg>
  );
};


/*
<svg width="100%" height="100%" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 7C13.7348 7 13.4804 6.89464 13.2929 6.70711C13.1054 6.51957 13 6.26522 13 6C13 5.73478 13.1054 5.48043 13.2929 5.29289C13.4804 5.10536 13.7348 5 14 5C14.2652 5 14.5196 5.10536 14.7071 5.29289C14.8946 5.48043 15 5.73478 15 6C15 6.26522 14.8946 6.51957 14.7071 6.70711C14.5196 6.89464 14.2652 7 14 7ZM8 7H2V2H8M15.77 4.23L15.78 4.22L12.06 0.5L11 1.56L13.11 3.67C12.17 4 11.5 4.93 11.5 6C11.5 6.3283 11.5647 6.65339 11.6903 6.95671C11.8159 7.26002 12.0001 7.53562 12.2322 7.76777C12.7011 8.23661 13.337 8.5 14 8.5C14.36 8.5 14.69 8.42 15 8.29V15.5C15 15.7652 14.8946 16.0196 14.7071 16.2071C14.5196 16.3946 14.2652 16.5 14 16.5C13.7348 16.5 13.4804 16.3946 13.2929 16.2071C13.1054 16.0196 13 15.7652 13 15.5V11C13 9.89 12.1 9 11 9H10V2C10 0.89 9.1 0 8 0H2C0.89 0 0 0.89 0 2V18H10V10.5H11.5V15.5C11.5 16.163 11.7634 16.7989 12.2322 17.2678C12.7011 17.7366 13.337 18 14 18C14.3283 18 14.6534 17.9353 14.9567 17.8097C15.26 17.6841 15.5356 17.4999 15.7678 17.2678C15.9999 17.0356 16.1841 16.76 16.3097 16.4567C16.4353 16.1534 16.5 15.8283 16.5 15.5V6C16.5 5.31 16.22 4.68 15.77 4.23Z" fill="currentColor"/>
</svg>*/
