import React, { useRef, useState } from 'react';
import styles from './SelectCard.module.scss';
import useClickOutside from 'src/CustomHooks/useClickOutside';
import ChevronDownSvg from 'src/components/UI/svg-icon/ChevronDownSvg';
import { TOptionsForSelectCard } from 'src/Pages/GSMNewPageCarrier/type';
import { formPrice } from 'src/common/form-price.helper';

interface IProps {
  sortOptions: TOptionsForSelectCard;
  sortNames: { value: string; label: string }[];
  widthButton?: string;
  sort: { value: string; label: string };
  setSort: React.Dispatch<React.SetStateAction<{ value: string; label: string }>>;
}
export const SelectCard = ({ sortOptions, sortNames, widthButton = '100%', sort, setSort }: IProps) => {
  const sortRef = useRef(null);
  const [openSort, setOpenSort] = useState<boolean>(false);
  useClickOutside(sortRef, setOpenSort);

  return (
    <div style={{ position: 'relative' }} ref={sortRef}>
      <div style={{ width: '100%' }}>
        <div
          className={`${styles.buttonOpenFilter} ${openSort ? styles.openedFilterButton : ''} ${!sort ? '' : styles.filteredFilterButton}`}
          style={{ width: widthButton }}
          onClick={() => setOpenSort(!openSort)}
        >
          {sort?.label}
          <ChevronDownSvg />
        </div>
      </div>
      {openSort && (
        <div className={styles.hiddenFiltersContainer} style={{ width: widthButton }}>
          {sortOptions.map((option, id) => {
            return (
              <div
                key={option?.value}
                className={styles.mainOptionContainer}
                onClick={() => {
                  setSort(sortNames?.find(item => item?.value === option?.value));
                  setOpenSort(false);
                }}
              >
                <div className={styles.optionContainer}>
                  <div className={styles.hiddenFiltersText}>{option?.card}</div>
                  <div className={styles.hiddenFiltersMoney}>{formPrice(option?.money, 'RUB', 'ru')}</div>
                </div>
                {id !== sortOptions?.length - 1 && <div className={styles.line}></div>}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
