// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CatalogMaterialCategories_container__R6CjX {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 16px;\n  border: 1px solid var(--color-gray200);\n  border-radius: 12px;\n  background-color: var(--color-gray50);\n}\n\n.CatalogMaterialCategories_title__etR66 {\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n}\n\n.CatalogMaterialCategories_categories__tZKXu {\n  display: flex;\n  flex-direction: row;\n  gap: 38px;\n}\n\n@media (max-width: 768px) {\n  .CatalogMaterialCategories_container__R6CjX {\n    gap: 16px;\n    padding: 16px;\n  }\n  .CatalogMaterialCategories_title__etR66 {\n    font-size: 18px;\n    line-height: 28px;\n  }\n  .CatalogMaterialCategories_categories__tZKXu {\n    flex-direction: column;\n    gap: 8px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/Catalog/CatalogMaterialCategories/CatalogMaterialCategories.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,sCAAA;EACA,mBAAA;EACA,qCAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE;IACE,SAAA;IACA,aAAA;EACF;EAEA;IACE,eAAA;IACA,iBAAA;EAAF;EAGA;IACE,sBAAA;IACA,QAAA;EADF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 16px;\n  border: 1px solid var(--color-gray200);\n  border-radius: 12px;\n  background-color: var(--color-gray50);\n}\n\n.title {\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n}\n\n.categories {\n  display: flex;\n  flex-direction: row;\n  gap: 38px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    gap: 16px;\n    padding: 16px;\n  }\n\n  .title {\n    font-size: 18px;\n    line-height: 28px;\n  }\n\n  .categories {\n    flex-direction: column;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CatalogMaterialCategories_container__R6CjX",
	"title": "CatalogMaterialCategories_title__etR66",
	"categories": "CatalogMaterialCategories_categories__tZKXu"
};
export default ___CSS_LOADER_EXPORT___;
