import React, { useEffect, useState } from 'react';
import styles from './EmployeesPage.module.scss';
import { PageWrapper } from '../../components/PageWrapper';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import CustomTable from '../../components/newUI/CustomTable/CustomTable';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { columnsEmployees, initStateEmployee, typeTranslations } from './Constans';
import { clearObject } from '../../common/ClearObject.helper';
import { IEmployees } from './type';
import FormEmployees from './Components/FormAdditionsEmployees/FormEmployees';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import { toast } from 'react-toastify';
import { declinationWord } from '../../common/declinationWord.helper';
import MobileCardEmployee from './Components/MobileCardEmployee/MobileCardEmployee';
import { useMediaQuery } from 'react-responsive';
import NewEmptySvg from '../../components/UI/svg-icon/NewEmptySvg';
import { openPopUp } from '../../common/use-popup.functiopn';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import img from "../../assets/img/EmptyPng.png";
import {EmptyList} from "../../components/EmptyList";
import { toastError } from 'src/common/toastError.helper';

const EmployeesPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [filterSearch, setFilterSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openFormAddition, setOpenFormAddition] = useState<boolean>(false);
  const [openFormEdit, setOpenFormEdit] = useState<boolean>(false);
  const [dataResponse, setDataResponse] = useState<IEmployees[]>();
  const [newEmployee, setNewEmployee] = useState<IEmployees>(initStateEmployee);
  const [editEmployee, setEditEmployee] = useState<IEmployees>(initStateEmployee);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const getData = async (isPagination: boolean) => {
    setLoading(true);
    try {
      const requsetOption = {
        searchString: filterSearch ? filterSearch.trim() : undefined,
        limit: 10,
        offset: isPagination ? offset : 0,
      };
      clearObject(requsetOption);
      const response = await fetchGet('/users/operators', requsetOption);
      const responseTableData: IEmployees[] = response?.response?.rows?.map(item => {
        return {
          ...item,
          fullName: {
            name: item?.name,
            surname: item?.surname,
            patronymic: item?.patronymic,
          },
        };
      });
      setTotalCount(response?.response?.count);

      if (isPagination && offset !== 0) {
        await setDataResponse(prevState => [...prevState, ...responseTableData]);
      } else {
        await setOffset(0);
        await setDataResponse(responseTableData);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getDataEditEmployee = (data: IEmployees) => {
    setEditEmployee({ ...data, type: { value: data?.type, label: typeTranslations[data?.type] } });
  };
  const addNewEmployee = async () => {
    try {
      const requestOption = {
        name: newEmployee?.name?.trim(),
        patronymic: newEmployee?.patronymic ? newEmployee?.patronymic?.trim() : undefined,
        phone: newEmployee?.phone,
        role: newEmployee?.type?.value,
        surname: newEmployee?.surname?.trim(),
        email: newEmployee?.profile?.email
      };

      await setOffset(0);

      const response = await fetchPost('/users/create-operator', 'POST', requestOption);
      if (toastError(response)) return;

      await setOpenFormAddition(false);
      await getData(false);
      toast.success('Данные успешно отправлены');
    } catch (e) {
      setOpenFormAddition(false);
      console.log(e);
    }
  };

  const editOldEmployee = async (id: number) => {
    try {
      const requestOption = {
        name: editEmployee?.name,
        patronymic: editEmployee?.patronymic ? editEmployee?.patronymic : undefined,
        phone: editEmployee?.phone,
        role: editEmployee?.type?.value,
        surname: editEmployee?.surname,
        email: editEmployee?.profile?.email
      };

      const response = await fetchPost(`/users/update-operator/${id}`, 'PATCH', requestOption);
      if (toastError(response)) return;
      await setOpenFormAddition(false);
      await setOpenFormEdit(false);
      await setOffset(0);
      await getData(false);
      toast.success('Данные успешно отправлены');
    } catch (e) {
      setOpenFormEdit(false);
      console.log(e);
    }
  };
  const deleteEmployee = async (id: number) => {
    try {
      const response = await fetchPost(`/users/delete-operator/${id}`, 'DELETE', {});
      if (toastError(response)) {
        setOpenFormEdit(false);
        return;
      }
      setOpenFormAddition(false);
      await setOffset(0);
      await getData(false);
      toast.success('Данные успешно удалены');
    } catch (e) {
      setOpenFormEdit(false);
      console.log(e);
    }
  };

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (totalCount > offset) {
      getData(true);
    }
  }, [offset]);
  useEffect(() => {
    setOffset(0);
    getData(false);
  }, [filterSearch]);
  return (
    <>
      <PageWrapper style={{ zIndex: '100' }}>
        <div className={styles.topContainer}>
          <div className={styles.titleAndSubTitleContainer}>
            <div className={styles.titleAndAddButton}>
              <TitleMain title={'Сотрудники'} />
              <ButtonFilled
                sizeText={'small'}
                svg={<NewPlusSvg />}
                width={isMobile ? '32px' : '140px'}
                height={isMobile ? '32px' : '40px'}
                onClick={() => openPopUp(setOpenFormAddition)}
                text={isMobile ? '' : 'Добавить'}
              />
            </div>
            <SubTitleMain
              subTitle={`В настоящий момент у вас ${totalCount} ${declinationWord(totalCount, ['сотрудник', 'сотрудника', 'сотрудников'])}`}
            />
          </div>
        </div>
        <div className={styles.Container}>
          <div style={{ width: '100%' }}>
            <InputSearch
              placeholder={'Поиск'}
              onInput={({ currentTarget: { value: search } }) => setFilterSearch(search)}
              value={filterSearch}
            />
          </div>
          {isMobile ? (
            dataResponse?.length ? (
              dataResponse?.map((employee: IEmployees) => (
                <div
                  onClick={() => {
                    setOpenFormEdit(true);
                    getDataEditEmployee(employee);
                  }}
                >
                  <MobileCardEmployee employee={employee} />
                </div>
              ))
            ) : (
               <EmptyList
                   title={'Сотрудники отсутствуют'}
                   subTitle={'В данный момент у вас нет сотрудников. Добавьте сотрудника чтобы увидеть его в общем списке'}
               />
            )
          ) : (
            <CustomTable
              emptyTitle={'Сотрудники отсутствуют'}
              emptyDescription={
                'В данный момент у вас нет сотрудников. Добавьте сотрудника чтобы увидеть его в общем списке.'
              }
              getRowData={getDataEditEmployee}
              isModal={true}
              setOpenModal={setOpenFormEdit}
              loading={loading}
              link={false}
              columns={columnsEmployees}
              data={dataResponse}
            />
          )}

          <FormEmployees
            title={'Добавление сотрудника'}
            sendFunction={addNewEmployee}
            setOpenFormAddition={setOpenFormAddition}
            openFormModal={openFormAddition}
            setEmployee={setNewEmployee}
            employee={newEmployee}
          />
          <FormEmployees
            title={'Редактирование сотрудника'}
            refusalFunction={deleteEmployee}
            sendFunction={editOldEmployee}
            setOpenFormAddition={setOpenFormEdit}
            openFormModal={openFormEdit}
            setEmployee={setEditEmployee}
            employee={editEmployee}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export default EmployeesPage;
