import React, { useRef, useState } from 'react';
import styles from './ComparsionTable.module.scss';
import { TComparsions } from '../../catalogTypes';
import { CloseSvg } from 'src/components/UI/svg-icon/CloseSvg';
import { useNavigate } from 'react-router-dom';
import { QuarryLink } from '../../components/QuarryLink/QuarryLink';
import { translateMainInfoToRussianFromBackend } from '../../constans';
import { optionsInputsOfMaterial } from 'src/Pages/ShowcasesPage/constans';

export const ComparsionTable = ({
  comparsions,
  deleteItem,
}: {
  comparsions: TComparsions;
  deleteItem: ({ id, category }: { id?: number; category: string }) => Promise<void>;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const properties = comparsions.length
    ? comparsions[0]?.properties
        ?.map(property => translateMainInfoToRussianFromBackend[property?.title] + ':')
        .filter(property => property !== 'Фракция:')
    : [];
  let titles = comparsions.length
    ? ['Витрина', 'Объем на продажу, тн:', 'Объем на продажу, м³:', 'Цена, тн:', 'Цена, м³:', ...properties]
    : [];
  let newComparsions = [];
  const sandClasses = optionsInputsOfMaterial.sand.class;

  const handleMouseDown = e => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        {titles.map((title, idx) => (
          <span key={idx} className={styles.title}>
            {title}
          </span>
        ))}
      </div>
      <div
        className={styles.container}
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        style={{ overflowX: 'auto', cursor: isDragging ? 'grabbing' : 'grab' }}
      >
        {comparsions?.map((comparsion, index) => {
          const newComparsion = [
            { title: 'restTon', value: comparsion?.volumeInTon + ' тн' },
            { title: 'restCubicMeter', value: comparsion?.volumeInCubicMeter + ' м³' },
            {
              title: 'pricePerTon',
              value: 'от ' + comparsion.minimalPricePerTon + ' ₽/тн',
            },
            {
              title: 'pricePerCubicMeter',
              value: 'от ' + comparsion.minimalPricePerCubicMeter + ' ₽/м³',
            },
          ]?.concat(comparsion?.properties);

          newComparsions?.push(newComparsion);
          const fractionValue = comparsion?.properties?.find(property => property?.title === 'fraction')?.value || '';

          return (
            <div key={`comparsions${index}`} className={styles.itemContainer}>
              <div className={styles.propertyContainerTitle}>
                <span className={styles.propertyTitle}>
                  {comparsion?.breed + (comparsion?.kind === null ? '' : ' ' + comparsion?.kind) + ' ' + fractionValue}
                </span>

                <div
                  className={styles.deleteProperty}
                  onClick={() => deleteItem({ id: comparsion?.id, category: 'materials' })}
                >
                  <CloseSvg width="16px" />
                </div>
              </div>
              <div className={styles.propertyContainer}>
                <div className={styles.propertyContainerText}>
                  <QuarryLink
                    onClick={() => {
                      navigate(`/catalog/showcases/${comparsion?.showcase?.id}`);
                    }}
                    quarryName={comparsion?.showcase?.title}
                  />
                </div>
              </div>
              {newComparsion?.map((property, propertyIndex) => {
                if (property.title === 'fraction') return;
                const findSand = sandClasses.find(elem => elem.value === property?.value);
                return (
                  <div className={styles.propertyContainer} key={propertyIndex}>
                    <div
                      key={`${property?.title + index}`}
                      className={
                        property?.value !== newComparsions[0][propertyIndex]?.value ? styles.differentProperty : ''
                      }
                    >
                      <span className={styles.propertyContainerText}>
                        {findSand?.value ? findSand?.label : property?.value}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
