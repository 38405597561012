import React from 'react';
import styles from './ReplenishmentsPage.module.scss';
import { PageWrapper } from 'src/components/PageWrapper';
import { Link } from 'react-router-dom';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { useMediaQuery } from 'react-responsive';

export const ReplenishmentsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <PageWrapper>
      <Link className="button-back" to={'/gsm'} style={{ marginTop: isMobile ? '8px' : '' }}>
        <div className="button-back__svg">
          <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
              fill="currentColor"
            />
          </svg>
        </div>
        Вернуться к ГСМ
      </Link>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <span className={styles.title}>Пополнение карт</span>
          <ButtonFilled text="Пополнить" width={isMobile ? '100%' : '101px'} sizeText="small" />
        </div>
      </div>
    </PageWrapper>
  );
};
