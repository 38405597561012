import React, { useEffect, useRef, useState } from 'react';
import styles from './SupplierUpdAndRegistryPage.module.scss';
import CustomTable from '../../components/newUI/CustomTable/CustomTable';
import TruckSvgUpdSup from '../../components/UI/svg-icon/TruckSvgUpdSup';
import HandCoinSvg from '../../components/UI/svg-icon/HandCoinSvg';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import NewErrorEmptySvg from '../../components/UI/svg-icon/NewErrorEmptySvg';
import { ButtonTransparent } from '../../components/UI/buttons/ButtonTransparent';
import { toast } from 'react-toastify';
import { formPrice } from '../../common/form-price.helper';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonNotFilled from "../../components/newUI/ButtonNotFilled/ButtonNotFilled";
import { toastError } from 'src/common/toastError.helper';

const SupplierUpdAndRegistryPageId = () => {
  const [dataResponse, setDataResponse] = useState<any>();
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const elementRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [more, setMore] = useState(isMobile ? 5 : 10);
  const [tableDataResponse, setTableDataResponse] = useState([]);
  const getData = async () => {
    setLoading(true);

    const pathArr = location.pathname.split('/');

    try {
      const response = await fetchGet(`/registries/${pathArr[pathArr.length-1]}`, {});
      setLoading(false);
      const correctionTableData = response?.response?.flights?.map(item => {
        return {
          ...item,
          price: item?.supplierPrice,
          total: item?.total,
          stateNumber: item?.car?.stateNumber,
          shipmentDate: item?.dateDownload,
          volumeUnit: {
            volume: item?.supplierFilledVolume,
            unit: item?.nomenclature?.unit,
          },
          nomenclature: item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
          nomenclatures: item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.characteristic,
        };
      });
      setTableDataResponse(correctionTableData);
      setDataResponse(response.response);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const columns = [
    {
      title: 'Дата',
      dataIndex: 'shipmentDate',
      width: 170,
      key: 'shipmentDate',
      render: (text: string) => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '14px 0px' }}>
          <p
            style={{
              paddingTop: '2px',
              height: '28px',
              alignItems: 'center',
              textAlign: 'center',
              width: '104px',
              borderRadius: '30px',
              border: '1px solid #2563EB',
              color: '#2563EB',
            }}
          >
            {text?.slice(0, 10)?.split('-')?.reverse()?.join('.')}
          </p>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Номенклатура',
      dataIndex: 'nomenclature',
      width: 345,
      key: 'nomenclature',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '14px 0px' }}>
          <p>{text}</p>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Характеристика номенклатуры',
      dataIndex: 'nomenclatures',
      width: 345,
      key: 'nomenclatures',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '14px 0px' }}>
          <p style={{ paddingRight: '5px' }}>{text}</p>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Общий объем',
      dataIndex: 'volumeUnit',
      width: 130,
      key: 'volumeUnit',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <p>
            {text.volume} {text.unit}{' '}
          </p>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Номер ТС',
      dataIndex: 'stateNumber',
      width: 120,
      key: 'stateNumber',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <p>{text}</p>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Цена за ед.',
      dataIndex: 'price',
      width: 120,
      key: 'price',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <p>{formPrice(text, 'RUB')}</p>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Итого с НДС',
      dataIndex: 'total',
      width: 120,
      key: 'total',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <p>{formPrice(text, 'RUB')}</p>
        </div>
      ),
    },
  ];

  const downloadedFile = async (id: number) => {
    try {
      const response = await fetchPost(`/reports/upd`, 'POST', {
        registryId: id,
      });
      if (toastError(response)) return;
      const link = document.createElement('a');
      link.download = response?.response?.name;

      link.href = response?.response?.file;

      link.click();

      return;
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="PageWrapper">
      <Link className="button-back" to="/document">
        <div className="button-back__svg">
          <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
              fill="currentColor"
            />
          </svg>
        </div>
        К списку документов
      </Link>
        <div className={styles.titleContainer}>
            <TitleMain title={`${dataResponse?.registryNumber} от ${dataResponse?.registryDate?.slice(0, 10)?.replaceAll('-', '.')} `}/>
        </div>
        {/*Закоментировано в связи с хот фиксом<Button
          left={false}
          right={false}
          sending={false}
          modifier={''}
          style={{
            marginTop: isMobile ? '8px' : '0',
            position: isMobile ? 'sticky' : 'static',
            top: isMobile ? '52px' : '20px',
            width: isMobile ? '100%' : '240px',
            zIndex: 10000,
          }}
          colorSvg={''}
          children={'Скачать упд'}
          color={''}
          onClick={() => downloadedFile(Number(window.location.href.split('/').reverse()[0]))}
          svg={''}
          disabled={!dataResponse?.flights?.length}
        />*/}
        
      <div className={styles.contentContainer}>
        {isMobile ? (
          <div>
            <div className={styles.infoContainer}>
              <div className={styles.idInfoContainerFirst}>
                <div
                  className={styles.svgInfoContainer}
                  style={{
                    marginRight: '25px',
                  }}
                >
                  <TruckSvgUpdSup />
                </div>
                <div className={styles.textInfoContainer}>
                  <p className={styles.titleInfoContainer}>Количество материала</p>
                  <p className={styles.valueInfoContainer}>
                    {dataResponse?.volumeDetail[0]?.value} {dataResponse?.volumeDetail[0]?.name}/
                    {dataResponse?.volumeDetail[1]?.value} {dataResponse?.volumeDetail[1]?.name}
                  </p>
                </div>
              </div>
              <div className={styles.idInfoContainer}>
                <div
                  className={styles.svgInfoContainer}
                  style={{
                    marginRight: '25px',
                  }}
                >
                  <HandCoinSvg />
                </div>
                <div className={styles.textInfoContainer}>
                  <p className={styles.titleInfoContainer}>Общая сумма</p>
                  <p className={styles.valueInfoContainer}>{formPrice(dataResponse?.documentAmount, 'RUB')}</p>
                </div>
              </div>
            </div>
            {!dataResponse?.flights?.length ? (
              <div>
                <div className={'emptyContentContainer'}>
                  <div
                    style={{
                      marginTop: '-80px',
                      textAlign: 'center',
                    }}
                  >
                    <NewErrorEmptySvg />
                  </div>
                  <div
                    style={{
                      marginTop: '-65px',
                    }}
                  >
                    <h4 className={'emptyTitle'}>Ошибка загрузки данных</h4>
                    <p className={'emptyDescription'}>
                      Мы не смогли загрузить данные таблицы. Попробуйте перезагрузить страницу или свяжитесь со службой
                      поддержки.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              dataResponse?.flights?.slice(0, more)?.map(item => (
                <div ref={elementRef} className={styles.mobileContainerTable}>
                  <div className={styles.mobileContainerDateAndNum}>
                    <p className={styles.mobileDate}>
                      {item?.dateDownload?.slice(0, 10)?.split('-')?.reverse()?.join('.')}
                    </p>
                    <p>{item?.nomenclature?.name}</p>
                  </div>
                  <div className={styles.mobileCharNum}>
                    <strong> Хар-ка номенклатуры:</strong>
                    <p>{item?.nomenclature?.characteristic}</p>
                  </div>
                  <div>
                    <div className={styles.mobileTotalVolume}>
                      <strong> Общий объем:</strong>
                      <p> {item?.supplierFilledVolume} м³</p>
                    </div>
                    <div className={styles.mobileNumTS}>
                      <strong>Номер ТС:</strong>
                      <p>{item?.car?.stateNumber}</p>
                    </div>
                    <div className={styles.mobilePrice}>
                      <strong>Цена за ед.:</strong>
                      <p> {formPrice(item?.supplierPrice,'RUB')}</p>
                    </div>
                  </div>
                  <div className={styles.mobileTotal}>
                    <strong>Итого с НДС:</strong>
                    <p>{formPrice(item?.total,'RUB')}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        ) : (
          <div>
            <div className={styles.infoContainer}>
              <div className={styles.idInfoContainerFirst}>
                <div className={styles.svgInfoContainer}>
                  <TruckSvgUpdSup />
                </div>
                <div className={styles.textInfoContainer}>
                  <p className={styles.titleInfoContainer}>Количество материала</p>
                  <p className={styles.valueInfoContainer}>
                    {dataResponse?.volumeDetail[0]?.value} {dataResponse?.volumeDetail[0]?.name}/
                    {dataResponse?.volumeDetail[1]?.value} {dataResponse?.volumeDetail[1]?.name}
                  </p>
                </div>
              </div>
              <div className={styles.idInfoContainer}>
                <div className={styles.svgInfoContainer}>
                  <HandCoinSvg />
                </div>
                <div className={styles.textInfoContainer}>
                  <p className={styles.titleInfoContainer}>Общая сумма</p>
                  <p className={styles.valueInfoContainer}>{formPrice(dataResponse?.documentAmount, 'RUB')}</p>
                </div>
              </div>
            </div>
            <CustomTable loading={loading} link={false} columns={columns} data={tableDataResponse?.slice(0, more)} />
          </div>
        )}
      </div>
      {tableDataResponse?.length > more && (
          <ButtonNotFilled
              margin={'40px 0 0 0'}
              onClick={() => setMore(isMobile ? more + 5 : more + 10)}
              text={'Показать ещё'}
              width={'100%'}
              color={'#ff5100'}
          />
      )}
    </div>
  );
};

export default SupplierUpdAndRegistryPageId;
