import React from 'react';
const CompareSvg = ({ width = '24px', active = false }: { width?: string, active?:boolean }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} fill={active?'currentColor':'none'}>
            <g clipPath="url(#clip0_27537_19724)">
                <path d="M5 6.66602C5 4.30899 5 3.13048 5.73223 2.39825C6.46447 1.66602 7.64298 1.66602 10 1.66602C12.357 1.66602 13.5355 1.66602 14.2678 2.39825C15 3.13048 15 4.30899 15 6.66602V13.3327C15 15.6897 15 16.8682 14.2678 17.6005C13.5355 18.3327 12.357 18.3327 10 18.3327C7.64298 18.3327 6.46447 18.3327 5.73223 17.6005C5 16.8682 5 15.6897 5 13.3327V6.66602Z"
                      stroke={active?'white':'currentColor'}
                      strokeWidth="1.5"/>
                <path d="M15 16.25C16.1668 16.25 16.7502 16.25 17.1958 16.0229C17.5878 15.8232 17.9065 15.5045 18.1063 15.1125C18.3333 14.6668 18.3333 14.0834 18.3333 12.9167V7.08333C18.3333 5.91656 18.3333 5.33317 18.1063 4.88752C17.9065 4.49552 17.5878 4.17681 17.1958 3.97707C16.7502 3.75 16.1668 3.75 15 3.75"
                      stroke="currentColor"
                      strokeWidth="1.5"/>
                <path d="M4.99984 16.25C3.83306 16.25 3.24967 16.25 2.80402 16.0229C2.41202 15.8232 2.09331 15.5045 1.89357 15.1125C1.6665 14.6668 1.6665 14.0834 1.6665 12.9167V7.08333C1.6665 5.91656 1.6665 5.33317 1.89357 4.88752C2.09331 4.49552 2.41202 4.17681 2.80402 3.97707C3.24967 3.75 3.83306 3.75 4.99984 3.75"
                      stroke="currentColor"
                      strokeWidth="1.5"/>
            </g>
            <defs>
                <clipPath id="clip0_27537_19724">
                    <rect width="100%" height="100%" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default CompareSvg;