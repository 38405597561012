import mobileHelpTour from '../../../../assets/img/TourImages/mobileHelpTour.png';
import DispatchTourUImg from '../../../../assets/img/TourImages/dispatchTourUImg.jpg';
import FlightTourImg from '../../../../assets/img/TourImages/FlightTourImg.png';
import DriverTourImg from '../../../../assets/img/TourImages/DriverTourImg.png';
import CarTourImg from '../../../../assets/img/TourImages/CarTourImg.png';
import EmployeesTourImg from '../../../../assets/img/TourImages/EmployeesTourImg.png';
import DocumentTourImg from '../../../../assets/img/TourImages/DocumentTourImg.png';
import PaymentProcessTourImg from '../../../../assets/img/TourImages/PaymentProcessTourImg.png';
import ProfileTourImg from '../../../../assets/img/TourImages/ProfileTourImg.png';
export const arrayOfLaptopTourContent=[
    {
        title:'Диспетчеризация',discription:'Распределяйте груз между рейсами и транспортными средствами, назначайте водителей. Отказывайтесь от остатков в случае необходимости и редактируйте планирование. Просматривайте общий список выполненных объемов в подразделе «Выполненные заявки».', 
        src:DispatchTourUImg,
    }, 
    {
        title:'Рейсы',discription:'Отслеживайте статус выполнения рейсов водителями, просматривайте полную информацию по рейсам, переназначайте водителей и транспортное средство, отказывайтесь от рейсов до выезда водителей.', 
        src:FlightTourImg,
    },
    {
        title:'Водители',discription:'Добавляйте и удаляйте водителей, чтобы назначать их на рейсы. Укажите их личные и паспортные данные, а также прикрепите водительское удостоверение и паспорт для регистрации.\n' + 
            'После добавления водитель получит уведомление о регистрации и сможет войти в свой личный кабинет. В случае удаления водителя его номер телефона будет удален из системы, а доступ к личному кабинету - потерян.\n', 
        src:DriverTourImg,
    },
    {
        title:'Транспортные средства',discription:'Добавляйте и удаляйте транспортные средства, распределяйте их между рейсами. Укажите номер и марку автомобиля, добавьте файл СТС и договор лизинга при наличии.', 
        src:CarTourImg,
    }, 
    {
        title:'Сотрудники',discription:'Добавляйте и удаляйте сотрудников и назначайте им роли менеджера, бухгалтера и администратора. У каждого сотрудника доступен свой функционал личного кабинета, необходимый для работы.\n' +
        'После добавления сотрудника он получит уведомление о регистрации и сможет войти в личный кабинет. При удалении сотрудник потеряет доступ к кабинету, его номер также удалится из системы.', 
        src:EmployeesTourImg,
    },
    {
        title:'Документы',discription:'Формируйте договоры поставки или перевозки и заверяйте их электронной подписью. Просматривайте уже сформированные договоры, а также спецификации к ним.\n' + 
            'В подразделе «Реестры» вы можете формировать электронный список реестров и отправлять его на проверку.', 
        src:DocumentTourImg,
    },
    {
        title:'Взаиморасчеты',discription:'Просматривайте количество оплаченных и неоплаченных рейсов, следите за суммой оплаты по оригиналам и сканам транспортных накладных.', 
        src:PaymentProcessTourImg,
    },
    {
        title:'Профиль',discription:'Добавляйте и просматривайте данные, редактируйте их при необходимости. \n' + 
            'Завершите регистрацию: заполните личные и юридические данные, прикрепите учредительные документы. Затем ваш профиль отправится на проверку, после прохождения которой вы сможете работать в личном кабинете.\n', 
        src:ProfileTourImg,
    },
    {
        title:'Помощь',discription:'Изучайте ответы на наиболее часто задаваемые вопросы.', 
        src:mobileHelpTour,
}];
