import React from 'react';
import styles from './CatalogMaterialCategories.module.scss';
import { catalogMaterialCategories } from '../catalogConstants';
import { CatalogMaterialCategory } from './CatalogMaterialCategory/CatalogMaterialCategory';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

export const CatalogMaterialCategories = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Категории материалов</h1>
      <div className={styles.categories}>
        {catalogMaterialCategories.map(category => {
          return (
            <div
              onClick={() => {
                navigate(`materials/${category.name}`);
              }}
            >
              <CatalogMaterialCategory
                key={category.title}
                title={category.title}
                subtitle={category?.subtitle || ''}
                image={isMobile ? category.mobileImage : category.image}
                textColor={category.textColor}
                subtextColor={category?.subtextColor || ''}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
