// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FavoriteMaterials_container__rwIiX {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 24px;\n}\n\n@media (max-width: 768px) {\n  .FavoriteMaterials_container__rwIiX {\n    grid-template-columns: 1fr 1fr;\n    gap: 16px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/Catalog/FavoritesPage/FavoriteMaterials/FavoriteMaterials.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sCAAA;EACA,SAAA;AACF;;AAEA;EACE;IACE,8BAAA;IACA,SAAA;EACF;AACF","sourcesContent":[".container {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 24px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    grid-template-columns: 1fr 1fr;\n    gap: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FavoriteMaterials_container__rwIiX"
};
export default ___CSS_LOADER_EXPORT___;
