import React, { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import styles from './ShareShowcasePopup.module.scss';
import { useMediaQuery } from 'react-responsive';
import useClickOutside from '../../../../CustomHooks/useClickOutside';
import { CloseSvg } from 'src/components/UI/svg-icon/CloseSvg';
import { ShareGradientSvg } from './share-images/ShareGradientSvg';
import { VKSvg } from './share-images/VKSvg';
import { WhatsUpSvg } from './share-images/WhatsUpSvg';
import { EmailSvg } from './share-images/EmailSvg';
import { toast } from 'react-toastify';

type TShareShowcasePopup = {
  title?:string
  isShowcasePublished: boolean;
  isSharePopupOpen: boolean;
  setIsSharePopupOpen: Dispatch<SetStateAction<boolean>>;
  link: string;
  logoImage: string;
  carrierName: string;
  [key: string]: any;
};

export const ShareShowcasePopup = ({
  isShowcasePublished,
  isSharePopupOpen,
  setIsSharePopupOpen,
  link,
  logoImage,
  carrierName,
  title = 'Поделиться витриной',
  ...props
}: TShareShowcasePopup) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const modalRef = useRef();
  useClickOutside(modalRef, setIsSharePopupOpen);
  const shareText =
    'Посмотрите, какие материалы у нас есть! Заходите в наш каталог на SOLBER и выбирайте то, что вам нужно!';
  const shareLink = `${window.location.origin}/${link}`;
  const iconsInfo: { name: string; icon: JSX.Element; link: string }[] = [
    { name: 'Копировать ссылку', icon: <ShareGradientSvg />, link: shareLink },
    {
      name: 'Вконтакте',
      icon: <VKSvg />,
      link: `https://vk.com/share.php?url=${shareLink}&title=Витрина "${carrierName}"&comment=${shareText}&image=${logoImage}`,
    },
    {
      name: 'WhatsApp',
      icon: <WhatsUpSvg />,
      link: `https://api.whatsapp.com/send?text=${shareText} ${shareLink}&utm_source=share2`,
    },
    {
      name: 'E-mail',
      icon: <EmailSvg />,
      link: `mailto:?subject=Витрина "${carrierName}"&body=${shareText}%20${shareLink}`,
    },
  ];

  const clickOnIcon = (link: string) => {
    window.open(link);
  };

  const copyLinkToClipboard = async text => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(<div>Ссылка скопирована в буфер обмена.</div>);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      {isSharePopupOpen && (
        <div className={styles.wrapper} {...props}>
          <div className={styles.container} ref={modalRef}>
            <div className={styles.titleContainer}>
              <div className={isMobile ? 'textH5' : 'textH6'} style={{ textAlign: 'center', marginBottom: '8px' }}>
                {title}
              </div>
              {isMobile && (
                <div style={{ marginTop: '2px', cursor: 'pointer' }} onClick={() => setIsSharePopupOpen(false)}>
                  <CloseSvg />
                </div>
              )}
            </div>
            <div className={styles.shareIconsContainer} style={isShowcasePublished ? {} : { opacity: 0.5 }}>
              {iconsInfo.map(icon => {
                return (
                  <div
                    key={icon.name}
                    className={styles.shareIconContainer}
                    style={isShowcasePublished ? {} : { cursor: 'auto' }}
                    onClick={() => {
                      if (!isShowcasePublished) return;
                      icon.name === 'Копировать ссылку' ? copyLinkToClipboard(icon.link) : clickOnIcon(icon.link);
                    }}
                  >
                    {icon.icon}
                    <p className={styles.text}>{icon.name}</p>
                  </div>
                );
              })}
            </div>
            {!isShowcasePublished && (
              <div className={styles.unpublishInfo}>
                Ваша витрина снята с публикации, перейдите в редактирование, чтобы изменить параметр
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
