import React, { useEffect, useRef } from 'react';
import styles from './DrawerMobile.module.scss';
import { CloseSvg } from '../../UI/svg-icon/CloseSvg';

interface IProps {
  children: any;
  open: boolean;
  close: () => void;
  heightDrawer?: string;
  closeButton?: boolean;
  afterClose?: () => void;
}

const DrawerMobile = ({ children, open, close, heightDrawer, closeButton = true, afterClose }: IProps) => {
  useEffect(() => {
    const rooDiv = document.getElementById('root');
    if (open) {
      rooDiv.style.overflow = 'hidden';
      rooDiv.style.height = '100vh';
      rooDiv.style.position = 'relative';
    } else {
      rooDiv.style.overflow = 'unset';
    }
  }, [open]);
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (afterClose) {
      const element = ref.current;
      if (element && !open) {
        element.addEventListener('transitionend', afterClose);
      }
      return () => {
        if (element) {
          element.removeEventListener('transitionend', afterClose);
        }
      };
    }
  }, [open]);

  return (
    <div className={open ? styles.openOverlay : styles.overlay} onClick={close}>
      <div
        ref={ref}
        style={{ height: heightDrawer && 'fit-content', borderRadius: heightDrawer && '12px 12px 0 0' }}
        className={open ? styles.openDrawer : styles.drawer}
        onClick={e => e.stopPropagation()}
      >
        {heightDrawer && (
          <div className={styles.triangleContainer}>
            <div className={styles.triangle} />
          </div>
        )}
        {closeButton && (
          <div className={styles.closeButtonContainer}>
            <div className={styles.closeButton} onClick={close}>
              <CloseSvg />
            </div>
          </div>
        )}
        <div style={{ overflow: 'auto', height: heightDrawer }}>{children}</div>
      </div>
    </div>
  );
};

export default DrawerMobile;
