import React from 'react';
import styles from './FavoriteMaterials.module.scss';
import { TFavoriteMaterials } from '../../catalogTypes';
import { MaterialItem } from 'src/Pages/ShowcasesPage/components/MaterialCatalog/MaterialItem/MaterialItem';

export const FavoriteMaterials = ({ materials }: TFavoriteMaterials) => {
  return (
    <div className={styles.container}>
      {materials.map(material => {
        return (
          <div key={material.id}>
            <MaterialItem material={material} link={`/catalog/materials/show/${material?.materialsId[0]}`} />
          </div>
        );
      })}
    </div>
  );
};
