import React, { useContext, useRef } from 'react';
import { ShipmentsIconSvg } from '../UI/svg-icon/ShipmentsIconSvg';
import { ShipmentsIconSvgActive } from '../UI/svg-icon/ShipmentsIconSvgActive';
import { ShipmentsIconSvgError } from '../UI/svg-icon/ShipmentsIconSvgError';
import { WalletSvg } from '../UI/svg-icon/WalletSvg';
import { WalletSvgActive } from '../UI/svg-icon/WalletSvgActive';
import { FileSvg } from '../UI/svg-icon/FileSvg';
import { FileSvgActive } from '../UI/svg-icon/FileSvgActive';
import { DocumentSvgActive } from '../UI/svg-icon/DocumentSvgActive';
import { MapMarkerMultipleSvg } from '../UI/svg-icon/MapMarkerMultipleSvg';
import { MapMarkerMultipleSvgActive } from '../UI/svg-icon/MapMarkerMultipleSvgActive';
import { ProfileErrorSvg } from '../UI/svg-icon/ProfileErrorSvg';
import { ProfileSvg } from '../UI/svg-icon/ProfileSvg';
import { ProfileSvgActive } from '../UI/svg-icon/ProfileSvgActive';
import { HelpSvg } from '../UI/svg-icon/HelpSvg';
import { HelpSvgActive } from '../UI/svg-icon/HelpSvgActive';
import stylesTour from './Tour.module.scss';
// @ts-ignore
import styles from './NewAsideNavSupplier.module.css';
import NewNavItem from '../newUI/NewNavItem/NewNavItem';
import EmployeesSvg from '../UI/svg-icon/EmployeesSvg';
import EmployeesActiveSvg from '../UI/svg-icon/EmployeesActiveSvg';
import { Tour } from 'antd';
import { Context } from '../../context/context';
import ShipmentTourImg from '../../assets/img/TourImages/ShipmentTourImg.png';
import SupplierPaymentProcess from '../../assets/img/TourImages/SupplierPaymentProcess.png';
import SupplierDocumentTourImg from '../../assets/img/TourImages/SupplierDocumentTourImg.png';
import QuarryTourImg from '../../assets/img/TourImages/QuarryTourImg.png';
import EmployeesTourImg from '../../assets/img/TourImages/EmployeesTourImg.png';
import ProfileTourImg from '../../assets/img/TourImages/ProfileTourImg.png';
import TourShowCase from '../../assets/img/TourImages/TourShowCase.png';
import { ShowcasesIconSvg } from '../UI/svg-icon/ShowcasesIconSvg';
import { ShowcasesIconSvgActive } from '../UI/svg-icon/ShowcasesIconSvgActive';
import NavIdeaItem from './components/NavIdeaItem/NavIdeaItem';

const fullAccess = ['admin', 'manager', 'supplier', 'accountant'];

const NewAsideNavSupplier = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const { setStartTour, startTour, user } = useContext(Context);

  const steps: any = [
    {
      title: <p className={stylesTour.title}>Витрины</p>,
      typeRole: fullAccess,
      cover: <img alt="tour.png" src={TourShowCase} />,
      description:
        'Разместите описания материалов, чтобы заказчики могли выбрать лучший вариант.' +
        ' Укажите породу, фракцию, насыпную плотность и другие характеристики материала, ' +
        'добавьте его паспорт и фотографии, укажите цену за единицу. ' +
        'Редактируйте витрину при необходимости и удаляйте неактуальные описания. Делитесь витриной через социальные сети или почту.',
      placement: 'right',
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnFirstNext },
      target: () => ref1.current,
    },
    {
      title: <p className={stylesTour.title}>Отгрузки</p>,
      typeRole: ['manager', 'supplier', 'admin'],

      cover: <img alt="tour.png" src={ShipmentTourImg} />,
      description:
        'Просматривайте уже отгруженные рейсы, а также рейсы, ожидающие отгрузки.' +
        ' Осуществляйте поиск по номеру рейса, водителю или номеру машины.',
      placement: 'right',
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnFirstNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      target: () => ref2.current,
    },
    /*{
      title: <p className={stylesTour.title}>Взаиморасчеты</p>,
      cover: <img alt="tour.png" src={SupplierPaymentProcess} />,
      placement: 'right',
      typeRole: ['accountant', 'supplier', 'admin'],
      description:
        'Просматривайте сумму задолженности покупателя и предоплату, формируйте историю расчетов во вкладке «В обработке».' +
        ' Во вкладке «Оплачено» работайте со списком оплаченных заказов.\n',
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      target: () => ref3.current,
    },*/
    {
      title: <p className={stylesTour.title}>Документы</p>,
      placement: 'right',
      typeRole: fullAccess,
      cover: <img alt="tour.png" src={SupplierDocumentTourImg} />,
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Формируйте договоры и заверяйте их электронной подписью. ' +
        'Просматривайте уже сформированные договоры, а также спецификации к ним. ' +
        'В подразделе «УПД» находятся соответствующие документы и реестр транспортных накладных.\n',
      target: () => ref4.current,
    },
    {
      title: <p className={stylesTour.title}>Карьеры</p>,
      placement: 'right',
      typeRole: ['manager', 'supplier', 'admin'],
      cover: <img alt="tour.png" src={QuarryTourImg} />,
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Добавляйте карьеры для возможности поставлять материалы с них.' +
        'Укажите наименование карьера, его тип, адрес, график работы, ответственного сотрудника, ' +
        'фотографии и схемы проезда. Редактируйте и удаляйте карьеры при необходимости.',
      target: () => ref5.current,
    },
    {
      title: <p className={stylesTour.title}>Сотрудники</p>,
      placement: 'right',
      typeRole: ['supplier'],
      cover: <img alt="tour.png" src={EmployeesTourImg} />,
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Добавляйте и удаляйте сотрудников, назначайте им роли менеджера, бухгалтера и администратора. ' +
        'У каждого сотрудника доступен свой функционал личного кабинета, необходимый для работы.\n' +
        'После добавления сотрудника он получит уведомление о регистрации и сможет войти в личный кабинет. ' +
        'При удалении сотрудник потеряет доступ к кабинету, его номер также удалится из системы.\n',
      target: () => ref6.current,
    },
    {
      title: <p className={stylesTour.title}>Профиль</p>,
      placement: 'right',
      typeRole: fullAccess,
      type: 'default',
      cover: <img alt="tour.png" src={ProfileTourImg} />,
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description:
        'Добавляйте и просматривайте данные, редактируйте их при необходимости. \n' +
        'Завершите регистрацию: заполните личные и юридические данные, прикрепите учредительные документы. ' +
        'Затем ваш профиль отправится на проверку, после прохождения которой вы сможете работать в личном кабинете',
      target: () => ref7.current,
    },
    {
      title: <p className={stylesTour.title}>Помощь</p>,
      typeRole: fullAccess,
      placement: 'right',
      type: 'default',
      nextButtonProps: { children: 'Продолжить', className: stylesTour.btnSecondNext },
      prevButtonProps: { children: 'Назад', className: stylesTour.btnPrev },
      description: 'Изучайте ответы на наиболее часто задаваемые вопросы.',
      target: () => ref8.current,
    },
  ].filter(item => item?.typeRole?.includes(user?.type));
  const supplierNavLink = [
    {
      name: 'Витрины',
      ref: ref1,
      link: '/showcases',
      type: fullAccess,
      notActiveProfile: false,
      svg: <ShowcasesIconSvg />,
      svgActive: <ShowcasesIconSvgActive />,
      errorProfile: false,
    },
    {
      name: 'Отгрузки',
      ref: ref2,
      link: '/shipments',
      type: ['manager', 'supplier', 'admin'],
      notActiveProfile: false,
      svg: <ShipmentsIconSvg />,
      svgActive: <ShipmentsIconSvgActive />,
      svgProfileError: <ShipmentsIconSvgError />,
      errorProfile: true,
    },
    /*{
      name: 'Взаиморасчеты',
      ref: ref3,
      link: '/supplierPayment',
      type: ['accountant', 'supplier', 'admin'],
      notActiveProfile: false,
      disable: true,
      svg: <WalletSvg />,
      svgActive: <WalletSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },*/
    {
      name: 'Документы',
      ref: ref4,
      link: '/document',
      type: fullAccess,
      notActiveProfile: false,
      svg: <FileSvg />,
      svgActive: <FileSvgActive />,
      svgProfileError: <DocumentSvgActive />,
      errorProfile: false,
    },
    {
      name: 'Карьеры',
      ref: ref5,
      link: '/quarries',
      notActiveProfile: false,
      type: ['manager', 'supplier', 'admin'],
      svg: <MapMarkerMultipleSvg />,
      svgActive: <MapMarkerMultipleSvgActive />,
      svgProfileError: <ProfileErrorSvg />,
      errorProfile: true,
    },
    {
      name: 'Сотрудники',
      ref: ref6,
      link: '/employees',
      type: ['supplier'],
      notActiveProfile: false,
      disable: true,
      svg: <EmployeesSvg />,
      svgActive: <EmployeesActiveSvg />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Профиль',
      ref: ref7,
      link: '/profile',
      type: fullAccess,
      notActiveProfile: false,
      svg: <ProfileSvg />,
      svgActive: <ProfileSvgActive />,
      svgProfileError: <ProfileErrorSvg />,
      errorProfile: true,
    },
    {
      name: 'Помощь',
      ref: ref8,
      link: '/help',
      type: fullAccess,
      notActiveProfile: false,
      svg: <HelpSvg />,
      svgActive: <HelpSvgActive />,
      svgProfileError: null,
    },
  ].filter(item => item?.type?.includes(user?.type));
  return (
    <div className={styles.AsideBox} style={{ zIndex: startTour ? '1000' : '9000' }}>
      {supplierNavLink.map(
        ({ name, link, ref, type, notActiveProfile, svg, svgActive, svgProfileError, errorProfile }) => (
          <div ref={ref}>
            <NewNavItem
              ref={ref}
              typeAccess={type}
              disabled={null}
              key={name}
              name={name}
              link={link}
              notActiveProfile={notActiveProfile}
              svg={svg}
              svgActive={svgActive}
              svgProfileError={svgProfileError}
              errorProfile={errorProfile}
            />
          </div>
        ),
      )}
      <Tour
        className={stylesTour.container}
        open={startTour}
        closeIcon={false}
        gap={{ offset: [-6, -2], radius: 12 }}
        onClose={() => setStartTour(false)}
        steps={steps}
        indicatorsRender={(current, total) => <span></span>}
      />

      {user?.verified && <NavIdeaItem />}
    </div>
  );
};

export default NewAsideNavSupplier;
