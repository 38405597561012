import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { SkeletonQuarry } from '../../components/skeletons/SkeletonQuarry';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { toast, ToastContainer } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { InputText } from '../../components/UI/inputs/InputText';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import { quarryData, QuarryData, quarryDataOptions } from '../../common/quarry-type';
import { useMediaQuery } from 'react-responsive';
import { ButtonTextTransparent } from '../../components/UI/buttons/ButtonTextTransparent';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { Basket } from '../../components/UI/svg-icon/Basket';
import { RadioBoxCircle } from '../../components/UI/checkBox/RadioBoxCircle';
import { TimePicker } from 'antd';
import styles from './SupplierQuarriesPage.module.scss';
import CustomYMap from '../../components/CustomYMap/CustomYMap';
import dayjs from 'dayjs';
import 'react-dadata/dist/react-dadata.css';
import CustomAddressInput from '../../components/newUI/CustomAddressInput/CustomAddressInput';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { SUBSERVER } from '../../common/sub-server.constant';
import { StopSvg } from '../../components/UI/svg-icon/StopSvg';
import { CirclePlusSvg } from '../../components/UI/svg-icon/CirclePlusSvg';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { clearObject } from 'src/common/ClearObject.helper';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { toastError } from 'src/common/toastError.helper';
import { styleStatusQuarries } from './SupplierQuarriesPage';
import { ProfilePageUploading } from '../../components/ProfilePageUploading';
import { Image } from '../../common/image.type';
import UploadImageContainer from './components/UploadImageContainer';
import ImagesContainer from './components/ImagesContainer';

const requestsUrlParams = {
  Отклонен: 'unconfirmed',
  Проверен: 'confirmed',
};
export const SupplierQuarryPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const [skeletonQuarry, setSkeletonQuarry] = useState(false);
  const [image, setImage] = useState('');
  const [popUpActive, setPopUpActive] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [, setDisabled] = useState(false);
  const [, setSending] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorSecond, setIsErrorSecond] = useState(false);
  const [helpPhone, setHelpPhone] = useState(false);
  const [helpPhoneSecond, setHelpPhoneSecond] = useState(false);
  const [quarry, setQuarry] = useState<any>();
  const [imagesLicense, setImagesLicense] = useState<Image[]>([]);
  const [imagesAgreement, setImagesAgreement] = useState<Image[]>([]);
  const [imagesScheme, setImagesScheme] = useState<Image[]>([]);
  const [uploadLicense, setUploadImagesLicense] = useState<Image[]>([]);
  const [uploadAgreement, setUploadImagesAgreement] = useState<Image[]>([]);
  const [uploadScheme, setUploadImagesScheme] = useState<Image[]>([]);
  const [showImages, setShowImages] = useState(false);
  const [data, setData] = useState<QuarryData>(quarryData);
  const [openLeftTimer, setOpenLeftTimer] = useState(false);
  const [openRightTimer, setOpenRightTimer] = useState(false);
  const [validateTime, setValidateTime] = useState<'' | 'error' | 'warning'>('');
  const [valueAddress, setValueAddress] = useState<DaDataSuggestion<DaDataAddress> | undefined>();
  const changeTime = (time: any, timeString: string | string[], input: string, inputTime: string) => {
    setData(prevState => ({
      ...prevState,
      workingHoursType: {
        ...prevState.workingHoursType,
        [input]: time,
        [inputTime]: time,
      },
    }));
  };
  const ValidateErrorFields = (): boolean => {
    return !!(
      (!validateName(data.name) || data.name) &&
      (!validateName(data.responsibleEmployee.person.person) || data.responsibleEmployee.person.person) &&
      data.quarryType &&
      data?.address?.latitude &&
      data?.address?.longitude &&
      !isError &&
      !isErrorSecond
    );
  };
  const validateName = value => value?.replace(/[\sА-Яа-яЁё\- ]/gi, '');
  const customTimePickerButton = (isLeft: boolean) => {
    const closeTimer = () => {
      if (isLeft) {
        setOpenLeftTimer(false);
      } else {
        setOpenRightTimer(false);
      }
    };

    return (
      <button onClick={closeTimer} className={styles.customRangePickerBtnСhoose}>
        Готово
      </button>
    );
  };

  const toggleImages = () => {
    setShowImages(!showImages);
  };

  const setDayJsFormatDate = time => {
    const correctDate = new Date();
    correctDate.setHours(time?.split(':')[0]);
    correctDate.setMinutes(time?.split(':')[1]);
    return dayjs(correctDate);
  };

  const getData = async () => {
    setSkeletonQuarry(true);
    try {
      const response = await fetchGet(`/quarries/${window.location.href.split('/').reverse()[0]}`, {});

      if (response?.response?.error) {
        console.log('error');
        toast.error('Ошибка получение данных');
        return;
      }
      setQuarry(response?.response);
      const responseFile = await fetch(
        `${SUBSERVER}/api/files/?fileBelongsId=${response.response?.id}&communicationCategory=quarries`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
          credentials: 'include',
        },
      );
      const dataFile = await responseFile.json();
      dataFile?.response?.forEach(file => {
        switch (file.category) {
          case 'Фотографии и схемы проезда': {
            setImagesScheme(prevState => [...prevState, file]);
            break;
          }
          case 'Лицензия на пользование недрами': {
            setImagesLicense(prevState => [...prevState, file]);
            break;
          }
          case 'Договор на продажу или дилерство': {
            setImagesAgreement(prevState => [...prevState, file]);
            break;
          }
        }
      });

      setData(prevState => ({
        ...prevState,
        name: response?.response?.name,
        bitrixId: response?.response?.bitrixId,
        address: response.response?.address,
        quarryType: response.response?.quarryType === 'Карьер' ? 'quarry' : 'transshipment',
        dispatcherPhone: response.response?.dispatcher?.phone,
        contactPersons: response?.response?.contactPersons.slice(1).map(item => {
          return {
            person: item.person,
            phone: item.phone,
            email: item.email,
            main: item?.contactPersonsCommunications?.main,
          };
        }),
        responsibleEmployee: {
          person: {
            person: response.response?.contactPersons.filter(
              person => person?.contactPersonsCommunications?.main === true,
            )[0]?.person,
            error: false,
          },
          status: response.response?.verifications?.[0]?.status?.title,
          phone: {
            phone: response.response?.contactPersons.filter(
              person => person?.contactPersonsCommunications?.main === true,
            )[0]?.phone,
            error: false,
          },
          email: {
            email: response.response?.contactPersons.filter(
              person => person?.contactPersonsCommunications?.main === true,
            )[0]?.email,
            error: false,
          },
          main: true,
        },
        files: response.response?.files,
        time: response.response?.workSchedulesGraph?.from,
        workingHoursType: {
          name: response.response?.workSchedulesGraph.aroundTheClock ? 'aroundTheClock' : 'daytime',
          workFrom: setDayJsFormatDate(response.response?.workSchedulesGraph.to),
          workTo: setDayJsFormatDate(response.response?.workSchedulesGraph.from),
        },
      }));

      setValueAddress({
        ...valueAddress,
        value: response.response?.address.address,
        data: {
          ...valueAddress?.data,
          geo_lat: response.response?.address.latitude.toString(),
          geo_lon: response.response?.address.longitude.toString(),
        },
      });

      setTimeout(() => {
        setSkeletonQuarry(false);
      }, 800);
    } catch (e) {
      setSkeletonQuarry(false);
      console.log(e);
    }
  };

  const quarryStatus = quarry?.verifications?.[0]?.status?.title;
  const correctionTime =
    data.workingHoursType.name === 'daytime'
      ? {
          workingHoursType: data.workingHoursType.name,
          from: data.workingHoursType.workTo?.format('HH:mm'),
          to: data.workingHoursType.workFrom?.format('HH:mm'),
        }
      : {
          workingHoursType: data.workingHoursType.name,
        };

  const sendQuarryData = async () => {
    setDisabled(true);
    setSending(true);
    const unconfirmedData = {
      /*trim убирает пробелы в начале и конце строки, replace() - убирает дубликат пробелов*/
      name: data.name.trim().replace(/ +(?= )/g, ''),
      quarryType: data?.quarryType.trim().replace(/ +(?= )/g, ''),
      dispatcherPhone: data?.dispatcherPhone,
      time: correctionTime,
      address: {
        address: valueAddress.value,
        latitude: Number(valueAddress?.data?.geo_lat),
        longitude: Number(valueAddress?.data?.geo_lon),
      },
      contactPersons: [
        {
          person: data.responsibleEmployee.person.person.trim().replace(/ +(?= )/g, ''),
          phone: data.responsibleEmployee.phone.phone,
          main: true,
          email: data.responsibleEmployee.email.email || undefined,
        },
        ...data?.contactPersons.map(item => {
          return {
            person: item?.person,
            phone: item?.phone,
            main: item?.main,
            email: item?.email || undefined,
          };
        }),
      ],
    };
    const confirmedData = {
      /*trim убирает пробелы в начале и конце строки, replace() - убирает дубликат пробелов*/
      name: data.name.trim().replace(/ +(?= )/g, ''),
      dispatcherPhone: data?.dispatcherPhone,
      time: correctionTime,
      contactPersons: [
        {
          person: data.responsibleEmployee.person.person.trim().replace(/ +(?= )/g, ''),
          phone: data.responsibleEmployee.phone.phone,
          main: true,
          email: data.responsibleEmployee.email.email || undefined,
        },
        ...data?.contactPersons.map(item => {
          return {
            person: item?.person,
            phone: item?.phone,
            main: item?.main,
            email: item?.email || undefined,
          };
        }),
      ],
    };
    const requestsBodyParams = {
      Отклонен: unconfirmedData,
      Проверен: confirmedData,
    };
    try {
      const correctionArrayFilesBody = [
        {
          communicationCategory: 'quarries',
          category: 'Фотографии и схемы проезда',
          fileBelongsId: quarry.id,
          files: uploadScheme.map(file => file.value),
          objectUuid: '',
        },
        {
          communicationCategory: 'quarries',
          category: 'Лицензия на пользование недрами',
          fileBelongsId: quarry.id,
          files: uploadLicense.map(file => file.value),
          objectUuid: '',
        },
        {
          communicationCategory: 'quarries',
          category: 'Договор на продажу или дилерство',
          fileBelongsId: quarry.id,
          files: uploadAgreement.map(file => file.value),
          objectUuid: '',
        },
      ];
      const requestOptionFile = correctionArrayFilesBody?.filter(item => item?.files?.length !== 0);
      const response = await fetchPost(
        `/quarries/${requestsUrlParams[quarryStatus]}/${window.location.href.split('/').reverse()[0]}`,
        'PATCH',
        clearObject(requestsBodyParams[quarryStatus]),
      );
      const responseFile = await fetchPost(`/files/`, 'POST', requestOptionFile);
      if (data?.bitrixId) {
        await fetch(`https://b24-bnchwk.bitrix24.ru/rest/3250/c2cra3q4lruhrbia/crm.deal.update.json`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: data?.bitrixId,
            fields: {
              UF_CRM_1731305358096: [
                ...responseFile?.response
                  .filter(itemFile => itemFile?.category === 'Фотографии и схемы проезда')
                  .map(file => file?.location),
                ...imagesScheme.map(file => file?.location),
              ],
              UF_CRM_1731305369098: [
                ...responseFile?.response
                  .filter(itemFile => itemFile?.category === 'Лицензия на пользование недрами')
                  .map(file => file?.location),
                ...imagesLicense.map(file => file?.location),
              ],
              UF_CRM_1731305379354: [
                ...responseFile?.response
                  .filter(itemFile => itemFile?.category === 'Договор на продажу или дилерство')
                  .map(file => file?.location),
                ...imagesAgreement.map(file => file?.location),
              ],
            },
          }),
        });
      }

      if (toastError(response)) {
        setSending(false);
        return;
      }

      toast.success('Данные успешно сохранены');
      setUpdatePage(!updatePage);
      setSending(false);
      navigate('/quarries');
    } catch (e) {
      setSending(false);
      console.log(e);
    }
  };

  const deleteQuarry = async () => {
    try {
      const response = await fetchPost(`/quarries/${window.location.href.split('/').reverse()[0]}`, 'DELETE', {});
      if (toastError(response)) return;
      toast.success('Карьер успешно удален');
      navigate('/quarries');
    } catch (e) {
      toast.error('Ошибка удаления карьера');
      console.log(e);
    }
  };
  const addPerson = () => {
    setData({
      ...data,
      contactPersons: [
        ...data?.contactPersons,
        {
          person: '',
          phone: '',
          email: '',
          main: false,
        },
      ],
    });
  };
  const deleteContact = (id: any, value: number) => {
    setData(prevState => {
      const copy = { ...prevState };
      copy.contactPersons = [...copy.contactPersons];
      copy.contactPersons.splice(value, 1);
      return copy;
    });
  };
  const changeValueContact = (key, value, input) => {
    setData(prevState => {
      const copy = { ...prevState };
      switch (input) {
        case 'person': {
          copy.contactPersons.find((el, index) => index === key).person = value;
          break;
        }
        case 'phone': {
          copy.contactPersons.find((el, index) => index === key).phone = value;
          break;
        }
        case 'email': {
          copy.contactPersons.find((el, index) => index === key).email = value;
          break;
        }
        default: {
          return copy;
        }
      }

      return copy;
    });
  };
  const createValidateTime = () => {
    if (data.workingHoursType.workFrom !== '' && data.workingHoursType.workTo !== '') {
      const timeFrom = data.workingHoursType.workFrom?.format('HH:mm');
      const to = data.workingHoursType.workTo?.format('HH:mm');
      const result = timeFrom?.split(':')[0] - to?.split(':')[0] < 4 && timeFrom !== '' && to !== '' ? 'error' : '';
      setValidateTime(result);
    }
  };
  useEffect(() => {
    createValidateTime();
  }, [data.workingHoursType.workTo]);
  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActive(openPopUp);
  };
  const deleteFile = async (id, name, communicationCategory) => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
      credentials: 'include',
      body: JSON.stringify({
        communicationCategory: communicationCategory,
        name: name,
        objectUuid: '',
      }),
    };
    try {
      // @ts-ignore
      const response = await fetch(`${SUBSERVER}/api/files/${id}`, requestOptions);
      const data = await response.json();
      if (toastError(data)) return;
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getData().catch(console.error);
  }, [updatePage]);

  useEffect(() => {
    setData(prevState => ({
      ...prevState,
      address: {
        ...prevState.address,
        latitude: Number(valueAddress?.data?.geo_lat),
        longitude: Number(valueAddress?.data?.geo_lon),
      },
    }));
  }, [valueAddress]);
  return (
    <>
      {skeletonQuarry ? (
        <PageWrapper>
          <SkeletonQuarry />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <ToastContainer />
          <Link className="button-back" to="/quarries">
            <div className="button-back__svg">
              <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            Вернуться к списку карьеров
          </Link>
          <div className={'supplier-quarry-page'}>
            <div style={{ display: 'flex', alignItems: 'start', gap: '10px', padding: '24px 0 40px 0' }}>
              <div className={' textH4 textH4_medium'}>{quarry?.name}</div>
              <p style={styleStatusQuarries[quarryStatus]}>{quarryStatus}</p>
            </div>
            {quarryStatus === 'Отклонен' && (
              <div className={styles.notificationContainer}>
                Карьер не прошел проверку. {quarry?.verifications?.[0]?.comment}
              </div>
            )}

            <div className={'page-content-inputs-box'}>
              <div>
                <div
                  style={{ marginBottom: isMobile ? '8px' : '0' }}
                  className={'page-content-inputs-box__title textH5 textH5_medium'}
                >
                  1. Наименование и тип карьера
                  <sup>&nbsp;*</sup>
                </div>
              </div>
              <div>
                <div className={'page-content-inputs-box__input'}>
                  <InputText
                    type={''}
                    disabled={quarryStatus === 'На проверке'}
                    isLabel={true}
                    value={data.name}
                    setValue={value => setData({ ...data, name: value })}
                    margin={'0'}
                    widthHelp={isMobile ? 280 : 400}
                    error={true}
                    showError={validateName(data.name)}
                    helpText={'Введите наименование карьера. Например: “Самойловский карьер”'}
                    textError={
                      validateName(data.name) ? 'Допускаются только русские буквы' : 'Поле обязательное для заполнения'
                    }
                    placeholder={'Наименование'}
                  />
                </div>
                <div className={'page-content-inputs-box__select'}>
                  <CustomSelect
                    isDisabled={quarryStatus !== 'Отклонен'}
                    error={!data.quarryType}
                    options={[
                      { value: 'quarry', label: 'Карьер' },
                      { value: 'transshipment', label: 'Перевалка' },
                    ]}
                    defaultValue={quarryDataOptions.filter(({ value }) => value === data.quarryType)}
                    setValue={value => setData({ ...data, quarryType: value.value })}
                    placeholder={'Тип'}
                    textError={'Поле обязательное для заполнения'}
                    isClearable={false}
                    isSearchable={false}
                    noOptionsMessage={null}
                  />
                </div>
              </div>
            </div>
            <div className={'page-content-inputs-box'}>
              <div>
                <div
                  style={{ marginBottom: isMobile ? '4px' : '0' }}
                  className={'page-content-inputs-box__title textH5 textH5_medium'}
                >
                  2. Адрес карьера
                </div>
              </div>
              <div>
                <div className={'page-content-inputs-box__sub-title textH6'}>
                  Фактический адрес карьера
                  <sup>&nbsp;*</sup>
                </div>
                <div className={'page-content-inputs-box__input'}>
                  <CustomAddressInput
                    disabled={quarryStatus !== 'Отклонен'}
                    placeholder={'Адрес'}
                    valueAddress={valueAddress}
                    setValueAddress={setValueAddress}
                  />
                </div>
                <div className={'page-content-inputs-box__sub-title textH6'}>Широта и Долгота</div>
                <div className={'page-content-inputs-box__inputs'}>
                  <InputText
                    type={'number'}
                    disabled={quarryStatus !== 'Отклонен'}
                    isLabel={true}
                    value={data?.address?.latitude}
                    error={true}
                    setValue={value =>
                      setData(prevState => ({
                        ...prevState,
                        address: { ...data.address, latitude: Number(value) },
                      }))
                    }
                    margin={'0'}
                    widthHelp={260}
                    helpText={'Введите широту адреса карьера. Например: “55.71656”'}
                    textError={'Поле обязательное для заполнения'}
                    placeholder={'Широта'}
                  />
                  <InputText
                    type={'number'}
                    disabled={quarryStatus !== 'Отклонен'}
                    isLabel={true}
                    value={data?.address?.longitude}
                    error={true}
                    setValue={value =>
                      setData(prevState => ({
                        ...prevState,
                        address: { ...data.address, longitude: Number(value) },
                      }))
                    }
                    margin={'0'}
                    widthHelp={260}
                    helpText={'Введите долготу адреса карьера. Например: “55.71656”'}
                    textError={'Поле обязательное для заполнения'}
                    placeholder={'Долгота'}
                  />
                </div>
                <div className={'page-content-inputs-box__map'}>
                  <div style={{ textAlign: 'end' }}>
                    <ButtonTextTransparent
                      onClick={() => setOpenMap(!openMap)}
                      children={openMap ? 'Скрыть карту' : 'Показать карту'}
                      addClass={''}
                    />
                  </div>
                  {openMap && <CustomYMap latitude={data?.address?.latitude} longitude={data?.address?.longitude} />}
                </div>
              </div>
            </div>
            <div className={'page-content-inputs-box'}>
              <div>
                <div
                  style={{ marginBottom: isMobile ? '8px' : '0' }}
                  className={'page-content-inputs-box__title textH5 textH5_medium'}
                >
                  3. График работы и телефон диспетчера
                  <sup>&nbsp;*</sup>
                </div>
              </div>
              <div>
                <div className={'aside-popup-content-box__input'}>
                  <div>
                    <div className={'orders-page-popup-box__radio'}>
                      <RadioBoxCircle
                        disabled={quarryStatus === 'На проверке'}
                        check={data.workingHoursType.name === 'aroundTheClock'}
                        onInput={value =>
                          setData({
                            ...data,
                            workingHoursType: { ...data.workingHoursType, name: 'aroundTheClock' },
                          })
                        }
                        name={'TypeOfAcceptance1'}
                        id={'5'}
                        value={'Круглосуточная'}
                      />
                    </div>
                    <div className={'orders-page-popup-box__radio'}>
                      <RadioBoxCircle
                        disabled={quarryStatus === 'На проверке'}
                        check={data.workingHoursType.name === 'daytime'}
                        onInput={value =>
                          setData({
                            ...data,
                            workingHoursType: { ...data.workingHoursType, name: 'daytime' },
                          })
                        }
                        name={'TypeOfAcceptance1'}
                        id={'6'}
                        value={'Дневная'}
                      />
                    </div>
                  </div>
                  <div className={'orders-page-popup-box__box-time'}>
                    <div
                      className={'orders-page-popup-box__time'}
                      style={{
                        width: '265px',
                      }}
                    >
                      <TimePicker
                        value={data.workingHoursType.workTo}
                        showNow={false}
                        needConfirm={false}
                        placeholder={'Период времени'}
                        format={'HH:mm'}
                        onChange={(time, timeString) => changeTime(time, timeString, 'workTo', 'workTimeTo')}
                        disabled={data.workingHoursType.name !== 'daytime' || quarryStatus === 'На проверке'}
                        defaultValue={data.workingHoursType.defaultWorkTo}
                        open={openLeftTimer}
                        onClick={() => setOpenLeftTimer(true)}
                        renderExtraFooter={() => customTimePickerButton(true)}
                      />
                    </div>
                    <div className={'orders-page-popup-box__or'}>до</div>
                    <div
                      className={'orders-page-popup-box__time'}
                      style={{
                        width: '265px',
                      }}
                    >
                      <TimePicker
                        showNow={false}
                        value={data.workingHoursType.workFrom}
                        needConfirm={false}
                        placeholder={'Период времени'}
                        format={'HH:mm'}
                        onChange={(time, timeString) => changeTime(time, timeString, 'workFrom', 'workTimeFrom')}
                        disabled={data.workingHoursType.name !== 'daytime' || quarryStatus === 'На проверке'}
                        open={openRightTimer}
                        onClick={() => setOpenRightTimer(true)}
                        renderExtraFooter={() => customTimePickerButton(false)}
                        status={validateTime}
                        defaultValue={data.workingHoursType.defaultWorkFrom}
                      />
                    </div>
                  </div>
                </div>
                <MyPhoneInput
                  disabled={quarryStatus !== 'Отклонен'}
                  setIsError={setIsError}
                  isError={isError}
                  isActive={helpPhone}
                  setIsActive={setHelpPhone}
                  value={data.dispatcherPhone}
                  onChange={value => setData({ ...data, dispatcherPhone: value })}
                  isFunction={null}
                  btnHelp={true}
                />
                {isError && <p style={{ color: '#EB5757', fontSize: '12px' }}>Поле обязательное для заполнения</p>}
              </div>
            </div>
            {
              <div className={'page-content-inputs-box'}>
                <div>
                  <div
                    style={{ marginBottom: isMobile ? '8px' : '0' }}
                    className={'page-content-inputs-box__title textH5 textH5_medium'}
                  >
                    4. Ответственный сотрудник
                    <sup>&nbsp;*</sup>
                  </div>
                </div>
                <div>
                  <div className={'page-content-inputs-box__input'}>
                    <InputText
                      type={''}
                      disabled={quarryStatus === 'На проверке'}
                      isLabel={true}
                      value={data.responsibleEmployee.person.person}
                      setValue={value =>
                        setData({
                          ...data,
                          responsibleEmployee: {
                            ...data.responsibleEmployee,
                            person: { ...data.responsibleEmployee.person, person: value },
                          },
                        })
                      }
                      margin={'0'}
                      widthHelp={isMobile ? 280 : 400}
                      error={true}
                      showError={validateName(data.responsibleEmployee.person.person)}
                      helpText={''}
                      textError={
                        validateName(data.responsibleEmployee.person.person)
                          ? 'Допускаются только русские буквы'
                          : 'Поле обязательное для заполнения'
                      }
                      placeholder={'Фамилия Имя'}
                    />
                  </div>
                  <div className={'page-content-inputs-box__input'}>
                    <InputText
                      type={''}
                      disabled={quarryStatus === 'На проверке'}
                      isLabel={true}
                      value={data.responsibleEmployee.email.email}
                      setValue={value =>
                        setData({
                          ...data,
                          responsibleEmployee: {
                            ...data.responsibleEmployee,
                            email: { ...data.responsibleEmployee.email, email: value?.replace(/\s/g, '') },
                          },
                        })
                      }
                      margin={'0'}
                      widthHelp={isMobile ? 280 : 400}
                      error={true}
                      showError={!data.responsibleEmployee.email.email}
                      helpText={''}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'Электронная почта'}
                    />
                  </div>
                  <MyPhoneInput
                    style={{ marginBottom: isMobile ? '8px' : '0' }}
                    disabled={quarryStatus === 'На проверке'}
                    setIsError={setIsErrorSecond}
                    isError={isErrorSecond}
                    isActive={helpPhoneSecond}
                    setIsActive={setHelpPhoneSecond}
                    value={data.responsibleEmployee.phone.phone}
                    onChange={value =>
                      setData({
                        ...data,
                        responsibleEmployee: {
                          ...data.responsibleEmployee,
                          phone: { ...data.responsibleEmployee.phone, phone: value },
                        },
                      })
                    }
                    isFunction={null}
                    btnHelp={true}
                  />
                  {isErrorSecond && (
                    <p style={{ color: '#EB5757', fontSize: '12px' }}>Поле обязательное для заполнения</p>
                  )}
                </div>
                <div className={'page-content-inputs-box'}>
                  <div>
                    <div className={'page-content-inputs-box__title textH5 textH5_medium'}>5. Доп контакты</div>
                  </div>
                  <div>
                    {data?.contactPersons?.length ? (
                      <div>
                        {data?.contactPersons?.map((el, index) => (
                          <div key={index}>
                            <div className={'objectPage-content__delete'}>
                              <div
                                className={'objectPage-content__delete-title'}
                              >{`Дополнительный контакт № ${index + 1}`}</div>
                              {!isMobile && quarryStatus !== 'На проверке' && (
                                <div
                                  className={'objectPage-content__delete-button'}
                                  onClick={() => deleteContact(el.id, index)}
                                >
                                  <span>
                                    <StopSvg />
                                  </span>
                                  Удалить
                                </div>
                              )}
                            </div>
                            <div className={'objectsPage-popup-box__input'}>
                              <InputText
                                type={''}
                                secondError={false}
                                disabled={quarryStatus === 'На проверке'}
                                isLabel={true}
                                value={data.contactPersons[index]?.person}
                                setValue={value => changeValueContact(index, value, 'person')}
                                margin="0"
                                fill={true}
                                error={true}
                                showError={validateName(data.contactPersons[index]?.person)}
                                helpText={''}
                                widthHelp={0}
                                textError={
                                  validateName(data.contactPersons[index]?.person)
                                    ? 'Допускаются только русские буквы'
                                    : 'Поле обязательное для заполнения'
                                }
                                placeholder={'ФИО'}
                              />
                            </div>
                            <div className={'objectsPage-popup-box__input'}>
                              <InputText
                                type={''}
                                secondError={false}
                                disabled={quarryStatus === 'На проверке'}
                                isLabel={true}
                                value={data.contactPersons[index]?.email}
                                setValue={value => changeValueContact(index, value, 'email')}
                                margin="0"
                                fill={true}
                                error={false}
                                showError={false}
                                helpText={''}
                                widthHelp={0}
                                textError={''}
                                placeholder={'Электронная почта'}
                              />
                            </div>
                            <div className={'objectsPage-popup-box__input'}>
                              <MyPhoneInput
                                builtInErrorState={true}
                                disabled={quarryStatus === 'На проверке'}
                                placeholder="Номер телефона"
                                setIsError={''}
                                isError={''}
                                isActive={true}
                                setIsActive={null}
                                value={data.contactPersons[index]?.phone}
                                onChange={value => changeValueContact(index, value, 'phone')}
                                isFunction={null}
                                btnHelp={false}
                              />
                            </div>
                            {isMobile && quarryStatus !== 'На проверке' && (
                              <div
                                className={'objectPage-content__delete-button'}
                                onClick={() => deleteContact(el.id, index)}
                              >
                                <span>
                                  <StopSvg />
                                </span>
                                Удалить
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className={'objectsPage-popup-box__add-button'}>
                      <ButtonFilled
                        widthSvg={'14px'}
                        text={'Добавить контакт'}
                        svg={<CirclePlusSvg />}
                        width={'220px'}
                        onClick={addPerson}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className={'page-content-inputs-box'}>
              <div
                style={{ marginBottom: isMobile ? '8px' : '0' }}
                className={'page-content-inputs-box__title textH5 textH5_medium'}
              >
                6. Фотографии и схема проезда
              </div>
              <div>
                <div>
                  <div style={{ marginBottom: '40px' }}>
                    <ButtonNotFilled
                      color={'#FF641D'}
                      text={showImages ? 'Скрыть' : 'Показать'}
                      width={'100%'}
                      onClick={toggleImages}
                    />
                  </div>
                  {showImages && (
                    <div style={{ marginBottom: '15px' }}>
                      <div className={'aside-popup-content-box'}>
                        <div className={'aside-popup-content-box__title textH6 textH6_medium'}>
                          Лицензия на пользование недрами
                          <sup>&nbsp;*</sup>
                        </div>

                        <div className={'statistics-page-popup__image'}>
                          <ProfilePageUploading
                            disabled={quarryStatus !== 'Отклонен'}
                            error={!imagesLicense.length && !uploadLicense.length}
                            name={'Приложите файлы или документ'}
                            desc={'в формате PNG, JPEG, PDF'}
                            setImages={setUploadImagesLicense}
                            images={uploadLicense}
                            count={3}
                          />
                          <UploadImageContainer
                            disabled={quarryStatus !== 'Отклонен'}
                            images={uploadLicense}
                            setImages={setUploadImagesLicense}
                            openPopupFunc={openPopupWithImg}
                          />
                          <ImagesContainer
                            disabled={quarryStatus !== 'Отклонен'}
                            images={imagesLicense}
                            setImages={setImagesLicense}
                            openPopupFunc={openPopupWithImg}
                            deleteFile={deleteFile}
                          />
                        </div>
                      </div>

                      <div className={'aside-popup-content-box'}>
                        <div className={'aside-popup-content-box__title textH6 textH6_medium'}>
                          Договор на продажу или дилерство
                        </div>
                        <div className={'statistics-page-popup__image'}>
                          <ProfilePageUploading
                            disabled={quarryStatus !== 'Отклонен'}
                            name={'Приложите файлы или документ'}
                            desc={'в формате PNG, JPEG, PDF'}
                            setImages={setUploadImagesAgreement}
                            images={uploadAgreement}
                            count={3}
                          />
                          <UploadImageContainer
                            disabled={quarryStatus !== 'Отклонен'}
                            images={uploadAgreement}
                            setImages={setUploadImagesAgreement}
                            openPopupFunc={openPopupWithImg}
                          />
                          <ImagesContainer
                            disabled={quarryStatus !== 'Отклонен'}
                            images={imagesAgreement}
                            setImages={setImagesAgreement}
                            openPopupFunc={openPopupWithImg}
                            deleteFile={deleteFile}
                          />
                        </div>
                      </div>
                      <div className={'aside-popup-content-box'}>
                        <div className={'aside-popup-content-box__title textH6 textH6_medium'}>
                          Фотографии и схема проезда
                        </div>

                        <div className={'statistics-page-popup__image'}>
                          <ProfilePageUploading
                            disabled={quarryStatus === 'На проверке'}
                            name={'Приложите файлы или документ'}
                            desc={'в формате PNG, JPEG, PDF'}
                            setImages={setUploadImagesScheme}
                            images={uploadScheme}
                            count={3}
                          />
                          <UploadImageContainer
                            disabled={quarryStatus === 'На проверке'}
                            images={uploadScheme}
                            setImages={setUploadImagesScheme}
                            openPopupFunc={openPopupWithImg}
                          />
                          <ImagesContainer
                            disabled={quarryStatus === 'На проверке'}
                            images={imagesScheme}
                            setImages={setImagesScheme}
                            openPopupFunc={openPopupWithImg}
                            deleteFile={deleteFile}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={'page-content-inputs-box__button'}>
                    <ButtonFilled
                      disabled={!ValidateErrorFields()}
                      onClick={sendQuarryData}
                      text={'Сохранить'}
                      width={'100%'}
                    />
                  </div>
                  <div className={'page-content-inputs-box__button'}>
                    <ButtonNotFilled
                      widthSvg={'18px'}
                      svg={<Basket />}
                      color={'#FF641D'}
                      text={'Удалить карьер'}
                      width={'100%'}
                      onClick={deleteQuarry}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={popUpActive ? 'Popup active' : 'Popup'}>
            <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
            <div className="Popup__wrapper">
              <div>
                <div>
                  <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                  <div className="Popup__content">
                    <div className="Popup__box">
                      <img src={image} alt={'Транспортная накладная'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
};
