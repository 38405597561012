import React, { useEffect, useLayoutEffect, useState } from 'react';
import styles from './EditAndAddMaterialPage.module.scss';
import TheTypeOfMaterial from './components/TheTypeOfMaterial/TheTypeOfMaterial';
import { ICheckBox, IMaterialButtons, ITypeInputsOfMaterial } from '../type';
import { arrayCompare, arrayMaterialButtons, initStateCheckBox, initStateInputsOfMaterial } from '../constans';
import InputsByMaterial from './components/InputsByMaterial/InputsByMaterial';
import { fetchGet, fetchPost } from '../../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import { Image } from '../../../common/image.type';
import { PageWrapper } from '../../../components/PageWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ButtonNotFilled from '../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import SuccessSvgVer2 from '../../../components/UI/svg-icon/SuccessSvgVer2';
import NewPlusSvg from '../../../components/UI/svg-icon/NewPlusSvg';
import DeleteOutlineSvg from '../../../components/UI/svg-icon/DeleteOutlineSvg';
import uuid from 'react-uuid';
import { toastError } from 'src/common/toastError.helper';
interface IProps {
  create?: boolean;
  routeFromIndividualMaterialPage?: boolean;
  edit?: boolean;
}
//@TODO переписать пропсы на type = 'create'|'routeFromIndividualMaterialPage'|'edit'
const EditAndAddMaterialPage = ({ create = false, routeFromIndividualMaterialPage = false, edit = false }: IProps) => {
  const [materialButtons, setMaterialButtons] = useState<IMaterialButtons[]>(arrayMaterialButtons);
  const [valueMaterial, setValueMaterial] = useState<string>('');
  const [valueOfInputsOfMaterial, setValueOfInputsOfMaterial] =
    useState<ITypeInputsOfMaterial>(initStateInputsOfMaterial);
  const [objectForCompare, setObjectForCompare] = useState<ITypeInputsOfMaterial>(initStateInputsOfMaterial);
  const [ownNameCheckBox, setOwnNameCheckBox] = useState<ICheckBox>(initStateCheckBox);
  const [fixedPriceCheckBox, setFixedPriceCheckBox] = useState<ICheckBox>({ id: 'fixedPrice', active: true });
  const [successCreateMaterial, setSuccessCreateMaterial] = useState<boolean>(false);
  const [disableButtonOfEdit, setDisableButtonOfEdit] = useState<boolean>(true);
  const [disableButtonOfAdd, setDisableButtonOfAdd] = useState<boolean>(false);
  const [disableDeleteButtonOfEdit, setDisableDeleteButtonOfEdit] = useState<boolean>(false);
  const [imagesPassport, setImagesPassport] = useState<Image[]>([]);
  const [imagesMaterial, setImagesMaterial] = useState<Image[]>([]);
  const [uploadPassport, setUploadPassport] = useState<Image[]>([]);
  const [uploadMaterial, setUploadMaterial] = useState<Image[]>([]);
  const [renderCount, setRenderCount] = useState<number>(0);
  const [renderCountValueMaterials, setRenderCountValueMaterials] = useState<number>(0);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isActive = fixedPriceCheckBox.active
    ? [{ region: 'Все', value: '', id: uuid(), error: false }]
    : [
        { region: '', value: '', id: uuid(), error: false },
        { region: '', value: '', id: uuid(), error: false },
      ];
  const addToValueOfInputsImages = () => {
    setValueOfInputsOfMaterial(prevState => ({
      ...prevState,
      [valueMaterial]: {
        ...prevState[valueMaterial],
        photo: uploadMaterial.map(file => file.value),
        passport: uploadPassport.map(file => file.value),
      },
    }));
  };
  useEffect(() => {
    addToValueOfInputsImages();
  }, [uploadPassport, uploadMaterial]);
  const convertObjectsFromObjectToValue = (obj: any): any => {
    let index: number = 0;
    let newConvertObjectPrices = obj.prices.map(price => {
      return { ...price, value: Number(price.value.replaceAll(',', '.')) };
    });
    let convertObject: any = {
      ...obj,
      volume: Number(obj.volume.replaceAll(',', '.')),
      prices: newConvertObjectPrices,
    };
    const arrayObjectValuesOfObj: any = [];
    const arrayValuesOfObj: any = Object.values(convertObject);
    arrayValuesOfObj.forEach(item => {
      if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
        const arrayValuesOfItem: any = Object.values(item);
        arrayObjectValuesOfObj.push(item.value === 'т' ? 'тонн' : arrayValuesOfItem[0]);
      } else {
        arrayObjectValuesOfObj.push(item === 'т' ? 'тонн' : item || undefined);
      }
    });
    for (let key in convertObject) {
      convertObject[key] = arrayObjectValuesOfObj[index++];
      if (
        typeof convertObject[key] === 'string' &&
        !arrayCompare.includes(convertObject[key]) &&
        key !== 'description'
      ) {
        convertObject[key] = convertObject[key].replaceAll(',', '.');
      }
    }
    return convertObject;
  };
  const sendValuesMaterial = async () => {
    const requestObj = { ...convertObjectsFromObjectToValue(valueOfInputsOfMaterial[valueMaterial]) };
    try {
      const response = await fetchPost(
        `/supplier/materials/${valueMaterial}` +
          `?showcaseId=${Number(pathname.split('/')[pathname.split('/').length - 1])}`,
        'POST',
        {
          ...requestObj,
          photo: requestObj?.photo?.length ? requestObj?.photo : undefined,
          passport: requestObj?.passport?.length ? requestObj?.passport : undefined,
        },
      );
      if (response.error) {
        setDisableButtonOfAdd(false);
        const errorMessages = response.message.map(message => {
          if (message !== 'Длина массива passport минимум 1 значение' && message !== 'passport must be an array') {
            return (
              <p>
                {message === 'Длина массива passport максимум 5 значений'
                  ? 'Загрузите хотя бы 1 фото паспорта материала'
                  : message}
                <br />
                <br />
              </p>
            );
          }
        });
        toast.error(<div>{errorMessages}</div>);
        return;
      }
      /*setErrorAfterSend(false);*/
      toast.success('Вы успешно добавили материал');
      setSuccessCreateMaterial(true);
      setDisableButtonOfAdd(false);
      resetInputs();
    } catch (e) {
      setDisableButtonOfAdd(false);
      console.log(e);
    }
  };
  const editValuesMaterial = async () => {
    const sizeUploadMaterial = uploadMaterial.reduce((size, file) => file.size + size, 0);
    const sizeUploadPassport = uploadPassport.reduce((size, file) => file.size + size, 0);
    if (sizeUploadPassport + sizeUploadMaterial > 51200) {
      toast.error('Вес файлов не должен превышать 50мб');
      return;
    }
    setDisableButtonOfEdit(true);
    const requestObj = { ...convertObjectsFromObjectToValue(valueOfInputsOfMaterial[valueMaterial]) };
    try {
      const response = await fetchPost(
        `/supplier/materials/${valueMaterial}/${Number(pathname.split('/')[pathname.split('/').length - 2])}`,
        'PATCH',
        {
          ...requestObj,
          photo: requestObj?.photo?.length ? requestObj?.photo : undefined,
          passport: requestObj?.passport?.length ? requestObj?.passport : undefined,
        },
      );
      if (response?.error) {
        setDisableButtonOfEdit(false);
        const errorMessages = response.message.map(message => {
          if (message !== 'Длина массива passport минимум 1 значение' && message !== 'passport must be an array') {
            return (
              <p>
                {message === 'Длина массива passport максимум 5 значений'
                  ? 'Загрузите хотя бы 1 фото паспорта материала'
                  : message}
                <br />
                <br />
              </p>
            );
          }
        });
        toast.error(<div>{errorMessages}</div>);
        return;
      }
      toast.success('Вы отредактировали материал');
      navigate(-1);
    } catch (e) {
      setDisableButtonOfEdit(false);
      console.log(e);
    }
  };
  const deleteMaterial = async () => {
    setDisableDeleteButtonOfEdit(true);
    try {
      const response = await fetchPost(
        `/supplier/materials/${pathname.split('/')[pathname.split('/').length - 2]}`,
        'DELETE',
        {},
      );
      if (toastError(response)) {
        setDisableDeleteButtonOfEdit(false);
        return;
      }
      toast.success('Материал успешно удален');
      navigate(-1);
    } catch (e) {
      setDisableDeleteButtonOfEdit(false);
      console.log(e);
    }
  };
  const editInfoInputs = async () => {
    try {
      const editInputs = await fetchGet(
        `/supplier/materials/${pathname.split('/')[pathname.split('/').length - 2]}`,
        {},
      );
      let newObj = { ...valueOfInputsOfMaterial };
      const currentMaterial = pathname.split('/')[pathname.split('/').length - 3];
      let passportImage = [];
      let materialImage = [];
      let newConvertObjectPrices = editInputs.response.prices.map(price => {
        return { ...price, value: String(price.value).replaceAll('.', ',') };
      });
      newObj[currentMaterial] = {
        ...editInputs.response,
        photo: [],
        passport: [],
        volume: String(editInputs.response.volume).replaceAll('.', ','),
        prices: newConvertObjectPrices,
      };
      editInputs?.response?.properties?.forEach(item => {
        if (item.title === 'sellUnit' && item.value === 'тонн') {
          newObj[currentMaterial][item.title] = 'т';
        } else {
          newObj[currentMaterial][item.title] = item.value;
        }
      });
      if (editInputs?.response?.prices.length > 1) {
        setFixedPriceCheckBox({ id: 'fixedPrice', active: false });
      } else {
        setFixedPriceCheckBox({ id: 'fixedPrice', active: true });
      }
      editInputs?.response?.files?.forEach(file => {
        if (file.category === 'Паспорт') {
          passportImage.push(file);
        } else {
          materialImage.push(file);
        }
      });
      for (let key in newObj[currentMaterial]) {
        if (
          typeof newObj[currentMaterial][key] === 'string' &&
          !arrayCompare.includes(newObj[currentMaterial][key]) &&
          key !== 'description'
        ) {
          newObj[currentMaterial][key] = newObj[currentMaterial][key].replace(/\./g, ',');
        }
      }
      setImagesPassport(passportImage);
      setImagesMaterial(materialImage);
      setValueOfInputsOfMaterial(newObj);
      setObjectForCompare(newObj);
    } catch (e) {
      console.log(e);
    }
  };
  const resetInputs = () => {
    setValueOfInputsOfMaterial(initStateInputsOfMaterial);
    setFixedPriceCheckBox({ id: 'fixedPrice', active: true });
    setImagesPassport([]);
    setImagesMaterial([]);
    setUploadPassport([]);
    setUploadMaterial([]);
  };
  const activeButtonByEditOrRouteFromIndividualMaterialPage = () => {
    if (edit) {
      return pathname.split('/')[pathname.split('/').length - 3];
    }
    if (routeFromIndividualMaterialPage) {
      return pathname.split('/')[pathname.split('/').length - 2];
    }
    return '';
  };
  const activeMaterialRouteFromIndividualMaterialPage = () => {
    setValueMaterial(activeButtonByEditOrRouteFromIndividualMaterialPage());
    const newData = [...materialButtons];
    newData.forEach(button => {
      button.active = button.value === activeButtonByEditOrRouteFromIndividualMaterialPage();
      edit && (button.disabled = button.value !== activeButtonByEditOrRouteFromIndividualMaterialPage());
    });
    setMaterialButtons(newData);
  };

  useLayoutEffect(() => {
    /*делаем активность всех МАТЕРИАЛОВ в <TheTypeOfMaterial> = false при маунтинге страницы*/
    const resetMaterialButtons = [...materialButtons];
    resetMaterialButtons.forEach(button => {
      button.active = false;
      button.disabled = false;
    });
    setMaterialButtons(resetMaterialButtons);
    setValueMaterial('');
    setOwnNameCheckBox(initStateCheckBox);
    /*При редактировании или перехода с индивидуальной страницы материала достаем из url название материала */
    (routeFromIndividualMaterialPage || edit) && activeMaterialRouteFromIndividualMaterialPage();
  }, []);

  useEffect(() => {
    /*При смене материала зануляются значения*/
    !edit && resetInputs();
    if (edit && valueOfInputsOfMaterial[valueMaterial]?.name?.length !== 0) {
      setOwnNameCheckBox({ id: 'ownName', active: true });
    }
  }, [valueMaterial]);
  useEffect(() => {
    setRenderCount(prevState => prevState + 1);
    if (renderCount > 1) {
      setValueOfInputsOfMaterial(prevState => ({
        ...prevState,
        [valueMaterial]: { ...prevState[valueMaterial], prices: isActive },
      }));
    }
  }, [fixedPriceCheckBox]);

  /*Если мы выбираем вид материала у ВТОРИЧНОГО ЩЕБНЯ, то обнуляем некоторые значения*/
  useEffect(() => {
    if (renderCountValueMaterials > 1) {
      setValueOfInputsOfMaterial(prevState => ({
        ...prevState,
        'secondary-crushed-stone': { ...prevState['secondary-crushed-stone'], fraction: '' },
      }));
    }
  }, [valueOfInputsOfMaterial['secondary-crushed-stone'].kind]);

  useEffect(() => {
    /*Если мы выбираем вид материала ПЕСКОГРУНТ у ПЕСКА, то  обнуляем классификацию*/
    if (renderCountValueMaterials > 1) {
      setValueOfInputsOfMaterial(prevState => ({ ...prevState, sand: { ...prevState.sand, classification: '' } }));
    }
  }, [valueOfInputsOfMaterial.sand.kind.value === 'Пескогрунт']);

  useEffect(() => {
    setRenderCountValueMaterials(prevState => prevState + 1);
    /*Если инпут значения равны тем, которые пришли с бэка - кнопка сохранить дизейблится*/
    setDisableButtonOfEdit(JSON.stringify(valueOfInputsOfMaterial) === JSON.stringify(objectForCompare));
  }, [valueOfInputsOfMaterial]);

  useEffect(() => {
    edit && editInfoInputs();
  }, []);

  return (
    <PageWrapper>
      <div className={styles.contentContainer}>
        <div className={styles.titleAndBackButtonAndSaveButtonContainer}>
          <div
            className="button-back"
            onClick={() => navigate(`/showcases/${pathname.split('/')[pathname.split('/').length - 1]}`)}
          >
            <div className="button-back__svg">
              <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            Вернуться к витрине
          </div>
          <div className={styles.titleAndSaveButtonContainer}>
            <h1 className={styles.titleText}>{edit ? 'Редактирование материала' : 'Добавление материала'}</h1>
            {!successCreateMaterial && (
              <>
                {!isMobile && (
                  <div className={styles.buttonsSaveAndSkip}>
                    {create && (
                      <ButtonNotFilled
                        text={'Пропустить'}
                        sizeText={'small'}
                        width={'110px'}
                        onClick={() => navigate(`/showcases/${pathname.split('/')[pathname.split('/').length - 1]}`)}
                      />
                    )}
                    {edit && (
                      <ButtonNotFilled
                        disabled={disableDeleteButtonOfEdit}
                        svg={<DeleteOutlineSvg />}
                        text={'Удалить'}
                        sizeText={'small'}
                        width={'137px'}
                        onClick={() => deleteMaterial()}
                      />
                    )}
                    <ButtonFilled
                      disabled={/*edit ? disableButtonOfEdit :*/ !(valueMaterial && !disableButtonOfAdd)}
                      width={'110px'}
                      sizeText={'small'}
                      text={'Сохранить'}
                      onClick={() => (edit ? editValuesMaterial() : sendValuesMaterial())}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {successCreateMaterial ? (
          <div className={styles.successContainer}>
            <SuccessSvgVer2 />
            <div className={styles.successContent}>
              <p className={styles.successThickText}>Материал успешно добавлен</p>
              <p className={styles.successSoftText}>Он появится в общем списке</p>
            </div>
            <ButtonFilled
              onClick={() => setSuccessCreateMaterial(false)}
              text={'Добавить еще материал'}
              svg={<NewPlusSvg />}
              width={isMobile ? '226px' : '290px'}
            />
          </div>
        ) : (
          <>
            <div className={styles.breedOfMaterialContainer}>
              <div className={styles.titleAndMaterialsContainer}>
                <div className={styles.titleOfProperty}>Выберите породу материала</div>
                <div className={styles.materialContainer}>
                  <TheTypeOfMaterial
                    ownNameCheckBox={ownNameCheckBox}
                    setOwnNameCheckBox={setOwnNameCheckBox}
                    materialButtons={materialButtons}
                    setMaterialButtons={setMaterialButtons}
                    setValueOfInputsOfMaterial={setValueOfInputsOfMaterial}
                    valueOfInputsOfMaterial={valueOfInputsOfMaterial}
                    valueMaterial={valueMaterial}
                    setValueMaterial={setValueMaterial}
                    routeFromIndividualMaterialPage={routeFromIndividualMaterialPage}
                    edit={edit}
                  />
                </div>
              </div>
            </div>
            {valueMaterial && (
              <InputsByMaterial
                valueOfInputsOfMaterial={valueOfInputsOfMaterial}
                setValueOfInputsOfMaterial={setValueOfInputsOfMaterial}
                valueMaterial={valueMaterial}
                imagesPassport={imagesPassport}
                setImagesPassport={setImagesPassport}
                imagesMaterial={imagesMaterial}
                setImagesMaterial={setImagesMaterial}
                uploadPassport={uploadPassport}
                setUploadPassport={setUploadPassport}
                uploadMaterial={uploadMaterial}
                setUploadMaterial={setUploadMaterial}
                setDisableButtonOfEdit={setDisableButtonOfEdit}
                edit={edit}
                fixedPriceCheckBox={fixedPriceCheckBox}
                setFixedPriceCheckBox={setFixedPriceCheckBox}
              />
            )}
            {isMobile && (
              <div className={styles.buttonsSaveAndSkip}>
                {create && (
                  <ButtonNotFilled
                    text={'Пропустить'}
                    sizeText={'small'}
                    width={'100%'}
                    onClick={() => navigate(`/showcases/${pathname.split('/')[pathname.split('/').length - 1]}`)}
                  />
                )}
                {edit && (
                  <ButtonNotFilled
                    disabled={disableDeleteButtonOfEdit}
                    svg={<DeleteOutlineSvg />}
                    text={'Удалить'}
                    sizeText={'small'}
                    width={'100%'}
                    onClick={() => deleteMaterial()}
                  />
                )}
                <ButtonFilled
                  disabled={/*edit ? disableButtonOfEdit : */ !(valueMaterial && !disableButtonOfAdd)}
                  width={'100%'}
                  sizeText={'small'}
                  text={'Сохранить'}
                  onClick={() => (edit ? editValuesMaterial() : sendValuesMaterial())}
                />
              </div>
            )}
          </>
        )}
      </div>
    </PageWrapper>
  );
};
export default EditAndAddMaterialPage;
