import React, { useState } from 'react';
import styles from './GSMNewCard.module.scss';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { WalletGSMSvg } from 'src/components/UI/svg-icon/WalletGSMSvg';
import { formCardNumber } from 'src/common/formCardNumber.helper';
import { ModalNew } from 'src/components/ModalNew/ModalNew';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../../components/UI/svg-icon/CloseSvg';
import { TCardInfo } from '../GSMtypes';
import cx from 'classnames';
import { formPrice } from 'src/common/form-price.helper';

export const GSMNewCard = ({ cardInfo }: { cardInfo: TCardInfo }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const refuelLinkObj = {
    iPhone: 'https://apps.apple.com/ru/app/%D1%80%D0%BD-%D0%BA%D0%B0%D1%80%D1%82/id1438092935',
    Android: 'https://www.rustore.ru/catalog/app/ru.rncard.virtualcard',
  };
  const getDeviceType = () => {
    const userAgent = navigator.userAgent;
    if (/iPhone/i.test(userAgent)) return 'iPhone';
    if (/Android/i.test(userAgent)) return 'Android';
    return 'Other';
  };
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <span className={styles.cardNumber}>{formCardNumber(cardInfo?.cardNumber)}</span>
        <div className={cx(styles.statusContainer, cardInfo?.status !== 'Активна' && styles.inactiveStatusContainer)}>
          {cardInfo?.status}
        </div>
      </div>
      <div className={styles.priceContainer}>
        <div style={{ paddingTop: '4px' }}>
          <WalletGSMSvg />
        </div>
        <span className={styles.price}>{formPrice(cardInfo?.limit?.current || 0, 'RUB', 'ru')}</span>
      </div>
      <ButtonFilled
        text={'Заправиться'}
        disabled={!isMobile}
        height={'40px'}
        width={'100%'}
        onClick={() => setOpenModal(true)}
      />
      {openModal && (
        <ModalNew openModal={openModal} setOpenModal={setOpenModal}>
          <div className={styles.modalContainer}>
            <div className={styles.mobileCloseButtonAndTitle}>
              <p className={styles.modalTitle}>Подтвердите переход</p>
              {isMobile && (
                <div className={styles.closeButton} onClick={() => setOpenModal(false)}>
                  <CloseSvg />
                </div>
              )}
            </div>
            <div className={styles.modalSubtitle}>
              Нажимая кнопку “Продолжить” вы подтверждаете переход в другой сервис
            </div>
            <div className={styles.modalButtonsContainer}>
              <ButtonNotFilled
                text="Отмена"
                sizeText={isMobile ? 'small' : 'big'}
                onClick={() => setOpenModal(false)}
              />
              <ButtonFilled
                onClick={() => window.location.assign(refuelLinkObj[getDeviceType()])}
                text="Продолжить"
                sizeText={isMobile ? 'small' : 'big'}
              />
            </div>
          </div>
        </ModalNew>
      )}
    </div>
  );
};
