import React from 'react';
import './RadioGroup.css';
import { Radio, RadioChangeEvent, ConfigProvider } from 'antd';
interface IProps {
  value: any;
  setValue: any;
  firstTitle: string;
  secondTitle: string;
  width: string;
  disabled: boolean;
  style?: React.CSSProperties;
}

const RadioGroup: React.FC<IProps> = ({
  value,
  disabled,
  setValue,
  firstTitle,
  secondTitle,
  width,
  style = { display: 'flex', justifyContent: 'space-between', width: width },
}) => {
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Radio: {
              radioSize: 24,
              dotSize: 10,
            },
          },
        }}
      >
        <Radio.Group onChange={onChange} value={value}>
          <div style={style}>
            <Radio value={firstTitle} disabled={disabled}>
              {firstTitle}
            </Radio>
            <Radio value={secondTitle} disabled={disabled}>
              {secondTitle}
            </Radio>
          </div>
        </Radio.Group>
      </ConfigProvider>
    </div>
  );
};

export default RadioGroup;
