export const initStateFilters = {
    search:'',
    grid:'Сетка',
    dateAdded:'По дате добавления',
};
export const optionsGrid = [
{ value:'Сетка',label:'Сетка' }
];
export const optionsDateAdded = [
    { value:'По дате добавления',label:'По дате добавления' }
];