import { Modal } from 'antd';
import React from 'react';
import styles from './ModalNew.module.scss';
import { useMediaQuery } from 'react-responsive';
import DrawerMobile from '../newUI/DrawerMobile/DrawerMobile';
import FrameModalSvg from '../UI/svg-icon/FrameModalSvg';

interface IProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<boolean>;
  children: any;
  width?: string;
  afterClose?: () => void;
  isDisabledClose?: boolean;
}

export const ModalNew = ({
  openModal,
  setOpenModal,
  children,
  width = '480px',
  afterClose,
  isDisabledClose = false,
}: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      {isMobile ? (
        <DrawerMobile
          afterClose={afterClose}
          closeButton={false}
          heightDrawer={'fit-content'}
          open={openModal}
          close={() => {
            if (!isDisabledClose) setOpenModal(false);
          }}
        >
          {children}
        </DrawerMobile>
      ) : (
        <Modal
          destroyOnClose={true}
          afterClose={afterClose}
          width={width}
          zIndex={9500}
          footer={null}
          closeIcon={false}
          open={openModal}
          onCancel={() => {
            if (!isDisabledClose) setOpenModal(false);
          }}
          className={styles.modal}
          centered
        >
          {children}
          {!isDisabledClose && (
            <div className={styles.closeButton}>
              <FrameModalSvg onClick={() => setOpenModal(false)} />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};
