// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowcasesPage_titleAndButtonContainer__XjIrE {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.ShowcasesPage_titleAndSubtitleAndButtonContainer__3tqMN {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n\n@media (max-width: 768px) {\n  .ShowcasesPage_titleAndButtonContainer__XjIrE {\n    margin-bottom: 4px;\n  }\n  .ShowcasesPage_titleAndSubtitleAndButtonContainer__3tqMN {\n    gap: 0;\n    margin-bottom: 24px;\n  }\n  .ShowcasesPage_subTitleContainer__OOs3E {\n    margin-bottom: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/ShowcasesPage/ShowcasesPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;AAEF;;AAAA;EACE;IACE,kBAAA;EAGF;EAAA;IACE,MAAA;IACA,mBAAA;EAEF;EAAA;IACE,mBAAA;EAEF;AACF","sourcesContent":[".titleAndButtonContainer{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.titleAndSubtitleAndButtonContainer{\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n@media (max-width: 768px){\n  .titleAndButtonContainer{\n    margin-bottom: 4px;\n\n  }\n  .titleAndSubtitleAndButtonContainer{\n    gap: 0;\n    margin-bottom: 24px;\n  }\n  .subTitleContainer{\n    margin-bottom: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleAndButtonContainer": "ShowcasesPage_titleAndButtonContainer__XjIrE",
	"titleAndSubtitleAndButtonContainer": "ShowcasesPage_titleAndSubtitleAndButtonContainer__3tqMN",
	"subTitleContainer": "ShowcasesPage_subTitleContainer__OOs3E"
};
export default ___CSS_LOADER_EXPORT___;
