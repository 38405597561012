import React from 'react';
import styles from './PriceFilterInput.module.scss';
import { formatNumber, removeSpaces } from '../../utils';

type Props = {
  value: any;
  placeholder: string;
  setValue: React.Dispatch<any>;
  [key: string]: any;
};

export const PriceFilterInput = ({ value, placeholder, setValue, ...props }: Props) => {
  const handleChange = (value: string) => {
    const cleanedValue = removeSpaces(value);
    setValue(cleanedValue);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className={`InputText__wrapper ` + styles.wrapper}>
        <div className={`InputText form-item ` + styles.inputText}>
          <input
            {...props}
            className={`InputText__input form-input ` + styles.inputForm}
            type={'text'}
            placeholder={placeholder}
            value={formatNumber(value) || ''}
            onChange={({ target: { value } }) => handleChange(value)}
          />
        </div>
      </div>
    </div>
  );
};
