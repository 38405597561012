import { configureStore } from '@reduxjs/toolkit';
import { employeesSlice } from './Pages/EmployeesPage/employeesSlice';
import { materialSlice } from './Pages/ShowcasesPage/services/material/materialSlice';
import { showcaseSlice } from './Pages/ShowcasesPage/services/showcase/showcaseSlice';

export const store = configureStore({
  reducer: {
    employees: employeesSlice.reducer,
    material: materialSlice.reducer,
    showcase: showcaseSlice.reducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
