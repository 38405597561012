import React from 'react';

export const EditSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0992 2.39109C15.6207 0.869636 18.0875 0.869636 19.6089 2.39109C21.1304 3.91255 21.1304 6.37932 19.6089 7.90077L12.0149 15.4948C11.5869 15.9228 11.3255 16.1842 11.0342 16.4115C10.691 16.6792 10.3196 16.9087 9.92667 17.096C9.59315 17.2549 9.24244 17.3718 8.66818 17.5632L5.99553 18.4541L5.35386 18.668C4.77951 18.8594 4.14629 18.7099 3.7182 18.2818C3.2901 17.8537 3.14062 17.2205 3.33207 16.6462L4.43683 13.3318C4.62822 12.7576 4.7451 12.4069 4.90405 12.0734C5.09132 11.6804 5.32084 11.309 5.58856 10.9658C5.81578 10.6745 6.07719 10.4131 6.50525 9.98509L14.0992 2.39109ZM5.96035 16.8847L5.11537 16.0397L5.84398 13.8538C6.05606 13.2176 6.14366 12.9589 6.25814 12.7187C6.39854 12.4241 6.57061 12.1457 6.77132 11.8883C6.93497 11.6785 7.12718 11.4845 7.60141 11.0102L13.4923 5.11934C13.7354 5.72954 14.1469 6.46622 14.8411 7.16041C15.5345 7.85378 16.2703 8.26513 16.88 8.50834L10.9898 14.3986C10.5155 14.8728 10.3215 15.065 10.1117 15.2287C9.85436 15.4294 9.57594 15.6015 9.28134 15.7419C9.04113 15.8564 8.78244 15.944 8.14619 16.156L5.96035 16.8847ZM18.0751 7.31327C17.9515 7.28609 17.7971 7.24525 17.6217 7.18438C17.1376 7.01646 16.501 6.69892 15.9018 6.09975C15.3026 5.50057 14.9851 4.86389 14.8171 4.37987C14.7559 4.20325 14.7149 4.04798 14.6877 3.92395L15.1599 3.45175C16.0956 2.51608 17.6126 2.51608 18.5483 3.45175C19.4839 4.38742 19.4839 5.90444 18.5483 6.84011L18.0751 7.31327Z"
        fill="currentColor"
      />
      <path
        d="M3.25002 22C3.25002 21.5858 3.58581 21.25 4.00002 21.25H20C20.4142 21.25 20.75 21.5858 20.75 22C20.75 22.4142 20.4142 22.75 20 22.75H4.00002C3.58581 22.75 3.25002 22.4142 3.25002 22Z"
        fill="currentColor"
      />
    </svg>
  );
};
