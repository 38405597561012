import React, { Dispatch, SetStateAction, useState } from 'react';
import styles from './ShowcaseDescriptionButtons.module.scss';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { EditSvg } from 'src/components/UI/svg-icon/EditSvg';
import { ShareSvg } from 'src/components/UI/svg-icon/ShareSvg';
import { ShareShowcasePopup } from 'src/Pages/ShowcasesPage/ShowcasePage/ShareShowcasePopup/ShareShowcasePopup';
import { useMediaQuery } from 'react-responsive';

type TShowcaseDescriptionButtons = {
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isSharePopupOpen: boolean;
  setIsSharePopupOpen: Dispatch<SetStateAction<boolean>>;
  isShowcasePublished: boolean;
  link: string;
  logoImage: string;
  carrierName: string;
};

export const ShowcaseDescriptionButtons = ({
  setIsEdit,
  isSharePopupOpen,
  setIsSharePopupOpen,
  isShowcasePublished,
  link,
  logoImage,
  carrierName,
}: TShowcaseDescriptionButtons) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [isPopupHovered, setIsPopupHovered] = useState(false);
  const shouldShowPopup = isMobile ? isSharePopupOpen : isPopupHovered;
  return (
    <div className={styles.buttonsContainer}>
      <ButtonNotFilled
        text={isMobile ? 'Редактировать данные' : ''}
        disabled={false}
        svg={<EditSvg />}
        onClick={() => setIsEdit(true)}
        width={isMobile ? '100%' : '40px'}
        height="40px"
        sizeText="small"
      />
      <div style={{ position: 'relative' }}>
        <ButtonFilled
          svg={<ShareSvg />}
          disabled={false}
          text={isMobile ? 'Поделиться витриной' : ''}
          width={isMobile ? '100%' : '40px'}
          height="40px"
          sizeText="small"
          onClick={e => {
            e.stopPropagation();
            if (isMobile) {
              setIsSharePopupOpen(!isSharePopupOpen);
            }
          }}
          onMouseEnter={() => {
            if (!isMobile) setIsPopupHovered(true);
          }}
          onMouseLeave={() => {
            if (!isMobile) setIsPopupHovered(false);
          }}
        />
        <ShareShowcasePopup
          isSharePopupOpen={shouldShowPopup}
          setIsSharePopupOpen={setIsSharePopupOpen}
          isShowcasePublished={isShowcasePublished}
          link={link}
          logoImage={logoImage}
          carrierName={carrierName}
          onMouseEnter={() => {
            if (!isMobile) setIsPopupHovered(true);
          }}
          onMouseLeave={() => {
            if (!isMobile) setIsPopupHovered(false);
          }}
        />
      </div>
    </div>
  );
};
