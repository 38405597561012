// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CatalogFilter_container__07pL4 {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.CatalogFilter_searchContainer__W9l9N {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  gap: 16px;\n  flex-wrap: wrap;\n}\n\n.CatalogFilter_showedFilters__336Pq {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n@media (max-width: 768px) {\n  .CatalogFilter_container__07pL4 {\n    gap: 24px;\n  }\n  .CatalogFilter_searchContainer__W9l9N {\n    display: flex;\n    flex-direction: column;\n    align-items: normal;\n    width: 100%;\n    gap: 8px;\n  }\n  .CatalogFilter_showedFilters__336Pq {\n    gap: 8px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/Catalog/CatalogFilter/CatalogFilter.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE;IACE,SAAA;EACF;EAEA;IACE,aAAA;IACA,sBAAA;IACA,mBAAA;IACA,WAAA;IACA,QAAA;EAAF;EAGA;IACE,QAAA;EADF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.searchContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  gap: 16px;\n  flex-wrap: wrap;\n}\n\n.showedFilters {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n@media (max-width: 768px) {\n  .container {\n    gap: 24px;\n  }\n\n  .searchContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: normal;\n    width: 100%;\n    gap: 8px;\n  }\n\n  .showedFilters {\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CatalogFilter_container__07pL4",
	"searchContainer": "CatalogFilter_searchContainer__W9l9N",
	"showedFilters": "CatalogFilter_showedFilters__336Pq"
};
export default ___CSS_LOADER_EXPORT___;
