import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { TitlePage } from '../../components/TitlePage';
import { Context } from '../../context/context';
import { useMediaQuery } from 'react-responsive';
import { FlightList } from '../../components/UI/FlightList/FlightList';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import { Flight } from '../../common/flight-type';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import { NotActiveProfile } from '../../components/NotActiveProfile';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import { statuses, StatusTabs } from '../../common/status-tabs.type';
import { Filter } from '../../common/filter.type';
import { optionVolume } from '../../common/select-format.type';
import { useNavigate, useLocation } from 'react-router-dom';
import useDebounce from '../../CustomHooks/useDebounce';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import { TitleFlightsDriver } from 'src/components/TitleFlightsDriver';
import styles from './FlightPage.module.scss';
import {EmptyList} from "../../components/EmptyList";

export const FlightsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [offset, setOffset] = useState<number>(0);
  const { user } = useContext(Context);
  const [activeFlightsList, setActiveFlightsList] = useState<Flight[]>([]);
  const [completedFlightsList, setCompletedFlightsList] = useState<Flight[]>([]);
  const [skeleton, setSkeleton] = useState(true);
  const [filter, setFilter] = useState<Filter>({ search: '', sort: { field: 'date', order: 'DESC' } });
  delete statuses['Проблемные'];
  const [statusTabs, setStatusTabs] = useState<StatusTabs>(statuses);
  const [status, setStatus] = useState<string>(location.hash ? location.hash.replace('#', '') : 'active');
  const [more, setMore] = useState(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const debouncedSearchTerm = useDebounce(filter.search, 500);

  const getActiveFlight = async () => {
    try {
      const activeFlight = await fetchGet(`/flights/driver/active`, {});

      const activeList: Flight[] = [{ ...activeFlight?.response, date: new Date(activeFlight?.response?.date) }];

      setActiveFlightsList(activeList);
    } catch (e) {
      console.log(e);
    }
  };

  const getCompletedFlights = async (status, isPagination: boolean) => {
    try {
      const flights = await fetchGet(`/flights/driver/completed`, {
        status: status,
        offset: isPagination ? offset : 0,
        limit: 10,
        order: filter.sort.order,
        searchString: filter.search,
        flightCombinedStatus: 'all',
      });

      const list: Flight[] = flights.response.rows.map(item => ({
        ...item,
        date: new Date(item.dateTn),
      }));

      setTotalCount(flights.response.count);
      if (isPagination && offset !== 0) {
        setCompletedFlightsList(prevState => [...prevState, ...list]);
      } else {
        setCompletedFlightsList(list);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadingProfileAndList = async (status, isPagination: boolean) => {
    setSkeleton(true);
    getActiveFlight();
    getCompletedFlights(status, isPagination);
    setTimeout(() => {
      setSkeleton(false);
    }, 800);
  };

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (totalCount > offset) {
      loadingProfileAndList(status, true);
    }
  }, [offset]);
  useEffect(() => {
    setOffset(0);
    loadingProfileAndList(status, false).catch(console.log);
  }, [more, filter.sort.order, debouncedSearchTerm]);

  const sortFlightStatus = statusName => {
    for (const [key, tab] of Object.entries(statusTabs)) {
      if (key === statusName) {
        setStatus(tab.statusName);
        navigate(`#${tab.statusName}`);
        setOffset(0);
        loadingProfileAndList(tab.statusName, false).catch(console.log);
      }
    }

    setStatusTabs(prevState => ({ ...prevState, ...statusTabs }));
  };
  // @ts-ignore
  return (
    <>
      {user.active ? (
        <PageWrapper>
          <div className="FlightSection__header">
            <TitlePage title={'Рейсы'} quantity={''} />
          </div>
          <div>
            <div className="FlightSection__subTitle" style={{ marginBottom: '40px' }}>
              Просматривайте ваши рейсы и отслеживайте их статус в реальном времени
            </div>

            {/* Текущий рейс */}
            <div className="FlightSection__content">
              <div className="FlightSection__header">
                <TitleFlightsDriver title={'Текущий рейс'} quantity={''} />
              </div>
              <div>
                <div className={`FlightSection__requestTitle`}>
                  {['Рейс', 'Загрузка', 'Разгрузка', 'Транспорт', 'Объем и груз', 'Стоимость рейса и статус'].map(
                    (item, index) => (
                      <div key={index}>{item}</div>
                    ),
                  )}
                </div>
              </div>
              {activeFlightsList[0]?.id ?
                  <FlightList
                      setFlightInfo={null}
                      setPopUpActive={null}
                      more={more}
                      setMore={setMore}
                      flightItems={activeFlightsList}
                      filter={filter}
                      isCompleted={false}
                  />
                  :
                  <div className={styles.emptyContainer}>
                    <p className={styles.titleTextEmpty}>В настоящий момент у вас нет активных рейсов</p>
                    <p className={styles.subTitleTextEmpty}>Тут будет отображаться информация по текущему рейсу, как только он появится</p>
                  </div>
              }

              {/* Выполненные рейсы */}
              <div className="FlightSection__header" style={isMobile ? { marginTop: '24px' } : { marginTop: '40px' }}>
                <TitleFlightsDriver title={'Выполненные рейсы'} quantity={''} />
              </div>

              {/* Поиск и фильтры */}
              <div
                className={isMobile ? styles.inputsAndSelectorsContainerMobile : styles.inputsAndSelectorsContainer}
                style={isMobile ? { flexDirection: 'column', marginBottom: '24px', gap: '8px' } : {}}
              >
                <div className={styles.inputSearchContainer}>
                  <InputSearch
                    placeholder={'Поиск'}
                    onInput={({ currentTarget: { value: search } }) =>
                      setFilter(prevState => ({ ...prevState, search }))
                    }
                    value={filter.search}
                  />
                </div>
                <div className="searchContent__select" style={isMobile ? {} : { width: '290px' }}>
                  <CustomSelect
                    error={false}
                    placeholder={'Сортировка'}
                    noOptionsMessage={'Нет вариантов'}
                    isClearable={false}
                    isSearchable={false}
                    options={optionVolume}
                    value={filter.sort.field?.label}
                    setValue={({ value, dataset }) =>
                      setFilter(prevState => ({
                        ...prevState,
                        sort: {
                          field: value || '',
                          order: dataset === 'DESC' ? 'DESC' : 'ASC',
                        },
                      }))
                    }
                  />
                </div>
              </div>

              {/* Рейсы */}
              {completedFlightsList?.length ? (
                <div>
                  <div className={`FlightSection__requestTitle completed`}>
                    {['Рейс', 'Загрузка', 'Разгрузка', 'Транспорт', 'Объем и груз', 'Стоимость рейса'].map(
                      (item, index) => (
                        <div key={index}>{item}</div>
                      ),
                    )}
                  </div>
                  {skeleton ? (
                    <div className="FlightSection__content">
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '485px ',
                        }}
                      >
                        <div>
                          <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <FlightList
                      setFlightInfo={null}
                      setPopUpActive={null}
                      more={more}
                      setMore={setMore}
                      flightItems={completedFlightsList}
                      filter={filter}
                      isCompleted={true}
                    />
                  )}
                </div>
              ) : (
                <EmptyList title={'Рейсы отсутствуют'} subTitle={'В данный момент у вас нет рейсов'}/>
              )}
            </div>
          </div>
        </PageWrapper>
      ) : (
        <PageWrapper>
          <TitlePage title={'Рейсы'} quantity={''} />
          <NotActiveProfile role={'водителя '} />
        </PageWrapper>
      )}
    </>
  );
};
