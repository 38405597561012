export const getDayOfWeek = date => {
  const dayOfWeek = new Date(date).getDay();
  return isNaN(dayOfWeek) ? null : ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'][dayOfWeek];
};

export const monthNames = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export const allMonths = [
  'Январь',
  'Февраль',
  'март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const monthDays = [
  { from: 1, to: 31 },
  { from: 1, to: [28, 29] },
  { from: 1, to: 31 },
  { from: 1, to: 30 },
  { from: 1, to: 31 },
  { from: 1, to: 30 },
  { from: 1, to: 31 },
  { from: 1, to: 31 },
  { from: 1, to: 30 },
  { from: 1, to: 31 },
  { from: 1, to: 30 },
  { from: 1, to: 31 },
];

export const getMonth = date => {
  const monthNumber = new Date(date)?.getMonth();
  return monthNames[monthNumber];
};

export const isLeapYear = (year: number) => {
  if (year % 4 !== 0) return false;
  if (year % 100 !== 0) return true;
  if (year % 400 !== 0) return false;
  return true;
};

export const isMonthPeriod = (from: number, to: number, month: number) => {
  if (monthDays[month].from === from && monthDays[month].to === to) return true;
  return false;
};

const options = {
  day: '2-digit' as const,
  month: '2-digit' as const,
  year: 'numeric' as const,
  hour: '2-digit' as const,
  minute: '2-digit' as const,
  hour12: false,
};

export const convertDate = (dateStr: string, isMobile: boolean) => {
  let [date, time] = new Date(dateStr).toLocaleString('ru-RU', options).split(',');
  if (isMobile) {
    const [day, month] = date.split('.');
    date = day + ' ' + monthNames[Number(month) - 1];
  }
  return [date, time];
};

export const getDate = (dayFrom: number, dayTo: number, monthFrom: number, monthTo: number, yearFrom: number) => {
  const monthPeriodFebruaryDayTo = isLeapYear(yearFrom) ? monthDays[1].to[1] === dayTo : monthDays[1].to[0] === dayTo; // проверка, равен ли последний день февраля (с високосным годом)
  // проверка выбран ли период целого месяца
  const isMonthPeriod =
    monthDays[monthFrom].from === dayFrom &&
    (monthFrom === 1 ? monthPeriodFebruaryDayTo : monthDays[monthFrom].to === dayTo) &&
    monthFrom === monthTo;
  const isOneMonth = monthFrom === monthTo ? '' : ' ' + monthNames[monthFrom]; // проверка на одинаковый месяц
  // проверка, нужно ли выводить dayFrom и месяц
  const dayFromWithMonth = monthFrom !== monthTo || dayFrom !== dayTo ? dayFrom + isOneMonth + ' - ' : '';
  // проверка, одинаковый ли день -> выбран ли целый месяц
  const isOneDay = isMonthPeriod ? allMonths[monthFrom] : dayFromWithMonth + dayTo + ' ' + monthNames[monthTo];
  const isAllYear = dayFrom === 1 && dayTo === 31 && monthFrom === 0 && monthTo === 11; // проверка целого года
  const allDate = isAllYear ? yearFrom : isOneDay; // проверка, выбран ли целый год
  return allDate;
};
