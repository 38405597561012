import React, { useEffect } from 'react';
import styles from '../../AdminTariffsAddPage.module.scss';
import {
  carrierParametersOfAddTariffs,
  changeFieldOfInputWithValidation,
  changeFieldOfSelector,
  currentSiteEnum,
  currentStyleByRole,
  nameOfParameters,
  supplierParametersOfAddTariffs,
} from '../../const';
import SelectLk from '../../../../../components/newUI/SelectLk/SelectLk';
import CustomRangePicker from '../../../../../components/newUI/CustomRangePicker/CustomRangePicker';
import CustomInput from '../../../../../components/newUI/CustomInput/CustomInput';
import { regNumber } from '../../../../../common/number.helper';
import { CheckBoxCustom } from '../../../../../components/UI/checkBox/CheckBoxCustom';
import { IParametersOfAddTariffs } from '../../type';
import { EmptyList } from '../../../../../components/EmptyList';
import CustomDatePicker from '../../../../../components/newUI/CustomDatePicker/CustomDatePicker';
import dayjs from 'dayjs';
interface IProps {
  mainParametersInfo: IParametersOfAddTariffs[];
  setMainParametersInfo: React.Dispatch<React.SetStateAction<IParametersOfAddTariffs[]>>;
  carrierParametersInfo: IParametersOfAddTariffs[];
  setCarrierParametersInfo: React.Dispatch<React.SetStateAction<IParametersOfAddTariffs[]>>;
  supplierParametersInfo: IParametersOfAddTariffs[];
  setSupplierParametersInfo: React.Dispatch<React.SetStateAction<IParametersOfAddTariffs[]>>;
  role: string;
  currentSite: string;
}

const InputsMapping = ({
  mainParametersInfo,
  setMainParametersInfo,
  carrierParametersInfo,
  setCarrierParametersInfo,
  supplierParametersInfo,
  setSupplierParametersInfo,
  role,
  currentSite,
}: IProps) => {
  const objectCompareForStateByRole = {
    main: mainParametersInfo,
    carrier: carrierParametersInfo,
    supplier: supplierParametersInfo,
    '': [{}] /*Если ничего не выбрано, то выводим сообщение вместо инпутов*/,
  };
  const objectCompareForSetStateByRole = {
    main: setMainParametersInfo,
    carrier: setCarrierParametersInfo,
    supplier: setSupplierParametersInfo,
  };
  const valueCostPrice: number = Number(
    objectCompareForStateByRole[role]?.find(item => item.name === nameOfParameters.COST_PRICE)?.value,
  );
  const valueExtraCharge: number = Number(
    objectCompareForStateByRole[role]?.find(item => item.name === nameOfParameters.EXTRA_CHARGE)?.value,
  );
  const pickCheckPrice = (id: string): void => {
    objectCompareForSetStateByRole[role](prevState =>
      prevState.map(input => {
        if (input.name === id) {
          return {
            ...input,
            check: !input.check,
          };
        }
        return input;
      }),
    );
  };
  const inputValue = (parameter: IParametersOfAddTariffs): string => {
    if (parameter.name === nameOfParameters.TOTAL_AMOUNT) {
      const result = (valueCostPrice * (valueExtraCharge + 100)) / 100;
      return result % 1 === 0 ? String(result) : result.toFixed(2);
    }
    return parameter.value;
  };

  useEffect(() => {
    if (currentSite === currentSiteEnum.ADD) {
      setCarrierParametersInfo(carrierParametersOfAddTariffs);
      setSupplierParametersInfo(supplierParametersOfAddTariffs);
    }
  }, [role]);
  return (
    <div className={styles.mainParametersInfoContainer}>
      {objectCompareForStateByRole[role]?.map(parameter => {
        const parameterClassname = currentStyleByRole(parameter.name)[role];
        switch (parameter.typeComponent) {
          case 'select':
            return (
              <div key={parameter.name} className={parameterClassname}>
                <p className={styles.parameterTitle}>
                  {parameter.title}
                  {parameter.required && <span style={{ color: 'var(--color-red600)' }}>{' ' + '*'}</span>}
                </p>
                <SelectLk
                  disabled={parameter.disabled}
                  searchFields={parameter.searchFields}
                  width={'100%'}
                  isSearch={parameter.isSearch}
                  heightSelectWindow={'195px'}
                  options={parameter.options}
                  value={parameter?.value}
                  placeholder={parameter.placeholder}
                  setValue={value => {
                    changeFieldOfSelector({
                      setState: objectCompareForSetStateByRole[role],
                      nameObject: parameter.name,
                      fieldOfObject: 'value',
                      valueField: value,
                      clearValue: false,
                    });
                  }}
                />
              </div>
            );
          case 'datePicker':
            return (
              <div key={parameter.name} className={parameterClassname}>
                <p className={styles.parameterTitle}>
                  {parameter.title}
                  {parameter.required && <span style={{ color: 'var(--color-red600)' }}>{' ' + '*'}</span>}
                </p>
                <CustomDatePicker
                  disabled={parameter.disabled}
                  value={parameter.date}
                  setValue={value => {
                    changeFieldOfSelector({
                      setState: objectCompareForSetStateByRole[role],
                      nameObject: parameter.name,
                      fieldOfObject: 'date',
                      valueField: value,
                      clearValue: false,
                    });
                  }}
                  width="100%"
                  height={'48px'}
                  placeholder={'Выберите дату'}
                />
              </div>
            );
          case 'input':
            return (
              <div key={parameter.name} className={parameterClassname}>
                <p className={styles.parameterTitle}>
                  {parameter.title}
                  {parameter.required && <span style={{ color: 'var(--color-red600)' }}>{' ' + '*'}</span>}
                </p>
                <CustomInput
                  width={'100%'}
                  suffix={parameter.suffix}
                  setValue={value => {
                    changeFieldOfInputWithValidation({
                      setState: objectCompareForSetStateByRole[role],
                      nameObject: parameter.name,
                      fieldOfObject: 'value',
                      valueField: value,
                      validation:
                        (parameter.validation.test(value) && value.length <= parameter.limitSymbol) || value === '',
                    });
                  }}
                  value={inputValue(parameter)}
                  placeholder={parameter.placeholder}
                  disabled={parameter.disabled}
                />
              </div>
            );
          case 'checkbox':
            return (
              <div key={parameter.name} className={parameterClassname}>
                <CheckBoxCustom
                  disabled={parameter.disabled}
                  children={parameter.title}
                  error={false}
                  check={parameter.check}
                  pick={pickCheckPrice}
                  name={parameter.name}
                  id={parameter.name}
                />
              </div>
            );
          case 'rangeInput':
            return (
              <div key={parameter.name} className={parameterClassname}>
                <p className={styles.parameterTitle}>
                  {parameter.title}
                  {parameter.required && <span style={{ color: 'var(--color-red600)' }}>{' ' + '*'}</span>}
                </p>
                <div className={styles.rangeInputContainer}>
                  <CustomInput
                    width={'103px'}
                    suffix={parameter.suffix}
                    setValue={value => {
                      changeFieldOfInputWithValidation({
                        setState: objectCompareForSetStateByRole[role],
                        nameObject: parameter.name,
                        fieldOfObject: 'valueFrom',
                        valueField: value,
                        validation: (regNumber.test(value) && value.length <= 10) || value === '',
                      });
                    }}
                    value={parameter.valueFrom}
                    placeholder={parameter.placeholder}
                    disabled={parameter.disabled}
                  />
                  <div className={styles.divider} />
                  <CustomInput
                    width={'103px'}
                    suffix={parameter.suffix}
                    setValue={value => {
                      changeFieldOfInputWithValidation({
                        setState: objectCompareForSetStateByRole[role],
                        nameObject: parameter.name,
                        fieldOfObject: 'valueTo',
                        valueField: value,
                        validation: (regNumber.test(value) && value.length <= 10) || value === '',
                      });
                    }}
                    value={parameter.valueTo}
                    placeholder={parameter.placeholder}
                    disabled={false}
                  />
                </div>
              </div>
            );
          default:
            return (
              <div className={styles.emptyListContainer}>
                <EmptyList title={'Выберите роль для отображения ограничений'} subTitle={'Роль не выбрана'} />
              </div>
            );
        }
      })}
    </div>
  );
};

export default InputsMapping;
