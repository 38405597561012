import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { HelpSvg } from '../svg-icon/HelpSvg';
import { HelpBalloon } from '../balloons/HelpBalloon';
import { ErrorSvg } from '../svg-icon/ErrorSvg';

dayjs.extend(customParseFormat);

export const DateInput = ({
  isHelp,
  textHelp,
  errorText,
  formatDate,
  maskFormatDate,
  value,
  setValue,
  setDisabled,
  disabled,
  isLabel,
  placeholder,
  margin,
  error,
  setError,
}) => {
  const [helpVisible, setHelpVisible] = useState(false);
  const [defaultValue] = useState(new Date().toLocaleString());

  const validateDate = value => {
    const customParseFormat = require('dayjs/plugin/customParseFormat');
    dayjs.extend(customParseFormat);
    const date = dayjs(value, formatDate, true);

    setValue(value);

    setError(!date.isValid() || date.year() <= 1930);
  };

  return (
    <div>
      <div className={`InputText__wrapper ${disabled ? 'disabled' : ''}`}>
        <div className={`InputText form-item ${error ? 'error ' : ''}${disabled ? 'disabled' : ''}`} style={{ marginBottom: margin }}>
          <InputMask
            className={`InputText__input form-input ${error ? 'error' : ''} ${disabled ? 'disabled' : ''}`}
            mask={maskFormatDate}
            maskChar={null}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onChange={({ target: { value } }) => validateDate(value)}
          />
          {isHelp && (
            <div
              className={`InputText__help ${error && 'error'}`}
              onMouseEnter={() => setHelpVisible(true)}
              onMouseLeave={() => setHelpVisible(false)}
            >
              {error ? <ErrorSvg /> : <HelpSvg />}
            </div>
          )}
          {helpVisible && <HelpBalloon children={textHelp} style={{ bottom: '65px', right: '5px' }} />}
          {isLabel && (
            <label className={`InputText__label form-label ${value ? 'valid' : ''} ${disabled ? 'disabled' : ''}`}>
              {placeholder}
            </label>
          )}
        </div>
      </div>
      <div>{error && <div className={'InputText__text'}>{errorText}</div>}</div>
    </div>
  );
};
