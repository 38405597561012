import React from 'react';
import styles from '../../DispatchingItemPage.module.scss';
import { toast } from 'react-toastify';
import { CopySvg } from '../../../../components/UI/svg-icon/CopySvg';
import { formVolume, ViewOfPassingVehicle } from '../../Constans/Constans';
import { formPrice } from '../../../../common/form-price.helper';
import { useMediaQuery } from 'react-responsive';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {nomenclatureUnitMapping} from "../../../../common/nomenclature-unit.mapping";
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';

const InfoBlockOfDispatchingItem = ({ infoData }) => {;
    const isMobile = useMediaQuery({ maxWidth: 768 });
    return (
        <div
            className={styles.rightSideInfoRequestContainer}
        >
            <div>
                <p
                    className={styles.textTitleContainer}
                    style={{ marginBottom: isMobile ? '8px' : '0' }}
                >
                    Информация по заявке
                </p>
            </div>
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    Дата доставки
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {infoData?.orderDate?.slice(0, 10).split('-').reverse().join('.')}
                </p>
            </div>
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    {isMobile ? `Контрагент` : `Контрагент получателя`}
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {infoData?.consignee?.title}
                </p>
            </div>
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    Выгрузка
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {infoData?.object?.address?.address}
                </p>
            </div>
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    Загрузка
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {infoData?.quarry?.address?.address}
                </p>
            </div>
            {isMobile ? (
                <div>
                    <CopyToClipboard text={infoData?.quarry?.address?.address}>
                        <ButtonSimple
                          text={'Скопировать адрес'}
                          onClick={() => toast.success('Ссылка скопирована в буфер обмена')}
                          isRightSvg={true}
                          svg={<CopySvg />}
                        />
                    </CopyToClipboard>
                </div>
            ) : (
                <></>
            )}
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    Время работы
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {infoData?.object?.workSchedulesGraph?.aroundTheClock
                        ? 'круглосуточно'
                        : infoData?.object?.workSchedulesGraph?.from + '-' + infoData?.object?.workSchedulesGraph?.to}
                </p>
            </div>
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    Контакты
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {infoData?.object?.contactPersons?.map(item => (
                        <span>{item?.phone ? item?.phone : 'Не указаны'}</span>
                    ))}
                </p>
            </div>
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    Вид проходящего ТС
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {infoData?.object?.semitrailers?.map(item => (
                        <span>{item?.title ? ViewOfPassingVehicle[item?.title] : 'Не указано'}</span>
                    ))}
                </p>
            </div>
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    Номенклатура
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {infoData?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument}
                </p>
            </div>
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    Объем/вес
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {formVolume(infoData?.volume)}
                </p>
            </div>
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    Ед измерения
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {nomenclatureUnitMapping[infoData?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.unit]}
                </p>
            </div>
            <div className={styles.rightSideBlockOfInfo}>
                <p className={styles.mainTextOfRightSideBlockOfInfo}>
                    Цена за ед.
                </p>
                <p></p>
                <p className={styles.subMainTextOfRightSideBlockOfInfo}>
                    {formPrice(infoData?.price, 'RUB')}
                </p>
            </div>
        </div>
    );
};

export default InfoBlockOfDispatchingItem;