import {
  IArrayFromBackend,
  IChangeFieldOfInputWithValidation,
  IChangeFieldOfSelector,
  IDataResponseTable,
  IOptions,
  IParametersOfAddTariffs,
  IResponseDetailsTariffWithArray,
} from './type';
import styles from './AdminTariffsAddPage.module.scss';
import cx from 'classnames';
import React from 'react';
import ShowMoreAdminTariffs from './components/ShowMoreAdminTariffs/ShowMoreAdminTariffs';
import { clearObject } from '../../../common/ClearObject.helper';
import dayjs, { Dayjs } from 'dayjs';
import { fetchPostFile } from '../../../common/proxy-1C-fetch-auth';
import { regForNumberWithPoint, regNumber } from '../../../common/number.helper';
export const getImages = (dataRequest, setImages) => {
  dataRequest?.files?.forEach(file => {
    setImages(prevState => {
      const fileMap = new Map(prevState.map(fileKeyValue => [fileKeyValue.name, fileKeyValue]));
      fileMap.set(file.name, file);
      return Array.from(fileMap.values());
    });
  });
};
export const sendUploadImages = async (uploadImages, id) => {
  if (uploadImages.length) {
    let formData = new FormData();
    uploadImages.forEach(image => {
      formData.append('file', image);
    });
    formData.append('fileBelongsId', id);
    formData.append('communicationCategory', 'tariffDetails');
    formData.append('category', 'Спецификация');
    await fetchPostFile('/gateway/files', 'POST', formData);
  }
};

export const requestDataTariff = (
  currentId: string,
  currentRole: string,
  mainParametersInfo: IParametersOfAddTariffs[],
  carrierParametersInfo: IParametersOfAddTariffs[],
  supplierParametersInfo: IParametersOfAddTariffs[],
  requestFor: 'patchSpec' | 'addSpec' | 'post' | 'patchTariffs',
  tariffDetailId?: number,
) => {
  return {
    ...(requestFor === 'patchSpec' && {
      dateFrom: convertDataForBackend(mainParametersInfo[3].date as string),
      dateTo: mainParametersInfo[4].date ? convertDataForBackend(mainParametersInfo[4].date as string) : null,
      priceOnRequest: mainParametersInfo[7].check,
      tariffDetailId: tariffDetailId,
    }),
    ...(requestFor === 'addSpec' && {
      tariffId: Number(currentId),
    }),
    ...(requestFor === 'post' && {
      dateFrom: convertDataForBackend(mainParametersInfo[3].date as string),
      dateTo: mainParametersInfo[4].date ? convertDataForBackend(mainParametersInfo[4].date as string) : null,
      priceOnRequest: mainParametersInfo[7].check,
      companyId: Number(mainParametersInfo[2].value),
      organizationId: Number(mainParametersInfo[0].value),
      margin: Number(mainParametersInfo[6].value),
      unit: mainParametersInfo[5].value,
    }),
    ...(requestFor === 'patchTariffs' && {
      dateFrom: convertDataForBackend(mainParametersInfo[3].date as string),
      dateTo: mainParametersInfo[4].date ? convertDataForBackend(mainParametersInfo[4].date as string) : null,
      priceOnRequest: mainParametersInfo[7].check,
    }),
    ...(currentRole === 'carrier' &&
      requestFor !== 'patchTariffs' && {
        carrierTariffDetails: clearObject({
          priceOnRequest: carrierParametersInfo[7].check,
          distanceFrom: Number(carrierParametersInfo[3].valueFrom),
          distanceTo: Number(carrierParametersInfo[3].valueTo),
          cost: Number(carrierParametersInfo[4].value),
          margin: Number(carrierParametersInfo[5].value),
          semitrailerId: Number(carrierParametersInfo[1].value),
          quarryId: carrierParametersInfo[2].value ? Number(carrierParametersInfo[2].value) : undefined,
          materialTypeId: Number(carrierParametersInfo[0].value),
        }),
      }),
    ...(currentRole === 'supplier' &&
      requestFor !== 'patchTariffs' && {
        supplierTariffDetails: clearObject({
          quarryId: Number(supplierParametersInfo[0].value),
          materialId: Number(supplierParametersInfo[1].value),
          volume: Number(supplierParametersInfo[2].value),
          cost: Number(supplierParametersInfo[3].value),
          margin: Number(supplierParametersInfo[4].value),
          priceOnRequest: supplierParametersInfo[6].check,
        }),
      }),
  };
};
export const convertForSelectOptions = (arrayFromBackend: IArrayFromBackend[]) => {
  if (Array.isArray(arrayFromBackend)) {
    return arrayFromBackend?.map(item => {
      return {
        value: String(item.id),
        label: item.title,
        ...(item.inn && {
          inn: item.inn,
        }),
      };
    });
  }
  return [];
};
export const mapDataForTable = (
  responseDetailsTariff: IResponseDetailsTariffWithArray,
  currentId: string,
): IDataResponseTable[] => {
  return responseDetailsTariff.tariffDetails.map(tariff => ({
    id: tariff?.id,
    materialType: tariff.materialType?.title,
    semitrailer: tariff.semitrailer?.title,
    quarry: tariff.quarry?.title,
    distance: {
      distanceFrom: tariff?.distanceFrom,
      distanceTo: tariff?.distanceTo,
    },
    material: tariff?.material?.title,
    cost: {
      value: tariff?.cost,
      unit: responseDetailsTariff?.unit,
    },
    margin: tariff?.margin,
    finalPrice: tariff?.finalPrice,
    requestPrice: tariff?.priceOnRequest,
    user: {
      fullName: tariff.user?.fullName,
      updatedAt: tariff.user?.updatedAt,
      id: tariff?.id,
      tariffId: Number(currentId),
    },
    volume: {
      value: tariff?.volume,
      unit: responseDetailsTariff?.unit,
    },
  }));
};
export const disableInputsInMainParametersArray = currentSite => {
  const enabledFields = [nameOfParameters.DATE_TO, nameOfParameters.DATE_FROM, nameOfParameters.PRICE_REQUEST];
  let newArrayWithDisabled = [...mainParametersOfAddTariffs];
  return newArrayWithDisabled.map(parameter => ({
    ...parameter,
    /*Если мы добавляем спецификацию, то дизейблятся все поля */
    disabled: currentSite === currentSiteEnum.ADD_SPEC ? true : !enabledFields.includes(parameter.name),
  }));
};
export const changeFieldOfInputWithValidation = ({
  setState,
  nameObject,
  fieldOfObject,
  valueField,
  validation,
}: IChangeFieldOfInputWithValidation): void => {
  setState(prevInputsInfo =>
    prevInputsInfo.map(input => {
      if (input.name === nameObject && validation) {
        return {
          ...input,
          [fieldOfObject]: valueField,
        };
      }
      return input;
    }),
  );
};
export const changeFieldOfSelector = ({
  setState,
  nameObject,
  fieldOfObject,
  valueField,
  clearValue,
}: IChangeFieldOfSelector) => {
  setState(prevInputsInfo =>
    prevInputsInfo.map(input => {
      if (input.name === nameObject && !clearValue) {
        return {
          ...input,
          [fieldOfObject]: valueField as string | string[] | boolean | void | Dayjs[],
        };
      }
      if (input.name === nameObject && clearValue) {
        return { ...input, value: '', options: valueField as IOptions[] };
      }
      return input;
    }),
  );
};
export const convertDataForBackend = (date: string | Date) => {
  const newDate = new Date(date);

  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const day = String(newDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
export enum currentSiteEnum {
  ADD = 'add',
  EDIT = 'edit',
  ID = 'id',
  ADD_SPEC = 'addSpec',
}
export enum nameOfParameters {
  ORGANIZER = 'organizer',
  ROLE = 'role',
  COUNTERPARTY = 'counterparty',
  DATE_FROM = 'dateFrom',
  DATE_TO = 'dateTo',
  UNIT = 'unit',
  EXTRA_CHARGE = 'extraCharge',
  PRICE_REQUEST = 'priceRequest',
  TYPE_MATERIAL = 'typeMaterial',
  SEMITRAILER = 'Semitrailer',
  QUARRY = 'quarry',
  RANGE_INPUT = 'rangeInput',
  VOLUME = 'volume',
  COST_PRICE = 'costPrice',
  TOTAL_AMOUNT = 'totalAmount',
  PRICE_REQUEST_CARRIER = 'priceRequestCarrier',
  MATERIAL = 'material',
  PRICE_REQUEST_SUPPLIER = 'priceRequestSupplier',
}
const objectConvertUnitForUser = {
  тонн: 'тн',
  'м³': 'м³',
};
const optionsOfUnit: IOptions[] = [
  { value: 'тонн', label: 'тн' },
  { value: 'м³', label: 'м³' },
];
const optionsOfRole: IOptions[] = [
  { value: 'carrier', label: 'Перевозчик' },
  { value: 'supplier', label: 'Поставщик' },
];
export const generateStyleMainParameters = parameterName => {
  const styleDateParameter =
    (parameterName === nameOfParameters.DATE_FROM || parameterName === nameOfParameters.DATE_TO) &&
    styles.dateParameter;
  const styleUnitParameter = parameterName === nameOfParameters.UNIT && styles.unitParameter;
  const styleMargin = parameterName === nameOfParameters.EXTRA_CHARGE && styles.extraChargeParameter;
  const stylePriceRequest = parameterName === nameOfParameters.PRICE_REQUEST && styles.priceRequestParameter;

  return [styles.parameterMainContainer, styleDateParameter, styleUnitParameter, styleMargin, stylePriceRequest];
};
const compareSupplierSecondString = [
  nameOfParameters.COST_PRICE,
  nameOfParameters.TOTAL_AMOUNT,
  nameOfParameters.EXTRA_CHARGE,
  nameOfParameters.PRICE_REQUEST_SUPPLIER,
];
export const generateStyleSupplierParameters = parameterName => {
  const styleSupplierPriceRequestParameter =
    parameterName === nameOfParameters.PRICE_REQUEST_SUPPLIER && styles.priceRequestParameter;
  const styleSupplierSecondStringParameter =
    compareSupplierSecondString.includes(parameterName) && styles.supplierSecondStringParameter;
  const styleSupplierQuarryAndMarginParameter =
    parameterName === nameOfParameters.EXTRA_CHARGE && styles.supplierMarginParameter;
  return [
    styles.parameterSupplierContainer,
    styleSupplierSecondStringParameter,
    styleSupplierPriceRequestParameter,
    styleSupplierQuarryAndMarginParameter,
  ];
};
const compareCarrierSecondString = [
  nameOfParameters.TOTAL_AMOUNT,
  nameOfParameters.EXTRA_CHARGE,
  nameOfParameters.COST_PRICE,
  nameOfParameters.PRICE_REQUEST_CARRIER,
];
export const generateStyleCarrierParameters = parameterName => {
  const styleCarrierRangeInputParameter =
    parameterName === nameOfParameters.RANGE_INPUT && styles.rangeInputCarrierParameter;
  const styleCarrierSecondStringParameter =
    compareCarrierSecondString.includes(parameterName) && styles.secondStringCarrierParameter;
  const styleCarrierPriceRequestParameter =
    parameterName === nameOfParameters.PRICE_REQUEST_CARRIER && styles.priceRequestCarrierParameter;
  const styleCarrierMarginParameter =
    parameterName === nameOfParameters.EXTRA_CHARGE && styles.extraChargeCarrierParameter;
  return [
    styles.parameterCarrierContainer,
    styleCarrierSecondStringParameter,
    styleCarrierPriceRequestParameter,
    styleCarrierRangeInputParameter,
    styleCarrierMarginParameter,
  ];
};

export const currentStyleByRole = parameterName => {
  return {
    main: cx(...generateStyleMainParameters(parameterName)),
    supplier: cx(...generateStyleSupplierParameters(parameterName)),
    carrier: cx(...generateStyleCarrierParameters(parameterName)),
  };
};
export const mainParametersOfAddTariffs: IParametersOfAddTariffs[] = [
  {
    /*0*/
    name: nameOfParameters.ORGANIZER,
    title: 'Организатор перевозки',
    typeComponent: 'select',
    searchFields: ['label'],
    value: '',
    options: [],
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
  },
  {
    /*1*/
    name: nameOfParameters.ROLE,
    title: 'Роль контрагента',
    typeComponent: 'select',
    searchFields: ['label'],
    value: '',
    options: optionsOfRole,
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
  },
  {
    /*2*/
    name: nameOfParameters.COUNTERPARTY,
    title: 'Контрагент',
    typeComponent: 'select',
    searchFields: ['label', 'inn'],
    value: '',
    options: [],
    placeholder: 'Выберите из списка',
    isSearch: true,
    required: true,
    disabled: false,
  },
  {
    /*3*/
    name: nameOfParameters.DATE_FROM,
    title: 'Период действия (дата начала)',
    typeComponent: 'datePicker',
    open: false,
    date: '',
    required: true,
    disabled: false,
  },
  {
    /*4*/
    name: nameOfParameters.DATE_TO,
    title: 'Период действия (дата окончания)',
    typeComponent: 'datePicker',
    open: false,
    date: '',
    required: false,
    disabled: false,
  },
  {
    /*5*/
    name: nameOfParameters.UNIT,
    title: 'Ед.изм.',
    typeComponent: 'select',
    searchFields: ['label'],
    value: '',
    options: optionsOfUnit,
    placeholder: 'тн',
    required: true,
    disabled: false,
  },
  {
    /*6*/
    name: nameOfParameters.EXTRA_CHARGE,
    title: 'Наценка',
    typeComponent: 'input',
    value: '',
    placeholder: 'Введите значение',
    suffix: '%',
    required: true,
    limitSymbol: 10,
    disabled: false,
    validation: regNumber,
  },
  {
    /*7*/
    name: nameOfParameters.PRICE_REQUEST,
    title: 'Цена под запрос',
    typeComponent: 'checkbox',
    check: false,
    required: true,
    disabled: false,
  },
];
export const carrierParametersOfAddTariffs: IParametersOfAddTariffs[] = [
  {
    /*0*/
    name: nameOfParameters.TYPE_MATERIAL,
    title: 'Тип материала',
    typeComponent: 'select',
    searchFields: ['label'],
    value: '',
    options: [],
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
    isSearch: true,
  },
  {
    /*1*/
    name: nameOfParameters.SEMITRAILER,
    title: 'Тип кузова',
    typeComponent: 'select',
    searchFields: ['label'],
    value: '',
    options: [],
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
  },
  {
    /*2*/
    name: nameOfParameters.QUARRY,
    title: 'Карьер',
    typeComponent: 'select',
    searchFields: ['label'],
    value: '',
    options: [],
    placeholder: 'Выберите из списка',
    required: false,
    disabled: false,
    isSearch: true,
  },
  {
    /*3*/
    name: nameOfParameters.RANGE_INPUT,
    title: 'Расстояние',
    typeComponent: 'rangeInput',
    valueTo: '',
    valueFrom: '',
    placeholder: '0 км',
    suffix: 'км',
    required: true,
    disabled: false,
  },
  {
    /*4*/
    name: nameOfParameters.COST_PRICE,
    title: 'Себестоимость',
    typeComponent: 'input',
    value: '',
    placeholder: 'Введите значение',
    suffix: '₽/тн',
    required: true,
    limitSymbol: 10,
    disabled: false,
    validation: regForNumberWithPoint,
  },
  {
    /*5*/
    name: nameOfParameters.EXTRA_CHARGE,
    title: 'Наценка',
    typeComponent: 'input',
    value: '',
    placeholder: '0 %',
    suffix: '%',
    required: true,
    limitSymbol: 10,
    disabled: false,
    validation: regNumber,
  },
  {
    /*6*/
    name: nameOfParameters.TOTAL_AMOUNT,
    title: 'Итоговая сумма',
    typeComponent: 'input',
    placeholder: '0 ₽/тн',
    suffix: '₽/тн',
    required: true,
    disabled: true,
    limitSymbol: 10,
  },
  {
    /*7*/
    name: nameOfParameters.PRICE_REQUEST_CARRIER,
    title: 'Цена под запрос',
    typeComponent: 'checkbox',
    check: false,
    required: true,
    disabled: false,
  },
];
export const supplierParametersOfAddTariffs: IParametersOfAddTariffs[] = [
  {
    /*0*/
    name: nameOfParameters.QUARRY,
    title: 'Карьер',
    typeComponent: 'select',
    searchFields: ['label'],
    value: '',
    options: [],
    placeholder: 'Выберите из списка',
    isSearch: true,
    required: true,
    disabled: false,
  },
  {
    /*1*/
    name: nameOfParameters.MATERIAL,
    title: 'Материал',
    typeComponent: 'select',
    searchFields: ['label'],
    value: '',
    options: [],
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
    isSearch: true,
  },
  {
    /*2*/
    name: nameOfParameters.VOLUME,
    title: 'Объем',
    typeComponent: 'input',
    value: '',
    placeholder: 'Введите значение',
    suffix: 'тн',
    required: true,
    limitSymbol: 20,
    disabled: false,
    validation: regNumber,
  },
  {
    /*3*/
    name: nameOfParameters.COST_PRICE,
    title: 'Себестоимость',
    typeComponent: 'input',
    value: '',
    placeholder: 'Введите значение',
    suffix: '₽/тн',
    required: true,
    limitSymbol: 10,
    disabled: false,
    validation: regForNumberWithPoint,
  },
  {
    /*4*/
    name: nameOfParameters.EXTRA_CHARGE,
    title: 'Наценка',
    typeComponent: 'input',
    value: '',
    placeholder: '0 %',
    suffix: '%',
    required: true,
    limitSymbol: 10,
    disabled: false,
    validation: regNumber,
  },
  {
    /*5*/
    name: nameOfParameters.TOTAL_AMOUNT,
    title: 'Итоговая сумма',
    typeComponent: 'input',
    placeholder: '0 ₽/тн',
    suffix: '₽/тн',
    required: true,
    disabled: true,
    limitSymbol: 10,
    validation: regNumber,
  },
  {
    /*6*/
    name: nameOfParameters.PRICE_REQUEST_SUPPLIER,
    title: 'Цена под запрос',
    typeComponent: 'checkbox',
    check: false,
    required: true,
    disabled: false,
  },
];
export const carrierColumnsOfSupplier = (
  stateTable: IDataResponseTable[],
  setStateTable: React.Dispatch<IDataResponseTable[]>,
) => [
  {
    title: 'Тип материала',
    dataIndex: 'materialType',
    width: 155,
    key: 'materialType',
    render: text => (
      <div className={styles.containerColumn} style={{ marginLeft: '24px' }}>
        <p style={{ width: '155px' }} className={styles.textTypeStyle}>
          {text}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Тип кузова',
    dataIndex: 'semitrailer',
    width: 100,
    key: 'semitrailer',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '100px' }} className={styles.textStyle}>
          {text}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Карьер',
    dataIndex: 'quarry',
    width: 120,
    key: 'quarry',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '120px' }} className={styles.textStyle}>
          {text}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Километраж',
    dataIndex: 'distance',
    width: 100,
    key: 'distance',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '100px' }} className={styles.textStyle}>
          {text.distanceFrom}-{text.distanceTo}км
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Себестоимость',
    dataIndex: 'cost',
    width: 100,
    key: 'cost',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '100px' }} className={styles.textStyle}>
          {text.value} ₽/{objectConvertUnitForUser[text.unit]}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Наценка',
    dataIndex: 'margin',
    width: 60,
    key: 'margin',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '60px' }} className={styles.textStyle}>
          {text}%
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Итого',
    dataIndex: 'finalPrice',
    width: 80,
    key: 'finalPrice',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '80px' }} className={styles.textStyle}>
          {text} ₽
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Цена под запрос',
    dataIndex: 'requestPrice',
    width: 90,
    key: 'requestPrice',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '90px' }} className={styles.textStyle}>
          {text ? 'Да' : 'Нет'}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Последнее изменение',
    dataIndex: 'user',
    width: 167,
    key: 'user',
    render: text => (
      <div className={styles.containerColumnLastChanges}>
        <div style={{ width: '127px' }} className={styles.lastChangeContainer}>
          <p className={styles.textStyle}>{dayjs(text.updatedAt).format('DD.MM.YYYY HH:mm')}</p>
          <p className={styles.textSoftStyle}>{text.fullName}</p>
        </div>
        <ShowMoreAdminTariffs
          setStateTable={setStateTable}
          stateTable={stateTable}
          id={text.id}
          tariffId={text.tariffId}
        />
      </div>
    ),
  },
];
export const supplierColumnsOfSupplier = (
  stateTable: IDataResponseTable[],
  setStateTable: React.Dispatch<IDataResponseTable[]>,
) => [
  {
    title: 'Тип материала',
    dataIndex: 'material',
    width: 374,
    key: 'material',
    render: text => (
      <div className={styles.containerColumn} style={{ marginLeft: '24px' }}>
        <p style={{ width: '374px' }} className={styles.textTypeStyle}>
          {text}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Объем',
    dataIndex: 'volume',
    width: 100,
    key: 'volume',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '100px' }} className={styles.textStyle}>
          {text.value} {objectConvertUnitForUser[text.unit]}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Себестоимость',
    dataIndex: 'cost',
    width: 120,
    key: 'cost',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '120px' }} className={styles.textStyle}>
          {text.value} ₽/{objectConvertUnitForUser[text.unit]}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Наценка',
    dataIndex: 'margin',
    width: 60,
    key: 'margin',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '60px' }} className={styles.textStyle}>
          {text}%
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Итого',
    dataIndex: 'finalPrice',
    width: 80,
    key: 'finalPrice',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '80px' }} className={styles.textStyle}>
          {text} ₽
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Цена под запрос',
    dataIndex: 'requestPrice',
    width: 100,
    key: 'requestPrice',
    render: text => (
      <div className={styles.containerColumn}>
        <p style={{ width: '100px' }} className={styles.textStyle}>
          {text ? 'Да' : 'Нет'}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Последнее изменение',
    dataIndex: 'user',
    width: 200,
    key: 'user',
    render: text => (
      <div className={styles.containerColumnLastChanges}>
        <div style={{ width: '160px' }} className={styles.lastChangeContainer}>
          <p className={styles.textStyle}>{dayjs(text.updatedAt).format('DD.MM.YYYY HH:mm')}</p>
          <p className={styles.textSoftStyle}>{text.fullName}</p>
        </div>
        <ShowMoreAdminTariffs
          setStateTable={setStateTable}
          stateTable={stateTable}
          id={text.id}
          tariffId={text.tariffId}
        />
      </div>
    ),
  },
];
export const objectCompareMainTitle = (currentId: string) => {
  return {
    add: 'Добавить тариф',
    edit: 'Редактировать спецификацию',
    id: `Тариф ${currentId}`,
    addSpec: 'Добавить спецификацию',
  };
};
