import React, { useEffect, useState } from 'react';
import styles from './FormReplenishmentsCardPage.module.scss';
import { PageWrapper } from '../../../components/PageWrapper';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { IDataCards, IInputsFormReplenishment, TOptionsForSelectCard } from '../type';
import {
  InitStateInputsFormReplenishment,
  initStateOptionSelect,
  initStateOptionsFormOfReplenishmentsCard,
} from '../constans';
import FormReplenishmentCard from './FormReplenishmentCard/FormReplenishmentCard';
import NavigateFormReplenishmentsCardPage from './components/NavigateFormReplenishmentsCardPage/NavigateFormReplenishmentsCardPage';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import { toastError } from 'src/common/toastError.helper';
import { formCardNumber } from 'src/common/formCardNumber.helper';
const FormReplenishmentsCardPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { pathname } = useLocation();
  const cardId = pathname.split('/')[2];

  const [codeValue, setCodeValue] = useState<string>('');
  const [step, setStep] = useState<'transfer' | 'smsAuth' | 'success'>('transfer');
  const [currentCard, setCurrentCard] = useState<IDataCards>();
  const [inputsFormReplenishment, setInputsFormReplenishment] = useState<IInputsFormReplenishment>(
    InitStateInputsFormReplenishment,
  );
  const [optionsForSelectCard, setOptionsForSelectCard] = useState<TOptionsForSelectCard>([initStateOptionSelect]);

  const [optionsFormOfReplenishmentsCard, setOptionsFormOfReplenishmentsCard] = useState<
    { value: string; label: string }[]
  >([initStateOptionsFormOfReplenishmentsCard]);

  useEffect(() => {
    getDataCards();
    getCompanyBalance();
  }, []);

  const getDataCards = async () => {
    try {
      const responseCards = await fetchGet('/fuel-cards', {});
      if (toastError(responseCards)) {
        return;
      }
      const filteredResponse = responseCards?.response.filter(
        cardInfo => cardInfo?.status === 'Активна' && cardInfo?.id != cardId,
      );
      setCurrentCard(responseCards?.response.find(card => card?.id == cardId));
      const optionsOpenSelect = [];
      const optionsCloseSelect = [];
      filteredResponse.forEach(item => {
        optionsOpenSelect.push({
          value: item?.id,
          card: item?.owner?.split(' ')[0] + ' ' + formCardNumber(item?.cardNumber),
          money: item?.limit?.current || 0,
        });
        optionsCloseSelect.push({ value: item?.id, label: formCardNumber(item?.cardNumber) });
      });
      setOptionsForSelectCard(prevState => [...prevState, ...optionsOpenSelect]);
      setOptionsFormOfReplenishmentsCard(prevState => [...prevState, ...optionsCloseSelect]);
    } catch (e) {
      console.log(e);
    }
  };

  const getCompanyBalance = async () => {
    try {
      const response = await fetchGet('/limits/available', {});
      if (toastError(response)) {
        return;
      }
      setOptionsForSelectCard(prevState => {
        const newOptions = [...prevState];
        newOptions[0] = { ...newOptions[0], money: response?.response?.limit.toString() };
        return newOptions;
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageWrapper style={{ backgroundColor: `${isMobile ? 'white' : 'var(--color-gray50)'}` }}>
      <div className={styles.backButtonAndTitleContainer}>
        <NavigateFormReplenishmentsCardPage setStep={setStep} step={step} cardNumber={currentCard?.cardNumber} />
        <h1 className={styles.title}>Пополнить карту</h1>
      </div>
      <FormReplenishmentCard
        inputsFormReplenishment={inputsFormReplenishment}
        setInputsFormReplenishment={setInputsFormReplenishment}
        step={step}
        setStep={setStep}
        codeValue={codeValue}
        setCodeValue={setCodeValue}
        optionsFormOfReplenishmentsCard={optionsFormOfReplenishmentsCard}
        optionsForSelectCard={optionsForSelectCard}
        currentCard={currentCard}
      />
    </PageWrapper>
  );
};

export default FormReplenishmentsCardPage;
