import React from 'react';
import styles from './PublicHeader.module.scss';
import LogoMobileSvg from '../UI/svg-icon/LogoMobileSvg';
import FooterLogoSvg from '../UI/svg-icon/FooterLogoSvg';
import FooterLogoTextSvgAgreement from '../UI/svg-icon/FooterLogoTextSvgAgreement';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
const PublicHeader = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    return (
        <div className={styles.header}>
            <div
                className={styles.logoContainer}
                onClick={() => {
                    navigate('/');
                }}
            >
                {isMobile ?
                    <>
                        <LogoMobileSvg/>
                        <p className={styles.textLogo}>
                            Платформа нерудных
                            <br/>
                            материалов
                        </p>
                    </>
                    :
                    <>
                        <FooterLogoSvg />
                        <FooterLogoTextSvgAgreement />
                    </>
                }
            </div>
        </div>
    );
};

export default PublicHeader;