import React from 'react';

const ChevronDownSvg = ({ width = '24px', color = '#1C274C' }: { width?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 25" fill="none">
      <path
        d="M19 10.5L12 16.5L5 10.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ChevronDownSvg;
