import React, { useEffect, useRef } from 'react';
import styles from './SortBox.module.scss';
import { SortArrowsSvg } from '../UI/svg-icon/SortArrowsSvg';
import useClickOutside from 'src/CustomHooks/useClickOutside';

interface IProps {
  sortOptions: { value: string; label: string }[];
  widthButton?: string;
  openSort: boolean;
  setOpenSort: React.Dispatch<React.SetStateAction<boolean>>;
  sort: { value: string; label: string } | '';
  setSort: React.Dispatch<React.SetStateAction<{ value: string; label: string } | ''>>;
  disabled?: boolean;
  label?: string;
}
export const SortBox = ({
  sortOptions,
  widthButton = '242px',
  openSort,
  setOpenSort,
  sort,
  setSort,
  disabled,
  label = 'Сортировка',
}: IProps) => {
  const sortRef = useRef(null);
  useClickOutside(sortRef, setOpenSort);

  return (
    <div style={{ position: 'relative' }} ref={sortRef}>
      <div style={{ width: widthButton }}>
        <div
          className={`${styles.buttonOpenFilter} ${openSort ? styles.openedFilterButton : ''} ${!sort ? '' : styles.filteredFilterButton}`}
          style={{ width: widthButton }}
          onClick={() => setOpenSort(!openSort)}
        >
          {sort ? sort?.label : label}
          <SortArrowsSvg />
        </div>
      </div>
      {openSort && (
        <div className={styles.hiddenFiltersContainer} style={{ width: widthButton }}>
          {sortOptions.map((option, id) => {
            return (
              <div
                key={option.value}
                onClick={() => {
                  setSort(option);
                  setOpenSort(false);
                }}
              >
                <div className={styles.hiddenFiltersText}>{option.label}</div>
                {id !== sortOptions.length - 1 && <div className={styles.line}></div>}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
