import React from 'react';
import LoadingSpin from './newUI/LoadingSpin/LoadingSpin';

export const PageWrapper = ({ children, loading = false, ...props }) => {
  if (loading) {
    return (
      <div id="PageWrapper" className="PageWrapper">
        <LoadingSpin />
      </div>
    );
  }
  return (
    <div {...props} id="PageWrapper" className="PageWrapper">
      {children}
    </div>
  );
};
