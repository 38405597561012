import React from 'react';
import styles from './GSMCardMainPage.module.scss';
import { WalletGSMSvg } from 'src/components/UI/svg-icon/WalletGSMSvg';
import { formCardNumber } from 'src/common/formCardNumber.helper';
import { useMediaQuery } from 'react-responsive';
import { ArrowRightSvg } from 'src/components/UI/svg-icon/ArrowRightSvg';
import { GSMLineSvg } from './GSMLineSvg';
import { useNavigate } from 'react-router-dom';

export const GSMCardMainPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <span className={styles.title}>Карта ГСМ</span>
      <div className={styles.topContainer}>
        <span className={styles.cardNumber}>{formCardNumber('3455456277103507')}</span>
        <div className={styles.statusContainer}>Активна</div>
      </div>
      <div className={styles.priceMainContainer}>
        <div className={styles.priceContainer}>
          <div style={{ paddingTop: '4px' }}>
            <WalletGSMSvg />
          </div>
          <span className={styles.price}>{`0 ₽`}</span>
        </div>
        <div className={styles.arrowContainer} onClick={() => navigate('/gsm')}>
          <ArrowRightSvg size="24px" />
        </div>
        <div className={styles.svgContainer}>
          <GSMLineSvg />
        </div>
      </div>
    </div>
  );
};
