import React, { useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import styles from './GSMNewPageCarrier.module.scss';
import TitleAndSubTitleMain from '../../components/UI/TitleAndSubtitle/TitleAndSubTitleMain';
import CurrentLimit from './components/CurrentLimit/CurrentLimit';
import GSMCardAdd from './components/GSMCardAdd/GSMCardAdd';
import ModalOfAddGsmCard from './components/ModalOfAddGSMCard/ModalOfAddGSMCard';
import Filters from './components/Filters/Filters';
import { IFilters } from './type';
import { initStateFilters } from './constans';
import GSMCard from './components/GSMCard/GSMCard';
import ModalOfLimitRequest from './components/ModalOfLimitRequest/ModalOfLimitRequest';
import { GSMStatisticsCard } from '../GSMNewPage/GSMStatisticsCard/GSMStatisticsCard';
const GSMNewPageCarrier = () => {
  const [openModalOfAddGSMCard, setOpenModalOfAddGSMCard] = useState<boolean>(false);
  const [openModalOfLimitRequest, setOpenModalOfLimitRequest] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>(initStateFilters);
  return (
    <PageWrapper>
      <TitleAndSubTitleMain title={'ГСМ'} subTitle={'Ваши топливные карты и детализация по ним'} />
      <div className={styles.container}>
          <div className={styles.priceAndPlotContainer}>
              <CurrentLimit setOpenModalOfLimitRequest={setOpenModalOfLimitRequest} />
              <GSMStatisticsCard />
          </div>
        <Filters filters={filters} setFilters={setFilters} />
        <div className={styles.cardsContainer}>
          <GSMCardAdd setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard} />
          <GSMCard />
        </div>
      </div>
      <ModalOfAddGsmCard
        openModalOfAddGSMCard={openModalOfAddGSMCard}
        setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard}
      />
      <ModalOfLimitRequest
        openModalOfAddGSMCard={openModalOfLimitRequest}
        setOpenModalOfAddGSMCard={setOpenModalOfLimitRequest}
      />
    </PageWrapper>
  );
};

export default GSMNewPageCarrier;
