import React from 'react';
import styles from './GSMCardAdd.module.scss';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import InfoSvg from '../../../../components/UI/svg-icon/InfoSvg';
import AddGSMSvg from '../../../../components/UI/svg-icon/AddGSMSvg';
import BackGroundGSMCardSvg from '../../../../components/UI/svg-icon/BackGroundGSMCardSvg';
import { useMediaQuery } from 'react-responsive';

interface IProps {
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
}

const GSMCardAdd = ({ setOpenModalOfAddGSMCard }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div className={styles.container} onClick={() => setOpenModalOfAddGSMCard(true)}>
      <div className={styles.tooltipContainer} onClick={event => event.stopPropagation()}>
        <CustomTooltip
          svg={<InfoSvg color={'#6C779C'} />}
          text={'Нужен текст'}
          widthTooltip={'120px'}
          widthSvg={'16px'}
          positionTooltipLeft={'-13px'}
          positionTooltipBottom={'23px'}
          MobileSideTooltip={'right'}
        />
      </div>
      <AddGSMSvg />
      <div>
        <p className={styles.mainTextCard}>Добавить топливную</p>
        <p className={styles.mainTextCard}>карту</p>
      </div>
      <div className={styles.backGround}>
        <BackGroundGSMCardSvg width={isMobile ? '134px' : '165px'} height={isMobile ? '153px' : '188px'} />
      </div>
    </div>
  );
};

export default GSMCardAdd;
