import React from 'react';

export const StopSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4998 10.6263C12.845 10.6263 13.1248 10.3465 13.1248 10.0013C13.1248 9.65612 12.845 9.3763 12.4998 9.3763H7.49984C7.15466 9.3763 6.87484 9.65612 6.87484 10.0013C6.87484 10.3465 7.15466 10.6263 7.49984 10.6263H12.4998Z"
        fill="#F4753A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99984 1.04297C5.05229 1.04297 1.0415 5.05375 1.0415 10.0013C1.0415 14.9489 5.05229 18.9596 9.99984 18.9596C14.9474 18.9596 18.9582 14.9489 18.9582 10.0013C18.9582 5.05375 14.9474 1.04297 9.99984 1.04297ZM2.2915 10.0013C2.2915 5.74411 5.74264 2.29297 9.99984 2.29297C14.257 2.29297 17.7082 5.74411 17.7082 10.0013C17.7082 14.2585 14.257 17.7096 9.99984 17.7096C5.74264 17.7096 2.2915 14.2585 2.2915 10.0013Z"
        fill="#F4753A"
      />
    </svg>
  );
};
