import React, { Dispatch, SetStateAction, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import './CustomRangePicker.css';
import { DatePicker, TimeRangePickerProps } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';

import 'dayjs/locale/ru';
import ButtonFilled from '../ButtonFilled/ButtonFilled';
import { useMediaQuery } from 'react-responsive';
import ButtonNotFilled from '../ButtonNotFilled/ButtonNotFilled';
import { RangePickerProps } from 'antd/es/date-picker';
import { CalendarSvg } from 'src/components/UI/svg-icon/CalendarSvg';
import { allMonths, getDate } from 'src/Pages/GSMNewPage/GSMHelpers';

interface IProps {
  width?: string;
  placeholder?: [string, string];
  open: boolean;
  isPresets?: boolean;
  dateRange: any;
  setDateRange: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  sendRequest?: (startDate: any, finishDate: any) => Promise<void>;
  height?: string;
  disabledDateBefore?: boolean;
  plusOneDayToApplyButton?: boolean;
  isNewStyle?: boolean;
  disabled?: boolean;
}

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const dateFormat = 'DD.MM.YYYY';
const nextDay = dayjs(new Date(new Date().setDate(new Date().getDate() + 1)));
const currentDay = dayjs(new Date());

const CustomRangePicker: React.FC<IProps> = ({
  width = '228px',
  placeholder,
  sendRequest,
  open,
  dateRange,
  setDateRange,
  setOpen,
  isPresets = false,
  height = '48px',
  disabledDateBefore,
  plusOneDayToApplyButton,
  isNewStyle = false,
  disabled = false,
}) => {
  const [rangePickerDate, setRangePickerDate] = useState<any>(
    plusOneDayToApplyButton ? [nextDay, nextDay] : [currentDay, currentDay],
  );
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const createCustomRangePickerFooter = () => {
    return (
      <div className={'containerCustomRangePickerBtn'}>
        <ButtonNotFilled
          width={isMobile ? '100%' : '140px'}
          text={'Отменить'}
          sizeText={'small'}
          onClick={() => {
            setOpen(false);
            setDateRange(['', '']);
          }}
        />
        <ButtonFilled
          height={'40px'}
          width={isMobile ? '100%' : '140px'}
          text={'Выбрать'}
          sizeText={'small'}
          onClick={() => {
            setOpen(false);
            setDateRange(rangePickerDate);
          }}
        />
      </div>
    );
  };
  const onChangeRangePicker = (dates, dateStrings) => {
    setRangePickerDate([
      dates[0].set('hour', 0).set('minute', 0).set('second', 0),
      dates[1].set('hour', 23).set('minute', 59).set('second', 59),
    ]);
  };
  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: '7 дней', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: '14 дней', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: '30 дней', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: '90 дней', value: [dayjs().add(-90, 'd'), dayjs()] },
  ];
  const disableBefore: RangePickerProps['disabledDate'] = (current: dayjs.Dayjs) => {
    return current && current < dayjs().endOf('day');
  };
  const today = new Date();
  const currentMonth = allMonths[today.getMonth()];
  // если не выбрана дата, выводим данные за месяц
  const dateText =
    dateRange && dateRange[0]
      ? getDate(dateRange[0]?.$D, dateRange[1]?.$D, dateRange[0]?.$M, dateRange[1]?.$M, dateRange[0]?.$y)
      : currentMonth;

  return (
    <>
      <div
        style={
          isNewStyle
            ? {
                width: width,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
              }
            : { width: width }
        }
      >
        {isNewStyle && (
          <div className="calendarContainer" onClick={() => setOpen(!open)}>
            <span className="calendarText">{dateText}</span>
            <CalendarSvg width={isMobile ? '16px' : '20px'} />
          </div>
        )}
        <RangePicker
          disabled={disabled}
          className={isNewStyle ? 'createDateRangePicker' : ''}
          disabledDate={disabledDateBefore && disableBefore}
          popupClassName={isNewStyle ? 'createDateRangePicker' : ''}
          style={{
            width: isNewStyle ? 0 : width,
            height: isNewStyle ? 0 : height,
            paddingLeft: '10px',
            paddingRight: '5px',
          }}
          locale={locale}
          presets={isPresets ? rangePresets : []}
          allowClear={false}
          open={open}
          placeholder={placeholder}
          onClick={() => setOpen(true)}
          renderExtraFooter={createCustomRangePickerFooter}
          format={dateFormat}
          value={dateRange}
          onChange={onChangeRangePicker}
        />
      </div>
    </>
  );
};

export default CustomRangePicker;
