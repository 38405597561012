import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './SelectedMaterialPage.module.scss';
import { PageWrapper } from '../../../components/PageWrapper';
import CustomTooltip from '../../../components/newUI/CustomTooltip/CustomTooltip';
import QuestionOutlineSvg from '../../../components/UI/svg-icon/QuestionOutlineSvg';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import { useMediaQuery } from 'react-responsive';
import { ArrowBack } from '../../../components/UI/svg-icon/ArrowBack';
import {
  convertTextFromFraction,
  headerButtonsArray,
  InitStateCurrentMaterialData,
  initStateUnitMeasurement,
  translateMainInfoToRussianFromBackend,
  priceByUnit,
  initStateShareInfo,
} from '../constans';
import OpenPhoto from '../components/OpenPhoto/OpenPhoto';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb, ConfigProvider } from 'antd';
import DashSvg from '../../../components/UI/svg-icon/DashSvg';
import { fetchGet, fetchPost } from '../../../common/proxy-1C-fetch-auth';
import { ICurrentMaterialData, IFractionButton, IShareInfo, IUnitMeasurement } from '../type';
import FileDocumentSvg from '../../../components/UI/svg-icon/FileDocumentSvg';
import { ImageContainer } from '../../../components/ImageContainer';
import { closePopUp, openPopUp } from '../../../common/open-close-popup.function';
import { Image } from '../../../common/image.type';
import { toastError } from '../../../common/toastError.helper';
import { convertValueFromNameMaterial, svgByMaterial } from '../../ShowcasesPage/constans';
import { PublicShowcaseHeader } from 'src/Pages/ShowcasesPage/ShowcasePage/PublicShowcaseHeader/PublicShowcaseHeader';
import { Context } from 'src/context/context';
import { ShareShowcasePopup } from '../../ShowcasesPage/ShowcasePage/ShareShowcasePopup/ShareShowcasePopup';
import useClickOutside from '../../../CustomHooks/useClickOutside';
import { toast } from 'react-toastify';
import { formPrice } from '../../../common/form-price.helper';
import { formVolume } from '../../DispatchingPages/Constans/Constans';

const SelectedMaterialPage = () => {
  const { pathname } = useLocation();
  const isPublicPath = pathname.startsWith('/store');
  const currentId = isPublicPath ? pathname.split('/')[3] : pathname.split('/')[4];
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { user } = useContext(Context);

  const [images, setImages] = useState<Image[]>([]);
  const [image, setImage] = useState<string>('');
  const [passport, setPassport] = useState<Image[]>([]);
  const [popUpActive, setPopUpActive] = useState(false);
  const [openPhoto, setOpenPhoto] = useState<boolean>(false);
  const [initialSlide, setInitialSlide] = useState<number>(0);
  const [currentMaterialData, setCurrentMaterialData] = useState<ICurrentMaterialData>(InitStateCurrentMaterialData);
  const [fractionButton, setFractionButton] = useState<IFractionButton[]>([]);
  const [unitMeasurementButton, setUnitMeasurementButton] = useState<IUnitMeasurement[]>(initStateUnitMeasurement);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [isCompare, setIsCompare] = useState<boolean>(false);
  const [sharePopupOpen, setSharePopupOpen] = useState<boolean>(false);
  const [isPopupHovered, setIsPopupHovered] = useState(false);
  const [compareOpen, setCompareOpen] = useState(false);
  const [shareInfo, setShareInfo] = useState<IShareInfo>(initStateShareInfo);

  const modalRef = useRef();
  const compareRef = useRef();
  useClickOutside(modalRef, setSharePopupOpen);
  useClickOutside(compareRef, setCompareOpen);
  const shouldShowPopup = isMobile ? sharePopupOpen : isPopupHovered;
  const nameBreedAndKindMaterial = currentMaterialData?.breed + ' ' + (currentMaterialData?.kind?.toLowerCase() || '');
  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActive(openPopUp);
  };

  const changeId = (newId: number): void => {
    let newPathArr: string[] = [];
    pathname.split('/').forEach((item, index) => {
      if (index === 4) {
        newPathArr.push(String(newId));
        return;
      }
      if (isPublicPath && index === 3) {
        newPathArr.push(String(newId));
        return;
      }
      newPathArr.push(item);
    });
    const newPath = newPathArr.join('/');

    navigate(newPath, { replace: true });
  };

  const getData = async (id: number, isMount = false) => {
    try {
      const response = await fetchGet(`/buyer/products/${isPublicPath ? 'store/' : ''}${currentId}`, {});
      setCurrentMaterialData(response?.response);
      if (isMount) {
        setFractionButton(
          response?.response?.otherProducts?.map(button => {
            return {
              ...button,
              active: button.id === Number(currentId),
            };
          }),
        );
        setUnitMeasurementButton(
          unitMeasurementButton.map(button => {
            return {
              ...button,
              active: button.value === response?.response?.sellUnit,
            };
          }),
        );
        setImages(response?.response?.files?.filter(image => image.category === 'Фото материала'));
        setPassport(response?.response?.files?.filter(image => image.category === 'Паспорт'));
        setIsFavorite(response?.response?.favorite);
        setIsCompare(response?.response?.comparison);
        setShareInfo(prevState => ({
          ...prevState,
          link: response?.response?.publicLink || pathname?.substr(1, pathname?.length - 1),
          logoImage: response?.response?.files[0],
          carrierName: `${response?.response?.breed} ${
            response?.response?.kind ? response?.response?.kind?.toLowerCase() : ''
          }`,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const changeFavorite = async () => {
    try {
      if (!isFavorite) {
        const response = await fetchPost(`/users/favourites/`, 'POST', {
          category: 'materials',
          itemId: currentMaterialData.id,
        });
        if (toastError(response)) return;
      } else {
        const response = await fetchPost(`/users/favourites/${currentMaterialData.id}`, 'DELETE', {
          category: 'materials',
        });
        if (toastError(response)) return;
      }

      setIsFavorite(!isFavorite);
    } catch (e) {
      console.log(e);
    }
  };
  const changeCompare = async () => {
    try {
      if (!isCompare) {
        const response = await fetchPost(`/users/comparisons`, 'POST', {
          category: 'materials',
          itemId: currentMaterialData.id,
        });
        if (toastError(response)) return;
      } else {
        const response = await fetchPost(`/users/comparisons`, 'DELETE', {
          category: 'materials',
          id: currentMaterialData.id,
        });
        if (toastError(response)) return;
      }
      setIsCompare(!isCompare);
      setCompareOpen(true);
    } catch (e) {
      console.log(e);
    }
  };
  const postUnit = async () => {
    try {
      const response = await fetchPost(`/buyer/carts`, 'POST', {
        unit: unitMeasurementButton?.find(item => item.active === true)?.value,
        materialId: currentMaterialData.id,
      });
      if (response.error) {
        const errorMessages = response.message.map(message => {
          return (
            <p>
              {message}
              <br />
              <br />
            </p>
          );
        });
        toast.error(<div>{errorMessages}</div>);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const changeButtonsActiveState = (buttonCompare: number | string, type: 'fraction' | 'unit'): void => {
    const newData = (type === 'fraction' && [...fractionButton]) || (type === 'unit' && [...unitMeasurementButton]);
    newData.forEach(button => {
      /*Если тип fraction, то сравниваем по id, если unit, то по value*/
      button.active = ((type === 'fraction' && button.id) || (type === 'unit' && button.value)) === buttonCompare;
    });
    if (type === 'fraction') {
      setFractionButton(newData as IFractionButton[]);
    }
    if (type === 'unit') {
      setUnitMeasurementButton(newData as IUnitMeasurement[]);
    }
  };

  useEffect(() => {
    getData(Number(currentId), true);
  }, [pathname]);
  return (
    <>
      {isPublicPath && !isMobile && <PublicShowcaseHeader />}
      <div
        className={isPublicPath && 'Page'}
        style={!isPublicPath ? { width: '100%' } : isMobile ? {} : { marginTop: '24px' }}
      >
        <div className={isPublicPath && !isMobile && 'Container'} style={!isPublicPath ? { width: '100%' } : {}}>
          <PageWrapper style={isPublicPath ? (isMobile ? { padding: '40px 16px' } : { paddingLeft: '40px' }) : {}}>
            <div className={styles.headContainer}>
              {!isPublicPath ? (
                isMobile ? (
                  <div onClick={() => navigate(-1)} className={styles.backButton}>
                    <div style={{ width: '16px', height: '16px' }}>
                      <ArrowBack />
                    </div>
                    <p>Назад</p>
                  </div>
                ) : (
                  <ConfigProvider
                    theme={{
                      components: {
                        Breadcrumb: {
                          itemColor: '#6C779C',
                          linkColor: '#6C779C',
                        },
                      },
                    }}
                  >
                    <Breadcrumb
                      separator={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <DashSvg />
                        </div>
                      }
                      items={[
                        {
                          title: <a onClick={() => navigate('/catalog')}>Каталог</a>,
                        },
                        {
                          title: (
                            <a
                              onClick={() =>
                                navigate(
                                  `/catalog/materials/${convertValueFromNameMaterial[currentMaterialData.breed]}`,
                                )
                              }
                            >
                              {currentMaterialData?.breed}
                            </a>
                          ),
                        },
                        {
                          title: nameBreedAndKindMaterial,
                        },
                      ]}
                    />
                  </ConfigProvider>
                )
              ) : (
                <div className={styles.publicHeader}>{isMobile ? '' : nameBreedAndKindMaterial}</div>
              )}
              <div className={styles.buttonsHead}>
                {headerButtonsArray(isFavorite, isCompare).map((item, index) => {
                  if (isPublicPath && index !== 2) return;
                  return (
                    <div key={item.text} style={{ position: 'relative' }}>
                      <div
                        onClick={e => {
                          e.stopPropagation();
                          if (isMobile && item.text === 'Поделиться') {
                            setSharePopupOpen(!sharePopupOpen);
                          }
                          (item.text === 'В избранное' && changeFavorite()) ||
                            ((item.text === 'Сравнить' || item.text === 'В сравнении') && changeCompare());
                        }}
                        key={index}
                        className={styles.buttonHeadContainer}
                        onMouseEnter={() => !isMobile && item.text === 'Поделиться' && setIsPopupHovered(true)}
                        onMouseLeave={() => !isMobile && item.text === 'Поделиться' && setIsPopupHovered(false)}
                      >
                        {item.svg}
                        {!isMobile && <p>{item.text}</p>}
                      </div>
                      {item.text === 'Поделиться' && (
                        <ShareShowcasePopup
                          title={'Поделиться материалом'}
                          isSharePopupOpen={shouldShowPopup}
                          setIsSharePopupOpen={setSharePopupOpen}
                          isShowcasePublished={true}
                          link={shareInfo.link}
                          logoImage={shareInfo.logoImage}
                          carrierName={shareInfo.carrierName}
                          onMouseEnter={() => {
                            if (!isMobile) setIsPopupHovered(true);
                          }}
                          onMouseLeave={() => {
                            if (!isMobile) setIsPopupHovered(false);
                          }}
                        />
                      )}
                      {item.text === 'В сравнении' && compareOpen && (
                        <div ref={compareRef} className={styles.compareContainer}>
                          <div>
                            <span className={styles.materialCompare}>{nameBreedAndKindMaterial}</span>&nbsp;
                            <span className={styles.normalTextCompare}>
                              добавлен к сравнению, чтобы увидеть больше, перейдите в
                            </span>
                            &nbsp;
                            <a className={styles.metaTextCompare} href={'/catalog/comparisons'}>
                              раздел сравнения товаров
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.contentContainer}>
              {isMobile ? (
                <>
                  <OpenPhoto
                    currentMaterialData={currentMaterialData}
                    initialSlide={initialSlide}
                    setInitialSlide={setInitialSlide}
                    openPhoto={openPhoto}
                    setOpenPhoto={setOpenPhoto}
                    images={images}
                  />
                </>
              ) : (
                <div className={styles.photosContainer}>
                  {images?.length > 1 && (
                    <div className={styles.attachedPhotos}>
                      {images?.map(
                        (image, index) =>
                          index !== 0 && (
                            <img
                              key={index}
                              onClick={() => {
                                setInitialSlide(index);
                                setOpenPhoto(true);
                              }}
                              src={image?.location}
                              alt={'img'}
                              className={styles.attachedPhoto}
                            />
                          ),
                      )}
                    </div>
                  )}
                  {images?.length ? (
                    images?.map(
                      (image, index) =>
                        index === 0 && (
                          <img
                            onClick={() => {
                              setInitialSlide(index);
                              setOpenPhoto(true);
                            }}
                            src={image?.location}
                            className={styles.mainPhoto}
                            alt={'img'}
                          />
                        ),
                    )
                  ) : (
                    <div
                      onClick={() => {
                        setInitialSlide(0);
                        setOpenPhoto(true);
                      }}
                      className={styles.mainPhoto}
                    >
                      {svgByMaterial[currentMaterialData?.breed]}
                    </div>
                  )}
                </div>
              )}
              <div className={styles.infoContainer}>
                <div className={styles.titlePriceInfoContainer}>
                  <div className={styles.titleInfoContainer}>{nameBreedAndKindMaterial}</div>
                  <div className={styles.priceAndOrderContainer}>
                    <div className={styles.priceContainer}>
                      <p style={{ whiteSpace: 'nowrap' }}>
                        от{' '}
                        {formPrice(
                          priceByUnit(currentMaterialData)[
                            unitMeasurementButton?.find(item => item.active === true)?.title
                          ],
                          'RUB',
                          'ru',
                        )}
                        /{unitMeasurementButton?.find(item => item.active === true)?.title}
                      </p>
                      <CustomTooltip
                        SideTooltip={'up'}
                        svg={<QuestionOutlineSvg />}
                        text={'Итоговая цена будет сформирована при оформлении заказа'}
                        widthTooltip={'300px'}
                      />
                    </div>
                    <ButtonFilled
                      text={'Заказать материал'}
                      sizeText={'small'}
                      width={isMobile ? '100%' : '183px'}
                      onClick={() => {
                        postUnit();
                        navigate(
                          !(user.activeProfile && user.type)
                            ? '/auth'
                            : `/catalog/materials/order/${currentMaterialData?.id}`,
                        );
                      }}
                    />
                  </div>
                </div>
                <div className={styles.contentInfoContainer}>
                  <div className={styles.paragraphInfoContent}>
                    <p className={styles.paragraphInfoText}>Единица измерения</p>
                    <div className={styles.buttonsContainer}>
                      {unitMeasurementButton.map((button, index) => (
                        <button
                          key={index}
                          className={styles.buttonUnitContainer}
                          style={
                            button.active
                              ? {
                                  border: '1px solid #F4753A',
                                  background: '#FFEFE6',
                                  color: '#F4753A',
                                }
                              : {}
                          }
                          onClick={() => {
                            !button.active && changeButtonsActiveState(button.value, 'unit');
                          }}
                        >
                          {button.title}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className={styles.paragraphInfoContent}>
                    <p className={styles.paragraphInfoText}>Фракция</p>
                    <div className={styles.buttonsContainer}>
                      {fractionButton?.map((button, index) => (
                        <button
                          key={index}
                          onClick={() => {
                            if (!button.active) {
                              changeButtonsActiveState(button.id, 'fraction');
                              changeId(button.id);
                            }
                          }}
                          className={styles.buttonFractionContainer}
                          style={
                            button.active
                              ? {
                                  border: '1px solid #F4753A',
                                  background: '#FFEFE6',
                                  color: '#F4753A',
                                }
                              : {}
                          }
                        >
                          {button.mainProperty}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className={styles.paragraphInfoContent}>
                    <p className={styles.paragraphInfoText}>Описание</p>
                    <div className={styles.descriptionText}>{currentMaterialData?.description}</div>
                  </div>
                  <div className={styles.paragraphInfoContent}>
                    <p className={styles.paragraphInfoText}>Основная информация</p>
                    <div className={styles.mainInfoContainer}>
                      {/* eslint-disable-next-line array-callback-return */}
                      {currentMaterialData?.properties.map((item, index) => {
                        return (
                          <div key={index} className={styles.keyAndValueContainer}>
                            <p className={styles.keyInfoText}>{translateMainInfoToRussianFromBackend[item.title]}</p>
                            <p className={styles.downBorderInfoText} />
                            <p className={styles.valueInfoText}>
                              {Object.keys(convertTextFromFraction).includes(item.value)
                                ? convertTextFromFraction[item.value]
                                : item.value}
                            </p>
                          </div>
                        );
                      })}
                      <div className={styles.keyAndValueContainer}>
                        <p className={styles.keyInfoText}>Объем материала</p>
                        <p className={styles.downBorderInfoText} />
                        {unitMeasurementButton?.find(item => item.active === true)?.value ===
                        currentMaterialData.sellUnit ? (
                          <p className={styles.valueInfoText}>
                            {formVolume(currentMaterialData?.volume)}{' '}
                            {unitMeasurementButton?.find(item => item.active === true)?.title}
                          </p>
                        ) : (
                          <p className={styles.valueInfoText}>
                            {formVolume(currentMaterialData?.volumeInOtherUnit)}{' '}
                            {unitMeasurementButton?.find(item => item.active === true)?.title}
                          </p>
                        )}
                      </div>
                      <div className={styles.keyAndValueContainer}>
                        <p className={styles.keyInfoText}>Сертификаты и паспорта</p>
                        <p className={styles.downBorderInfoText} />
                        <p className={styles.valueInfoTextOrange}>
                          <p>Показать файлы</p>
                          <CustomTooltip
                            openTooltipOnClick={true}
                            svg={<FileDocumentSvg />}
                            widthTooltip={'fit-content'}
                            SideTooltip={'left'}
                            MobileSideTooltip={'left'}
                            text={
                              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                {passport?.map(file => (
                                  <>
                                    {file.category === 'Паспорт' && (
                                      <ImageContainer
                                        id={file?.id}
                                        value={
                                          file?.mimeType === 'image/png' || file.mimeType === 'image/jpeg'
                                            ? null
                                            : file?.location
                                        }
                                        name={file?.name}
                                        size={file?.size}
                                        isSize={file?.isSize}
                                        date={file?.date}
                                        isDate={file?.isDate}
                                        type={file?.mimeType}
                                        openPopUp={() =>
                                          (file?.mimeType === 'image/png' || file?.mimeType === 'image/jpeg') &&
                                          openPopupWithImg(file?.location)
                                        }
                                        children={undefined}
                                      ></ImageContainer>
                                    )}
                                  </>
                                ))}
                              </div>
                            }
                          />
                        </p>
                      </div>
                      <div className={styles.keyAndValueContainer}>
                        <p className={styles.keyInfoText}>Витрина</p>
                        <p className={styles.downBorderInfoText} />
                        <p
                          style={{ cursor: 'pointer' }}
                          className={styles.valueInfoTextOrange}
                          onClick={() =>
                            navigate(
                              `/${isPublicPath ? 'store' : 'catalog/showcases'}/${currentMaterialData?.showcase?.id}`,
                            )
                          }
                        >
                          {currentMaterialData?.showcase?.title}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isMobile && (
              <OpenPhoto
                currentMaterialData={currentMaterialData}
                initialSlide={initialSlide}
                setInitialSlide={setInitialSlide}
                openPhoto={openPhoto}
                setOpenPhoto={setOpenPhoto}
                images={images}
              />
            )}
            <div onClick={e => e.stopPropagation()} className={popUpActive ? 'Popup active' : 'Popup'}>
              <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
              <div className="Popup__wrapper">
                <div>
                  <div>
                    <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                    <div className="Popup__content">
                      <div className="Popup__box">
                        <img src={image} alt={''} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PageWrapper>
        </div>
      </div>
    </>
  );
};

export default SelectedMaterialPage;
