import React, { useState } from 'react';
import styles from './NavIdeaItem.module.scss';
import { useNavigate } from 'react-router-dom';

const NavIdeaItem = () => {
    const navigate=useNavigate();
    const[showIdeaContainer,setShowIdeaContainer]=useState<boolean>(false);
    return (
        <div className={styles.ideaGlobalContainer}>
            <div className={styles.ideaItem} onMouseEnter={()=>{setShowIdeaContainer(true);}}
                 onMouseLeave={()=>{setShowIdeaContainer(false);}}
            >
                <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.55 3.96078L3.96 2.55078L5.75 4.35078L4.34 5.76078L2.55 3.96078ZM10 3.05078H12V0.0507812H10V3.05078ZM0 12.0508H3V10.0508H0V12.0508ZM12 19.0008V15.0408L13 14.4608C14.24 13.7408 15 12.4208 15 11.0008C15 8.79078 13.21 7.00078 11 7.00078C8.79 7.00078 7 8.79078 7 11.0008C7 12.4208 7.77 13.7408 9 14.4608L10 15.0408V19.0008H12ZM14 21.0008H8V16.1908C6.21 15.1508 5 13.2208 5 11.0008C5 7.69078 7.69 5.00078 11 5.00078C14.31 5.00078 17 7.69078 17 11.0008C17 13.2208 15.79 15.1508 14 16.1908V21.0008ZM19 12.0508H22V10.0508H19V12.0508ZM16.24 4.34078L18.03 2.54078L19.44 3.95078L17.64 5.74078L16.24 4.34078Z" fill="currentColor"/>
                </svg>
            </div>
            {showIdeaContainer &&
                <div className={styles.ideaTextWrapperContainer}
                     onMouseEnter={()=>{setShowIdeaContainer(true);}}
                     onMouseLeave={()=>{setShowIdeaContainer(false);}}>
                    <div   className={styles.ideaTextContainer}>
                        <p className={styles.ideaTextDescription}> Если у вас есть идеи и предложения по улучшению нашей платформы,
                            то мы будем рады вас услышать. Ваше мнение очень важно для нас!</p>
                        <span onClick={()=>navigate('/suggestAnIdea')} className={styles.ideaBtn}>Предложить идею</span>
                    </div>
                </div> }

        </div>
    );
};

export default NavIdeaItem;