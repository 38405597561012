import React from 'react';

export const DownloadDocumentSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_30665_35614)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 10.2083C2.84518 10.2083 3.125 10.4881 3.125 10.8333C3.125 12.0295 3.12633 12.8638 3.21096 13.4932C3.29317 14.1047 3.44354 14.4285 3.67418 14.6591C3.90481 14.8898 4.22862 15.0401 4.8401 15.1224C5.46956 15.207 6.30382 15.2083 7.5 15.2083H12.5C13.6962 15.2083 14.5304 15.207 15.1599 15.1224C15.7714 15.0401 16.0952 14.8898 16.3258 14.6591C16.5565 14.4285 16.7068 14.1047 16.789 13.4932C16.8737 12.8638 16.875 12.0295 16.875 10.8333C16.875 10.4881 17.1548 10.2083 17.5 10.2083C17.8452 10.2083 18.125 10.4881 18.125 10.8333V10.879C18.125 12.0187 18.125 12.9373 18.0279 13.6598C17.9271 14.4099 17.7113 15.0414 17.2097 15.543C16.7081 16.0446 16.0766 16.2604 15.3265 16.3612C14.604 16.4583 13.6854 16.4583 12.5457 16.4583H7.45428C6.31462 16.4583 5.39602 16.4583 4.67354 16.3612C3.92345 16.2604 3.29189 16.0446 2.79029 15.543C2.2887 15.0414 2.07295 14.4099 1.9721 13.6598C1.87497 12.9373 1.87498 12.0187 1.875 10.879C1.875 10.8638 1.875 10.8486 1.875 10.8333C1.875 10.4881 2.15482 10.2083 2.5 10.2083Z"
          fill="#F4753A"
          stroke="#F4753A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99984 12.2917C10.1753 12.2917 10.3427 12.2179 10.4611 12.0884L13.7944 8.44258C14.0274 8.18783 14.0097 7.7925 13.7549 7.55959C13.5002 7.32667 13.1048 7.34437 12.8719 7.59912L10.6248 10.0569V0.833353C10.6248 0.488175 10.345 0.208353 9.99984 0.208353C9.65466 0.208353 9.37484 0.488175 9.37484 0.833353V10.0569L7.12778 7.59912C6.89486 7.34437 6.49953 7.32667 6.24478 7.55959C5.99003 7.7925 5.97232 8.18783 6.20524 8.44258L9.53857 12.0884C9.65698 12.2179 9.82437 12.2917 9.99984 12.2917Z"
          fill="#F4753A"
          stroke="#F4753A"
        />
      </g>
      <defs>
        <clipPath id="clip0_30665_35614">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
