import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import styles from './ShowcaseDescription.module.scss';
import CustomTooltip from 'src/components/newUI/CustomTooltip/CustomTooltip';
import NewPlusSvg from 'src/components/UI/svg-icon/NewPlusSvg';
import { useMediaQuery } from 'react-responsive';
import { InputText } from 'src/components/UI/inputs/InputText';
import { InputTextArea } from 'src/components/UI/inputs/InputTextArea';
import { uploadImage } from '../../utils';
import defaultLogoImage from '../../images/default-logo.png';
import { toast } from 'react-toastify';

type TShowcaseDescription = {
  isCreatePage: boolean;
  logoImage: string;
  setLogoImage: Dispatch<SetStateAction<string>>;
  carrierName: string;
  setCarrierName: Dispatch<SetStateAction<string>>;
  carrierDescription: string;
  setCarrierDescription: Dispatch<SetStateAction<string>>;
  setIsChangedLogo?: Dispatch<SetStateAction<boolean>>;
  error: boolean;
  setError: Dispatch<SetStateAction<{ carrierName: boolean; quarry: boolean }>>;
};

export const ShowcaseDescription = ({
  isCreatePage,
  logoImage,
  setLogoImage,
  carrierName,
  setCarrierName,
  carrierDescription,
  setCarrierDescription,
  setIsChangedLogo = () => {},
  error,
  setError,
}: TShowcaseDescription) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleContainerClick = () => {
    fileInputRef.current?.click();
  };

  const handleUpload = e => {
    const file = e.target.files[0];
    if (file.size > 5242880) {
      toast.warning('Размер файла превышает 5 МБ.');
      e.target.value = '';
      return;
    }
    uploadImage(e, setLogoImage);
    if (!isCreatePage) {
      setIsChangedLogo(true);
    }
  };

  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.mobileContainer}>
        <div className={styles.logoContainer} onClick={handleContainerClick}>
          <div
            className={styles.logoImage}
            style={{
              backgroundImage: `url(${logoImage ? logoImage : defaultLogoImage})`,
            }}
          />
          <CustomTooltip
            MobileSideTooltip={'right'}
            SideTooltip={'right'}
            svg={<NewPlusSvg />}
            positionTooltipBottom={'26px'}
            text={
              <p>
                Загрузите логотип вашего карьера. <br /> Разрешение изображения 72 х 72, размер до 5 МБ, форматы: .png,
                .jpg
              </p>
            }
            widthTooltip={isMobile ? '280px' : '498px'}
            positionTooltipLeft={isMobile && '-9px'}
          />
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            ref={fileInputRef}
            onChange={e => handleUpload(e)}
            style={{ display: 'none' }}
          />
        </div>
        {isMobile && (
          <InputText
            clearButton={carrierName !== ''}
            type={''}
            disabled={false}
            isLabel={true}
            value={carrierName}
            setValue={value => setCarrierName(value)}
            margin={''}
            error={error}
            showError={error}
            textError={''}
            width={'100%'}
            placeholder={'Наименование'}
            helpText={
              error ? (
                <p>
                  Данное поле обязательно к заполнению. <br />
                  Введите наименование для вашего карьера
                </p>
              ) : (
                ''
              )
            }
            widthHelp={'210px'}
            widthSvg="20px"
            svgColor="#DC2626"
            onBlur={() => {
              setError(prevState => {
                return { ...prevState, carrierName: false };
              });
            }}
            maxLength="100"
          />
        )}
      </div>
      <div className={styles.inputContainer}>
        {!isMobile && (
          <InputText
            clearButton={carrierName !== ''}
            type={''}
            disabled={false}
            isLabel={true}
            value={carrierName}
            setValue={value => setCarrierName(value)}
            margin={''}
            error={error}
            showError={error}
            helpText={
              error ? (
                <p>
                  Данное поле обязательно к заполнению. <br />
                  Введите наименование для вашего карьера
                </p>
              ) : (
                ''
              )
            }
            widthHelp={'324px'}
            widthSvg="20px"
            svgColor="#DC2626"
            textError={''}
            width={'100%'}
            placeholder={'Наименование'}
            maxLength="100"
            onBlur={() => {
              setError(prevState => {
                return { ...prevState, carrierName: false };
              });
            }}
          />
        )}
        <InputTextArea
          isLabel={true}
          value={carrierDescription}
          placeholder={'Описание'}
          padding={'24px 16px 8px'}
          height={isMobile ? '160px' : '90px'}
          setValue={value => setCarrierDescription(value)}
          margin="0"
          disabled={false}
          maxLength="1000"
          style={{ resize: 'none', height: isMobile ? '128px' : '54px' }}
        />
      </div>
    </div>
  );
};
