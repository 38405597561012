// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GSMNewPageCarrier_container__3UAtg {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.GSMNewPageCarrier_cardsContainer__J3KkM {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 22px;\n}\n\n.GSMNewPageCarrier_cardsContainerList__lOXBh {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.GSMNewPageCarrier_priceAndPlotContainer__9uJkb {\n  display: flex;\n  flex-direction: row;\n  gap: 32px;\n}\n\n@media (max-width: 768px) {\n  .GSMNewPageCarrier_container__3UAtg {\n    gap: 24px;\n  }\n  .GSMNewPageCarrier_priceAndPlotContainer__9uJkb {\n    flex-direction: column;\n    gap: 24px;\n  }\n  .GSMNewPageCarrier_cardsContainer__J3KkM {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/GSMNewPageCarrier/GSMNewPageCarrier.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AACA;EACE,aAAA;EACA,sCAAA;EACA,SAAA;AAEF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAGF;;AADA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAIF;;AAFA;EACE;IACE,SAAA;EAKF;EAHA;IACE,sBAAA;IACA,SAAA;EAKF;EAHA;IACE,aAAA;IACA,sBAAA;IACA,SAAA;EAKF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n.cardsContainer {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  gap: 22px;\n}\n.cardsContainerList{\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n.priceAndPlotContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 32px;\n}\n@media (max-width: 768px) {\n  .container {\n    gap: 24px;\n  }\n  .priceAndPlotContainer {\n    flex-direction: column;\n    gap: 24px;\n  }\n  .cardsContainer{\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GSMNewPageCarrier_container__3UAtg",
	"cardsContainer": "GSMNewPageCarrier_cardsContainer__J3KkM",
	"cardsContainerList": "GSMNewPageCarrier_cardsContainerList__lOXBh",
	"priceAndPlotContainer": "GSMNewPageCarrier_priceAndPlotContainer__9uJkb"
};
export default ___CSS_LOADER_EXPORT___;
