import React, { useState } from 'react';
import styles from './GSMStatisticsCard.module.scss';
import { GSMStatisticsPlot } from './GSMStatisticsPlot/GSMStatisticsPlot';
import { CustomSelect } from 'src/components/UI/select/CustomSelect';
import { CalendarArrows } from '../components/CalendarArrows/CalendarArrows';

const options = [
  { value: 'По сумме', label: 'По сумме' },
  { value: 'По виду топлива', label: 'По виду топлива' },
];

export const GSMStatisticsCard = () => {
  const [sort, setSort] = useState<{ label: string; value: string }>(options[0]);
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div>
          <CustomSelect
            height40px={true}
            options={options}
            setValue={setSort}
            value={sort}
            placeholder={''}
            isClearable={false}
            error={false}
            isSearchable={false}
            noOptionsMessage={''}
          />
        </div>
        <div className={styles.dateContainer}>
          <CalendarArrows isLeft={true} />
          <span className={styles.text}>1-7 октября</span>
          <CalendarArrows isLeft={false} />
        </div>
      </div>
      <GSMStatisticsPlot type={sort === options[0] ? 'line' : 'pie'} />
    </div>
  );
};
