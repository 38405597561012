import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../../../../../components/newUI/BackButton/BackButton';
import { Breadcrumb, ConfigProvider } from 'antd';
import DashSvg from '../../../../../components/UI/svg-icon/DashSvg';
interface IProps {
  step: 'transfer' | 'smsAuth' | 'success';
  setStep: React.Dispatch<'transfer' | 'smsAuth' | 'success'>;
  cardNumber?: string;
}
const NavigateFormReplenishmentsCardPage = ({ step, setStep, cardNumber = '' }: IProps) => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const currentId = pathname.split('/')[2];
  const navigateBackButtonMobile = (step: 'transfer' | 'smsAuth' | 'success'): (() => void) => {
    switch (step) {
      case 'transfer': {
        return () => navigate(`/gsm/${currentId}`);
      }
      case 'smsAuth': {
        return () => setStep('transfer');
      }
      case 'success': {
        return () => navigate('/gsm');
      }
    }
  };
  return (
    <>
      {isMobile ? (
        <BackButton textButton={'Вернуться назад'} onClick={navigateBackButtonMobile(step)} />
      ) : (
        <>
          {step === 'success' ? (
            <BackButton textButton={'Вернуться назад'} onClick={() => navigate(`/gsm`)} />
          ) : (
            <ConfigProvider
              theme={{
                components: {
                  Breadcrumb: {
                    itemColor: '#6C779C',
                    linkColor: '#6C779C',
                  },
                },
              }}
            >
              <Breadcrumb
                separator={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DashSvg />
                  </div>
                }
                items={[
                  {
                    title: <a onClick={() => navigate('/gsm')}>ГСМ</a>,
                  },
                  {
                    title: (
                      <a onClick={() => navigate(`/gsm/${currentId}`)}>
                        {cardNumber ? `*${cardNumber.slice(-3, cardNumber.length)}` : ''}
                      </a>
                    ),
                  },
                  {
                    title: step === 'smsAuth' ? <a onClick={() => setStep('transfer')}>Пополнение</a> : `Пополнение`,
                  },
                  step === 'smsAuth' && {
                    title: `Код подтверждения`,
                  },
                ].filter(Boolean)}
              />
            </ConfigProvider>
          )}
        </>
      )}
    </>
  );
};

export default NavigateFormReplenishmentsCardPage;
