import React from 'react';
import styles from './CurrentLimit.module.scss';
import { WalletGSMSvg } from '../../../../components/UI/svg-icon/WalletGSMSvg';
import { formPrice } from '../../../../common/form-price.helper';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { useNavigate } from 'react-router-dom';
import { Progress } from 'antd';
interface IProps {
  setOpenModalOfLimitRequest: React.Dispatch<boolean>;
}

const CurrentLimit = ({ setOpenModalOfLimitRequest }: IProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.containerLimit}>
        <div className={styles.mainBlockContainer}>
          <p className={styles.titleMainBlock}>Текущий лимит ГСМ</p>
          <button disabled={false} className={styles.buttonMainBlock} onClick={() => setOpenModalOfLimitRequest(true)}>
            Запросить
          </button>
        </div>
        <div className={styles.walletContainer}>
          <div className={styles.moneyContainer}>
            <WalletGSMSvg />
            <p className={styles.moneyText}>{formPrice(0, 'RUB', 'ru')}</p>
          </div>
          <div className={styles.progressLineContainer}>
            <Progress trailColor={'white'} showInfo={false} percent={10} size={['100%', 6]} strokeColor="#FFEFE6" />
          </div>
          <div className={styles.distributionContainer}>Распределено {formPrice(0, 'RUB', 'ru')}</div>
        </div>
        <ButtonNotFilled
          disabled={false}
          text={'Пополнить карты'}
          width={'100%'}
          sizeText={'small'}
          height="40px"
          onClick={() => navigate('/gsm/replenishments')}
        />
      </div>
    </div>
  );
};

export default CurrentLimit;
