import React, { useContext, useRef } from 'react';
import { Carousel, ConfigProvider } from 'antd';
import styles from './MobileTour.module.scss';
import ButtonFilled from '../../../newUI/ButtonFilled/ButtonFilled';
import { arrayOfMobileTourContent } from './constans';
import { Context } from '../../../../context/context';

const MobileTour = () => {
  const { setStartTour, user } = useContext(Context);
  const ref = useRef();
  const dotCount: number = arrayOfMobileTourContent[user.type === 'driver' ? 'driver' : user.companyType].length - 1;
    const nextSlide = () => {
    // @ts-ignore
    ref.current.next();
  };
  return (
    <div className={styles.container}>
      <ConfigProvider
        theme={{
          components: {
            Carousel: {
              dotWidth: 8,
              dotHeight: 8,
              dotActiveWidth: 8,
            },
          },
        }}
      >
        <Carousel
          beforeChange={(from: number, to: number) => {
            if (from === dotCount && to === dotCount) {
              setStartTour(false);
            }
          }}
          ref={ref}
          infinite={false}
        >
          {arrayOfMobileTourContent[user.type === 'driver' ? 'driver' : user.companyType].map(slide => (
            <div key={slide.title} className={styles.slideContainer}>
              <div>
                <h1 className={styles.slideTitle}>{slide.title}</h1>
                <p className={styles.slideDescription}>{slide.discription}</p>
              </div>
              <img
                style={slide.title === 'Помощь' ? { marginBottom: '30vh', height: '300px' } : {}}
                className={styles.img}
                src={slide.src}
                alt=""
              />
            </div>
          ))}
        </Carousel>
      </ConfigProvider>

      <div className={styles.containerBtn}>
        <ButtonFilled onClick={() => nextSlide()} text={'Продолжить'} height={'40px'} width={'100%'} />
      </div>
    </div>
  );
};

export default MobileTour;
