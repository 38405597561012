import React, { useEffect, useState } from 'react';
import styles from './AdminTariffsAddPage.module.scss';
import { PageWrapper } from '../../../components/PageWrapper';
import BackButton from '../../../components/newUI/BackButton/BackButton';
import { useLocation, useNavigate } from 'react-router-dom';
import TitleMain from '../../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import {
  carrierColumnsOfSupplier,
  carrierParametersOfAddTariffs,
  currentSiteEnum,
  disableInputsInMainParametersArray,
  getImages,
  mainParametersOfAddTariffs,
  mapDataForTable,
  objectCompareMainTitle,
  requestDataTariff,
  sendUploadImages,
  supplierColumnsOfSupplier,
  supplierParametersOfAddTariffs,
} from './const';
import {
  IDataResponseTable,
  IParametersOfAddTariffs,
  IResponseDetailsTariff,
  IResponseDetailsTariffWithArray,
} from './type';
import { toastError } from '../../../common/toastError.helper';
import InputsMapping from './components/InputsMapping/InputsMapping';
import { fetchGet, fetchPost } from '../../../common/proxy-1C-fetch-auth';
import CustomTable from '../../../components/newUI/CustomTable/CustomTable';
import ButtonNotFilled from '../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { CopySvg } from '../../../components/UI/svg-icon/CopySvg';
import { ButtonSimple } from '../../../components/newUI/ButtonSimple/ButtonSimple';
import { PlusSvg } from '../../../components/UI/svg-icon/PlusSvg';
import InputFile from '../../../components/newUI/InputFile/InputFile';
import LoadingSpin from '../../../components/newUI/LoadingSpin/LoadingSpin';
import { toast } from 'react-toastify';
import {
  getCarrierValue,
  getListCompany,
  getListMaterialsByQuarry,
  getListMaterialsCarrier,
  getListOrganization,
  getListQuarryCarrier,
  getListQuarrySupplier,
  getListSemitrailers,
  getMainValue,
  getSupplierValue,
} from './utils';

interface IProps {
  currentSite: string;
}

const AdminTariffsAddPage = ({ currentSite }: IProps) => {
  const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false);
  const [loadingTariff, setLoadingTariff] = useState<boolean>(
    currentSite === currentSiteEnum.EDIT || currentSite === currentSiteEnum.ID,
  );
  const [dataResponseTable, setDataResponseTable] = useState<IDataResponseTable[]>();
  const [mainParametersInfo, setMainParametersInfo] = useState<IParametersOfAddTariffs[]>(mainParametersOfAddTariffs);
  const [carrierParametersInfo, setCarrierParametersInfo] =
    useState<IParametersOfAddTariffs[]>(carrierParametersOfAddTariffs);
  const [supplierParametersInfo, setSupplierParametersInfo] =
    useState<IParametersOfAddTariffs[]>(supplierParametersOfAddTariffs);
  const [imagesScan, setImagesScan] = useState([]);
  const [uploadImagesScan, setUploadImagesScan] = useState([]);
  const [showUploadImages, setShowUploadImages] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let currentId: string = currentSite !== currentSiteEnum.ADD ? pathname.split('/')[2] : '';
  let tariffDetailId: string = currentSite === currentSiteEnum.EDIT ? pathname.split('/')[4] : '';
  let currentRole: string = mainParametersInfo[1].value;

  const objectCompareColumnsByRole = {
    carrier: carrierColumnsOfSupplier(dataResponseTable, setDataResponseTable),
    supplier: supplierColumnsOfSupplier(dataResponseTable, setDataResponseTable),
  };
  const objectCompareStateByRole = {
    carrier: carrierParametersInfo,
    supplier: supplierParametersInfo,
  };

  const patchTariffs = async () => {
    setLoadingSaveButton(true);
    try {
      const responsePatchTariffs = await fetchPost(
        `/admin/tariffs/${currentId}`,
        'PATCH',
        requestDataTariff(
          currentId,
          currentRole,
          mainParametersInfo,
          carrierParametersInfo,
          supplierParametersInfo,
          'patchTariffs',
        ),
      );
      if (toastError(responsePatchTariffs)) return;
      await sendUploadImages(uploadImagesScan, currentId);
      toast.success('Вы успешно сохранили изменения');
      setUploadImagesScan([]);
      setShowUploadImages([]);
      await getListDetailsTariff();
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingSaveButton(false);
    }
  };
  const patchSpecification = async () => {
    setLoadingSaveButton(true);
    try {
      const responsePatchTariff = await fetchPost(
        `/admin/tariffs/${currentId}`,
        'PATCH',
        requestDataTariff(
          currentId,
          currentRole,
          mainParametersInfo,
          carrierParametersInfo,
          supplierParametersInfo,
          'patchSpec',
          Number(tariffDetailId),
        ),
      );
      if (toastError(responsePatchTariff)) return;
      await sendUploadImages(uploadImagesScan, currentId);
      toast.success('Вы успешно сохранили изменения');
      navigate(`/tariffs/${currentId}`);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingSaveButton(false);
    }
  };
  const addTariff = async () => {
    setLoadingSaveButton(true);
    try {
      const responseAddTariff = await fetchPost(
        '/admin/tariffs',
        'POST',
        requestDataTariff(
          currentId,
          currentRole,
          mainParametersInfo,
          carrierParametersInfo,
          supplierParametersInfo,
          'post',
        ),
      );
      if (toastError(responseAddTariff)) return;
      await sendUploadImages(uploadImagesScan, responseAddTariff.id);
      toast.success('Вы успешно добавили тариф');
      navigate(`/tariffs/${responseAddTariff.id}`);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingSaveButton(false);
    }
  };
  const addSpecification = async () => {
    setLoadingSaveButton(true);
    try {
      const responseAddSpecification = await fetchPost(
        '/admin/tariffs/detail',
        'POST',
        requestDataTariff(
          currentId,
          currentRole,
          mainParametersInfo,
          carrierParametersInfo,
          supplierParametersInfo,
          'addSpec',
          Number(tariffDetailId),
        ),
      );
      if (toastError(responseAddSpecification)) return;
      toast.success('Вы успешно добавили спецификацию');
      navigate(`/tariffs/${currentId}`);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingSaveButton(false);
    }
  };
  const getListDetailsTariff = async () => {
    setLoadingTariff(true);
    try {
      const responseDetailsTariff: IResponseDetailsTariff = await fetchGet(
        `/admin/tariffs/${Number(currentId)}`,
        currentSite === currentSiteEnum.EDIT && { tariffDetailId: tariffDetailId },
      );
      // Делаем так, чтобы tariffDetails был массивом, ибо в одном месте бэк возвращает объект - в другом уже массив
      const responseDetailsTariffAsArray: IResponseDetailsTariffWithArray = {
        ...responseDetailsTariff,
        tariffDetails: Array.isArray(responseDetailsTariff.tariffDetails) && responseDetailsTariff.tariffDetails,
      };

      setMainParametersInfo(disableInputsInMainParametersArray(currentSite)); /*дизейблим поля*/
      let role = responseDetailsTariff.company.type;
      await getMainValue(responseDetailsTariff, setMainParametersInfo, role);
      if (currentSite === currentSiteEnum.EDIT) {
        role === 'carrier' &&
          (await getCarrierValue(
            responseDetailsTariff,
            setCarrierParametersInfo,
            mainParametersInfo,
            carrierParametersInfo,
          ));
        role === 'supplier' &&
          (await getSupplierValue(
            responseDetailsTariff,
            mainParametersInfo,
            supplierParametersInfo,
            setSupplierParametersInfo,
          ));
      }
      currentSite === currentSiteEnum.ID &&
        setDataResponseTable(mapDataForTable(responseDetailsTariffAsArray, currentId));
      await getImages(responseDetailsTariff, setImagesScan);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTariff(false);
    }
  };
  const getListDetailsInAddSpec = async () => {
    setLoadingTariff(true);
    try {
      const responseDetailsTariff: IResponseDetailsTariff = await fetchGet(`/admin/tariffs/${Number(currentId)}`, {});
      setMainParametersInfo(disableInputsInMainParametersArray(currentSite)); /*дизейблим поля*/
      let role = responseDetailsTariff.company.type;
      await getMainValue(responseDetailsTariff, setMainParametersInfo, role);
      if (role === 'supplier') {
        await getListQuarrySupplier(mainParametersInfo, setSupplierParametersInfo, responseDetailsTariff.company.id);
      }
      if (role === 'carrier') {
        await getListMaterialsCarrier(setCarrierParametersInfo);
        await getListSemitrailers(setCarrierParametersInfo);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTariff(false);
    }
  };
  const isParameterInvalid = (parameter: IParametersOfAddTariffs): boolean => {
    if (!parameter.required) {
      return false;
    }
    return (
      parameter.value === '' ||
      parameter.valueTo === '' ||
      parameter.valueFrom === '' ||
      parameter.date === ('' || null)
    );
  };
  const disableSaveButton = (): boolean => {
    const hasInvalidMainParameters = mainParametersInfo.some((parameter: IParametersOfAddTariffs) =>
      isParameterInvalid(parameter),
    );
    const hasInvalidRoleParameters =
      currentSite === currentSiteEnum.ADD &&
      objectCompareStateByRole[currentRole]?.some((parameter: IParametersOfAddTariffs) =>
        isParameterInvalid(parameter),
      );
    return hasInvalidMainParameters || hasInvalidRoleParameters || loadingSaveButton;
  };
  useEffect(() => {
    if (currentSite === currentSiteEnum.ID || currentSite === currentSiteEnum.EDIT) {
      getListDetailsTariff();
    }
    if (currentSite === currentSiteEnum.ADD_SPEC) {
      getListDetailsInAddSpec();
    }
    if (currentSite === currentSiteEnum.ADD) {
      getListOrganization(setMainParametersInfo);
    }
  }, []);
  useEffect(() => {
    if (loadingTariff) return;
    currentRole && currentSite === currentSiteEnum.ADD && getListCompany(currentRole, setMainParametersInfo);
    if (currentRole === 'carrier' && currentSite !== currentSiteEnum.ID) {
      getListMaterialsCarrier(setCarrierParametersInfo);
      getListSemitrailers(setCarrierParametersInfo);
    }
  }, [currentRole]);
  useEffect(() => {
    if (loadingTariff) return;
    if (mainParametersInfo[2].value && currentRole === 'supplier' && currentSite !== currentSiteEnum.ID) {
      getListQuarrySupplier(mainParametersInfo, setSupplierParametersInfo);
    }
  }, [mainParametersInfo[2]]);
  useEffect(() => {
    if (loadingTariff) return;
    if (carrierParametersInfo[0].value && currentRole === 'carrier') {
      getListQuarryCarrier(carrierParametersInfo, setCarrierParametersInfo);
    }
  }, [carrierParametersInfo[0]]);
  useEffect(() => {
    if (loadingTariff) return;
    if (supplierParametersInfo[0].value) {
      getListMaterialsByQuarry(supplierParametersInfo, setSupplierParametersInfo);
    }
  }, [supplierParametersInfo[0]]);
  const handleSaveButtonOnClick = {
    [currentSiteEnum.ADD]: addTariff,
    [currentSiteEnum.ID]: patchTariffs,
    [currentSiteEnum.EDIT]: patchSpecification,
    [currentSiteEnum.ADD_SPEC]: addSpecification,
  };
  return (
    <PageWrapper>
      <div className={styles.topContainer}>
        <BackButton textButton={'Вернуться к тарифам'} onClick={() => navigate('/tariffs')} />
        <div className={styles.titleAndSaveButtonContainer}>
          <TitleMain fontSize={'24px'} title={objectCompareMainTitle(currentId)[currentSite]} />
          <div className={styles.buttonsContainer}>
            {currentSite === currentSiteEnum.ID && (
              <ButtonNotFilled svg={<CopySvg />} width={'158px'} text={'Создать копию'} sizeText={'small'} />
            )}
            <ButtonFilled
              sizeText={'small'}
              onClick={handleSaveButtonOnClick[currentSite]}
              text={'Сохранить'}
              width={'140px'}
              disabled={disableSaveButton()}
            />
          </div>
        </div>
      </div>
      {loadingTariff ? (
        <LoadingSpin />
      ) : (
        <>
          <InputsMapping
            currentSite={currentSite}
            carrierParametersInfo={carrierParametersInfo}
            setCarrierParametersInfo={setCarrierParametersInfo}
            supplierParametersInfo={supplierParametersInfo}
            setSupplierParametersInfo={setSupplierParametersInfo}
            mainParametersInfo={mainParametersInfo}
            setMainParametersInfo={setMainParametersInfo}
            role={'main'}
          />
          {currentSite !== currentSiteEnum.ADD_SPEC && (
            <div style={{ width: '350px' }}>
              <InputFile
                titleText={'Прикрепить файл скана спецификации'}
                subTitleText={''}
                formData={true}
                images={imagesScan}
                setImages={setImagesScan}
                uploadImages={uploadImagesScan}
                setUploadImages={setUploadImagesScan}
                showUploadImages={showUploadImages}
                setShowUploadImages={setShowUploadImages}
              />
            </div>
          )}
          <div className={styles.subTitleContainer}>
            {currentSite !== currentSiteEnum.ID && (
              <>
                <div className={styles.idSubtitleContainer}>
                  <p className={styles.subTitleText}>Параметры</p>
                </div>
                <InputsMapping
                  currentSite={currentSite}
                  carrierParametersInfo={carrierParametersInfo}
                  setCarrierParametersInfo={setCarrierParametersInfo}
                  supplierParametersInfo={supplierParametersInfo}
                  setSupplierParametersInfo={setSupplierParametersInfo}
                  mainParametersInfo={mainParametersInfo}
                  setMainParametersInfo={setMainParametersInfo}
                  role={currentRole}
                />
              </>
            )}
            {currentSite === currentSiteEnum.ID && (
              <>
                <div className={styles.idSubtitleContainer}>
                  <p className={styles.subTitleText}>Спецификации к тарифу</p>
                  <ButtonSimple
                    text={'Добавить спецификацию'}
                    onClick={() => navigate('add_specification')}
                    svg={<PlusSvg width={'14px'} />}
                  />
                </div>
                <div className={styles.customTableContainer}>
                  <CustomTable columns={objectCompareColumnsByRole[currentRole]} data={dataResponseTable} />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </PageWrapper>
  );
};

export default AdminTariffsAddPage;
