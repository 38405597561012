import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';

type TCreateEditButton = { isCreatePage: boolean; disabled: boolean; createShowcase: () => void };

export const CreateEditButton = ({ isCreatePage, disabled, createShowcase }: TCreateEditButton) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <ButtonFilled
      onClick={createShowcase}
      svg={null}
      disabled={disabled}
      text={isCreatePage ? 'Сохранить и продолжить' : 'Сохранить изменения'}
      width={isMobile ? '100%' : isCreatePage ? '201px' : '178px'}
      height="40px"
      sizeText="small"
    />
  );
};
