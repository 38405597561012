import React, { useEffect, useState } from 'react';
import styles from './IndividualMaterialPage.module.scss';
import { PageWrapper } from '../../../components/PageWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    convertNameFromValueMaterial,
    convertValueFromNameMaterial,
    optionsKindOfMaterial,
} from '../constans';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../../components/UI/svg-icon/NewPlusSvg';
import { CustomSelect } from '../../../components/UI/select/CustomSelect';
import { useMediaQuery } from 'react-responsive';
import { fetchGet } from '../../../common/proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import { IMaterials, IOptions } from '../type';
import { clearObject } from '../../../common/ClearObject.helper';
import { MaterialItem } from '../components/MaterialCatalog/MaterialItem/MaterialItem';
import { toastError } from 'src/common/toastError.helper';
const IndividualMaterialPage = () => {
    const { pathname } = useLocation();
    const currentMaterial = pathname.split('/')[pathname.split('/').length-3];
    const currentShowcaseId = pathname.split('/')[pathname.split('/').length-1];
    const [isFirstRender,setIsFirstRender] = useState<number>(0);
    const [materials, setMaterials] = useState<IMaterials[]>([]);
    const [materialKind,setMaterialKind] = useState<IOptions>(optionsKindOfMaterial[currentMaterial][0]);

    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const getMaterialById = async () => {
        const kind =localStorage.getItem('currentKindMaterialForSelect');
        try {
            const requestOption={
                breed:convertNameFromValueMaterial[currentMaterial],
                showcaseId:currentShowcaseId,
                kind:(!isFirstRender?kind:materialKind.value) || undefined
            };
            const response = await fetchGet(`/supplier/materials`, clearObject(requestOption));
            const responseMaterial:IMaterials[] = response?.response?.map(material=>{
                return {
                    ...material,
                    idShowCase:currentShowcaseId,
                };
            });
            setMaterials(responseMaterial);
            if (toastError(response)) return;
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(()=>{
        getMaterialById();
        const kind =localStorage.getItem('currentKindMaterialForSelect');
        if (!kind) {
            setMaterialKind({ value: undefined, label: 'Все' },);
            return;
        }
        setMaterialKind({ value: kind, label: kind },);

    },[]);

    useEffect(()=>{
        if(isFirstRender>=1){
            getMaterialById();
        }
        setIsFirstRender(isFirstRender+1);
    },[materialKind]);

    return (
        <PageWrapper>
            <div className={styles.container}>
                <div className="button-back" onClick={()=>navigate(`/showcases/${currentShowcaseId}`)}>
                    <div className="button-back__svg">
                        <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    Вернуться к витрине
                </div>
                <div className={styles.topTitleContainer}>
                    <div className={styles.titleAndButton}>
                        <p className={styles.titleText}>{convertNameFromValueMaterial[currentMaterial]}</p>
                        {!isMobile &&
                            <ButtonFilled
                                svg={<NewPlusSvg/>}
                                text={'Добавить материал'}
                                width={'195px'}
                                sizeText={'small'}
                                onClick={()=>navigate(`/showcases/materials/${currentMaterial}/${currentShowcaseId}`)}
                            />

                        }
                    </div>
                    <div className={styles.countProductContainer}>
                        Всего {materials?.length} товара
                    </div>
                </div>
                {isMobile &&
                    <ButtonFilled
                        svg={<NewPlusSvg/>}
                        text={'Добавить материал'}
                        width={'100%'}
                        sizeText={'small'}
                        onClick={()=>navigate(`/showcases/materials/${currentMaterial}/${currentShowcaseId}`)}
                    />
                }
                <div className={styles.contentContainer}>
                    <div className={styles.selectAndTextContainer}>
                        <p className={styles.textSelect}>Выберите вид</p>
                        <div className={styles.selectContainer}>
                            <CustomSelect
                                options={optionsKindOfMaterial[currentMaterial]}
                                setValue={setMaterialKind}
                                placeholder={''}
                                isClearable={false}
                                error={false}
                                isSearchable={false}
                                noOptionsMessage={''}
                                isDisabled={false}
                                value={materialKind}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.cardsContainer}>
                    {materials?.map((material)=>(
                        <MaterialItem
                            material={material}
                            link={`/showcases/materials/edit/${convertValueFromNameMaterial[material.breed]}/${material.id}/${material.idShowCase}`}/>
                    ))}
                </div>
            </div>
        </PageWrapper>
    );
};

export default IndividualMaterialPage;