import React, { useEffect, useRef, useState } from 'react';
import styles from './ComparsionsPage.module.scss';
import { PageWrapper } from 'src/components/PageWrapper';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import { useMediaQuery } from 'react-responsive';
import { Basket } from 'src/components/UI/svg-icon/Basket';
import cx from 'classnames';
import { InputText } from 'src/components/UI/inputs/InputText';
import { CustomSelect } from 'src/components/UI/select/CustomSelect';
import { fetchGet, fetchPost } from 'src/common/proxy-1C-fetch-auth';
import { toastError } from 'src/common/toastError.helper';
import { ComparsionTable } from './ComparsionTable/ComparsionTable';
import { CloseSvg } from 'src/components/UI/svg-icon/CloseSvg';
import { TComparsions } from '../catalogTypes';
import { clearObject } from 'src/common/ClearObject.helper';

export const ComparsionsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [activeTab, setActiveTab] = useState<string>('');
  const [tabs, setTabs] = useState<string[]>([]);
  const [materials, setMaterials] = useState<TComparsions>([]);
  const [objectPlace, setObjectPlace] = useState<{ value: string; label: string } | null>(null);
  const [address, setAddress] = useState<string>('');
  const scrollRef = useRef(null);
  const activeTabRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(1);

  useEffect(() => {
    const savedTab = localStorage.getItem('savedTab');
    setActiveTab(savedTab !== null ? JSON.parse(savedTab) : tabs[0]);
    getTabs();
  }, []);

  const handleMouseDown = e => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 1.5;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };
  useEffect(() => {
    if (activeTabRef.current && scrollRef.current) {
      const activeTabRect = activeTabRef.current.getBoundingClientRect();
      const containerRect = scrollRef.current.getBoundingClientRect();
      if (activeTabRect.right > containerRect.right) {
        scrollRef.current.scrollLeft += activeTabRect.right - containerRect.right + 200;
      } else if (activeTabRect.left < containerRect.left) {
        scrollRef.current.scrollLeft -= containerRect.left - activeTabRect.left;
      }
    }
    setMaterials([]);
    if (tabs.length) getTabMaterials();
  }, [activeTab, tabs]);

  const getTabs = async () => {
    try {
      const response = await fetchGet('/users/comparisons', {});
      if (toastError(response)) return;
      setTabs(response?.response);
      setActiveTab(response?.response[0] || '');
    } catch (e) {
      console.log(e);
    }
  };

  const getTabMaterials = async () => {
    try {
      const response = await fetchGet('/users/comparisons/subcategory', { subcategory: activeTab });
      if (toastError(response)) return;
      setMaterials(response?.response);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteItem = async ({ id = undefined, category }: { id?: number; category: string }) => {
    try {
      const response = await fetchPost(
        '/users/comparisons',
        'DELETE',
        clearObject({
          category,
          id,
          subcategory: category === 'materialGroups' ? activeTab : undefined,
        }),
      );
      if (toastError(response)) return;
      if (category === 'materials' || category === 'all') getTabMaterials();
      if (category === 'materialGroups' || materials?.length <= 1) getTabs();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageWrapper>
      <div className={styles.container}>
        <div className={styles.titleAndButtonContainer}>
          <div className={'textH4 textH4_medium'}>Товары в сравнении</div>
          <ButtonNotFilled
            svg={<Basket />}
            widthSvg={!isMobile ? '24px' : '16px'}
            width={!isMobile ? '142px' : '32px'}
            height={!isMobile ? '40px' : '32px'}
            sizeText={'small'}
            text={!isMobile ? 'Удалить все' : ''}
            onClick={() => deleteItem({ category: 'all' })}
          />
        </div>
        <div className={styles.deliveryContainer}>
          {!isMobile && <div className={styles.deliveryTitle}>Выберите объект доставки</div>}
          <div className={styles.deliverySubcontainer}>
            <div className={styles.deliveryText}>{isMobile ? 'Выберите объект доставки' : 'Куда'}</div>
            <div className={styles.inputsContainer}>
              <CustomSelect
                options={[
                  { value: 'Адрес1', label: 'Адрес1Адрес1' },
                  { value: 'Адрес2', label: 'Адрес2' },
                ]}
                maxTagCount={1}
                maxCount={1}
                placeholder={'Выберите объект из списка'}
                mode={null}
                value={objectPlace}
                setValue={value => setObjectPlace(value)}
                disabled={false}
                status={''}
                errorText={''}
                isClearable={false}
                error={false}
                isSearchable={false}
                noOptionsMessage={''}
                height40px={isMobile}
                style={{ margin: '0' }}
              />
              <InputText
                isLabel={true}
                margin={''}
                error={false}
                type={'text'}
                value={address}
                textError={''}
                placeholder={'Адрес объекта'}
                setValue={value => setAddress(value)}
                disabled={true}
                helpText={''}
                height40px={isMobile}
              />
            </div>
          </div>
        </div>
        <div
          className={styles.tabsContainer}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          style={{ overflowX: 'auto', cursor: isDragging ? 'grabbing' : 'grab' }}
          ref={scrollRef}
        >
          {tabs.map((tab, index) => {
            return (
              <div
                key={`tab${index}`}
                className={cx(styles.tab, activeTab === tab && styles.activeTab)}
                onClick={() => {
                  setActiveTab(tab);
                }}
                ref={activeTab === tab ? activeTabRef : null}
              >
                <span>{tab}</span>
                <div
                  className={styles.deleteTab}
                  onClick={e => {
                    e.stopPropagation();
                    deleteItem({ category: 'materialGroups' });
                  }}
                >
                  <CloseSvg width="16px" />
                </div>
              </div>
            );
          })}
        </div>
        <ComparsionTable comparsions={materials} deleteItem={deleteItem} />
      </div>
    </PageWrapper>
  );
};
