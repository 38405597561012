import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../../components/PageWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import TitleMain from '../../../components/UI/TitleAndSubtitle/TitleMain';
import styles from './OrderMaterialPage.module.scss';
import { regForNumberWithComma, svgByMaterial } from '../../ShowcasesPage/constans';
import {
    cartOrder,
    infoArrayOrderCard,
    InitStateCurrentMaterialData,
    initStateOrderInputs, optionsDays,
    optionsPayment, sellUnitView
} from '../constans';
import CustomInput from '../../../components/newUI/CustomInput/CustomInput';
import { CustomSelect } from '../../../components/UI/select/CustomSelect';
import CustomRangePicker from '../../../components/newUI/CustomRangePicker/CustomRangePicker';
import { CheckBoxCustom } from '../../../components/UI/checkBox/CheckBoxCustom';
import { ICheckBox } from '../../ShowcasesPage/type';
import CustomDatePicker from '../../../components/newUI/CustomDatePicker/CustomDatePicker';
import CustomTooltip from '../../../components/newUI/CustomTooltip/CustomTooltip';
import QuestionOutlineSvg from '../../../components/UI/svg-icon/QuestionOutlineSvg';
import TextArea from 'antd/es/input/TextArea';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import { useMediaQuery } from 'react-responsive';
import { fetchGet, fetchPost } from '../../../common/proxy-1C-fetch-auth';
import { ICurrentMaterialData, IObjectItems, IOrderInputs } from '../type';
import { clearObject } from '../../../common/ClearObject.helper';
import { toast } from 'react-toastify';
import { SelectCustom } from '../../../components/UI/select/SelectCustom';
import SuccessSvgVer2 from '../../../components/UI/svg-icon/SuccessSvgVer2';
const OrderMaterialPage = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const currentId = pathname.split('/')[4];
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [periodCheckBox, setPeriodCheckBox] = useState<ICheckBox>({ id: 'period', active: false });
    const [orderInputs,setOrderInputs] = useState<IOrderInputs>(initStateOrderInputs);
    const [currentMaterialData,setCurrentMaterialData] = useState<ICurrentMaterialData>(InitStateCurrentMaterialData);
    const [objectItems, setObjectItems] = useState<IObjectItems[]>([]);
    const [openCalendar, setOpenCalendar] = useState<boolean>(false);
    const [loadingPostOrder,setLoadingPostOrder] = useState<boolean>(false);
    const [successOrder,setSuccessOrder] = useState<boolean>(false);
    const pickCheckPeriod = (id:string):void => {
        setPeriodCheckBox(prevState => ({ id: id, active: !prevState.active }));
    };
    function minusHours(date:Date, hours:number) {
        date.setTime(date.getTime() - hours * 60 * 60 * 1000);

        return date;
    }
    const disableOrderButton = (obj:IOrderInputs):boolean => {
        let mainInputs = Object.entries(obj).some(([key,value]) => {
            if (key === 'comment') {
                return false;
            }
            return !value;
        });

        if (periodCheckBox.active){
            return !(orderInputs.delivery.startDate && orderInputs.delivery.dayVolume &&
                orderInputs.delivery.daysOfWeek.length && orderInputs.delivery.finishDate && !mainInputs);
        }
        return !(orderInputs.delivery.finishDate && !mainInputs);
    };
    const getData = async () => {
        try {
            const response = await fetchGet(`/buyer/products/${currentId}`,{});
            if (response.error) {
                const errorMessages = response.message.map(message => {
                    return(
                        <p>
                            {message}
                            <br/>
                            <br/>
                        </p>
                    );
                });
                toast.error(<div>{errorMessages}</div>);
                return;
            }
            const selectedUnit = await fetchGet('/buyer/carts',{});
            setCurrentMaterialData({ ...response?.response,selectedUnit:selectedUnit?.response?.unit });
            const objects = await fetchGet(`/objects`, {});
            setObjectItems(objects?.response?.map((object)=>{
                return{
                    value:object?.id,
                    label:object?.name,
                    address:object?.address?.address
                };
            }));
            
        } catch (e){
            console.log(e);
        }
    };
    const postOrder = async () => {
        let date = new Date();
        let currentTimeZoneOffsetInHours = date.getTimezoneOffset() / 60;
        const correctFinishDate=new Date(orderInputs.delivery.finishDate);
        const correctStartDate=new Date(orderInputs.delivery.startDate);

        setLoadingPostOrder(true);
        const requestOptions = {
            payment: orderInputs.payment,
            comment: orderInputs.comment || undefined,
            volume: Number(orderInputs.volume.replaceAll(',','.')),
            objectId: orderInputs.objectId,
            materialId: Number(currentId),
            unit:currentMaterialData?.selectedUnit,
            delivery: {
                interval: periodCheckBox.active,
                startDate: orderInputs.delivery.startDate ? minusHours(correctStartDate, currentTimeZoneOffsetInHours): undefined,
                finishDate: orderInputs.delivery.finishDate ? minusHours(correctFinishDate,currentTimeZoneOffsetInHours): undefined,
                dayVolume: Number(orderInputs.delivery.dayVolume.replaceAll(',','.')) || undefined,
                daysOfWeek: orderInputs.delivery.daysOfWeek.length ? orderInputs.delivery.daysOfWeek.map((item)=>{
                    return item.value;
                }) : undefined
            }
        };
        clearObject(requestOptions);
        clearObject(requestOptions.delivery);
        try {
            const response = await fetchPost('/orders','POST',requestOptions);
            if (response?.error) {
                setLoadingPostOrder(false);
                const errorMessages = response.message.map(message => {
                    return(
                        <p>
                            {message}
                            <br/>
                            <br/>
                        </p>
                    );
                });
                toast.error(<div>{errorMessages}</div>);
                return;
            }
            toast.success('Вы успешно оформили заказ');
            setLoadingPostOrder(false);
            setSuccessOrder(true);
        } catch (e) {
            setLoadingPostOrder(false);
            console.log(e);
        }
    };
    useEffect(()=>{
        isMobile && (document.getElementById('PageWrapper').style.backgroundColor = '#F5F6F8');
        getData();
    },[]);
    useEffect(()=>{
        setOrderInputs(prevState => ({ ...prevState,delivery:initStateOrderInputs.delivery }));
    },[periodCheckBox]);

    useEffect(()=>{
        disableOrderButton(orderInputs);
    },[orderInputs]);

    return (
        <PageWrapper>
            {successOrder ?
                <div className={styles.successContainer}>
                    <SuccessSvgVer2/>
                    <div className={styles.successContent}>
                        <p className={styles.successThickText}>Заказ успешно оформлен</p>
                        <p className={styles.successSoftText}>Статус ваших заказов можете отслеживать на странице Заказы</p>
                    </div>
                    <ButtonFilled
                        onClick={()=> {
                            navigate('/catalog');
                            setSuccessOrder(false);
                        }}
                        text={'Заказать еще материал'}
                        width={isMobile?'226px':'290px'}
                    />
                </div>
                :
                <div className={styles.container}>
                    <div className={styles.titleAndBackButtonContainer}>
                        <div className="button-back" onClick={()=>navigate(-1)}>
                            <div className="button-back__svg">
                                <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                            Вернуться к материалу
                        </div>
                        <TitleMain title={'Оформление'}/>
                    </div>
                    <div className={styles.cardAndTitleContainer}>
                        <p className={styles.titleCard}>Выбранные товары</p>
                        <div className={styles.cardContainer}>
                            <div style={{ width:isMobile?'55px':'240px', flex:isMobile&&'0 0 auto' }}>{svgByMaterial[currentMaterialData.breed]}</div>
                            <div className={styles.infoArrayContainer}>
                                {infoArrayOrderCard(currentMaterialData).map((info,index)=>{
                                    return (
                                        <div key={index} className={styles.strokeInfoContent}>
                                            {info.title!=='Наименование:' &&
                                                <>
                                                    <p className={styles.infoContent}>{info.title}</p>
                                                    <p className={styles.downBorder} />
                                                </>
                                            }
                                            <p className={info.title==='Фракция:'?styles.fractionInfoContent:styles.infoContent}>
                                                {info.value}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={styles.orderContainer}>
                        <div className={styles.contentOrderContainer}>
                            {!isMobile && <p className={styles.titleCard}>Выберите объем материала и адрес доставки</p>}
                            <div className={styles.volumeAndAddressContainer}>
                                <div className={styles.textAndInputOfTheMaterial} style={{ width:isMobile?'100%':'142px' }}>
                                    <p className={styles.textInput}>Объем материала, {sellUnitView[currentMaterialData?.selectedUnit]}</p>
                                    <CustomInput
                                        setValue={e=> {
                                            if (regForNumberWithComma.test(e) || e === ''){
                                                setOrderInputs(prevState => ({ ...prevState, volume: e }));
                                            }
                                        }}
                                        value={orderInputs.volume}
                                        placeholder={'0'}
                                        disabled={false}/>
                                </div>
                                <div className={styles.textAndInputOfTheMaterial}>
                                    <p className={styles.textInput}>Куда</p>
                                    <div className={styles.inputsAddressOfTheMaterialContainer}>
                                        <CustomSelect
                                            error={false}
                                            isDisabled={false}
                                            setValue={e=>setOrderInputs(prevState => ({ ...prevState,objectId:e.value,address:e.address }))}
                                            options={objectItems}
                                            placeholder={'Выберите объект из списка'}
                                            noOptionsMessage={'Нет вариантов'}
                                            isSearchable={true}
                                            isClearable={false}
                                        />
                                        <CustomInput value={orderInputs.address} placeholder={'Адрес объекта'} disabled={true}/>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.dateVolumeDaysContainer}>
                                <div style={{ width:isMobile?'100%':'auto' }} className={styles.textAndInputOfTheMaterial}>
                                    <p className={styles.textInput}>Дата доставки</p>
                                    <div className={styles.dateTransport}>
                                        {!periodCheckBox.active ?
                                            <CustomDatePicker
                                                plusOneDayToApplyButton={true}
                                                minDate={new Date(new Date().setDate(new Date().getDate() +1)).toISOString().split('T')[0]}
                                                height={'48px'}
                                                width={isMobile?'100%':'244px'}
                                                placeholder={'Дата'}
                                                value={orderInputs.delivery.finishDate}
                                                setValue={(e)=>setOrderInputs(prevState =>
                                                    ({ ...prevState,delivery:{ ...prevState.delivery,finishDate:e } }))}
                                            />
                                            :
                                            <CustomRangePicker
                                                plusOneDayToApplyButton={true}
                                                disabledDateBefore={true}
                                                isPresets={true}
                                                dateRange={[orderInputs.delivery.startDate,orderInputs.delivery.finishDate]}
                                                setDateRange={e=>setOrderInputs(prevState =>
                                                    ({ ...prevState,delivery:{ ...prevState.delivery,startDate:e[0],finishDate:e[1] } }))}
                                                open={openCalendar}
                                                setOpen={setOpenCalendar}
                                                placeholder={['Дата', 'Дата']}
                                                width={isMobile?'100%':'244px'}
                                            />
                                        }
                                        <CheckBoxCustom
                                            children={'Период'}
                                            error={false}
                                            check={periodCheckBox.active}
                                            pick={pickCheckPeriod}
                                            name={'period'}
                                            id={'period'}
                                        />
                                    </div>
                                </div>
                                {periodCheckBox.active &&
                                    <>
                                        <div className={styles.textAndInputOfTheMaterial} style={{ width:isMobile?'100%':'142px' }}>
                                            <p className={styles.textInput}>Суточный объем, м³</p>
                                            <CustomInput
                                                setValue={e=> {
                                                    if (regForNumberWithComma.test(e) || e === ''){
                                                        setOrderInputs(prevState => 
                                                            ({ ...prevState, delivery:{ ...prevState.delivery,dayVolume:e } }));
                                                    }
                                                }}
                                                value={orderInputs.delivery.dayVolume}
                                                placeholder={'0'}
                                                disabled={false}/>
                                        </div>
                                        <div className={styles.textAndInputOfTheMaterial} style={{ width:isMobile?'100%':'246px' }}>
                                            <p className={styles.textInput}>Выберите дни приёмки</p>
                                            <SelectCustom
                                                style={{ height:'48px',width:'100%' }}
                                                options={optionsDays}
                                                maxTagCount={isMobile?4:3}
                                                placeholder={'День'}
                                                mode={'multiple'}
                                                value={orderInputs.delivery.daysOfWeek}
                                                setValue={e=>setOrderInputs(prevState =>
                                                    ({ ...prevState,delivery:{ ...prevState.delivery,daysOfWeek:e } }))}
                                                disabled={false}
                                                status={''}
                                                errorText={''}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                            <div className={styles.textAndInputOfTheMaterial}>
                                <p className={styles.textInput}>Комментарий к заказу</p>
                                <TextArea value={orderInputs.comment}
                                          className={styles.textAreaContainer}
                                          showCount
                                          maxLength={300}
                                          placeholder={'Напишите условия доставки'}
                                          onChange={(e)=>setOrderInputs(prevState => ({ ...prevState,comment:e.target.value }))}
                                          disabled={false}
                                />
                            </div>
                        </div>
                        <div className={styles.priceListContainer}>
                            {!isMobile && <p className={styles.titleCard}>Ваш заказ</p>}
                            <div style={{ gap:'16px' }} className={styles.infoArrayContainer}>
                                {cartOrder(currentMaterialData,orderInputs).map((cart,index)=>{
                                    return (
                                        <div key={index} className={styles.strokeInfoContent}>
                                            <p className={styles.cartTitle}>{cart.title}</p>
                                            <div style={{ whiteSpace:'normal' }}>
                                                {cart.title === 'Итоговая цена' &&
                                                    <CustomTooltip
                                                        svg={<QuestionOutlineSvg />}
                                                        text={'Итоговая цена зависит от выбранного вами объекта'}
                                                        widthSvg={'24px'}
                                                        widthTooltip={isMobile?'220px':'400px'}
                                                        SideTooltip={'up'}
                                                    />
                                                }
                                            </div>

                                            <p className={styles.downBorder} />
                                            <p className={styles.cartValue}>
                                                {cart.value}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                            {!isMobile &&
                                <>
                                    <div className={styles.textAndInputOfTheMaterial}>
                                        <p className={styles.textInput}>Способ оплаты</p>
                                        <CustomSelect
                                            error={false}
                                            isDisabled={false}
                                            setValue={e=>setOrderInputs(prevState => ({ ...prevState,payment:e.value }))}
                                            options={optionsPayment}
                                            placeholder={'Выберите ТС'}
                                            noOptionsMessage={'Нет вариантов'}
                                            isSearchable={false}
                                            isClearable={false}
                                        />
                                    </div>
                                    <ButtonFilled
                                        text={'Оформить заказ'}
                                        width={'100%'}
                                        onClick={()=>postOrder()}
                                        disabled={disableOrderButton(orderInputs) || loadingPostOrder}
                                    />
                                </>
                            }
                        </div>
                        {isMobile &&
                            <>
                                <div className={styles.textAndInputOfTheMaterial}>
                                    <p className={styles.textInput}>Способ оплаты</p>
                                    <CustomSelect
                                        error={false}
                                        isDisabled={false}
                                        setValue={e=>setOrderInputs(prevState => ({ ...prevState,payment:e.value }))}
                                        options={optionsPayment}
                                        placeholder={'Выберите ТС'}
                                        noOptionsMessage={'Нет вариантов'}
                                        isSearchable={false}
                                        isClearable={false}
                                    />
                                </div>
                                <ButtonFilled
                                    text={'Оформить заказ'}
                                    width={'100%'}
                                    onClick={()=>postOrder()}
                                    disabled={disableOrderButton(orderInputs) || loadingPostOrder}
                                />
                            </>
                        }
                    </div>
                </div> 
            }
        </PageWrapper>
    );
};

export default OrderMaterialPage;