import React from 'react';
import { ConfigProvider, Table } from 'antd';
import './CustomTable.css';
import { useNavigate } from 'react-router-dom';
import NewEmptySvg from '../../UI/svg-icon/NewEmptySvg';

// @ts-ignore
import LoadingGif from '../../../assets/img/Loader-Solber.gif';
import img from "../../../assets/img/EmptyPng.png";
interface IProps {
  setOpenModal?: any;
  isModal?: boolean;
  getRowData?: any;
  columns?: any;
  data?: any;
  link?: boolean;
  loading?: boolean;
  isRowRedData?: string;
  emptyTitle?: string;
  emptyFilterTitle?:string
  emptyDescription?: string;
  activityFilter?:boolean;
  linkTextBeforeId?:string;
}
const CustomTable: React.FC<IProps> = ({
  columns,
  emptyDescription = 'По выбранным фильтрам нет документов.',
  emptyTitle = 'Документов не найдено.',
  emptyFilterTitle = 'Сформируйте историю расчетов',
  data,
  link,
  linkTextBeforeId,
  loading = false,
  setOpenModal,
  isModal,
  getRowData,
  isRowRedData,
  activityFilter=true,
}) => {
  const navigate = useNavigate();
  const isRowRed = rowData => {
    if (rowData[isRowRedData]) {
      return 'table-row-red';
    } else {
      return 'table-row-light';
    }
  };
  const Spin = () => {
    return (
      <div style={{ marginTop: '160px' }}>
        <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
      </div>
    );
  };
  return (
    <ConfigProvider
      renderEmpty={() => (
        <div>
            {loading ?
                <div style={{ height:'330px' }}/>
                :
                <div className={'emptyContentContainer'}>
                  <div
                    style={{
                      marginTop: '-100px',
                    }}
                  >
                    <img src={img} alt=""/>
                  </div>
                  <div
                    style={{
                      marginTop: '-120px',
                    }}
                  >
                    {activityFilter
                        ?
                        <>
                          <h4 className={'emptyTitle'}>{emptyTitle}</h4>
                          <p className={'emptyDescription'}>
                            {emptyDescription}
                            <br />
                            Попробуйте изменить параметры
                          </p>
                        </>
                        :
                        <>
                          <h4 className={'emptyTitle'}>{emptyFilterTitle}</h4>
                          <p className={'emptyDescription'}>
                            Нажмите на нужный вам параметр и/или выберите дату
                          </p>
                        </>
                    }

                  </div>
                </div>
            }
        </div>
      )}
    >
      <div>
        <Table
          rowClassName={(record, index) => isRowRed(record)}
          loading={{ indicator: <Spin />, spinning: loading }}
          style={{
            background: '#EFF6FF',
          }}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                link && navigate(`${linkTextBeforeId ? linkTextBeforeId + '/' : ''}${record.id}`);
                isModal && setOpenModal(true);
                isModal && getRowData(record);
              },
            };
          }}
          columns={columns}
          dataSource={data}
        />
      </div>
    </ConfigProvider>
  );
};

export default CustomTable;
