import React, { useState } from 'react';
import { formPrice } from 'src/common/form-price.helper';
import styles from './GSMCard.module.scss';
import BackGroundGSMCardSvg from '../../../../components/UI/svg-icon/BackGroundGSMCardSvg';
import { useMediaQuery } from 'react-responsive';
import { Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import { formCardNumber } from 'src/common/formCardNumber.helper';

const GsmCard = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const onChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <>
      <div className={styles.container} onClick={() => navigate('1')}>
        <div className={styles.moneyAndActiveButtonContainer}>
          <p className={styles.textMoney}>{formPrice(8944.77, 'RUB', 'ru')}</p>
          <div className={styles.activeContainer} onClick={event => event.stopPropagation()}>
            <p className={styles.activeText}>Активна</p>
            <Switch className={styles.switcher} checked={isChecked} onChange={onChange} />
          </div>
        </div>
        <div className={styles.cardHolderAndNumberContainer}>
          <div className={styles.cardHolderContainer}>
            <p className={styles.cardHolderTitle}>Владелец</p>
            <p className={styles.cardHolderName}>Перевозчиков Перевоз Перевозович</p>
          </div>
          <p className={styles.numberText}>{formCardNumber('3455456277103507')}</p>
        </div>
        <div className={styles.backGround}>
          <BackGroundGSMCardSvg width={isMobile ? '134px' : '165px'} height={isMobile ? '153px' : '188px'} />
        </div>
      </div>
    </>
  );
};

export default GsmCard;
