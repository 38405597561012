import React from 'react';

export const GsmSvg = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="gas-outline">
              <path id="Vector" d="M19.77 7.23L19.78 7.22L16.06 3.5L15 4.56L17.11 6.67C16.17 7.03 15.5 7.93 15.5 9C15.5 9.3283 15.5647 9.65339 15.6903 9.95671C15.8159 10.26 16.0001 10.5356 16.2322 10.7678C16.7011 11.2366 17.337 11.5 18 11.5C18.36 11.5 18.69 11.42 19 11.29V18.5C19 18.7652 18.8946 19.0196 18.7071 19.2071C18.5196 19.3946 18.2652 19.5 18 19.5C17.7348 19.5 17.4804 19.3946 17.2929 19.2071C17.1054 19.0196 17 18.7652 17 18.5V14C17 13.4696 16.7893 12.9609 16.4142 12.5858C16.0391 12.2107 15.5304 12 15 12H14V5C14 4.46957 13.7893 3.96086 13.4142 3.58579C13.0391 3.21071 12.5304 3 12 3H6C5.46957 3 4.96086 3.21071 4.58579 3.58579C4.21071 3.96086 4 4.46957 4 5V21H14V13.5H15.5V18.5C15.5 19.163 15.7634 19.7989 16.2322 20.2678C16.7011 20.7366 17.337 21 18 21C18.3283 21 18.6534 20.9353 18.9567 20.8097C19.26 20.6841 19.5356 20.4999 19.7678 20.2678C19.9999 20.0356 20.1841 19.76 20.3097 19.4567C20.4353 19.1534 20.5 18.8283 20.5 18.5V9C20.5 8.31 20.22 7.68 19.77 7.23ZM12 13.5V19H6V12H12V13.5ZM12 10H6V5H12V10ZM18 10C17.7348 10 17.4804 9.89464 17.2929 9.70711C17.1054 9.51957 17 9.26522 17 9C17 8.73478 17.1054 8.48043 17.2929 8.29289C17.4804 8.10536 17.7348 8 18 8C18.2652 8 18.5196 8.10536 18.7071 8.29289C18.8946 8.48043 19 8.73478 19 9C19 9.26522 18.8946 9.51957 18.7071 9.70711C18.5196 9.89464 18.2652 10 18 10Z" fill="currentColor"/>
          </g>
      </svg>
  );
};
/*
<svg width="100%" height="100%" viewBox="0 0 17 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2691 4.23L16.2791 4.22L12.5591 0.5L11.4991 1.56L13.6091 3.67C12.6691 4.03 11.9991 4.93 11.9991 6C11.9991 6.3283 12.0638 6.65339 12.1894 6.95671C12.3151 7.26002 12.4992 7.53562 12.7314 7.76777C13.2002 8.23661 13.8361 8.5 14.4991 8.5C14.8591 8.5 15.1891 8.42 15.4991 8.29V15.5C15.4991 15.7652 15.3938 16.0196 15.2062 16.2071C15.0187 16.3946 14.7643 16.5 14.4991 16.5C14.2339 16.5 13.9796 16.3946 13.792 16.2071C13.6045 16.0196 13.4991 15.7652 13.4991 15.5V11C13.4991 10.4696 13.2884 9.96086 12.9133 9.58579C12.5383 9.21071 12.0296 9 11.4991 9H10.4991V2C10.4991 1.46957 10.2884 0.960859 9.91334 0.585786C9.53827 0.210714 9.02956 0 8.49913 0H2.49913C1.9687 0 1.45999 0.210714 1.08492 0.585786C0.709844 0.960859 0.49913 1.46957 0.49913 2V18H10.4991V10.5H11.9991V15.5C11.9991 16.163 12.2625 16.7989 12.7314 17.2678C13.2002 17.7366 13.8361 18 14.4991 18C14.8274 18 15.1525 17.9353 15.4558 17.8097C15.7592 17.6841 16.0348 17.4999 16.2669 17.2678C16.499 17.0356 16.6832 16.76 16.8088 16.4567C16.9345 16.1534 16.9991 15.8283 16.9991 15.5V6C16.9991 5.31 16.7191 4.68 16.2691 4.23ZM8.49913 10.5V16H2.49913V9H8.49913V10.5ZM8.49913 7H2.49913V2H8.49913V7ZM14.4991 7C14.2339 7 13.9796 6.89464 13.792 6.70711C13.6045 6.51957 13.4991 6.26522 13.4991 6C13.4991 5.73478 13.6045 5.48043 13.792 5.29289C13.9796 5.10536 14.2339 5 14.4991 5C14.7643 5 15.0187 5.10536 15.2062 5.29289C15.3938 5.48043 15.4991 5.73478 15.4991 6C15.4991 6.26522 15.3938 6.51957 15.2062 6.70711C15.0187 6.89464 14.7643 7 14.4991 7Z" fill="currentColor"/>
</svg>*/
