import React, { useState } from 'react';
import { PageWrapper } from '../../../components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import FormReplenishment from './components/FormReplenishment/FormReplenishment';
import { IInputsFormReplenishment } from '../type';
import { InitStateInputsFormReplenishment } from '../constans';
import NavigateFormReplenishmentsPage from './components/NavigateFormReplenishmentsPage/NavigateFormReplenishmentsPage';
import styles from './FormReplenishmentsPage.module.scss';

const FormReplenishmentsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [codeValue, setCodeValue] = useState<string>('');
  const [step, setStep] = useState<'transfer' | 'smsAuth' | 'success'>('transfer');
  const [inputsFormReplenishment, setInputsFormReplenishment] = useState<IInputsFormReplenishment>(
    InitStateInputsFormReplenishment,
  );
  return (
    <PageWrapper style={{ backgroundColor: `${isMobile ? 'white' : 'var(--color-gray50)'}` }}>
      <div className={styles.backButtonAndTitleContainer}>
        <NavigateFormReplenishmentsPage step={step} setStep={setStep} />
        <h1 className={styles.title}>Пополнение карт</h1>
      </div>
      <FormReplenishment
        inputsFormReplenishment={inputsFormReplenishment}
        setInputsFormReplenishment={setInputsFormReplenishment}
        step={step}
        setStep={setStep}
        codeValue={codeValue}
        setCodeValue={setCodeValue}
      />
    </PageWrapper>
  );
};

export default FormReplenishmentsPage;
