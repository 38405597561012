import React, { useEffect, useState } from 'react';
import styles from './GSMCardPageCard.module.scss';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { WalletGSMSvg } from 'src/components/UI/svg-icon/WalletGSMSvg';
import { formCardNumber } from 'src/common/formCardNumber.helper';
import { ModalNew } from 'src/components/ModalNew/ModalNew';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import { useMediaQuery } from 'react-responsive';
import { Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TCardInfo } from 'src/Pages/GSMNewPage/GSMtypes';
import { formPrice } from 'src/common/form-price.helper';
import { fetchPost } from 'src/common/proxy-1C-fetch-auth';
import { toastError } from 'src/common/toastError.helper';
import { ISwitcherState } from '../../type';
import { initStateSwitcherState } from '../../constans';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';

interface IProps {
  cardInfo: TCardInfo;
  setOpenModalOfCloseCard: React.Dispatch<boolean>;
}
export const GSMCardPageCard = ({ cardInfo, setOpenModalOfCloseCard }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [switchState, setSwitchState] = useState<ISwitcherState>(initStateSwitcherState);

  const switchText = switchState.check ? 'Активна' : 'Заблокирована';
  const ifCardWithSwitcher = cardInfo?.status === 'Активна' || cardInfo?.status === 'Заблокирована';
  const statusText = ifCardWithSwitcher ? switchText : cardInfo?.status;

  const switchCardStatus = async (id: number) => {
    setSwitchState(prevState => ({ ...prevState, sending: true, check: !switchState.check }));
    try {
      const responseSwitchCardStatus = await fetchPost(`/fuel-cards/switch-card-status/${id}`, 'PATCH', {
        status: switchState.check ? 'Заблокирована' : 'Активна',
      });
      if (toastError(responseSwitchCardStatus)) {
        setSwitchState(prevState => ({ ...prevState, sending: false, check: switchState.check }));
        return;
      }
      setSwitchState(prevState => ({ ...prevState, sending: false }));
    } catch (e) {
      setSwitchState(prevState => ({ ...prevState, sending: false, check: switchState.check }));
      console.log(e);
    }
  };

  const refuelLinkObj = {
    iPhone: 'https://apps.apple.com/ru/app/%D1%80%D0%BD-%D0%BA%D0%B0%D1%80%D1%82/id1438092935',
    Android: 'https://www.rustore.ru/catalog/app/ru.rncard.virtualcard',
    Other: 'https://www.rustore.ru/catalog/app/ru.rncard.virtualcard',
  };
  const getDeviceType = () => {
    const userAgent = navigator.userAgent;
    if (/iPhone/i.test(userAgent)) return 'iPhone';
    if (/Android/i.test(userAgent)) return 'Android';
    return 'Other';
  };

  useEffect(() => {
    setSwitchState(prevState => ({ ...prevState, check: cardInfo?.status === 'Активна' }));
  }, [cardInfo]);

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <span className={styles.cardNumber}>{formCardNumber(cardInfo?.cardNumber)}</span>
        <div className={styles.activeContainer}>
          <p className={styles.activeText}>{statusText}</p>
          {ifCardWithSwitcher && (
            <Switch
              disabled={switchState.sending}
              className={styles.switcher}
              checked={switchState.check}
              onChange={() => switchCardStatus(cardInfo?.id)}
            />
          )}
        </div>
      </div>
      <div className={styles.priceAndCardHolderContainer}>
        <div className={styles.priceContainer}>
          <div style={{ paddingTop: '4px' }}>
            <WalletGSMSvg />
          </div>
          <span className={styles.price}>{formPrice(cardInfo?.limit?.current || 0, 'RUB', 'ru')}</span>
        </div>
        <div className={styles.cardHolderContainer}>
          <p className={styles.cardHolderTitle}>Владелец</p>
          <p className={styles.cardHolderName}>{cardInfo?.owner}</p>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <ButtonNotFilled
          text="Пополнить"
          disabled={!ifCardWithSwitcher}
          height={'40px'}
          width={'100%'}
          onClick={() => navigate(`replenishments`)}
        />
        <ButtonFilled
          text="Заправиться"
          disabled={!ifCardWithSwitcher}
          height={'40px'}
          width={'100%'}
          onClick={() => setOpenModal(true)}
        />
      </div>
      {isMobile && (
        <div className={styles.closeCardButton}>
          <ButtonSimple
            text={cardInfo?.deletedAt ? 'Отменить закрытие' : 'Закрыть карту'}
            disabled={cardInfo?.status === 'Выпускается'}
            onClick={() => setOpenModalOfCloseCard(true)}
            bigMobileText={true}
          />
        </div>
      )}
      <ModalNew openModal={openModal} setOpenModal={setOpenModal}>
        <div className={styles.modalContainer}>
          <div className={styles.modalTitle}>Подтвердите переход</div>
          <div className={styles.modalSubtitle}>
            Нажимая кнопку “Продолжить” вы подтверждаете переход в другой сервис
          </div>
          <div className={styles.modalButtonsContainer}>
            <ButtonNotFilled text="Отмена" sizeText={isMobile ? 'small' : 'big'} onClick={() => setOpenModal(false)} />
            <ButtonFilled
              text="Продолжить"
              sizeText={isMobile ? 'small' : 'big'}
              onClick={() => window.location.assign(refuelLinkObj[getDeviceType()])}
            />
          </div>
        </div>
      </ModalNew>
    </div>
  );
};
