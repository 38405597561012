import React, { useContext, useEffect, useRef } from 'react';
import styles from './LaptopTour.module.scss';
import { Carousel, ConfigProvider } from 'antd';
import ButtonFilled from '../../../newUI/ButtonFilled/ButtonFilled';
import { Context } from '../../../../context/context';
import { arrayOfLaptopTourContent } from './constans';
import uuid from 'react-uuid';
const LaptopTour = () => {
    const { setStartTour,startTour } = useContext(Context);
    const ref =useRef();
    const nextSlide=()=>{
        // @ts-ignore
        ref.current.next();
    };
    useEffect(()=>{
        document.getElementById('Container').style.width='auto';
    },[startTour]);

    return (
        <div>
            <div className={styles.container}>
                <ConfigProvider
                    theme={{
                        components: {
                            Carousel: {
                                dotWidth:24,
                                dotHeight:3,
                                dotActiveWidth:8,

                            },
                        },
                    }}
                >
                    <Carousel dotPosition={'left'} beforeChange={(from, to)=> {
                        if (from===8 && to === 8){
                            document.getElementById('Container').style.width='1472px';
                            setStartTour(false);
                        }
                    }}  ref={ref}  infinite={false}>
                        {arrayOfLaptopTourContent.map((slide)=>
                            <div key={slide.title} className={styles.slideContainer}>
                                <img className={styles.img} style={slide.title==='Помощь'?{ height:'300px' }:{}}
                                     src={slide.src} alt=""/>
                                <div className={styles.contentContainer}>
                                    <div className={styles.titleAndDescription}>
                                        <h1 className={styles.slideTitle} >{slide.title}</h1>
                                        <p className={styles.slideDescription} >{slide.discription}</p>
                                    </div>
                                    <ButtonFilled onClick={()=>nextSlide()} text={'Продолжить'} height={'40px'} width={'100%'}/>
                                </div>
                            </div>
                        )}
                    </Carousel>
                </ConfigProvider>
            </div>
        </div>
    );
};

export default LaptopTour;