import React from 'react';
import styles from './GSMNewCardPage.module.scss';
import { useMediaQuery } from 'react-responsive';
import { PageWrapper } from 'src/components/PageWrapper';
import { GSMStatisticsCard } from 'src/Pages/GSMNewPage/GSMStatisticsCard/GSMStatisticsCard';
import { CalendarSvg } from 'src/components/UI/svg-icon/CalendarSvg';
import { GSMOperationsHistoryTable } from 'src/Pages/GSMNewPage/GSMOperationsHistoryTable/GSMOperationsHistoryTable';
import { mockGSMOperationsHistoryTable } from 'src/Pages/GSMNewPage/GSMconstants';
import { GSMCardPageCard } from './GSMCardPageCard/GSMCardPageCard';
import { Link } from 'react-router-dom';

export const GSMNewCardPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <PageWrapper style={{ backgroundColor: `${isMobile ? 'white' : 'var(--color-gray50)'}` }}>
      <div className={styles.topContainer}>
        <Link className="button-back" to="/gsm">
          <div className="button-back__svg">
            <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                fill="currentColor"
              />
            </svg>
          </div>
          К списку топливных карт
        </Link>
        {!isMobile && <div className={styles.closeCardButton}>Закрыть карту</div>}
      </div>
      <div className={styles.container}>
        <div className={styles.priceAndPlotContainer}>
          <GSMCardPageCard />
          <GSMStatisticsCard />
        </div>
        <div className={styles.operationsHistoryContainer}>
          <div className={styles.operationsHistoryHeader}>
            <span className={styles.subheader}>История операций</span>
            <div className={styles.calendarContainer}>
              <span className={styles.calendarText}>Октябрь</span>
              <CalendarSvg width={isMobile ? '16px' : '20px'} />
            </div>
          </div>
          <GSMOperationsHistoryTable operations={mockGSMOperationsHistoryTable} />
        </div>
      </div>
    </PageWrapper>
  );
};
