import React from 'react';

export const FavoriteShowcasesSvg = ({ width = '24px', color = 'white' }: { width?: string; color?: string }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4033 26.3369L14.4025 26.3363C10.9591 23.2138 8.14151 20.6582 6.17869 18.2584C4.22162 15.8657 3.16667 13.6891 3.16667 11.3333C3.16667 7.50281 6.16948 4.5 10 4.5C12.1686 4.5 14.2578 5.51197 15.6193 7.11084L16 7.55787L16.3807 7.11084C17.7422 5.51197 19.8314 4.5 22 4.5C25.8305 4.5 28.8333 7.50281 28.8333 11.3333C28.8333 13.6891 27.7784 15.8658 25.8212 18.2602C23.8632 20.6557 21.0548 23.2078 17.6232 26.3262L17.5981 26.349L17.5971 26.35L16.0013 27.7917L14.4033 26.3369Z"
        fill={color}
        stroke="currentColor"
      />
    </svg>
  );
};
