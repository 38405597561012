import React from 'react';

const DashSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.56 7.33273V8.66607H3L3.00007 7.33203L13.56 7.33273Z" fill="#6C779C"/>
        </svg>
    );
};

export default DashSvg;