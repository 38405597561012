import React, { useEffect, useRef, useState } from 'react';
import styles from './StepsToAddCardGSM.module.scss';
import { CustomSelect } from '../../../../components/UI/select/CustomSelect';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import { InputVerification } from '../../../../components/UI/inputs/InputVerification';
import SuccessSvgVer2 from '../../../../components/UI/svg-icon/SuccessSvgVer2';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';

interface IProps {
  step: 'selectDriver' | 'smsAuth' | 'success';
  setStep: React.Dispatch<'selectDriver' | 'smsAuth' | 'success'>;
  codeValue: string;
  setCodeValue: React.Dispatch<string>;
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
}

const StepsToAddCardGsm = ({ setOpenModalOfAddGSMCard, step, setStep, codeValue, setCodeValue }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [focus, setFocus] = useState<boolean>(false);
  const [errorSending, setErrorSending] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(10);

  const refCode = useRef<HTMLInputElement | null>();
  const inputVerificationChange = ({ target: { value } }) => {
    setCodeValue(value.replace(/\D/gu, '').slice(0, 5));
  };
  const convertVisualOfTimerCount = (): string => {
    if (timer < 10) {
      return `00:0${timer}`;
    } else {
      return `00:${timer}`;
    }
  };
  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  return (
    <>
      {step === 'selectDriver' && (
        <div className={styles.selectDriverContainer}>
          <div className={styles.mobileCloseButtonAndTitle}>
            <p className={styles.selectDriverTitle}>Выпуск топливной карты</p>
            {isMobile && (
              <div className={styles.closeButton} onClick={() => setOpenModalOfAddGSMCard(false)}>
                <CloseSvg />
              </div>
            )}
          </div>
          <div className={styles.selectContainer}>
            <p className={styles.toText}>Кому</p>
            <CustomSelect
              error={false}
              placeholder={''}
              noOptionsMessage={''}
              isSearchable={false}
              isClearable={false}
              options={''}
              setValue={() => ''}
            />
          </div>
          <div className={styles.buttonsContainer}>
            <ButtonNotFilled onClick={() => setOpenModalOfAddGSMCard(false)} text={'Отмена'} width={'100%'} />
            <ButtonFilled onClick={() => setStep('smsAuth')} text={'Выпустить'} width={'100%'} />
          </div>
        </div>
      )}
      {step === 'smsAuth' && (
        <div className={styles.smsAuthContainer}>
          <div className={styles.mobileCloseButtonAndTitle}>
            <p className={styles.smsAuthTitle}>Для подтверждения операции введите код из смс</p>
            {isMobile && (
              <div className={styles.closeButton} onClick={() => setOpenModalOfAddGSMCard(false)}>
                <CloseSvg />
              </div>
            )}
          </div>
          <p className={styles.smsAuthSubTitle}>Мы отправили код подтверждения на номер</p>
          <p className={styles.smsAuthNumber}>+7 999 534 12 64</p>
          <InputVerification
            style={{ height: '80px', padding: '16px' }}
            placeholder="00000"
            errorSending={errorSending}
            setErrorSending={setErrorSending}
            onInput={inputVerificationChange}
            value={codeValue}
            refNew={refCode}
            focus={focus}
            setFocus={setFocus}
            autoFocus={true}
          />
          {errorSending && <div className={styles.descriptionError}>Вы ввели неверный код. Попробуйте еще раз.</div>}
          <div style={{ marginTop: '24px' }}>
            {timer > 0 ? (
              <div className={styles.newCodeCounter}>
                Получить новый код можно <br />
                через {convertVisualOfTimerCount()}
              </div>
            ) : (
              <ButtonFilled text={'Отправить код повторно'} width={'100%'} onClick={() => ''} disabled={false} />
            )}
          </div>
        </div>
      )}
      {step === 'success' && (
        <div className={styles.successContainer}>
          {isMobile && (
            <div onClick={() => setOpenModalOfAddGSMCard(false)} className={styles.closeSvg}>
              <CloseSvg />
            </div>
          )}
          <div className={styles.successContent}>
            <SuccessSvgVer2 />
            <div className={styles.successContentText}>
              <p className={styles.successThickText}>Карта успешно добавлена</p>
              <p className={styles.successSoftText}>Она появится в общем списке</p>
            </div>
          </div>
          <ButtonFilled width={'100%'} text={'Готово'} onClick={() => setOpenModalOfAddGSMCard(false)} />
        </div>
      )}
    </>
  );
};

export default StepsToAddCardGsm;
