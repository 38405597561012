import { TGSMOperation } from './GSMtypes';

export const mockGSMOperationsHistoryTable: TGSMOperation[] = [
  { date: '06.11.2024 (23:59)', fuelType: 'ДТ', operationType: 'Заправка (списание средств с карты)', price: '-4500' },
  { date: '05.11.2024 (22:45)', fuelType: '', operationType: 'Оплата ГСМ', price: '+30000' },
  { date: '04.11.2024 (19:30)', fuelType: '95', operationType: 'Заправка (списание средств с карты)', price: '-6200' },
  { date: '03.11.2024 (16:15)', fuelType: 'ДТ', operationType: 'Заправка (списание средств с карты)', price: '-5000' },
  { date: '02.11.2024 (12:00)', fuelType: '92', operationType: 'Заправка (списание средств с карты)', price: '-3400' },
  { date: '01.11.2024 (08:45)', fuelType: '', operationType: 'Оплата ГСМ', price: '+45000' },
  { date: '31.10.2024 (23:59)', fuelType: 'ДТ', operationType: 'Заправка (списание средств с карты)', price: '-7800' },
  { date: '30.10.2024 (20:20)', fuelType: '92', operationType: 'Заправка (списание средств с карты)', price: '-6000' },
  { date: '29.10.2024 (17:10)', fuelType: '95', operationType: 'Заправка (списание средств с карты)', price: '-5500' },
  { date: '28.10.2024 (14:05)', fuelType: 'ДТ', operationType: 'Заправка (списание средств с карты)', price: '-3200' },
  { date: '27.10.2024 (11:55)', fuelType: '', operationType: 'Оплата ГСМ', price: '+41000' },
  { date: '26.10.2024 (09:30)', fuelType: '95', operationType: 'Заправка (списание средств с карты)', price: '-4700' },
];

export const mockGSMStatisticsData = [
  { date: '2023-10-01', sum: 8000 },
  { date: '2023-10-02', sum: 5000 },
  { date: '2023-10-03', sum: 7000 },
  { date: '2023-10-04', sum: 4500 },
  { date: '2023-10-05', sum: 2000 },
  { date: '2023-10-06', sum: 2000 },
  { date: '2023-10-07', sum: 1500 },
];

export const plotOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      border: {
        color: '#E8EBF0',
        width: 1,
      },
      grid: {
        color: '#E8EBF0',
      },
      ticks: {
        maxTicksLimit: 5,
        font: {
          size: 12,
          family: 'Roboto',
          weight: 'normal' as const,
        },
        color: '#6C779C',
      },
    },
    x: {
      border: {
        color: '#E8EBF0',
        width: 1,
      },
      grid: {
        color: '#E8EBF0',
      },
      ticks: {
        font: {
          size: 12,
          family: 'Roboto',
          weight: 'normal' as const,
          lineHeight: 1.5,
        },
        color: '#6C779C',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};
