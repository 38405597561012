import React from 'react';
import styles from './OperationsArray.module.scss';
import { IOperation } from '../../../type';
import { daysArray, monthsArray, svgStatus } from '../../../constans';
import { formPrice } from '../../../../../common/form-price.helper';
import { useMediaQuery } from 'react-responsive';
interface IProps {
  dataOperation: IOperation[];
  date: string;
}
const OperationsArray = ({ dataOperation, date }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const convertDate = (): string => {
    const newDate = new Date(date);
    return `${newDate.getDate()} ${monthsArray[newDate.getMonth()]}, ${daysArray[newDate.getDay()]}`;
  };

  return (
    <div className={styles.container}>
      {isMobile ? (
        <>
          <div className={styles.dateContainer}>{convertDate()}</div>
          {dataOperation?.map(operation => (
            <div key={operation?.id} className={styles.operationContainer}>
              <div className={styles.mobileTimeAndMoneyContainer}>
                <div className={styles.textOperation}>{operation?.date}</div>
                <div className={styles.mobileStatusAndMoneyContainer}>
                  <p className={styles.textOperationMoney}>
                    {formPrice(operation?.amount, 'RUB', 'ru').replace('-', '— ')}
                  </p>
                  {svgStatus('16px')[operation?.status]}
                </div>
              </div>

              <div className={styles.textOperation}>{operation?.type}</div>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className={styles.dateContainer}>{convertDate()}</div>
          {dataOperation?.map(operation => (
            <div key={operation?.id} className={styles.operationContainer}>
              <div style={{ width: '42px' }} className={styles.textOperation}>
                {operation?.date}
              </div>
              <div style={{ width: '413px' }} className={styles.textOperation}>
                {operation?.type}
              </div>
              <div style={{ width: '160px' }} className={styles.textOperationMoney}>
                {formPrice(operation?.amount, 'RUB', 'ru').replace('-', '— ')}
              </div>
              {svgStatus('20px')[operation?.status]}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default OperationsArray;
