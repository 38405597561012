import React from 'react';
interface IProps {
    color:string
}
const InfoSvg = ({ color }) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Union">
                <path d="M8.00065 11.834C8.27679 11.834 8.50065 11.6101 8.50065 11.334V7.33398C8.50065 7.05784 8.27679 6.83398 8.00065 6.83398C7.72451 6.83398 7.50065 7.05784 7.50065 7.33398V11.334C7.50065 11.6101 7.72451 11.834 8.00065 11.834Z" fill={color}/>
                <path d="M8.00065 4.66732C8.36884 4.66732 8.66732 4.96579 8.66732 5.33398C8.66732 5.70217 8.36884 6.00065 8.00065 6.00065C7.63246 6.00065 7.33398 5.70217 7.33398 5.33398C7.33398 4.96579 7.63246 4.66732 8.00065 4.66732Z" fill={color}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0.833984 8.00065C0.833984 4.04261 4.04261 0.833984 8.00065 0.833984C11.9587 0.833984 15.1673 4.04261 15.1673 8.00065C15.1673 11.9587 11.9587 15.1673 8.00065 15.1673C4.04261 15.1673 0.833984 11.9587 0.833984 8.00065ZM8.00065 1.83398C4.5949 1.83398 1.83398 4.5949 1.83398 8.00065C1.83398 11.4064 4.5949 14.1673 8.00065 14.1673C11.4064 14.1673 14.1673 11.4064 14.1673 8.00065C14.1673 4.5949 11.4064 1.83398 8.00065 1.83398Z" fill={color}/>
            </g>
        </svg>
    );
};

export default InfoSvg;