import React, { Dispatch, SetStateAction, useRef } from 'react';
import styles from './ShowcaseCover.module.scss';
import NewPlusSvg from 'src/components/UI/svg-icon/NewPlusSvg';
import { HelpTooltip } from 'src/components/HelpTooltip';
import { useMediaQuery } from 'react-responsive';
import CustomTooltip from 'src/components/newUI/CustomTooltip/CustomTooltip';
import { uploadImage } from '../../utils';
import defaultHeaderImage from '../../images/default-header.png';
import { toast, ToastContainer } from 'react-toastify';

type TShowcaseCover = {
  isCreatePage: boolean;
  isEdit: boolean;
  coverImage: string;
  setCoverImage: Dispatch<SetStateAction<string>>;
  setIsChangedCover?: Dispatch<SetStateAction<boolean>>;
};

export const ShowcaseCover = ({
  isCreatePage,
  isEdit,
  coverImage,
  setCoverImage,
  setIsChangedCover = () => {},
}: TShowcaseCover) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleContainerClick = () => {
    fileInputRef.current?.click();
  };
  const handleUpload = e => {
    const file = e.target.files[0];
    if (file.size > 10485760) {
      toast.warning('Размер файла превышает 10 МБ.');
      e.target.value = '';
      return;
    }
    uploadImage(e, setCoverImage);
    if (!isCreatePage) {
      setIsChangedCover(true);
    }
  };
  return (
    <div className={styles.container + ' ' + (isEdit ? styles.edit : '')} onClick={handleContainerClick}>
      <div
        className={styles.coverImage + ' ' + (!isEdit ? styles.coverImageView : '')}
        style={{
          backgroundImage: `url(${coverImage ? coverImage : defaultHeaderImage})`,
        }}
      />
      {isEdit && (
        <div className={styles.changeCoverButton}>
          <CustomTooltip
            MobileSideTooltip={'right'}
            SideTooltip={'up'}
            svg={<NewPlusSvg />}
            positionTooltipBottom={'26px'}
            text={
              <p>
                Загрузите обложку для вашей витрины. <br />
                Разрешение изображения 1302 х 160, размер до 10 МБ, форматы: .png, .jpg
              </p>
            }
            widthTooltip={isMobile ? '300px' : '529px'}
            positionTooltipLeft={isMobile && '-9px'}
          />
          <div className={styles.text}>Заменить обложку для витрины</div>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            ref={fileInputRef}
            onChange={e => handleUpload(e)}
            style={{ display: 'none' }}
          />
        </div>
      )}
    </div>
  );
};
