import React, { FC, useState } from 'react';
import { IRegistryId } from './types';
import FileSign from '../../../components/UI/svg-icon/FileSign';
import { Modal } from 'antd';
import styles from './UpdInfoContainer.module.scss';
import HandCoinOutline from '../../../components/UI/svg-icon/HandCoinOutline';
import MapMarkerMultipleOutline from '../../../components/UI/svg-icon/mapMarkerMultipleOutline';
import TruckFastOutline from '../../../components/UI/svg-icon/TruckFastOutline';
import DumpTruckOutline from '../../../components/UI/svg-icon/DumpTruckOutline';
import { formPrice } from '../../../common/form-price.helper';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';
interface IProps {
  data: IRegistryId;
  objectName: string[];
  deliveryType: string[];
}
const UpdInfoContainer: FC<IProps> = ({ data, objectName, deliveryType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.mobileAllBlocks}>
      {' '}
      {/*все блоки*/}
      <div className={styles.block} style={{ marginBottom: 0 }}>
        {' '}
        {/*первый блок*/}
        <div className={styles.titleOfBlock}>
          {' '}
          {/*1-строка*/}
          <div>
            <DumpTruckOutline />
          </div>
          <div className={styles.textOfTitleBlock}>Номенкатура и кол-во</div>
        </div>
        <div className={styles.arrayOfNum}>
          {' '}
          {/*2-строка*/}
          {data?.volumeDetail.map(item => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={styles.textString}>{item.nomenclatureName}</div>
              <div className={styles.textString}>
                <span>
                  {item.value} {item.unitName}{' '}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.block} style={{ borderBottom: '1px solid #E5E5E5' }}>
        {' '}
        {/*второй блок*/}
        <div className={styles.titleOfBlock}>
          {' '}
          {/*1-строка*/}
          <div>
            <HandCoinOutline />
          </div>
          <div className={styles.textOfTitleBlock}>Общая сумма</div>
        </div>
        <div className={styles.textString}>
          {' '}
          {/*2-строка*/}
          {formPrice(data?.documentAmount, 'RUB')}
        </div>
      </div>
      <div className={styles.block}>
        {' '}
        {/*третий блок*/}
        <div className={styles.titleOfBlock}>
          {' '}
          {/*1 строка*/}
          <div>
            <MapMarkerMultipleOutline />
          </div>
          <div className={styles.textOfTitleBlock}>Адрес объектов</div>
        </div>
        {objectName?.map(item => (
          <div className={styles.textString}>
            {' '}
            {/*2 строка*/}
            {item}
          </div>
        ))}
      </div>
      <div className={styles.block}>
        {' '}
        {/*четвертый блок*/}
        <div className={styles.titleOfBlock}>
          {' '}
          {/*1строка*/}
          <div>
            <TruckFastOutline />
          </div>
          <div className={styles.textOfTitleBlock}>Тип перевозки</div>
        </div>
        {deliveryType?.map(item => (
          <div className={styles.textString}>
            {' '}
            {/*2строка*/}
            {item}
          </div>
        ))}
      </div>
      <div className={styles.block}>
        {' '}
        {/*пятый блок*/}
        <div className={styles.titleOfBlock}>
          {' '}
          {/*1 строка*/}
          <div>
            <FileSign />
          </div>
          <div className={styles.textOfTitleBlock}>Комментарий</div>
        </div>
        <div className={styles.textOfComment}>
          {' '}
          {/*2 строка*/}
          {data?.verifications[0]?.comment.length < 115
            ? data?.verifications[0]?.comment
            : `${data?.verifications[0]?.comment.slice(0, 114)}...`}
        </div>
        <div className={styles.buttonOfModal}>
          {/*3строка*/}
          {!(data?.verifications[0]?.comment?.length < 115) && (
            <div className={styles.buttonOfModalSecondBlock}>
              <ButtonSimple text={'Показать полностью'} onClick={showModal} />
            </div>
          )}
        </div>
        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
          <h3>Комментарий</h3>
          <p>{data?.verifications[0]?.comment}</p>
        </Modal>
      </div>
    </div>
  );
};

export default UpdInfoContainer;
