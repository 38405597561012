// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GSMStatisticsPlot_container__30ly8 {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 182px;\n}\n\n@media (max-width: 768px) {\n  .GSMStatisticsPlot_container__30ly8 {\n    height: 160px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/GSMNewPage/GSMStatisticsCard/GSMStatisticsPlot/GSMStatisticsPlot.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 182px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    height: 160px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GSMStatisticsPlot_container__30ly8"
};
export default ___CSS_LOADER_EXPORT___;
