import React, { useState } from 'react';
import styles from './CustomTooltip.module.scss';
import { useMediaQuery } from 'react-responsive';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface IProps {
  style?: any /*Стиль общего контейнера*/;
  openTooltipOnClick?:boolean /*Открытия тултипа на клик*/
  styleWindowTooltip?:any
  svg: any /*Картинка (куда наводиться)*/;
  svgError?: any /*Картинка ошибки*/;
  error?: boolean /*Нужно ли выводить ошибку*/;
  text: string | ReactJSXElement /*Всплывающий текст*/;
  widthSvg?: string /*Ширина SVG, формат:'24px'*/;
  widthTooltip: string /*Ширина компьютерного всплывающего окна, формат:'24px'*/;
  SideTooltip?: 'left' | 'up' | 'right' /*(дефолт ПРАВАЯ), в которую направлено всплывающее окно*/;
  positionTooltipRight?:string
  positionTooltipBottom?:string
  positionTooltipLeft?:string
  MobileSideTooltip?:
    | 'left'
    | 'up'
    | 'right' /*МОБИЛЬНАЯ ВЕРСИЯ (дефолт наверх), в которую направлено всплывающее окно*/;
}
const CustomTooltip = ({
  svg,
  text,
  widthSvg,
  widthTooltip,
  SideTooltip = 'right',
  MobileSideTooltip = 'up',
  style,
  styleWindowTooltip,
  error,
  svgError,
  positionTooltipRight,
  positionTooltipLeft,
  positionTooltipBottom,
  openTooltipOnClick = false,
}: IProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const chooseSideTooltip = (side: string): any => {
    if (isMobile) {
      switch (side) {
        case 'left':
          return styles.LeftSideTooltipContainer;
          break;
        case 'right':
          return styles.RightSideTooltipContainer;
          break;
        case 'up':
          return styles.tooltipContainer;
          break;
      }
    } else {
      switch (side) {
        case 'left':
          return styles.LeftSideTooltipContainer;
          break;
        case 'up':
          return styles.tooltipContainer;
          break;
        case 'right':
          return styles.RightSideTooltipContainer;
          break;
      }
    }
  };
  const onMouseEnterHandler = () => {
    setShowTooltip(true);
  };
  const onMouseLeaveHandler = () => {
    setShowTooltip(false);
  };
  const styleContainer = {
    ...style,
    justifyContent:(SideTooltip==='up'||MobileSideTooltip==='up') && 'center'
  };
  const styleTooltip = {
    ...styleWindowTooltip,
    width: widthTooltip,
    right:positionTooltipRight,
    bottom:positionTooltipBottom,
    left:positionTooltipLeft,
  };
  return (
    <div style={styleContainer} className={styles.container} onClick={e => openTooltipOnClick && e.stopPropagation()}>
      <div
        style={{ width: widthSvg ? widthSvg : '24px', height: widthSvg ? widthSvg : '24px' }}
        className={styles.svgContainer}
        onMouseEnter={!openTooltipOnClick && onMouseEnterHandler}
        onMouseLeave={!openTooltipOnClick && onMouseLeaveHandler}
        onClick={()=>openTooltipOnClick && setShowTooltip(!showTooltip)}
      >
        {error ? svgError : svg}
      </div>
      {showTooltip && text && (
        <div
          style={styleTooltip}
          className={chooseSideTooltip(isMobile ? MobileSideTooltip : SideTooltip)}
          onClick={()=>openTooltipOnClick && setShowTooltip(false)}
        >
          <p className={styles.textTooltip}>{text}</p>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
