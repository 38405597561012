import React, { useEffect, useState } from 'react';
import styles from './CardWithCheckboxContainer.module.scss';
import { CheckBoxCustom } from 'src/components/UI/checkBox/CheckBoxCustom';
import GSMCard from '../../components/GSMCard/GSMCard';
import { IDataCards, IFilters } from '../../type';

type TProps = {
  filters: IFilters;
  dataCard?: IDataCards;
  setDataCards?: React.Dispatch<React.SetStateAction<IDataCards[]>>;
  isAllClicked: boolean;
};

export const CardWithCheckboxContainer = ({ filters, dataCard, isAllClicked, setDataCards }: TProps) => {
  const [isChecked, setIsChecked] = useState<{ id: string; active: boolean }>({
    id: '',
    active: false,
  });
  useEffect(() => {
    setIsChecked({ id: String(dataCard?.id), active: isAllClicked });
  }, [isAllClicked]);
  const pickCheck = (id: string) => {
    setIsChecked(prevState => ({ id: id, active: !prevState.active }));
    setDataCards(prevState =>
      prevState.map(card => ({ ...card, checkActive: card.id === Number(id) ? !card.checkActive : card.checkActive })),
    );
  };
  return (
    <div className={styles.container}>
      <CheckBoxCustom error={false} check={isChecked.active} pick={pickCheck} name={dataCard?.id} id={dataCard?.id} />
      <div style={{ minWidth: 'calc(100% - 32px)' }}>
        <GSMCard switcher={false} filters={filters} dataCard={dataCard} />
      </div>
    </div>
  );
};
