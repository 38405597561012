import React from 'react';
import styles from './BackButton.module.scss';
import { ArrowBack } from '../../UI/svg-icon/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

interface IProps {
  onClick: any;
  textButton?: string;
}
const BackButton = ({ onClick, textButton }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div style={{ marginTop: isMobile && '8px' }} onClick={onClick} className={styles.backButtonAndTextContainer}>
      <div className={styles.backButton}>
        <ArrowBack />
      </div>
      <p className={styles.textBackButton}>{textButton}</p>
    </div>
  );
};

export default BackButton;
