import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { SkeletonProfile } from '../../components/skeletons/SkeletonProfile';
import { toast, ToastContainer } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { TitleName } from '../../components/TitleName';
import { InputText } from '../../components/UI/inputs/InputText';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { Button } from '../../components/UI/buttons/Button';
import { InputTextArea } from '../../components/UI/inputs/InputTextArea';
import { CirclePlusSvg } from '../../components/UI/svg-icon/CirclePlusSvg';
import { StopSvg } from '../../components/UI/svg-icon/StopSvg';
import { useMediaQuery } from 'react-responsive';
import { RadioBoxCircle } from '../../components/UI/checkBox/RadioBoxCircle';
import { IObject } from './types/object';
import { TimePicker } from 'antd';
import styles from '../ObjectsPage/ObjectsPage.module.scss';
import { CheckBoxCustom } from '../../components/UI/checkBox/CheckBoxCustom';
import CustomYMap from '../../components/CustomYMap/CustomYMap';
import dayjs from 'dayjs';
import { ButtonTransparent } from '../../components/UI/buttons/ButtonTransparent';
import { Basket } from '../../components/UI/svg-icon/Basket';
import { ButtonTextTransparent } from '../../components/UI/buttons/ButtonTextTransparent';
import CustomAddressInput from '../../components/newUI/CustomAddressInput/CustomAddressInput';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import ButtonFilled from "../../components/newUI/ButtonFilled/ButtonFilled";
import ButtonNotFilled from "../../components/newUI/ButtonNotFilled/ButtonNotFilled";
import { toastError } from 'src/common/toastError.helper';

export const ObjectPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [skeleton, setSkeleton] = useState<boolean>(true);
  const [valueAddress, setValueAddress] = useState<DaDataSuggestion<DaDataAddress> | undefined>();
  const [disabled, setDisabled] = useState(false);
  const [sending, setSending] = useState(false);
  const [errorCheckBox, setErrorCheckBox] = useState(false);
  const [openLeftTimer, setOpenLeftTimer] = useState(false);
  const [openRightTimer, setOpenRightTimer] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [validateTime, setValidateTime] = useState<'' | 'error' | 'warning'>('');

  const [objectData, setObjectData] = useState<IObject>({
    name: '',
    address: {
      address: '',
      latitude: 0,
      longitude: 0,
    },
    comment: '',
    typeTC: [
      {
        id: 'everyone',
        active: false,
        title: 'Проходят все',
      },
      {
        id: 'semitrailer-30-45',
        active: false,
        title: 'Полуприцеп 30 м³ - 45 т',
      },
      {
        id: 'four-axis-30-45',
        active: false,
        title: '4х-осные 30 м³ - 45 т',
      },
      {
        id: 'four-axis-20-30',
        active: false,
        title: '4х-осные 20 м³ - 30 т',
      },
      {
        id: 'three-axis-10-15',
        active: false,
        title: '3х-осные 10 м³ - 15 т',
      },
    ],
    contactPersons: [
      {
        person: '',
        phone: '',
        main: true,
      },
    ],
    objectContacts: {
      objectContactsMain: {
        person: '',
        phone: '',
      },

      objectContactsAdditionally: [],
    },
    workingHoursType: {
      name: '',
      workTo: '',
      workFrom: '',
    },
    semitrailers: [''],
  });

  const navigate = useNavigate();

  const setDayJsFormatDate = time => {
    const correctDate = new Date();
    correctDate.setHours(time.split(':')[0]);
    correctDate.setMinutes(time.split(':')[1]);
    return dayjs(correctDate);
  };

  const getObject = async () => {
    try {
      const object = await fetchGet(`/objects/${window.location.href.split('/').reverse()[0]}`, {});

      if (!object.response) {
        toast.error('Ошибка получения данных');
        return;
      }

      const additionalContacts = object.response.contactPersons.find(
        person => person.contact_persons_communications.main,
      );

      setValueAddress({
        ...valueAddress,
        value: object.response?.address.address,
        data: {
          ...valueAddress?.data,
          geo_lat: object.response?.address.latitude.toString(),
          geo_lon: object.response?.address.longitude.toString(),
        },
      });
      setObjectData({
        ...objectData,
        name: object.response?.name || '',
        address: {
          ...objectData.address,
          address: object.response?.address?.address || '',
          latitude: object.response?.address?.latitude || null,
          longitude: object.response?.address?.longitude || null,
        },
        comment: object.response?.comment || '',
        objectContacts: {
          ...objectData.objectContacts,
          objectContactsMain: {
            ...objectData.objectContacts?.objectContactsMain,
            person:
              object.response.contactPersons.filter(person => person?.contact_persons_communications?.main === true)[0]
                ?.person || '',
            phone:
              object.response.contactPersons.filter(person => person?.contact_persons_communications?.main === true)[0]
                ?.phone || '',
          },
          objectContactsAdditionally: additionalContacts
            ? object.response.contactPersons.filter(person => !person.contact_persons_communications.main)
            : [],
        },
        semitrailers: object.response.semitrailers.map(item => item.title),
        typeTC: objectData.typeTC.map(item => {
          const semitrailerTitles = object.response.semitrailers.map(semitrailer => semitrailer.title);

          if (semitrailerTitles.includes(item.id) || semitrailerTitles.includes('everyone')) {
            return {
              ...item,
              active: true,
            };
          }

          if (semitrailerTitles.length === 4) {
            return {
              ...item,
              id: 'everyone',
              active: true,
              title: 'Проходят все',
            };
          }

          return {
            ...item,
          };
        }),
        workingHoursType: {
          name: object.response?.workSchedulesGraph.aroundTheClock ? 'aroundTheClock' : 'daytime',
          workFrom: setDayJsFormatDate(object.response?.workSchedulesGraph.to),
          workTo: setDayJsFormatDate(object.response?.workSchedulesGraph.from),
        },
      });

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      console.log(e);
      navigate('/objects');
    }
  };

  const correctionTime =
    objectData.workingHoursType.name === 'daytime'
      ? {
          workingHoursType: objectData.workingHoursType.name,
          from: objectData.workingHoursType.workTo?.format('HH:mm'),
          to: objectData.workingHoursType.workFrom?.format('HH:mm'),
        }
      : {
          workingHoursType: objectData.workingHoursType.name,
        };

  const sendObjectData = async id => {
    setDisabled(true);
    try {
      const dataObject = await fetchPost(`/objects/${window.location.href.split('/').reverse()[0]}`, 'PATCH', {
        name: objectData.name.trim(),
        address: {
          address: valueAddress.value,
          latitude: Number(objectData?.address?.latitude),
          longitude: Number(objectData?.address?.longitude),
        },
        comment: objectData.comment,
        contactPersons: [
          {
            person: objectData.objectContacts.objectContactsMain.person.trim(),
            phone: objectData.objectContacts.objectContactsMain.phone,
            main: true,
          },
          ...objectData.objectContacts.objectContactsAdditionally.map(contact => ({
            person: contact.person.trim(),
            phone: contact.phone,
            main: false,
          })),
        ],
        semitrailers: objectData.semitrailers,
        time: correctionTime,
      });

      if (toastError(dataObject)) {
        setSending(false);
        setErrorCheckBox(!objectData.semitrailers.length);
        setDisabled(false);
        return;
      }

      toast.success('Данные успешно сохранены');
      setDisabled(false);
      setSending(false);
      navigate('/objects');
    } catch (e) {
      setDisabled(false);
      setSending(false);
      console.log(e);
    }
  };

  const customTimePickerButton = (isLeft: boolean) => {
    const closeTimer = () => {
      if (isLeft) {
        setOpenLeftTimer(false);
      } else {
        setOpenRightTimer(false);
      }
    };

    return (
      <button onClick={closeTimer} className={styles.customRangePickerBtnСhoose}>
        Готово
      </button>
    );
  };

  const changeTime = (time: any, timeString: string | string[], input: string, inputTime: string) => {
    setObjectData(prevState => ({
      ...prevState,
      workingHoursType: {
        ...prevState.workingHoursType,
        [input]: time,
        [inputTime]: time,
      },
    }));
  };

  const pickCheck = id => {
    const newTypeTC = [];
    const newTypeTCSend = [];

    if (id === 'everyone') {
      const { active } = objectData.typeTC.find(item => item.id === id);

      objectData.typeTC.forEach(item => {
        active ? (item.active = false) : (item.active = true);

        newTypeTC.push(item);
      });

      const semitrailersIds = objectData.typeTC
        .filter(item => item.active && item.id !== 'everyone')
        .map(item => item.id);

      setObjectData(prevState => ({ ...prevState, typeTC: newTypeTC, semitrailers: semitrailersIds }));

      return;
    }

    objectData.typeTC.forEach(item => {
      if (item.id === 'everyone') {
        item.active = false;
      }

      newTypeTC.push(item);
    });

    newTypeTC.splice(0, newTypeTC.length);

    objectData.typeTC.forEach(item => {
      if (item.id === id) {
        item.active = !item.active;
      }

      newTypeTC.push(item);
      if (item.active) {
        newTypeTCSend.push(item.id);
      }
    });

    setObjectData(prevState => ({ ...prevState, typeTC: newTypeTC, semitrailers: newTypeTCSend }));
  };

  const addPerson = () => {
    setObjectData({
      ...objectData,
      objectContacts: {
        ...objectData.objectContacts,
        objectContactsAdditionally: [
          ...objectData.objectContacts.objectContactsAdditionally,
          {
            person: '',
            phone: '',
            deleted: false,
          },
        ],
      },
    });
  };

  const deleteContact = (value: number) => {
    setObjectData(prevState => {
      const copy = { ...prevState };
      copy.objectContacts.objectContactsAdditionally.splice(value, 1);
      return copy;
    });
  };

  const changeValueContact = (key, value, input) => {
    setObjectData(prevState => {
      const copy = { ...prevState };
      switch (input) {
        case 'person': {
          copy.objectContacts.objectContactsAdditionally.find((el, index) => index === key).person = value;
          break;
        }
        case 'phone': {
          copy.objectContacts.objectContactsAdditionally.find((el, index) => index === key).phone = value;
          break;
        }
        default: {
          return copy;
        }
      }

      return copy;
    });
  };

  const deleteObject = async () => {
    try {
      await fetchPost(`/objects/${window.location.href.split('/').reverse()[0]}`, 'DELETE', {});
      toast.success('Данные успешно сохранены');
      navigate('/objects');
    } catch (e) {
      toast.error('Ошибка отправки данных');
      setSending(false);
      console.log(e);
    }
  };

  const createValidateTime = () => {
    if (objectData.workingHoursType.workFrom !== '' && objectData.workingHoursType.workTo !== '') {
      const timeFrom = objectData.workingHoursType.workFrom?.format('HH:mm');
      const to = objectData.workingHoursType.workTo?.format('HH:mm');
      const result = timeFrom.split(':')[0] - to.split(':')[0] < 4 && timeFrom !== '' && to !== '' ? 'error' : '';
      setValidateTime(result);
    }
  };
  useEffect(() => {
    createValidateTime();
  }, [objectData.workingHoursType.workTo]);
  useEffect(() => {
    setObjectData({
      ...objectData,
      address: {
        ...objectData.address,

        latitude: Number(valueAddress?.data?.geo_lat),
        longitude: Number(valueAddress?.data?.geo_lon),
      },
    });
  }, [valueAddress]);

  useEffect(() => {
    getObject().catch(console.error);
  }, []);

  return (
    <>
      {skeleton ? (
        <PageWrapper>
          <ToastContainer />
          <SkeletonProfile />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <ToastContainer />
          <Link className="button-back" to="/objects">
            <div className="button-back__svg">
              <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            Вернуться к списку объектов
          </Link>
          <div className={'objectPage'}>
            <div className="objectPage__title">Редактирование данных объекта </div>
            <div className={'objectPage-content'}>
              <div>
                <div className={'page-content-inputs-box'}>
                  <div className={'page-content-inputs-box'}>
                    <div>
                      <div className={'page-content-inputs-box__title textH5'}>
                        Наименование объекта
                        <sup>&nbsp;*</sup>
                      </div>
                    </div>
                    <div className="ProfilePage__inputs">
                      <InputText
                        type={''}
                        secondError={false}
                        disabled={false}
                        isLabel={true}
                        value={objectData.name || ''}
                        error={true}
                        setValue={name =>
                          setObjectData({
                            ...objectData,
                            address: {
                              ...objectData.address,
                            },
                            name: name,
                          })
                        }
                        margin={'0'}
                        fill={true}
                        helpText={'Введите наименование объекта. Например: "Сокольники"'}
                        widthHelp={0}
                        textError={'Поле обязательное для заполнения'}
                        placeholder="Наименование"
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: '24px' }}>
                  <div className={'page-content-inputs-box'}>
                    <div>
                      <div className={'page-content-inputs-box__title textH5'}>Адрес объекта</div>
                    </div>
                    <div>
                      <div className={'page-content-inputs-box__sub-title textH6'}>
                        Фактический адрес объекта
                        <sup>&nbsp;*</sup>
                      </div>
                      <div className={'page-content-inputs-box__input'}>
                        <CustomAddressInput
                          placeholder={'Адрес'}
                          valueAddress={valueAddress}
                          setValueAddress={setValueAddress}
                        />
                        <InputTextArea
                          isLabel={true}
                          margin={'16px'}
                          placeholder={'Комментарий'}
                          disabled={false}
                          value={objectData.comment}
                          setValue={comment =>
                            setObjectData({
                              ...objectData,
                              address: {
                                ...objectData.address,
                              },
                              comment: comment,
                            })
                          }
                        />
                      </div>
                      <div className={'page-content-inputs-box__sub-title textH6'}>Широта и Долгота</div>
                      <div className={'page-content-inputs-box__inputs'}>
                        <InputText
                          type={'number'}
                          disabled={false}
                          isLabel={true}
                          value={objectData?.address?.latitude}
                          error={false}
                          setValue={value =>
                            setObjectData({
                              ...objectData,
                              address: {
                                ...objectData.address,
                                latitude: value,
                              },
                            })
                          }
                          margin={'0'}
                          widthHelp={260}
                          helpText={'Введите широту адреса карьера. Например: “55.71656”'}
                          textError={'Поле обязательное для заполнения'}
                          placeholder={'Широта'}
                        />
                        <InputText
                          type={'number'}
                          disabled={false}
                          isLabel={true}
                          value={objectData?.address?.longitude}
                          error={false}
                          setValue={value =>
                            setObjectData({
                              ...objectData,
                              address: {
                                ...objectData.address,
                                longitude: value,
                              },
                            })
                          }
                          margin={'0'}
                          widthHelp={260}
                          helpText={'Введите долготу адреса карьера. Например: “55.71656”'}
                          textError={'Поле обязательное для заполнения'}
                          placeholder={'Долгота'}
                        />
                      </div>
                      <div className={'page-content-inputs-box__map'}>
                        <div style={{ textAlign: 'end' }}>
                          <ButtonTextTransparent
                            onClick={() => setOpenMap(!openMap)}
                            children={openMap ? 'Скрыть карту' : 'Показать карту'}
                            addClass={''}
                          />
                        </div>
                        {openMap && (
                          <CustomYMap
                            latitude={objectData?.address?.latitude}
                            longitude={objectData?.address?.longitude}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '24px' }}></div>

                  <div className={'page-content-inputs-box'}>
                    <div>
                      <div className={'page-content-inputs-box__title textH5'}>Контакты на объекте </div>
                    </div>
                    <div>
                      <div className={'page-content-inputs-box__sub-title textH6'}>
                        Основной контакт
                        <sup>&nbsp;*</sup>
                      </div>
                      <div className="ProfilePage__inputs">
                        <div className={'objectsPage-popup-box__input'}>
                          <InputText
                            type={''}
                            secondError={false}
                            disabled={false}
                            isLabel={true}
                            value={objectData.objectContacts.objectContactsMain.person}
                            setValue={value =>
                              setObjectData({
                                ...objectData,
                                objectContacts: {
                                  ...objectData.objectContacts,
                                  objectContactsMain: {
                                    ...objectData.objectContacts.objectContactsMain,
                                    person: value,
                                  },
                                },
                              })
                            }
                            margin="0"
                            fill={true}
                            error={false}
                            helpText={`Введите ФИО. Например: “Иванов Иван Иванович” `}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder={'ФИО'}
                          />
                        </div>
                        <div className={'objectsPage-popup-box__input'}>
                          <MyPhoneInput
                            disabled={false}
                            placeholder="Номер телефона"
                            setIsError={null}
                            isError={false}
                            isActive={true}
                            setIsActive={null}
                            value={objectData.objectContacts.objectContactsMain.phone?.replace('+', '')}
                            onChange={phone =>
                              setObjectData({
                                ...objectData,
                                objectContacts: {
                                  ...objectData.objectContacts,
                                  objectContactsMain: {
                                    ...objectData.objectContacts.objectContactsMain,
                                    phone: phone,
                                  },
                                },
                              })
                            }
                            helpText={`Введите ФИО. Например: “Иванов Иван Иванович” `}
                            isFunction={null}
                            btnHelp={false}
                          />
                        </div>
                      </div>
                      {objectData.objectContacts.objectContactsAdditionally?.length ? (
                        <div>
                          {objectData.objectContacts.objectContactsAdditionally?.map((el, index) => (
                            <div key={index}>
                              <div className={'objectPage-content__delete'}>
                                <div
                                  className={'objectPage-content__delete-title'}
                                >{`Дополнительный контакт № ${index + 1}`}</div>
                                {!isMobile && (
                                  <div
                                    className={'objectPage-content__delete-button'}
                                    onClick={() => deleteContact(index)}
                                  >
                                    <span>
                                      <StopSvg />
                                    </span>
                                    Удалить
                                  </div>
                                )}
                              </div>
                              <div className={'objectsPage-popup-box__input'}>
                                <InputText
                                  type={''}
                                  secondError={false}
                                  disabled={false}
                                  isLabel={true}
                                  value={objectData.objectContacts?.objectContactsAdditionally[index]?.person}
                                  setValue={value => changeValueContact(index, value, 'person')}
                                  margin="0"
                                  fill={true}
                                  error={false}
                                  helpText={''}
                                  widthHelp={0}
                                  textError={'Поле обязательное для заполнения'}
                                  placeholder={'ФИО'}
                                />
                              </div>
                              <div className={'objectsPage-popup-box__input'}>
                                <MyPhoneInput
                                  disabled={false}
                                  placeholder="Номер телефона"
                                  setIsError={null}
                                  isError={false}
                                  isActive={true}
                                  setIsActive={null}
                                  value={objectData.objectContacts?.objectContactsAdditionally[index]?.phone?.replace(
                                    '+',
                                    '',
                                  )}
                                  onChange={value => changeValueContact(index, value, 'phone')}
                                  isFunction={null}
                                  btnHelp={false}
                                />
                              </div>
                              {isMobile && (
                                <div
                                  className={'objectPage-content__delete-button'}
                                  onClick={() => deleteContact(index)}
                                >
                                  <span>
                                    <StopSvg />
                                  </span>
                                  Удалить
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className={'objectsPage-popup-box__add-button'}>
                        <ButtonFilled
                            onClick={addPerson}
                            text={'Добавить контакт'}
                            svg={<CirclePlusSvg />}
                            widthSvg={'14px'}
                            width={'220px'}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '24px' }}></div>
                  <div className={'page-content-inputs-box'}>
                    <div>
                      <div className={'page-content-inputs-box__title textH5'}>
                        Вид ТС
                        <sup>&nbsp;*</sup>
                      </div>
                    </div>
                    <div className="ProfilePage__inputs">
                      {objectData.typeTC.map(item => (
                        <div key={item.id} className={'orders-page-popup-box__check-box'}>
                          <CheckBoxCustom
                            children={item.title}
                            error={errorCheckBox}
                            check={item.active}
                            pick={pickCheck}
                            name={'typeTC'}
                            id={item.id}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div style={{ borderTop: '1px solid #E5E5E5', margin: '24px 0' }}></div>
                <div style={{ marginTop: '24px' }}>
                  <div className={'page-content-inputs-box'}>
                    <div>
                      <div className={'page-content-inputs-box__title textH5'}>
                        График приемки
                        <sup>&nbsp;*</sup>
                      </div>
                    </div>
                    <div className="ProfilePage__checkbox">
                      <div>
                        <div className={'orders-page-popup-box__radio'}>
                          <RadioBoxCircle
                            check={objectData.workingHoursType.name === 'aroundTheClock'}
                            onInput={value =>
                              setObjectData({
                                ...objectData,
                                workingHoursType: { ...objectData.workingHoursType, name: 'aroundTheClock' },
                              })
                            }
                            name={'TypeOfAcceptance1'}
                            id={'5'}
                            value={'Круглосуточная'}
                          />
                        </div>
                        <div className={'orders-page-popup-box__radio'}>
                          <RadioBoxCircle
                            check={objectData.workingHoursType.name === 'daytime'}
                            onInput={value =>
                              setObjectData({
                                ...objectData,
                                workingHoursType: { ...objectData.workingHoursType, name: 'daytime' },
                              })
                            }
                            name={'TypeOfAcceptance1'}
                            id={'6'}
                            value={'Дневная'}
                          />
                        </div>
                        <div className={'orders-page-popup-box__box-time'}>
                          <div className={'orders-page-popup-box__time'}>
                            <TimePicker
                              showNow={false}
                              needConfirm={false}
                              placeholder={'Период времени'}
                              format={'HH:mm'}
                              onChange={(time, timeString) => changeTime(time, timeString, 'workTo', 'workTimeTo')}
                              disabled={objectData.workingHoursType.name !== 'daytime'}
                              defaultValue={objectData.workingHoursType.workTo}
                              open={openLeftTimer}
                              onClick={() => setOpenLeftTimer(true)}
                              renderExtraFooter={() => customTimePickerButton(true)}
                            />
                          </div>
                          <div className={'orders-page-popup-box__or'}>до</div>
                          <div className={'orders-page-popup-box__time'}>
                            <TimePicker
                              showNow={false}
                              needConfirm={false}
                              placeholder={'Период времени'}
                              format={'HH:mm'}
                              onChange={(time, timeString) => changeTime(time, timeString, 'workFrom', 'workTimeFrom')}
                              disabled={objectData.workingHoursType.name !== 'daytime'}
                              open={openRightTimer}
                              onClick={() => setOpenRightTimer(true)}
                              renderExtraFooter={() => customTimePickerButton(false)}
                              status={validateTime}
                              defaultValue={objectData.workingHoursType.workFrom}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="ProfilePage__container">
                            <TitleName field={false} children="" />
                            <div className="page-content-inputs-box__button">
                              <ButtonFilled margin={'40px 0 0 0'}
                                            text={'Сохранить'}
                                            onClick={sendObjectData}
                                            width={'100%'}
                                            disabled={disabled}
                              />
                            </div>
                            <div className={'page-content-inputs-box__button'}>
                              <ButtonNotFilled
                                color={'#ff5100'}
                                onClick={deleteObject}
                                svg={<Basket />}
                                widthSvg={'18px'}
                                text={'Удалить объект'}
                                width={'100%'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
};
