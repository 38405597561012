import React from 'react';

const PrevCarouselSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="17" viewBox="0 0 8 17" fill="none">
            <path d="M7 1.5L1 8.5L7 15.5" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default PrevCarouselSvg;