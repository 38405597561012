import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../../../../../components/PageWrapper';
import { SkeletonDocuments } from '../../../../../../components/skeletons/SkeletonDocuments';
import { CheckBoxCustom } from '../../../../../../components/UI/checkBox/CheckBoxCustom';
import { FrameScroll } from '../../../../../../components/FrameScroll';
import { fetchGet, fetchPost } from '../../../../../../common/proxy-1C-fetch-auth';
import { formPrice } from '../../../../../../common/form-price.helper';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../../../../components/UI/buttons/Button';
import { NotificationPopup } from '../../../../../../components/NotificationPopup';
import { ButtonTransparent } from '../../../../../../components/UI/buttons/ButtonTransparent';
import { SelectBox } from '../../../../../../components/SelectBox';
import { SelectCustom } from '../../../../../../components/UI/select/SelectCustom';
import { useMediaQuery } from 'react-responsive';
import { EmptyList } from '../../../../../../components/EmptyList';
import { openPopUp } from '../../../../../../common/open-close-popup.function';
import CustomRangePicker from '../../../../../../components/newUI/CustomRangePicker/CustomRangePicker';
import CheckBoxDocumentsPage from '../../../CheckBoxDocumentsPage/CheckBoxDocumentsPage';
import { downloadedOrPrintFile } from '../../../../../../common/downloaded-or-print.function';
import { toast } from 'react-toastify';
import { clearObject } from '../../../../../../common/ClearObject.helper';
import ButtonNotFilled from "../../../../../../components/newUI/ButtonNotFilled/ButtonNotFilled";
import ButtonFilled from "../../../../../../components/newUI/ButtonFilled/ButtonFilled";
import { toastError } from 'src/common/toastError.helper';

export const CreationOfRegistry = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  const [skeleton, setSkeleton] = useState(true);
  const [more, setMore] = useState(10);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [popUpActive, setPopUpActive] = useState(false);
  const [filterButton, setFilterButton] = useState(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>(['', '']);
  const [selectNomenclature, setSelectNomenclature] = useState([]);
  const [selectFlightType, setSelectFlightType] = useState<{ value: string; label: string }>();
  const [documentList, setDocumentList] = useState<any>([]);
  const [documentListTimes, setDocumentListTimes] = useState<any>([]);
  const [nomenclature, setNomenclature] = useState<any>([]);
  const [content, setContent] = useState<string>('send');
  const [isFirstRender, setIsFirstRender] = useState<number>(0);
  const [errorText, setErrorText] = useState<string>('');
  const flightType = [
    { value: 'delivery', label: 'Перевозка' },
    { value: 'transportation', label: 'Поставка' },
  ];
  const documentDta: {}[] = [];
  const optionsNomenclature = [];
  const optionsAddress = [];
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const sendDocuments = async () => {
    const data = [];

    documentListTimes.forEach(({ id, active }) => {
      if (active) {
        data.push(id);
      }
    });

    try {
      const response = await fetchPost(`/registries`, 'POST', {
        documentsIds: data,
      });

      if (response.error) {
        setContent('error');
        setErrorText(response.message[0]);
        console.log('error');
        return;
      }

      setContent('success');
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (content === 'success' && popUpActive === false) {
      navigate('/document');
    }
  }, [content, popUpActive]);

  const getDocuments = async (id?: any[], flightType?: string) => {
    let requestData = {};
    requestData = {
      startDate: dateRange[0] ? new Date(dateRange[0]) : undefined,
      finishDate: dateRange[1] ? new Date(dateRange[1]) : undefined,
      nomenclaturesId: id?.length > 0 ? id : undefined,
      flightType: flightType ? flightType : undefined,
    };
    let correctRequestData = clearObject(requestData);

    if (isEmpty(requestData)) {
      setIsFirstRender(isFirstRender + 1);
    }

    try {
      const documentsResponse = await fetchGet('/flights/documents', {
        ...correctRequestData,
        operation: 'create',
      });

      for (const data of documentsResponse.response) {
        documentDta.push({
          id: data.id,
          item: {
            'Дата документа': {
              text: `${new Date(data?.dateTn).toLocaleString().split(',')[0]}`,
            },
            Поставщик: {
              text: !!data?.supplier?.title ? data?.supplier?.title : 'Нет данных',
            },
            'Пункт отправления': { /*тут*/
              text: !!data?.quarry?.address?.address ? data?.quarry?.address?.address : 'Нет данных',
            },
            Заказчик: {
              text: !!data?.client?.title ? data.client?.title : 'Нет данных',
            },
            'Пункт назначения': {
              text: !!data?.object?.address?.address ? data?.object?.address?.address : 'Нет данных',
            },
            Номенклатура: {
              text: !!data?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument
                  ? data?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument : 'Нет данных',
            },
            '№ТТН/ТН': {
              text: !!data?.numberTn ? data?.numberTn : 'Нет данных',
            },
            '№ТС': {
              text:
                !!data?.car?.stateNumber && !!data?.car?.carBrand?.brand
                  ? `${data?.car?.carBrand?.brand} ${data?.car?.stateNumber}`
                  : 'Нет данных',
            },
            Водитель: {
              text: data?.driver?.fullName ? data?.driver?.fullName : 'Нет данных',
            },
            'Вес/Объем': {
              text: !!data?.unloadingVolume ? data?.unloadingVolume : 'Нет данных',
            },
            'Ед. изм.': {
              text: !!data?.unitUnloading ? data?.unitUnloading : 'Нет данных',
            },
            'Цена перевозки': {
              // @ts-ignore
              text: !!data?.carrierPrice ? formPrice(Number(data?.carrierPrice) || 0, 'RUB') : 'Нет данных',
            },
            Сумма: {
              text:
                !!data?.carrierPrice && !!data?.unloadingVolume
                  ? formPrice(Number((data?.carrierPrice * data?.unloadingVolume).toFixed(2)) || 0, 'RUB')
                  : 'Нет данных',
            },
          },
          active: false,
        });
      }
      if (isEmpty(requestData))
        documentsResponse.response.forEach((item, index) => {
          if (optionsNomenclature.find(({ label }) => label === item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument)) {
            return;
          }

          optionsNomenclature.push({ value: item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.id, label:
            item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument });
        });

      documentsResponse.response.forEach((item, index) => {
        if (optionsAddress.find(({ label }) => label === item?.object?.address?.address)) {
          return;
        }

        optionsAddress.push({ value: item?.object?.address?.address, label: item?.object?.address?.address });
      });

      setDocumentList(documentDta);
      setDocumentListTimes(documentDta);
      if (isEmpty(requestData)) {
        setNomenclature(optionsNomenclature);
      }

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      setTimeout(() => {
        setSkeleton(false);
      }, 800);
      console.log(e);
    }
  };

  const pickCheck = (id: number | string) => {
    const newDocumentList = [];
    if (id === 'all') {
      if (checkAll === false) {
        documentList.forEach(item => {
          item.active = true;
          newDocumentList.push(item);
        });
      } else {
        documentList.forEach(item => {
          item.active = false;
          newDocumentList.push(item);
        });
      }
    } else {
      documentList.forEach(item => {
        if (item.id === id) {
          item.active = !item.active;
        }
        newDocumentList.push(item);
      });
    }
    setDocumentList(newDocumentList);
  };

  useEffect(() => {
    let activeFilterDocumentList = documentList.filter(item => item.active === true);
    if (activeFilterDocumentList.length === documentList.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [pickCheck]);

  const filterByFlightType = () => {
    const arrayIds = selectNomenclature.map(itemNomenclatur => {
      return itemNomenclatur.value;
    });
    if (isFirstRender >= 1) {
      getDocuments(arrayIds, selectFlightType?.value).catch(console.log);
    }
  };

  useEffect(() => {
    filterByFlightType();
  }, [selectFlightType, selectNomenclature, dateRange]);

  const downloadedPreviewRegistry = async () => {
    const data = [];

    documentListTimes.forEach(({ id, active }) => {
      if (active) {
        data.push(id);
      }
    });

    try {
      const response = await fetchPost(`/reports/registry/preview`, 'POST', {
        documentsIds: [...new Set(data)],
      });
      if (!response.error) {
        let pdfWindow = window.open('');
        pdfWindow.document.write('<html<head><title>' + 'fileName' + '</title><style>body{margin: 0;}</style></head>');
        pdfWindow.document.write(
          `<body><embed width='100%' height='100%' src='${encodeURI(response?.response?.file)}'></embed></body></html>`,
        );
        pdfWindow.document.close();
      }

      if (toastError(response)) return;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDocuments().catch(console.log);
  }, []);

  useEffect(() => {
    setButtonDisabled(!documentList.some(({ active }) => active));
  }, [pickCheck]);

  return (
    <>
      {skeleton ? (
        <PageWrapper>
          <SkeletonDocuments />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <div className={'documents-registries-page'}>
            <Link className="button-back" to="/document">
              <div className="button-back__svg">
                <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              К списку документов
            </Link>
            <div
              style={{ margin: '40px 0 32px', display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'end' }}
            >
              <div className={'documents-registries-page__button'}>
                <ButtonNotFilled
                    text={'Предпросмотр реестра'}
                    width={'240px'}
                    onClick={() => downloadedPreviewRegistry()}
                    disabled={buttonDisabled}
                />
              </div>
              <div className={'documents-registries-page__button'}>
                <ButtonFilled
                    onClick={() => {
                      setPopUpActive(openPopUp);
                      setContent('send');
                    }}
                    disabled={buttonDisabled}
                    width={'240px'}
                    text={'Сформировать'}
                />
              </div>
            </div>
            <div className={'documents-registries-page__selects'}>
              <SelectBox gridTemplateColumns={isMobile ? '100%' : '310px 310px 310px'}>
                <CustomRangePicker
                  width={'100%'}
                  isPresets={true}
                  open={openCalendar}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setOpen={setOpenCalendar}
                />
                <SelectCustom
                  options={nomenclature}
                  maxTagCount={2}
                  placeholder={'По номенклатуре'}
                  mode={'multiple'}
                  value={selectNomenclature ? selectNomenclature : []}
                  setValue={setSelectNomenclature}
                  disabled={false}
                  status={''}
                  errorText={''}
                />
                <SelectCustom
                  allowClear
                  options={flightType}
                  maxTagCount={1}
                  placeholder={'По типу договора'}
                  value={selectFlightType}
                  setValue={setSelectFlightType}
                  disabled={false}
                  status={''}
                  errorText={''}
                />
              </SelectBox>
            </div>
            {documentList ? (
              <FrameScroll
                items={documentList.slice(0, more)}
                gridTemplateColumnsLeft={isMobile ? '1fr' : '20px 128px 128px 149px'}
                gridTemplateColumnsRight={
                  isMobile ? '1fr' : '128px 128px 149px 128px 128px 149px 128px 128px 149px 128px'
                }
                paddingLeft={'475px'}
                asideElement={(name, id, check) => (
                  <div>
                    <CheckBoxDocumentsPage
                      children={''}
                      error={false}
                      check={check}
                      pick={pickCheck}
                      name={name}
                      id={id}
                      checkAll={checkAll}
                    />
                  </div>
                )}
                slice={3}
              />
            ) : (
              <EmptyList title={'Реестры не найдено'} subTitle={'В данном разделе пока нет Реестров'} />
            )}
            {documentList?.length > more - 1 && (
                <ButtonNotFilled
                    margin={'28px 0 0 0'}
                    onClick={() => setMore(more + 10)}
                    text={'Показать ещё'}
                    width={'100%'}
                    color={'#ff5100'}
                />
            )}
          </div>
          <NotificationPopup
            popUpActive={popUpActive}
            setPopUpActive={setPopUpActive}
            content={content}
            send={sendDocuments}
            title={'Вы уверены, что хотите сформировать реестр?'}
            subTitle={'После подтверждения создания, Реестр нельзя будет отредактировать'}
            titleError={'Произошла ошибка'}
            subTitleError={`${errorText}`}
            titleSuccess={'Реестр сформирован'}
            subTitleSuccess={'Сформированный реестр появится в таблице раздела Реестр'}
            textButtonSend={'Да, подтвердить'}
            textButtonClose={'Нет, вернуться к формированию'}
          />
        </PageWrapper>
      )}
    </>
  );
};
