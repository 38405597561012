import React, { useEffect, useState } from 'react';
import CustomTable from '../../../components/newUI/CustomTable/CustomTable';
import styles from './AwaitingShipment.module.scss';
import { useMediaQuery } from 'react-responsive';
import { InputSearch } from '../../../components/UI/inputs/InputSearch';
import TextBoxSearchOutlineSvg from '../../../components/UI/svg-icon/textBoxSearchOutlineSvg';
import CustomInputValueAndCheckBox from '../../../components/newUI/CustomInputValueAndCheckBox/CustomInputValueAndCheckBox';
import { fetchGet, fetchPost } from '../../../common/proxy-1C-fetch-auth';
import { Filter } from '../../../common/filter.type';

import MobileAwaitingShipment from './MobileAwaitingShipment/MobileAwaitingShipment';
import { toast } from 'react-toastify';
import { clearObject } from '../../../common/ClearObject.helper';
import { IDataResponseAwaitingShipped } from '../type';
import { convertActualVisionUnit } from 'src/common/convertActualVisionUnit.helper';
import { toastError } from 'src/common/toastError.helper';
interface IProps {
  callBackDataCount: (count: string) => void;
  callBackDataAwaitingShipment: (flights: IDataResponseAwaitingShipped[]) => void;
  tab: string;
  isQr: boolean;
  updatePage: boolean;
}

const AwaitingShipment = ({ callBackDataCount, tab, callBackDataAwaitingShipment, isQr, updatePage }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [dataResponse, setDataResponse] = useState<IDataResponseAwaitingShipped[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<Filter>({ search: '', sort: { field: 'сначала новые', order: 'DESC' } });
  const correctOffsetGetData = async () => {
    setOffset(0);
    if (tab === '1') {
      getData(false);
    }
  };
  useEffect(() => {
    correctOffsetGetData();
  }, [tab, filter, isQr, updatePage]);
  useEffect(() => {
    setOffset(0);
    getData(false);
  }, [updatePage]);

  const getData = async (isPagination: boolean) => {
    const requsetOption = {
      searchString: filter.search ? filter.search : undefined,
      status: 'ACTIVE',
      limit: 10,
      offset: isPagination ? offset : 0,
    };
    clearObject(requsetOption);

    setLoading(true);
    try {
      const response = await fetchGet('/shipments', requsetOption);
      const responseTableData = response?.response?.rows?.map(item => {
        return {
          flight: {
            flightName: item?.flightName,
            id: item.id,
          },
          loading: {
            organization: item?.supplier?.title,
            title: item?.quarry?.address?.address,
          },
          vehicle: {
            car: item?.car?.carBrand?.brand,
            number: item?.car?.stateNumber,
            driver: item?.driver?.fullName,
          },
          volumeAndLoad: {
            unit: item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.unit,
            volume: item?.volumePlan,
            nomenclature: item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
          },
          actualLoading: {
            id: item.id,
            unit: item?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.unit,
          },
        };
      });
      callBackDataCount(response?.response?.count);
      setTotalCount(response?.response?.count);
      if (isPagination && offset !== 0) {
        await setDataResponse(prevState => [...prevState, ...responseTableData]);
        callBackDataAwaitingShipment([...dataResponse, ...responseTableData]);
      } else {
        await setDataResponse(responseTableData);
        callBackDataAwaitingShipment(responseTableData);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (tab === '1' && totalCount > dataResponse?.length) {
      getData(true);
    }
  }, [offset]);
  const downloadRegistry = async id => {
    try {
      const response = await fetchPost(`/attorneys/generate`, 'POST', { id });
      if (toastError(response)) return;

      const link = document.createElement('a');
      link.download = response?.response?.name;

      link.href = response?.response?.file;

      link.click();

      return;
    } catch (e) {
      console.log(e);
    }
  };
  // @ts-ignore
  const columns = [
    {
      title: 'Рейс',
      dataIndex: 'flight',
      width: 168,
      key: 'flight',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '24px 0px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div className={styles.svgContainer} onClick={() => downloadRegistry(text?.id)}>
              <TextBoxSearchOutlineSvg />
            </div>
            <div className={styles.textBorder}>
              <p className={styles.text}>{text?.flightName}</p>
            </div>
          </div>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Загрузка',
      dataIndex: 'loading',
      width: 294,
      key: 'loading',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div className={styles.textContainer}>
            <p className={styles.topText}>{text?.organization}</p>
            <p className={styles.bottomText}>{text?.title}</p>
          </div>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Транспортное средство',
      dataIndex: 'vehicle',
      width: 294,
      key: 'vehicle',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div className={styles.textContainer}>
            <p className={styles.topText}>
              {text?.car} {text?.number}
            </p>
            <p className={styles.bottomText}>{text?.driver}</p>
          </div>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Объем и груз',
      dataIndex: 'volumeAndLoad',
      width: 294,
      key: 'volumeAndLoad',
      render: text => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div className={styles.textContainer}>
            <p className={styles.topText}>
              {text?.volume} {convertActualVisionUnit(text?.unit)}
            </p>
            <p className={styles.bottomText}>{text?.nomenclature}</p>
          </div>
          <div style={{ color: '#E5E5E5', height: '28px', border: '1px solid #E5E5E5', marginLeft: 'auto' }}></div>
        </div>
      ),
    },
    {
      title: 'Фактическая погрузка с ед. измерения',
      dataIndex: 'actualLoading',
      width: 236,
      key: 'actualLoading',
      render: text => (
        <div>
          <CustomInputValueAndCheckBox
            dataResponse={dataResponse}
            setDataResponse={setDataResponse}
            id={text?.id}
            unit={convertActualVisionUnit(text?.unit)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={!isMobile && styles.Container}>
      {isMobile ? (
        /*Мобильная версия*/
        <MobileAwaitingShipment filter={filter} setFilter={setFilter} dataResponse={dataResponse} />
      ) : (
        /*Компьютерная версия*/
        <div>
          <div className={styles.containerInput}>
            <div>
              <InputSearch
                placeholder={'Поиск по номеру рейса, водителю или номеру машины'}
                onInput={({ currentTarget: { value: search } }) => setFilter(prevState => ({ ...prevState, search }))}
                value={filter?.search}
              />
            </div>
          </div>
          <div>
            <CustomTable loading={loading} link={false} columns={columns} data={dataResponse} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AwaitingShipment;
