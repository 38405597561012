import React, { useState } from 'react';
import { IFilters } from '../../type';
import GridCardAdd from './components/GridCardAdd/GridCardAdd';
import ListCardAdd from './components/ListCardAdd/ListCardAdd';

interface IProps {
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
  filters: IFilters;
  drivers: { value: number; label: string }[];
  checkAvailableDrivers: boolean;
}
const GSMCardAdd = ({ setOpenModalOfAddGSMCard, filters, drivers, checkAvailableDrivers }: IProps) => {
  return (
    <>
      {filters.grid === 'Сетка' ? (
        <GridCardAdd
          checkAvailableDrivers={checkAvailableDrivers}
          drivers={drivers}
          setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard}
        />
      ) : (
        <ListCardAdd
          checkAvailableDrivers={checkAvailableDrivers}
          drivers={drivers}
          setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard}
        />
      )}
    </>
  );
};

export default GSMCardAdd;
