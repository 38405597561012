import React, { useEffect, useRef, useState } from 'react';
import styles from './OpenPhoto.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Carousel, Modal } from 'antd';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import NextCarouselSvg from '../../../../components/UI/svg-icon/NextCarouselSvg';
import PrevCarouselSvg from '../../../../components/UI/svg-icon/PrevCarouselSvg';
import DrawerMobile from '../../../../components/newUI/DrawerMobile/DrawerMobile';
import { Image } from '../../../../common/image.type';
import { svgByMaterial } from '../../../ShowcasesPage/constans';
import { ICurrentMaterialData } from '../../type';
interface IProps {
    images:Image[]
    openPhoto:boolean
    setOpenPhoto:React.Dispatch<boolean>
    initialSlide:number
    setInitialSlide:React.Dispatch<number>
    currentMaterialData:ICurrentMaterialData
}
const OpenPhoto = ({ images,openPhoto,setOpenPhoto,initialSlide,setInitialSlide,currentMaterialData }:IProps) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const slider = useRef(null);
    const slider2 = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [widthCarouselContainer, setWidthCarouselContainer] = useState<number>(0);
    useEffect(() => {
        /*Вешаем слушатель события разрешения(ширины) окна*/
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        /*Подписка на событие*/
        window.addEventListener('resize', handleResize);

        if(windowWidth>320){
                setWidthCarouselContainer(windowWidth-32);
        }
        /*Удаляем событие*/
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth]);
    useEffect(() => {
        const rooDiv = document.getElementById('root');
        if (openPhoto) {
            rooDiv.style.overflow = 'hidden';
            rooDiv.style.height = '100vh';
            rooDiv.style.position = 'relative';
        } else {
            rooDiv.style.overflow = 'unset';
        }
    }, [openPhoto]);

    useEffect(() => {
        if (slider.current) {
            slider?.current?.goTo(initialSlide);
            slider2?.current?.goTo(initialSlide);
        }
    }, [openPhoto]);
    return (
        <>
            {isMobile ?
                <>
                    <div style={{ width:`${widthCarouselContainer}px` }} className={styles.carouselContainerMobile}>
                        <Carousel
                            initialSlide={initialSlide}
                            afterChange={(e)=>setInitialSlide(e)}
                            dots={false}
                            infinite={false}
                            ref={slider}
                        >
                            {images?.length ? images?.map((image,index)=>(
                                <div key={index} onClick={()=> {
                                    setInitialSlide(index);
                                    setOpenPhoto(true);
                                }}>
                                    <img className={styles.photoMobile}  src={image?.location} alt="img"/>
                                </div>
                            ))
                                :
                                <div className={styles.photoMobile} onClick={()=>setOpenPhoto(true)}>
                                    {svgByMaterial[currentMaterialData?.breed]}
                                </div>
                            }
                        </Carousel>
                        <div className={styles.dotsContainer}>
                            {images.map((image,index)=>(
                                <div
                                    key={index}
                                    className={initialSlide === index ? styles.focusDot : styles.dot}
                                    onClick={()=> {
                                        slider.current.goTo(index);
                                        setInitialSlide(index);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                    <DrawerMobile open={openPhoto} close={()=> setOpenPhoto(false)}>
                        <div style={{ display:'flex',height:'100%',alignItems:'center',marginLeft:'-16px' }}>
                            <div onClick={()=>setOpenPhoto(true)} style={{ width:`${widthCarouselContainer+32}px` }}>
                                <Carousel
                                    afterChange={(e)=> {
                                        setInitialSlide(e);
                                    }} 
                                    dots={false}
                                    initialSlide={initialSlide} ref={slider2}
                                    speed={0} effect={'fade'} adaptiveHeight={true} infinite={false}
                                >
                                    {images?.length ? images?.map((image,index)=>(
                                        <img key={index} src={image?.location} alt="img"/>
                                    ))
                                        :
                                        svgByMaterial[currentMaterialData?.breed]
                                    }
                                </Carousel>
                                <div className={styles.dotsContainerFixed}>
                                    {images.map((image,index)=>(
                                        <div
                                            key={image.value}
                                            className={initialSlide === index ? styles.focusDot : styles.dot}
                                            onClick={()=> {
                                                slider2.current.goTo(index);
                                                setInitialSlide(index);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </DrawerMobile>
                </>
                :
                <Modal
                    width={'fit-content'}
                    zIndex={9500}
                    footer={null}
                    closeIcon={false}
                    open={openPhoto}
                    onCancel={()=>setOpenPhoto(false)}
                    className={styles.modal}
                >
                    <div className={styles.title}>Щебень гравийный</div>
                    <div className={styles.carouselContainer}>
                        <Carousel afterChange={(e)=>setInitialSlide(e)}
                                  dots={false}
                                  initialSlide={initialSlide}
                                  speed={0}
                                  effect={'fade'}
                                  ref={slider}
                                  infinite={false}
                                  adaptiveHeight={true}
                        >
                            {images?.length ? images?.map((image:any,index)=>(
                                <img key={index} src={image?.location} alt={''}/>
                            ))
                                :
                                svgByMaterial[currentMaterialData?.breed]
                            }
                        </Carousel>
                        {images?.length >= 1 &&
                            <>
                                <div className={styles.arrowNext} onClick={()=> slider.current.next()}><NextCarouselSvg/></div>
                                <div className={styles.arrowBack} onClick={()=> slider.current.prev()}><PrevCarouselSvg/></div>
                            </>
                        }
                        <div className={styles.dotsContainer}>
                        {images?.map((image,index)=>(
                                <div
                                    key={index}
                                    className={initialSlide === index ? styles.focusDot : styles.dot}
                                    onClick={()=> {
                                        slider.current.goTo(index);
                                        setInitialSlide(index);
                                    }}
                                />
                        ))}
                        </div>
                    </div>
                    <div className={styles.closeButton}>
                        <FrameModalSvg onClick={()=>setOpenPhoto(false)} />
                    </div>
                </Modal>
            }
        </>
        
    );
};

export default OpenPhoto;