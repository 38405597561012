import React from 'react';

export const ShareGradientSvg = ({
  width = '16px',
  colorOne = '#3B519D',
  colorTwo = '#1C274C',
}: {
  width?: string;
  colorOne?: string;
  colorTwo?: string;
}) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#3B519D" />
      <g clip-path="url(#clip0_25988_112173)">
        <path
          d="M18.6976 21.332C17.6936 20.3242 17.7841 18.5993 18.8996 17.4794L22.1314 14.2351C23.2469 13.1153 24.9651 13.0245 25.9691 14.0323C26.973 15.0402 26.8826 16.7651 25.7671 17.8849L24.1512 19.5071"
          stroke="#3B519D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M21.3026 18.668C22.3065 19.6758 22.2161 21.4007 21.1006 22.5206L19.4847 24.1427L17.8688 25.7649C16.7533 26.8848 15.0351 26.9755 14.0311 25.9677C13.0271 24.9598 13.1176 23.2349 14.2331 22.115L15.849 20.4929"
          stroke="#1C274C"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_25988_112173">
          <rect width="16" height="16" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  );
};
