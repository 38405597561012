import React, { useEffect, useState } from 'react';
import styles from './FavoritesPage.module.scss';
import { PageWrapper } from 'src/components/PageWrapper';
import { Link, useNavigate } from 'react-router-dom';
import { FavoriteMaterials } from './FavoriteMaterials/FavoriteMaterials';
import ShowcaseCards from 'src/Pages/ShowcasesPage/components/ShowcaseCards/ShowcaseCards';
import { dataCardResponseType, IMaterials } from 'src/Pages/ShowcasesPage/type';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import { toastError } from 'src/common/toastError.helper';
import cx from 'classnames';

export const FavoritesPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'materials' | 'showcases' | ''>('');
  const [materials, setMaterials] = useState<IMaterials[]>([]);
  const [showcases, setShowcases] = useState<dataCardResponseType[]>([]);

  useEffect(() => {
    const savedTab = localStorage.getItem('savedTab');
    setActiveTab(savedTab !== null ? JSON.parse(savedTab) : 'materials');
  }, []);

  useEffect(() => {
    if (activeTab) {
      localStorage.setItem('savedTab', JSON.stringify(activeTab));
      getItems();
    }
  }, [activeTab]);

  const getItems = async () => {
    try {
      const response = await fetchGet('/users/favourites', {
        category: activeTab === 'showcases' ? activeTab : 'materialGroups',
      });
      if (toastError(response)) return;
      if (activeTab === 'showcases') {
        setShowcases(response.response);
        return;
      }
      setMaterials(response.response);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageWrapper>
      <div className={styles.topContainer}>
        <Link
          className="button-back"
          to="/catalog"
          onClick={() => {
            navigate(-1);
          }}
        >
          <div className="button-back__svg">
            <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                fill="currentColor"
              />
            </svg>
          </div>
          Назад
        </Link>
      </div>
      <div className={styles.container}>
        <div className={'textH4 textH4_medium'}>Избранное</div>
        <div className={styles.tabsContainer}>
          <div
            className={cx(styles.tab, activeTab === 'materials' && styles.activeTab)}
            onClick={() => setActiveTab('materials')}
          >
            Материалы
          </div>
          <div
            className={cx(styles.tab, activeTab === 'showcases' && styles.activeTab)}
            onClick={() => setActiveTab('showcases')}
          >
            Витрины
          </div>
        </div>
        {activeTab === 'materials' ? (
          <FavoriteMaterials materials={materials} />
        ) : (
          <ShowcaseCards dataCardResponse={showcases} />
        )}
      </div>
    </PageWrapper>
  );
};
