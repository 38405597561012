import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { DevelopmentSvg } from '../../components/UI/svg-icon/DevelopmentSvg';
import { TitlePage } from '../../components/TitlePage';
import { toast, ToastContainer } from 'react-toastify';
import { Button } from '../../components/UI/buttons/Button';
import { PlusSvg } from '../../components/UI/svg-icon/PlusSvg';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { useMediaQuery } from 'react-responsive';
import { ButtonTransparent } from '../../components/UI/buttons/ButtonTransparent';
import { AsidePopup } from '../../components/AsidePopup';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { CloseSvg } from '../../components/UI/svg-icon/CloseSvg';
import { InputText } from '../../components/UI/inputs/InputText';
import { RadioBoxCircle } from '../../components/UI/checkBox/RadioBoxCircle';
import { CirclePlusSvg } from '../../components/UI/svg-icon/CirclePlusSvg';
import { NavLink, useNavigate } from 'react-router-dom';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { StopSvg } from '../../components/UI/svg-icon/StopSvg';
import { SkeletonObjects } from '../../components/skeletons/SkeletonObjects';
import { ArrowSvg } from '../../components/UI/svg-icon/ArrowSvg';
import { CheckBoxCustom } from '../../components/UI/checkBox/CheckBoxCustom';
import { TimePicker } from 'antd';
import CustomYMap from '../../components/CustomYMap/CustomYMap';
import { IObject } from '../ObjectPage/types/object';
import styles from './ObjectsPage.module.scss';
import CustomAddressInput from '../../components/newUI/CustomAddressInput/CustomAddressInput';
import { ButtonTextTransparent } from '../../components/UI/buttons/ButtonTextTransparent';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import TitleMain from "../../components/UI/TitleAndSubtitle/TitleMain";
import ButtonFilled from "../../components/newUI/ButtonFilled/ButtonFilled";
import NewPlusSvg from "../../components/UI/svg-icon/NewPlusSvg";
import SubTitleMain from "../../components/UI/TitleAndSubtitle/SubTitleMain";
import ButtonNotFilled from "../../components/newUI/ButtonNotFilled/ButtonNotFilled";
import { toastError } from 'src/common/toastError.helper';
export const ObjectsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [disabled, setDisabled] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [sending, setSending] = useState(false);
  const [development] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [valueAddress, setValueAddress] = useState<DaDataSuggestion<DaDataAddress> | undefined>();
  const [openMap, setOpenMap] = useState(false);
  const [popup, setPopup] = useState(false);
  const [objectItems, setObjectItems] = useState([]);
  const [errorCheckBox, setErrorCheckBox] = useState(false);
  const [openLeftTimer, setOpenLeftTimer] = useState(false);
  const [openRightTimer, setOpenRightTimer] = useState(false);
  const [more, setMore] = useState<number>(isMobile ? 4 : 10);
  const navigate = useNavigate();
  const [objectData, setObjectData] = useState<IObject>({
    name: '',
    address: {
      address: '',
      latitude: 0,
      longitude: 0,
    },
    comment: '',
    typeTC: [
      {
        id: 'everyone',
        active: false,
        title: 'Проходят все',
      },
      {
        id: 'semitrailer-30-45',
        active: false,
        title: 'Полуприцеп 30 м³ - 45 т',
      },
      {
        id: 'four-axis-30-45',
        active: false,
        title: '4х-осные 30 м³ - 45 т',
      },
      {
        id: 'four-axis-20-30',
        active: false,
        title: '4х-осные 20 м³ - 30 т',
      },
      {
        id: 'three-axis-10-15',
        active: false,
        title: '3х-осные 10 м³ - 15 т',
      },
    ],
    objectContacts: {
      objectContactsMain: {
        person: '',
        phone: '',
      },

      objectContactsAdditionally: [],
    },
    workingHoursType: {
      name: '',
      workTo: '',
      workFrom: '',
    },
    semitrailers: ['four-axis-30-45', 'semitrailer-30-45'],
  });

  const getDataObjects = async () => {
    try {
      const objects = await fetchGet(`/objects`, {});

      if (!objects.response) {
        return;
      }

      setObjectItems(objects.response);

      setTimeout(() => {
        setSkeleton(false);
      }, 800);
    } catch (e) {
      console.log(e);
    }
  };

  const sendObjectData = async () => {
    const correctionTime =
      objectData.workingHoursType.name === 'daytime'
        ? {
            workingHoursType: objectData.workingHoursType.name,
            from: objectData.workingHoursType.workTo,
            to: objectData.workingHoursType.workFrom,
          }
        : {
            workingHoursType: objectData.workingHoursType.name,
          };

    try {
      const dataObject = await fetchPost(`/objects`, 'POST', {
        name: objectData.name.trim(),
        address: {
          address: valueAddress.value,
          latitude: Number(objectData?.address?.latitude),
          longitude: Number(objectData?.address?.longitude),
        },
        comment: objectData.comment,
        contactPersons: [
          {
            person: objectData.objectContacts.objectContactsMain.person.trim(),
            phone: objectData.objectContacts.objectContactsMain.phone,
            main: true,
          },
          ...objectData.objectContacts.objectContactsAdditionally.map(contact => ({
            person: contact.person.trim(),
            phone: contact.phone,
            main: false,
          })),
        ],
        semitrailers: objectData.semitrailers,
        time: correctionTime,
      });
      if (toastError(dataObject)) {
        setSending(false);
        setErrorCheckBox(!objectData.semitrailers.length);
        return;
      }
      toast.success('Данные успешно сохранены');
      setUpdatePage(!updatePage);
      setPopup(closePopUp);
      setSending(false);
      navigate('/objects');
    } catch (e) {
      setSending(false);
      console.log(e);
    }
  };

  const pickCheck = id => {
    const newTypeTC = [];
    const newTypeTCSend = [];

    if (id === 'everyone') {
      const { active } = objectData.typeTC.find(item => item.id === id);

      objectData.typeTC.forEach(item => {
        active ? (item.active = false) : (item.active = true);

        newTypeTC.push(item);
      });

      const semitrailersIds = objectData.typeTC
        .filter(item => item.active && item.id !== 'everyone')
        .map(item => item.id);

      setObjectData(prevState => ({ ...prevState, typeTC: newTypeTC, semitrailers: semitrailersIds }));

      return;
    }

    objectData.typeTC.forEach(item => {
      if (item.id === 'everyone') {
        item.active = false;
      }

      newTypeTC.push(item);
    });

    newTypeTC.splice(0, newTypeTC.length);

    objectData.typeTC.forEach(item => {
      if (item.id === id) {
        item.active = !item.active;
      }

      newTypeTC.push(item);
      if (item.active) {
        newTypeTCSend.push(item.id);
      }
    });

    setObjectData(prevState => ({ ...prevState, typeTC: newTypeTC, semitrailers: newTypeTCSend }));
  };

  const changeTime = (time: any, timeString: string | string[], input: string, inputTime: string) => {
    setObjectData(prevState => ({
      ...prevState,
      workingHoursType: {
        ...prevState.workingHoursType,
        [input]: timeString,
        [inputTime]: timeString,
      },
    }));
  };

  const deleteContact = (value: number) => {
    setObjectData(prevState => {
      const copy = { ...prevState };
      copy.objectContacts.objectContactsAdditionally = [...copy.objectContacts.objectContactsAdditionally];
      copy.objectContacts.objectContactsAdditionally.splice(value, 1);
      return copy;
    });
  };

  const changeValueContact = (key, value, input) => {
    setObjectData(prevState => {
      const copy = { ...prevState };
      switch (input) {
        case 'person': {
          copy.objectContacts.objectContactsAdditionally.find((el, index) => index === key).person = value;
          break;
        }
        case 'phone': {
          copy.objectContacts.objectContactsAdditionally.find((el, index) => index === key).phone = value;
          break;
        }
        default: {
          return copy;
        }
      }

      return copy;
    });
  };

  const addPerson = () => {
    setObjectData({
      ...objectData,
      objectContacts: {
        ...objectData.objectContacts,
        objectContactsAdditionally: [
          ...objectData.objectContacts.objectContactsAdditionally,
          {
            person: '',
            phone: '',
            deleted: false,
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (!popup) {
      setOpenLeftTimer(false);
      setOpenRightTimer(false);
    }
  }, [popup]);

  const customTimePickerButton = (isLeft: boolean) => {
    const closeTimer = () => {
      if (isLeft) {
        setOpenLeftTimer(false);
      } else {
        setOpenRightTimer(false);
      }
    };

    return (
      <button onClick={closeTimer} className={styles.customRangePickerBtnСhoose}>
        Готово
      </button>
    );
  };

  useEffect(() => {
    setDisabled(
      !Boolean(
        objectData.name &&
          valueAddress?.value &&
          objectData.objectContacts.objectContactsMain.person &&
          objectData.objectContacts.objectContactsMain.phone,
      ),
    );
  }, [
    objectData.name,
    valueAddress?.value,
    objectData.objectContacts.objectContactsMain.person,
    objectData.objectContacts.objectContactsMain.phone,
    objectData.semitrailers,
    objectData.workingHoursType,
  ]);
  useEffect(() => {
    if (popup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [popup]);

  useEffect(() => {
    getDataObjects().catch(console.log);
  }, [updatePage]);
  useEffect(() => {
    setObjectData({
      ...objectData,
      address: {
        ...objectData.address,
        latitude: Number(valueAddress?.data?.geo_lat),
        longitude: Number(valueAddress?.data?.geo_lon),
      },
    });
  }, [valueAddress]);

  return (
    <>
      {development ? (
        <PageWrapper>
          <h1>Объекты</h1>
          <div className="development">
            <div className="development__banner banner">
              <div className="banner__svg">
                <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0M10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18V18Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="banner__text">Раздел находится в разработке и будет доступен через некоторое время</div>
            </div>
            <div className="development__content item">
              <div className="item__svg">
                <DevelopmentSvg />
              </div>
              <div className="item__text">
                <div className="item__title">В разработке</div>
                <div className="item__subTitle">Этот раздел разрабатывается</div>
              </div>
            </div>
          </div>
        </PageWrapper>
      ) : skeleton ? (
        <PageWrapper>
          <SkeletonObjects />
        </PageWrapper>
      ) : (
        <PageWrapper style={{ zIndex: popup ? '10000' : '1000' }}>
          <ToastContainer />
          <div className={'objectsPage'}>
            <div className={styles.titleAndSubTitleContainer}>
              <div className={styles.titleAndAddButton}>
                <TitleMain title={'Объекты'} />
                <ButtonFilled
                    sizeText={'small'}
                    svg={<NewPlusSvg />}
                    width={isMobile ? '32px' : '177px'}
                    height={isMobile ? '32px' : '40px'}
                    onClick={() => setPopup(openPopUp)}
                    text={isMobile ? '' : 'Добавить объект'}
                />
              </div>
              <SubTitleMain
                  subTitle={'Удаление, добавление и редактирование актуальной информации о строительных объектах'}
              />
            </div>
            <div className={'objectsPage__content content'}>
              {!isMobile && objectItems.length ? (
                <div className={'content__titles'}>
                  <div className={'content__title smallR'}>Название объекта</div>
                  <div className={'content__title smallR'}>Адрес объекта</div>
                </div>
              ) : (
                <></>
              )}
              <div className={'content__list'}>
                {objectItems.slice(0, more).map(item => (
                  <NavLink to={`/objects/${item.id}`} key={item.id} className={'content__item item'}>
                    <div className={'item__name largeM'}>
                      {item?.name || 'Нет данных'}
                      {isMobile && <p>Название объекта</p>}
                    </div>
                    <div className={'item__address largeR'}>
                      {item?.address?.address || 'Нет данных'}
                      {isMobile && <p>Адрес объекта</p>}
                    </div>
                    {!isMobile && (
                      <div className={'item__icon'}>
                        <ArrowSvg />
                      </div>
                    )}
                  </NavLink>
                ))}
              </div>
              {objectItems.length > more && (
                <div className={'objectsPage__button'}>
                  <ButtonNotFilled
                      onClick={() => setMore(more + 10)}
                      text={'Показать ещё'}
                      width={'100%'}
                      color={'#ff5100'}
                  />
                </div>
              )}
            </div>
          </div>
          <AsidePopup open={popup} close={setPopup}>
            <div className={'objectsPage-popup'}>
              <div className={'objectsPage-popup-header'}>
                <div className={`objectsPage-popup-header__title ${isMobile ? 'H4Medium' : 'H2Medium'}`}>
                  Добавление объекта
                </div>
                <div className={'objectsPage-popup-header__close'} onClick={() => setPopup(closePopUp)}>
                  <CloseSvg />
                </div>
              </div>
              <div className={'objectsPage-popup__content'}>
                <div className={'objectsPage-popup-box'}>
                  <div className={'objectsPage-popup-box__title H4Medium'}>
                    1. Наименование объекта
                    <sup>*</sup>
                  </div>
                  <div className={'objectsPage-popup-box__input'}>
                    <InputText
                      type={''}
                      disabled={false}
                      isLabel={true}
                      value={objectData.name}
                      setValue={value =>
                        setObjectData({
                          ...objectData,
                          address: {
                            ...objectData.address,
                          },
                          name: value,
                        })
                      }
                      margin="0"
                      error={false}
                      helpText={'Введите наименование объекта. Например: “Сокольники”'}
                      widthHelp={0}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'Наименование'}
                    />
                  </div>
                </div>
                <div className={'objectsPage-popup-box'}>
                  <div className={'objectsPage-popup-box__title H4Medium'}>2. Адрес объекта</div>
                  <div className={'objectsPage-popup-box__subTitle H4Medium'}>
                    Фактический адрес объекта
                    <sup>*</sup>
                  </div>
                  <div className={'aside-popup-content-box__input'}>
                    <CustomAddressInput
                      placeholder={'Адрес'}
                      valueAddress={valueAddress}
                      setValueAddress={setValueAddress}
                    />
                  </div>
                  <div className={'objectsPage-popup-box__input'}>
                    <InputText
                      type={''}
                      disabled={false}
                      isLabel={true}
                      value={objectData.comment}
                      setValue={value =>
                        setObjectData({
                          ...objectData,
                          address: {
                            ...objectData.address,
                          },
                          comment: value,
                        })
                      }
                      margin="0"
                      error={false}
                      helpText={''}
                      widthHelp={0}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'Комментарий'}
                    />
                  </div>
                  <div className={'aside-popup-content-box__sub-title textH6'}>Широта и Долгота</div>
                  <div className={'aside-popup-content-box__input'}>
                    <InputText
                      type={'number'}
                      disabled={false}
                      isLabel={true}
                      value={objectData?.address?.latitude}
                      error={false}
                      setValue={value =>
                        setObjectData({
                          ...objectData,
                          address: {
                            ...objectData.address,
                            latitude: value,
                          },
                        })
                      }
                      margin={'0'}
                      widthHelp={260}
                      helpText={'Введите широту адреса карьера. Например: “55.71656”'}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'Широта'}
                    />
                  </div>
                  <div className={'aside-popup-content-box__input'}>
                    <InputText
                      type={'number'}
                      disabled={false}
                      isLabel={true}
                      value={objectData?.address?.longitude}
                      error={false}
                      setValue={value =>
                        setObjectData({
                          ...objectData,
                          address: {
                            ...objectData.address,
                            longitude: value,
                          },
                        })
                      }
                      margin={'0'}
                      widthHelp={260}
                      helpText={'Введите долготу адреса карьера. Например: “55.71656”'}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'Долгота'}
                    />
                  </div>
                  <div className={'aside-popup-content-box__map'}>
                    <div style={{ textAlign: 'end' }}>
                      <ButtonTextTransparent
                        onClick={() => setOpenMap(!openMap)}
                        children={openMap ? 'Скрыть карту' : 'Показать карту'}
                        addClass={''}
                      />
                    </div>
                    {openMap && (
                      <CustomYMap latitude={objectData?.address?.latitude} longitude={objectData?.address?.longitude} />
                    )}
                  </div>
                </div>
                <div className={'objectsPage-popup-box'}>
                  <div className={'objectsPage-popup-box__title H4Medium'}>3. Контакты на объекте</div>
                  <div className={'objectsPage-popup-box__subTitle H4Medium'}>
                    Основной контакт
                    <sup>*</sup>
                  </div>
                  <div className={'objectsPage-popup-box__input'}>
                    <InputText
                      type={''}
                      secondError={false}
                      disabled={false}
                      isLabel={true}
                      value={objectData.objectContacts.objectContactsMain.person}
                      setValue={value =>
                        setObjectData({
                          ...objectData,
                          objectContacts: {
                            ...objectData.objectContacts,
                            objectContactsMain: {
                              ...objectData.objectContacts.objectContactsMain,
                              person: value,
                            },
                          },
                        })
                      }
                      margin="0"
                      fill={true}
                      error={false}
                      helpText={''}
                      widthHelp={0}
                      textError={'Поле обязательное для заполнения'}
                      placeholder={'ФИО'}
                    />
                  </div>
                  <div className={'objectsPage-popup-box__input'}>
                    <MyPhoneInput
                      disabled={false}
                      placeholder="Номер телефона"
                      setIsError={null}
                      isError={false}
                      isActive={true}
                      setIsActive={null}
                      value={objectData.objectContacts.objectContactsMain.phone?.replace('+', '')}
                      onChange={phone =>
                        setObjectData({
                          ...objectData,
                          objectContacts: {
                            ...objectData.objectContacts,
                            objectContactsMain: {
                              ...objectData.objectContacts.objectContactsMain,
                              phone: phone,
                            },
                          },
                        })
                      }
                      isFunction={null}
                      btnHelp={false}
                    />
                  </div>
                  {objectData.objectContacts.objectContactsAdditionally.length ? (
                    <div>
                      {objectData.objectContacts.objectContactsAdditionally.map((el, index) => (
                        <div key={index}>
                          <div className={'objectPage-content__delete'}>
                            <div
                              className={'objectPage-content__delete-title'}
                            >{`Дополнительный контакт № ${index + 1}`}</div>
                            {!isMobile && (
                              <div className={'objectPage-content__delete-button'} onClick={() => deleteContact(index)}>
                                <span>
                                  <StopSvg />
                                </span>
                                Удалить
                              </div>
                            )}
                          </div>
                          <div className={'objectsPage-popup-box__input'}>
                            <InputText
                              type={''}
                              secondError={false}
                              disabled={false}
                              isLabel={true}
                              value={objectData.objectContacts?.objectContactsAdditionally[index].person}
                              setValue={value => changeValueContact(index, value, 'person')}
                              margin="0"
                              fill={true}
                              error={false}
                              helpText={''}
                              widthHelp={0}
                              textError={'Поле обязательное для заполнения'}
                              placeholder={'ФИО'}
                            />
                          </div>
                          <div className={'objectsPage-popup-box__input'}>
                            <MyPhoneInput
                              disabled={false}
                              placeholder="Номер телефона"
                              setIsError={null}
                              isError={false}
                              isActive={true}
                              setIsActive={null}
                              value={objectData.objectContacts?.objectContactsAdditionally[index].phone.replace(
                                '+',
                                '',
                              )}
                              onChange={value => changeValueContact(index, value, 'phone')}
                              isFunction={null}
                              btnHelp={false}
                            />
                          </div>
                          {isMobile && (
                            <div className={'objectPage-content__delete-button'} onClick={() => deleteContact(index)}>
                              <span>
                                <StopSvg />
                              </span>
                              Удалить
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={'objectsPage-popup-box__add-button'}>
                    <ButtonFilled
                        onClick={addPerson}
                        text={'Добавить контакт'}
                        svg={<CirclePlusSvg />}
                        widthSvg={'14px'}
                        width={'220px'}
                    />
                  </div>
                </div>

                <div className={'orders-page-popup-box'}>
                  <div className={'orders-page-popup-box__title H4Medium'}>
                    4. Вид ТС
                    <sup>*</sup>
                  </div>
                  {objectData.typeTC.map(item => (
                    <div key={item.id} className={'orders-page-popup-box__check-box'}>
                      <CheckBoxCustom
                        children={item.title}
                        error={errorCheckBox}
                        check={item.active}
                        pick={pickCheck}
                        name={'typeTC'}
                        id={item.id}
                      />
                    </div>
                  ))}
                </div>
                <div className={'objectsPage-popup-box'}>
                  <div className={'objectsPage-popup-box__title H4Medium'}>
                    5. График приемки
                    <sup>*</sup>
                  </div>
                  <div className={'objectsPage-popup-box'}>
                    <div>
                      <div className={'orders-page-popup-box__radio'}>
                        <RadioBoxCircle
                          check={objectData.workingHoursType.name === 'aroundTheClock'}
                          onInput={value =>
                            setObjectData({
                              ...objectData,
                              workingHoursType: { ...objectData.workingHoursType, name: 'aroundTheClock' },
                            })
                          }
                          name={'TypeOfAcceptance1'}
                          id={'5'}
                          value={'Круглосуточная'}
                        />
                      </div>
                      <div className={'orders-page-popup-box__radio'}>
                        <RadioBoxCircle
                          check={objectData.workingHoursType.name === 'daytime'}
                          onInput={value =>
                            setObjectData({
                              ...objectData,
                              workingHoursType: { ...objectData.workingHoursType, name: 'daytime' },
                            })
                          }
                          name={'TypeOfAcceptance1'}
                          id={'6'}
                          value={'Дневная'}
                        />
                      </div>
                    </div>
                    <div className={'orders-page-popup-box__box-time'}>
                      <div className={'orders-page-popup-box__time'}>
                        <TimePicker
                          showNow={false}
                          needConfirm={false}
                          placeholder={'Период времени'}
                          format={'HH:mm'}
                          onChange={(time, timeString) => changeTime(time, timeString, 'workTo', 'workTimeTo')}
                          disabled={objectData.workingHoursType.name !== 'daytime'}
                          defaultValue={objectData.workingHoursType.defaultWorkTo}
                          open={openLeftTimer}
                          onClick={() => setOpenLeftTimer(true)}
                          renderExtraFooter={() => customTimePickerButton(true)}
                        />
                      </div>
                      <div className={'orders-page-popup-box__or'}>до</div>
                      <div className={'orders-page-popup-box__time'}>
                        <TimePicker
                          showNow={false}
                          needConfirm={false}
                          placeholder={'Период времени'}
                          format={'HH:mm'}
                          onChange={(time, timeString) => changeTime(time, timeString, 'workFrom', 'workTimeFrom')}
                          disabled={objectData.workingHoursType.name !== 'daytime'}
                          open={openRightTimer}
                          onClick={() => setOpenRightTimer(true)}
                          renderExtraFooter={() => customTimePickerButton(false)}
                          status={
                            objectData.workingHoursType.workFrom.split(':')[0] -
                              objectData.workingHoursType.workTo.split(':')[0] <
                              4 &&
                            objectData.workingHoursType.workFrom !== '' &&
                            objectData.workingHoursType.workTo !== ''
                              ? 'error'
                              : ''
                          }
                          defaultValue={objectData.workingHoursType.defaultWorkFrom}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ButtonFilled
                      margin={'40px 0 0 0'}
                      width={'100%'}
                      onClick={sendObjectData}
                      text={'Сохранить'}
                      disabled={disabled}
                  />
                </div>
              </div>
            </div>
          </AsidePopup>
        </PageWrapper>
      )}
    </>
  );
};
