import { IArrayFromBackend, IParametersOfAddTariffs, IResponseDetailsTariff } from './type';
import { fetchGet } from '../../../common/proxy-1C-fetch-auth';
import { changeFieldOfSelector, convertForSelectOptions, nameOfParameters } from './const';
import dayjs from 'dayjs';
import React from 'react';

export const getListOrganization = async (setMainParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>) => {
  try {
    const responseOrganization: IArrayFromBackend[] = await fetchGet(`/admin/companies/organizations`, {});
    changeFieldOfSelector({
      setState: setMainParametersInfo,
      nameObject: nameOfParameters.ORGANIZER,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseOrganization),
      clearValue: false,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListCompany = async (
  currentRole: string,
  setMainParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
) => {
  try {
    const responseListCompany: IArrayFromBackend[] = await fetchGet(`/admin/companies/list`, { type: currentRole });
    changeFieldOfSelector({
      setState: setMainParametersInfo,
      nameObject: nameOfParameters.COUNTERPARTY,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListCompany),
      clearValue: true,
    });
    return responseListCompany;
  } catch (e) {
    console.log(e);
  }
};
export const getListQuarryCarrier = async (
  carrierParametersInfo: IParametersOfAddTariffs[],
  setCarrierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  materialTypeId?: number,
) => {
  try {
    const responseListQuarry: IArrayFromBackend[] = await fetchGet(`/admin/quarries/list`, {
      materialTypeId: materialTypeId || Number(carrierParametersInfo[0].value),
    });
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.QUARRY,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListQuarry),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListQuarrySupplier = async (
  mainParametersInfo: IParametersOfAddTariffs[],
  setSupplierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  companyId?: number,
) => {
  try {
    const responseListQuarry: IArrayFromBackend[] = await fetchGet(`/admin/quarries/list`, {
      companyId: companyId || Number(mainParametersInfo[2].value),
    });
    changeFieldOfSelector({
      setState: setSupplierParametersInfo,
      nameObject: nameOfParameters.QUARRY,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListQuarry),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListMaterialsCarrier = async (setCarrierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>) => {
  try {
    const responseListMaterialsCarrier: IArrayFromBackend[] = await fetchGet(`/admin/materials/types`, {});
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.TYPE_MATERIAL,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListMaterialsCarrier),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListSemitrailers = async (setCarrierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>) => {
  try {
    const responseListMaterialsCarrier: IArrayFromBackend[] = await fetchGet(`/admin/cars/semitrailers`, {});
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.SEMITRAILER,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListMaterialsCarrier),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListMaterialsByQuarry = async (
  supplierParametersInfo: IParametersOfAddTariffs[],
  setSupplierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  quarryId?: number,
) => {
  try {
    const responseListMaterialsByQuarry: IArrayFromBackend[] = await fetchGet(`/admin/quarries/list/materials`, {
      quarryId: quarryId || Number(supplierParametersInfo[0].value),
    });
    changeFieldOfSelector({
      setState: setSupplierParametersInfo,
      nameObject: nameOfParameters.MATERIAL,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListMaterialsByQuarry),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getMainValue = async (
  responseDetailsTariff: IResponseDetailsTariff,
  setMainParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  currentRole,
) => {
  await getListOrganization(setMainParametersInfo).then(() => {
    changeFieldOfSelector({
      setState: setMainParametersInfo,
      nameObject: nameOfParameters.ORGANIZER,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.organization?.id),
      clearValue: false,
    });
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.ROLE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.company?.type),
    clearValue: false,
  });
  await getListCompany(currentRole, setMainParametersInfo).then(resCompany => {
    changeFieldOfSelector({
      setState: setMainParametersInfo,
      nameObject: nameOfParameters.COUNTERPARTY,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.company?.id),
      clearValue: false,
    });
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.DATE_FROM,
    fieldOfObject: 'date',
    valueField: dayjs(responseDetailsTariff?.dateFrom),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.DATE_TO,
    fieldOfObject: 'date',
    valueField: responseDetailsTariff.dateTo ? dayjs(responseDetailsTariff.dateTo) : null,
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.UNIT,
    fieldOfObject: 'value',
    valueField: responseDetailsTariff?.unit,
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.EXTRA_CHARGE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff?.margin),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.PRICE_REQUEST,
    fieldOfObject: 'check',
    valueField: responseDetailsTariff?.priceOnRequest,
    clearValue: false,
  });
};
export const getCarrierValue = async (
  responseDetailsTariff: IResponseDetailsTariff,
  setCarrierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  mainParametersInfo: IParametersOfAddTariffs[],
  carrierParametersInfo: IParametersOfAddTariffs[],
) => {
  await getListMaterialsCarrier(setCarrierParametersInfo).then(() => {
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.TYPE_MATERIAL,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails.materialType?.id),
      clearValue: false,
    });
  });
  await getListSemitrailers(setCarrierParametersInfo).then(() => {
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.SEMITRAILER,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails.semitrailer?.id),
      clearValue: false,
    });
  });
  await getListQuarryCarrier(
    carrierParametersInfo,
    setCarrierParametersInfo,
    responseDetailsTariff.tariffDetails.materialType?.id,
  ).then(() => {
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.QUARRY,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails.quarry?.id),
      clearValue: false,
    });
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.RANGE_INPUT,
    fieldOfObject: 'valueFrom',
    valueField: String(responseDetailsTariff.tariffDetails?.distanceFrom),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.RANGE_INPUT,
    fieldOfObject: 'valueTo',
    valueField: String(responseDetailsTariff.tariffDetails?.distanceTo),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.COST_PRICE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.tariffDetails?.cost),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.EXTRA_CHARGE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.tariffDetails?.margin),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.PRICE_REQUEST_CARRIER,
    fieldOfObject: 'check',
    valueField: responseDetailsTariff.tariffDetails?.priceOnRequest,
    clearValue: false,
  });
};
export const getSupplierValue = async (
  responseDetailsTariff: IResponseDetailsTariff,
  mainParametersInfo: IParametersOfAddTariffs[],
  supplierParametersInfo: IParametersOfAddTariffs[],
  setSupplierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
) => {
  await getListQuarrySupplier(mainParametersInfo, setSupplierParametersInfo, responseDetailsTariff.company.id).then(
    () => {
      changeFieldOfSelector({
        setState: setSupplierParametersInfo,
        nameObject: nameOfParameters.QUARRY,
        fieldOfObject: 'value',
        valueField: String(responseDetailsTariff.tariffDetails.quarry?.id),
        clearValue: false,
      });
    },
  );
  await getListMaterialsByQuarry(
    supplierParametersInfo,
    setSupplierParametersInfo,
    responseDetailsTariff.tariffDetails.quarry.id,
  ).then(() => {
    changeFieldOfSelector({
      setState: setSupplierParametersInfo,
      nameObject: nameOfParameters.MATERIAL,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails.material?.id),
      clearValue: false,
    });
  });
  changeFieldOfSelector({
    setState: setSupplierParametersInfo,
    nameObject: nameOfParameters.VOLUME,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.tariffDetails?.volume),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setSupplierParametersInfo,
    nameObject: nameOfParameters.COST_PRICE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.tariffDetails?.cost),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setSupplierParametersInfo,
    nameObject: nameOfParameters.EXTRA_CHARGE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.tariffDetails?.margin),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setSupplierParametersInfo,
    nameObject: nameOfParameters.PRICE_REQUEST_SUPPLIER,
    fieldOfObject: 'check',
    valueField: responseDetailsTariff.tariffDetails?.priceOnRequest,
    clearValue: false,
  });
};
