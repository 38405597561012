import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { formPrice } from '../common/form-price.helper';

export const RequestItemCarrier = ({
  number,
  numberTn,
  date,
  nameLoading,
  addressLoading,
  nameUnloading,
  addressUnloading,
  carBrand,
  carNumber,
  driverName,
  volume,
  volumeUnloading,
  carrierPrice,
  measurement,
  measurementUnloading,
  cargo,
  status,
  disabled,
  isErrorVolume = '',
  statusTab,
  ...props
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const requestItemClass = isMobile ? '' : statusTab === 'Выполнен' ? 'completedRequestItem' : 'activeRequestItem';

  if (measurement === 'тонн') measurement = 'тн';
  if (measurementUnloading === 'тонн') measurementUnloading = 'тн';

  return (
    <div
      {...props}
      className={
        disabled === 'Рейс отменен'
          ? `requestItem Carrier disabled ${requestItemClass}`
          : `requestItem Carrier ${requestItemClass}`
      }
    >
      {isMobile ? (
        <>
          {statusTab === 'Выполнен' ? (
            <>
              <div className="requestItem__box line">
                <div className="requestItem__subBox__completed">
                  <div className="requestItem__subBox__completed__item">
                    <div className="requestItem__text">
                      {number === '' || number == null ? 'Номер рейса' : `Рейс ${number}`}
                    </div>
                    <div className="requestItem__subTitle">{date === '' || date == null ? 'Дата поставки' : date}</div>
                  </div>
                  <div className="requestItem__subBox__completed__item">
                    <div className="requestItem__text">
                      {numberTn === '' || numberTn == null ? '' : `№TH: ${numberTn}`}
                    </div>
                    <div className={`requestItem__box ${isMobile ? '' : 'line'}`} style={{ margin: '0', padding: '0' }}>
                      <div className="requestItem__text">{status}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="requestItem__box line">
                <div className="requestItem__title">
                  {nameLoading === '' || nameLoading == null ? 'Название компании А' : `Загрузка: ${nameLoading}`}
                </div>
                <div className="requestItem__title">
                  {nameUnloading === '' || nameUnloading == null ? 'Название компании Б' : `Выгрузка: ${nameUnloading}`}
                </div>
              </div>

              <div className="requestItem__box line">
                <div className="requestItem__subTitle">
                  {cargo === '' || cargo == null ? 'Описание (материал, тип, фракция, насыпная плотность и тд)' : cargo}
                </div>
                <div className={`requestItem__container ${isErrorVolume}`}>
                  <div className="requestItem__title">{`По загрузке/выгрузке: ${volume}`}</div>
                  {volume !== 'Нет данных' && (
                    <div className="requestItem__title">{`${measurement ? measurement : ''}`}</div>
                  )}
                  {volumeUnloading && (
                    <>
                      <div className="requestItem__title">{`/`}</div>
                      <div className="requestItem__title">{`${volumeUnloading}`}</div>
                      {volumeUnloading !== 'Нет данных' && (
                        <div className="requestItem__title">{`${measurementUnloading ? measurementUnloading : ''}`}</div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="requestItem__box line">
                <div className="requestItem__subBox">
                  <div>
                    <div className="requestItem__text">
                      {number === '' || number == null ? 'Номер рейса' : `Рейс ${number}`}
                    </div>
                    <div className="requestItem__subTitle">{date === '' || date == null ? 'Дата поставки' : date}</div>
                  </div>
                  <div className={`requestItem__box ${isMobile ? '' : 'line'}`} style={isMobile ? { margin: '0' } : {}}>
                    <div className="requestItem__title">{status}</div>
                  </div>
                </div>
              </div>

              <div className="requestItem__box line">
                <div className="requestItem__title">
                  {nameLoading === '' || nameLoading == null ? 'Название компании А' : nameLoading}
                </div>
                <div className="requestItem__subTitle">
                  {addressLoading === '' || addressLoading == null ? 'Адрес погрузки' : addressLoading}
                </div>
              </div>

              <div className="requestItem__box line">
                <div className="requestItem__title">
                  {nameUnloading === '' || nameUnloading == null ? 'Название компании Б' : nameUnloading}
                </div>
                <div className="requestItem__subTitle">
                  {addressUnloading === '' || addressUnloading == null ? 'Адрес выгрузки' : addressUnloading}
                </div>
              </div>

              <div className="requestItem__box line">
                <div className="requestItem__container">
                  <div className="requestItem__title">{volume ? volume : 0}</div>
                  {volume !== 'Нет данных' && (
                    <div className="requestItem__title">{` ${measurement ? measurement : ''}`}</div>
                  )}
                </div>
                <div className="requestItem__subTitle">
                  {cargo === '' || cargo == null ? 'Описание (материал, тип, фракция, насыпная плотность и тд)' : cargo}
                </div>
              </div>
            </>
          )}

          <div className="requestItem__box line">
            <div className="requestItem__title">
              {(carNumber === '' || carNumber == null) && (carBrand === '' || carBrand == null)
                ? 'Машина не назначена'
                : (carBrand || '') + ' ' + (carNumber || '')}
            </div>
            <div className="requestItem__subTitle">
              {driverName === '' || driverName == null ? 'Водитель не назначен' : driverName}
            </div>
          </div>

          <div className={`requestItem__box`}>
            <div className="requestItem__price__container">
              <div className="requestItem__subTitle">Стоимость</div>
              <div className="requestItem__price-text" style={!isMobile ? { paddingLeft: '8px' } : {}}>
                {carrierPrice === '' || carrierPrice == null || isNaN(carrierPrice)
                  ? 'Ставка за рейс'
                  : formPrice(carrierPrice > 0 ? carrierPrice : 0, 'RUB')}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="requestItem__box line">
            <div className="requestItem__title">{date === '' || date == null ? 'Дата поставки' : date}</div>
            <div className="requestItem__subTitle">
              {number === '' || number == null ? 'Номер рейса' : `Рейс: ${number}`}
            </div>
            <div className="requestItem__subTitle">{numberTn === '' || numberTn == null ? '' : `№TH: ${numberTn}`}</div>
          </div>

          <div className="requestItem__box line">
            <div className="requestItem__title">
              {nameLoading === '' || nameLoading == null ? 'Название компании А' : `${nameLoading}`}
            </div>
            <div className="requestItem__subTitle">
              {addressLoading === '' || addressLoading == null ? 'Адрес погрузки' : addressLoading}
            </div>
          </div>

          <div className="requestItem__box line">
            <div className="requestItem__title">
              {nameUnloading === '' || nameUnloading == null ? 'Название компании Б' : `${nameUnloading}`}
            </div>
            <div className="requestItem__subTitle">
              {addressUnloading === '' || addressUnloading == null ? 'Адрес выгрузки' : addressUnloading}
            </div>
          </div>

          <div className="requestItem__box line">
            <div className="requestItem__title">
              {(carNumber === '' || carNumber == null) && (carBrand === '' || carBrand == null)
                ? 'Машина не назначена'
                : (carBrand || '') + ' ' + (carNumber || '')}
            </div>
            <div className="requestItem__subTitle">
              {driverName === '' || driverName == null ? 'Водитель не назначен' : driverName}
            </div>
          </div>
          {statusTab === 'Выполнен' ? (
            <div className={`requestItem__box ${isMobile ? '' : 'line'}`}>
              <div className={`requestItem__container ${isErrorVolume}`}>
                <div className="requestItem__title">{`${volume ? volume : '0'}`}</div>
                {volume !== 'Нет данных' && (
                  <div className="requestItem__title">{`${measurement ? measurement : ''}`}</div>
                )}
                {volumeUnloading && (
                  <>
                    <div className="requestItem__title">{`/`}</div>
                    <div className="requestItem__title">{`${volumeUnloading}`}</div>
                    {volumeUnloading !== 'Нет данных' && (
                      <div className="requestItem__title">{`${measurementUnloading ? measurementUnloading : ''}`}</div>
                    )}
                  </>
                )}
              </div>
              <div className="requestItem__subTitle">{cargo}</div>
            </div>
          ) : (
            <>
              <div className={`requestItem__box ${isMobile ? '' : 'line'}`}>
                <div className={`requestItem__container`}>
                  <div className="requestItem__title">{`${volume ? volume : '0'}`}</div>
                  {volume !== 'Нет данных' && (
                    <div className="requestItem__title">{`${measurement ? measurement : ''}`}</div>
                  )}
                </div>
                <div className="requestItem__subTitle">{cargo}</div>
              </div>
            </>
          )}
          <div
            className={`requestItem__box ${isMobile || statusTab === 'Выполнен' ? '' : 'line'}`}
            style={{ display: `${isMobile ? 'none' : 'flex'}`, padding: `${statusTab === 'Выполнен' ? '0 8px' : ''}` }}
          >
            <div className="requestItem__title">{status}</div>
          </div>
          {statusTab !== 'Выполнен' && (
            <div className={`requestItem__box`}>
              <div className="requestItem__subTitle" style={!isMobile ? { paddingLeft: '8px' } : {}}>
                {carrierPrice === '' || carrierPrice == null || isNaN(carrierPrice)
                  ? 'Ставка за рейс'
                  : formPrice(carrierPrice > 0 ? carrierPrice : 0, 'RUB')}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
