import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import styles from './GSMStatisticsCard.module.scss';
import { GSMStatisticsPlot } from './GSMStatisticsPlot/GSMStatisticsPlot';
import { CustomSelect } from 'src/components/UI/select/CustomSelect';
import { CalendarArrows } from '../components/CalendarArrows/CalendarArrows';
import { Context } from 'src/context/context';
import { TPlotData } from '../GSMtypes';
import { getMonth } from '../GSMHelpers';

type TData = {
  plotData: TPlotData;
  sort: { label: string; value: string };
  setSort: Dispatch<SetStateAction<{ label: string; value: string }>>;
  plotDates: { from: Date; to: Date };
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
};

const options = [
  { value: 'По сумме', label: 'По сумме' },
  { value: 'По виду топлива', label: 'По виду топлива' },
];

const optionsDriver = [
  { value: 'По сумме', label: 'По сумме' },
  { value: 'По литрам', label: 'По литрам' },
];

export const GSMStatisticsCard = ({ plotData, sort, setSort, plotDates, offset, setOffset }: TData) => {
  const { user } = useContext(Context);
  const isDriver = user?.type === 'driver';
  const typeDriverOrCarrier = isDriver ? 'liters' : 'pie';
  const type = sort?.value === options[0]?.value ? 'sum' : typeDriverOrCarrier;

  if (type === 'pie') plotData?.sort((a, b) => b.value - a.value);

  const labels = plotData?.length ? plotData.map(item => item?.key) : [];
  const data = plotData?.length ? plotData.map(item => item?.value) : [];

  const labelFrom = plotDates?.from;
  const labelTo = plotDates?.to;

  const dateText = `${labelFrom.getDate()}${getMonth(labelTo) === getMonth(labelFrom) ? '' : ' ' + getMonth(labelFrom)}-${labelTo.getDate()} ${getMonth(labelTo)}`;
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div>
          <CustomSelect
            height40px={true}
            options={isDriver ? optionsDriver : options}
            setValue={setSort}
            value={sort}
            placeholder={''}
            isClearable={false}
            error={false}
            isSearchable={false}
            noOptionsMessage={''}
          />
        </div>
        <div className={styles.dateContainer}>
          <div
            onClick={() => {
              setOffset(offset - 7);
            }}
            style={{ cursor: 'pointer' }}
          >
            <CalendarArrows isLeft={true} />
          </div>
          <span className={styles.text}>{dateText}</span>
          <div
            onClick={() => {
              if (offset === 0) return;
              setOffset(offset + 7);
            }}
            style={offset !== 0 ? { cursor: 'pointer' } : { cursor: 'initial', visibility: 'hidden' }}
          >
            <CalendarArrows isLeft={false} />
          </div>
        </div>
      </div>
      {plotData?.length ? (
        <GSMStatisticsPlot type={type} labels={labels} data={data} />
      ) : (
        <div className={styles.emptyPlot}>
          <span className={styles.emptyText}>Начните пользоваться картой и статистика появится</span>
        </div>
      )}
    </div>
  );
};
