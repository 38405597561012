import React from 'react';
import styles from './GSMOperationsHistoryTable.module.scss';
import { TGSMOperation } from '../GSMtypes';
import cx from 'classnames';
import { formPrice } from 'src/common/form-price.helper';
import { SortArrowsSvg } from 'src/components/UI/svg-icon/SortArrowsSvg';
import ChevronDownSvg from 'src/components/UI/svg-icon/ChevronDownSvg';
import { useMediaQuery } from 'react-responsive';

const headers = ['Дата', 'Вид топлива', 'Вид операции', 'Сумма, ₽'];

const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

const changeVisualPrice = (price: string, isMobile: boolean) => {
  let formattedPrice = formPrice(price.slice(1, price.length), 'RUB');
  return price[0] + formattedPrice.slice(0, isMobile ? formattedPrice.length : formattedPrice.length - 2);
};

const convertDate = (dateStr: string) => {
  const [fullDate, fullTime] = dateStr.split(' ');
  const date = fullDate.slice(0, 5);
  const time = fullTime.slice(1, -1);

  const [day, month] = date.split('.');
  const monthName = months[parseInt(month, 10) - 1];

  return [`${day} ${monthName}`, time];
};

export const GSMOperationsHistoryTable = ({ operations }: { operations: TGSMOperation[] }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className={styles.container}>
      {!isMobile && (
        <div className={cx(styles.row, styles.headerRow)}>
          {headers.map((header, index) => {
            return (
              <div
                key={`header${index}`}
                className={cx(
                  styles.cell,
                  styles.headerCell,
                  index === 0 && styles.leftCell,
                  index === 3 && styles.rightCell,
                )}
              >
                <span>{header}</span>
                {index === 0 || index === 3 ? (
                  <SortArrowsSvg width="16px" color={'var(--color-blue900)'} />
                ) : (
                  <ChevronDownSvg width="16px" color={'var(--color-blue900)'} />
                )}
              </div>
            );
          })}
        </div>
      )}
      {operations.map((operation, index) => {
        const [date, time] = convertDate(operation.date);
        return (
          <div key={`operation${index}`} className={styles.mobileTable}>
            {isMobile && <span className={styles.tableTitle}>{date}</span>}
            <div className={styles.row}>
              <div className={cx(styles.cell, styles.leftCell)}>{isMobile ? time : operation.date}</div>
              {!isMobile && <div className={styles.cell}>{operation.fuelType || '–'}</div>}
              <div className={styles.cell}>{operation.operationType + (isMobile ? ' ' + operation.fuelType : '')}</div>
              <div
                className={cx(
                  styles.cell,
                  styles.rightCell,
                  operation.price[0] === '-' ? styles.redPrice : styles.greenPrice,
                )}
              >
                {changeVisualPrice(operation.price, isMobile)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
