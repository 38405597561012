import React from 'react';
import styles from './UserAgreement.module.scss';
import { agreement } from './constans';
import FooterLk from '../../components/newUI/FooterLk/FooterLk';
import PublicHeader from '../../components/PublicHeader/PublicHeader';

interface IProps {
  agreementType: string;
}
const Agreements = ({ agreementType }: IProps) => {
    return (
        <div className={styles.pageContainer}>
            <PublicHeader/>
            {agreement[agreementType]}
            <FooterLk />
        </div>
  );
};

export default Agreements;
