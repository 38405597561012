import React from 'react';
import Select from 'react-select';

type Props = {
  options: any;
  setValue: any;
  placeholder: string;
  isClearable: boolean;
  error: boolean;
  isSearchable: boolean;
  isDisabled?: boolean;
  noOptionsMessage: string;
  height40px?: boolean;
  [key: string]: any;
};

export const CustomSelect = ({
  value,
  updateDate = false,
  errorAfterSend = false,
  setErrorAfterSend,
  validateErrorWithSend = false,
  error,
  options,
  setValue,
  placeholder,
  textError = '',
  isClearable,
  noOptionsMessage,
  height40px,
  isDisabled = false,
  isSearchable,
  ...props
}: Props) => {
  const styles = {};

  return (
    <>
      <Select
        {...props}
        value={value}
        isDisabled={isDisabled}
        placeholder={placeholder}
        styles={styles}
        isClearable={isClearable}
        isSearchable={isSearchable}
        className={`${
          height40px ? `CustomSelectHeight40px` : `CustomSelect`
        } ${(validateErrorWithSend ? error /*&& valueError*/ : error) ? 'error' : ''}`}
        classNamePrefix={`${height40px ? `CustomSelectHeight40px` : `CustomSelect`}`}
        options={options}
        noOptionsMessage={({ inputValue }) => !inputValue && noOptionsMessage}
        onChange={e => {
          setValue(e);
        }}
      />
      {error && <div className={'InputText__text'}>{textError}</div>}
    </>
  );
};
