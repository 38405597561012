import React, { useEffect, useState } from 'react';
import styles from './GSMNewPage.module.scss';
import { PageWrapper } from 'src/components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import { GSMNewCard } from './GSMNewCard/GSMNewCard';
import { GSMOperationsHistoryTable } from './GSMOperationsHistoryTable/GSMOperationsHistoryTable';
import { GSMStatisticsCard } from './GSMStatisticsCard/GSMStatisticsCard';
import { TCardInfo, TGSMOperation, TOperationsFilter, TOperationsSort, TPlotData } from './GSMtypes';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import { EmptyList } from 'src/components/EmptyList';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import CustomRangePicker from 'src/components/newUI/CustomRangePicker/CustomRangePicker';
import { clearObject } from 'src/common/ClearObject.helper';
import { isLeapYear, monthDays } from './GSMHelpers';
import dayjs from 'dayjs';

export const GSMNewPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [cardInfo, setCardInfo] = useState<TCardInfo | null>(null);
  const [operationsHistoryInfo, setOperationsHistoryInfo] = useState<TGSMOperation[]>([]);
  const [plotData, setPlotData] = useState<TPlotData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOperationsLoading, setIsOperationsLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<{ label: string; value: string }>({ value: 'По сумме', label: 'По сумме' });
  const [plotDates, setPlotDates] = useState<{ from: Date; to: Date }>({
    from: null,
    to: null,
  });
  const [dateOffset, setDateOffset] = useState<number>(0);
  const [operationsOffset, setOperationsOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  const [operationsSort, setOperationsSort] = useState<TOperationsSort>('lower-date');
  const [operationsFilter, setOperationsFilter] = useState<TOperationsFilter>({
    fuel: { value: 'Все', label: 'Все' },
    operation: { value: 'Все', label: 'Все' },
  });

  useEffect(() => {
    getCardInfo();
  }, []);

  useEffect(() => {
    getPlotInfo();
  }, [cardInfo, sort, plotDates]);

  useEffect(() => {
    changeDate();
  }, [dateOffset]);

  useEffect(() => {
    if (totalCount > operationsOffset && operationsHistoryInfo?.length) {
      getOperationsHistory(true);
    }
  }, [operationsOffset]);

  useEffect(() => {
    const today = new Date();
    if (!dateRange[0]) {
      //последний день месяца (в феврале - 28 или 29)
      const lastDay =
        today.getMonth() === 1
          ? isLeapYear(today.getFullYear())
            ? monthDays[1].to[1]
            : monthDays[1].to[0]
          : monthDays[today.getMonth()]?.to;

      setDateRange([
        dayjs(new Date(new Date(today.setDate(1)).setHours(0, 0, 0))),
        dayjs(new Date(new Date(today.setDate(lastDay)).setHours(23, 59, 59))),
      ]);
    }

    if (operationsOffset !== 0) setOperationsOffset(0);
    if (operationsHistoryInfo?.length) setOperationsHistoryInfo([]);
    getOperationsHistory(false);
  }, [cardInfo, operationsSort, dateRange, operationsFilter]);

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOperationsOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getCardInfo = async () => {
    try {
      setIsLoading(true);
      const response = await fetchGet('/fuel-cards/driver', {});
      if (response?.error) {
        setIsLoading(false);
        return;
      }
      const cardData = response?.response;
      setCardInfo({
        id: cardData?.id,
        cardNumber: cardData?.cardNumber,
        status: cardData?.status,
        limit: {
          id: cardData?.limit?.id,
          current: cardData?.limit?.current,
        },
        owner: cardData?.owner,
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getPlotInfo = async () => {
    if (!cardInfo?.id || !plotDates?.from || !plotDates?.to) return;
    const dateFromISO = plotDates?.from?.toISOString();
    const dateToISO = plotDates?.to?.toISOString();
    try {
      const response = await fetchGet('/transactions/report/card', {
        cardId: Number(cardInfo?.id),
        display: sort?.value === 'По сумме' ? 'money' : 'volume',
        dateFrom: dateFromISO,
        dateTo: dateToISO,
      });
      if (response?.error) {
        setIsLoading(false);
        return;
      }
      const plotInfoData = response?.response;
      setPlotData(plotInfoData?.result);
    } catch (e) {
      console.log(e);
    }
  };
  const getOperationsHistory = async (isPagination: boolean) => {
    if (!cardInfo?.id || !dateRange[0] || !dateRange[1]) {
      return;
    }
    const dateFromISO = new Date(dateRange[0].$d.setHours(3, 0, 0)).toISOString();
    const dateToISO = new Date(dateRange[1].$d.setHours(26, 59, 59)).toISOString();
    try {
      setIsOperationsLoading(true);
      const response = await fetchGet(
        '/transactions/card',
        clearObject({
          cardId: Number(cardInfo?.id),
          limit: 10,
          offset: isPagination ? operationsOffset : 0,
          dateFrom: dateFromISO,
          dateTo: dateToISO,
          order: operationsSort,
          fuel:
            typeof operationsFilter?.fuel !== 'string' && operationsFilter?.fuel?.value !== 'Все'
              ? operationsFilter?.fuel?.value
              : undefined,
          operation:
            typeof operationsFilter?.operation !== 'string' && operationsFilter?.operation?.value !== 'Все'
              ? operationsFilter?.operation?.value
              : undefined,
        }),
      );
      if (response?.error) {
        setIsOperationsLoading(false);
        return;
      }
      const operationsInfoData = response?.response?.transactions;
      setTotalCount(response?.response?.rows);
      if (isPagination && operationsOffset !== 0) {
        setOperationsHistoryInfo(prevState => [...prevState, ...operationsInfoData]);
      } else {
        setOperationsHistoryInfo(operationsInfoData);
      }
      setIsOperationsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const changeDate = () => {
    setPlotDates({
      from: new Date(new Date(new Date().setDate(new Date().getDate() + 1 + dateOffset - 7)).setHours(3, 0, 0)),
      to: new Date(new Date(new Date().setDate(new Date().getDate() + 1 + dateOffset)).setHours(2, 59, 59)),
    });
  };

  return (
    <PageWrapper style={{ backgroundColor: `${isMobile ? 'white' : 'var(--color-gray50)'}` }}>
      <div className={styles.container}>
        <div className={styles.titleAndSubtitleContainer}>
          <span className={styles.title}>ГСМ</span>
          <span className={styles.subtitle}>Ваши топливные карты и детализация по ним</span>
        </div>
        {cardInfo?.id ? (
          <>
            <div className={styles.priceAndPlotContainer}>
              <GSMNewCard cardInfo={cardInfo} />
              {plotDates?.from && plotDates?.to && (
                <GSMStatisticsCard
                  plotData={plotData}
                  sort={sort}
                  setSort={setSort}
                  plotDates={plotDates}
                  offset={dateOffset}
                  setOffset={setDateOffset}
                />
              )}
            </div>
            <div className={styles.operationsHistoryContainer}>
              <div className={styles.operationsHistoryHeader}>
                <span className={styles.subheader}>История операций</span>
                <CustomRangePicker
                  width={'260px'}
                  isPresets={true}
                  open={openCalendar}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setOpen={setOpenCalendar}
                  placeholder={['Начало', 'Конец']}
                  height="40px"
                  isNewStyle={true}
                />
              </div>
              {isOperationsLoading ? (
                <div className={styles.loadingContainer}>
                  <div className={styles.loadingContent}>
                    <LoadingSpin />
                  </div>
                </div>
              ) : (
                <>
                  {dateRange[0] && dateRange[1] && (
                    <GSMOperationsHistoryTable
                      operations={operationsHistoryInfo}
                      operationsSort={operationsSort}
                      setOperationsSort={setOperationsSort}
                      filter={operationsFilter}
                      setFilter={setOperationsFilter}
                    />
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {isLoading ? (
              <div className={styles.loadingContainer}>
                <div className={styles.loadingContent}>
                  <LoadingSpin />
                </div>
              </div>
            ) : (
              <EmptyList
                title={'Карта ГСМ отсутствует'}
                subTitle={'Данные появятся в разделе, как только карта будет добавлена'}
              />
            )}
          </>
        )}
      </div>
    </PageWrapper>
  );
};
