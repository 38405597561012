// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardWithCheckboxContainer_container__phGti {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/Pages/GSMNewPageCarrier/ReplenishmentsPage/CardWithCheckboxContainer/CardWithCheckboxContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CardWithCheckboxContainer_container__phGti"
};
export default ___CSS_LOADER_EXPORT___;
