import React, { useEffect, useState } from 'react';
import styles from './OperationHistory.module.scss';
import { PageWrapper } from '../../../components/PageWrapper';
import TitleMain from '../../../components/UI/TitleAndSubtitle/TitleMain';
import BackButton from '../../../components/newUI/BackButton/BackButton';
import { useNavigate } from 'react-router-dom';
import OperationsArray from './components/OperationsArray/OperationsArray';
import { fetchGet } from '../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../common/toastError.helper';
import { IDataOperations, IResponseOperations } from '../type';
import CustomRangePicker from '../../../components/newUI/CustomRangePicker/CustomRangePicker';
import dayjs from 'dayjs';
import { isLeapYear, monthDays } from '../../GSMNewPage/GSMHelpers';
import { EmptyList } from '../../../components/EmptyList';
const OperationHistory = () => {
  const navigate = useNavigate();
  const [dataOperations, setDataOperations] = useState<IDataOperations>({});
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [dateRange, setDateRange] = useState<any>(['', '']);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [operationsOffset, setOperationsOffset] = useState<number>(0);
  const daysCountOfDataOperations = Object.keys(dataOperations)?.length;
  const getDataOperations = async (isPagination: boolean) => {
    setIsLoadingPage(true);
    if (!dateRange[0] || !dateRange[1]) {
      return;
    }
    const dateFromISO = new Date(dateRange[0].$d.setHours(3, 0, 0)).toISOString();
    const dateToISO = new Date(dateRange[1].$d.setHours(26, 59, 59)).toISOString();
    try {
      const responseOperations: IResponseOperations = await fetchGet('/finance/operations', {
        limit: 10,
        offset: isPagination ? operationsOffset : 0,
        dateFrom: dateFromISO,
        dateTo: dateToISO,
      });
      if (toastError(responseOperations)) return;

      const rows = responseOperations?.response?.rows;
      rows?.forEach(item => {
        const [date, time] = item.date.split('T');
        setDataOperations(prevState => {
          return {
            ...prevState,
            [date]: [
              ...(prevState[date] || []),
              { amount: item?.amount, date: time?.slice(0, 5), id: item?.id, status: item?.status, type: item?.type },
            ],
          };
        });
      });
      setTotalCount(responseOperations?.response?.count);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingPage(false);
    }
  };
  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOperationsOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const today = new Date();
    if (!dateRange[0]) {
      //последний день месяца (в феврале - 28 или 29)
      const lastDay =
        today.getMonth() === 1
          ? isLeapYear(today.getFullYear())
            ? monthDays[1].to[1]
            : monthDays[1].to[0]
          : monthDays[today.getMonth()]?.to;

      setDateRange([
        dayjs(new Date(new Date(today.setDate(1)).setHours(0, 0, 0))),
        dayjs(new Date(new Date(today.setDate(lastDay)).setHours(23, 59, 59))),
      ]);
    }
    if (operationsOffset !== 0) setOperationsOffset(0);
    if (daysCountOfDataOperations) setDataOperations({});
    getDataOperations(false);
  }, [dateRange]);

  useEffect(() => {
    if (daysCountOfDataOperations === 0) return;
    if (totalCount > operationsOffset) {
      getDataOperations(true);
    }
  }, [operationsOffset]);

  return (
    <PageWrapper loading={isLoadingPage}>
      <div className={styles.backButtonAndTitleContainer}>
        <BackButton textButton={'Вернуться к взаиморасчетам'} onClick={() => navigate('/paymentProcess')} />
        <div className={styles.titleAndDateContainer}>
          <TitleMain fontSize={'24px'} title={'Операции по балансу'} />
          <div className={styles.mobileRangePickerContainer}>
            <CustomRangePicker
              width={'fit-content'}
              isPresets={true}
              open={openCalendar}
              dateRange={dateRange}
              setDateRange={setDateRange}
              setOpen={setOpenCalendar}
              placeholder={['Начало', 'Конец']}
              height="40px"
              isNewStyle={true}
            />
          </div>
        </div>
      </div>
      {daysCountOfDataOperations > 0 ? (
        <div className={styles.operationsContainer}>
          {Object.entries(dataOperations).map(([date, operations]) => {
            return <OperationsArray key={date} dataOperation={operations} date={date} />;
          })}
        </div>
      ) : (
        <EmptyList
          title={'История операций отсутствует'}
          subTitle={'Статистика за выбранный период пока отсутствует'}
        />
      )}
    </PageWrapper>
  );
};

export default OperationHistory;
