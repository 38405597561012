import React, { useEffect, useRef } from 'react';
import styles from './SortBox.module.scss';
import { SortArrowsSvg } from '../UI/svg-icon/SortArrowsSvg';
import useClickOutside from 'src/CustomHooks/useClickOutside';
import cx from 'classnames';

interface IProps {
  sortOptions: { value: string; label: string }[];
  widthButton?: string;
  openSort: boolean;
  setOpenSort: React.Dispatch<React.SetStateAction<boolean>>;
  sort: { value: string; label: string } | '';
  setSort: React.Dispatch<React.SetStateAction<{ value: string; label: string } | ''>>;
  disabled?: boolean;
  label?: string;
  isSelectHidden?: boolean;
  refItem?: React.MutableRefObject<any>;
}
export const SortBox = ({
  sortOptions,
  widthButton = '242px',
  openSort,
  setOpenSort,
  sort,
  setSort,
  disabled,
  label = 'Сортировка',
  isSelectHidden = false,
  refItem = null,
}: IProps) => {
  const sortRef = useRef(null);
  useClickOutside(refItem ? refItem : sortRef, setOpenSort);

  return (
    <div style={{ position: 'relative' }} ref={sortRef}>
      <div style={{ width: widthButton }} className={isSelectHidden ? styles.hiddenFilterButton : ''}>
        <div
          className={`${styles.buttonOpenFilter} ${openSort ? styles.openedFilterButton : ''} ${!sort ? '' : styles.filteredFilterButton}`}
          style={{ width: widthButton }}
          onClick={() => setOpenSort(!openSort)}
        >
          {sort ? sort?.label : label}
          <SortArrowsSvg />
        </div>
      </div>
      {openSort && (
        <div
          className={cx(styles.hiddenFiltersContainer, isSelectHidden ? styles.hiddenSelectFiltersContainer : '')}
          style={{ width: widthButton }}
        >
          <div className={styles.optionsContainer}>
            {sortOptions.map((option, index) => {
              return (
                <div
                  className={styles.option}
                  key={option?.value}
                  onClick={() => {
                    setSort(option);
                    setOpenSort(false);
                  }}
                >
                  <div className={styles.hiddenFiltersText}>{option.label}</div>
                  {index !== sortOptions.length - 1 && <div className={styles.line}></div>}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
