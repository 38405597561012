import React, { useState } from 'react';
import StepsToAddCardGSM from '../StepsToAddCardGSM/StepsToAddCardGSM';
import { fetchPost } from '../../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../../common/toastError.helper';
import { ModalNew } from '../../../../components/ModalNew/ModalNew';

interface IProps {
  openModalOfAddGSMCard: boolean;
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
  drivers: { value: number; label: string }[];
}

const ModalOfAddGsmCard = ({ openModalOfAddGSMCard, setOpenModalOfAddGSMCard, drivers }: IProps) => {
  const [step, setStep] = useState<'selectDriver' | 'success'>('selectDriver');
  const [pickDriver, setPickDriver] = useState<{ value: number; label: string }>({ value: null, label: '' });
  const [sending, setSending] = useState<boolean>(false);
  const issueOfFuelCard = async () => {
    setSending(true);
    try {
      const responseIssueOfFuelCard = await fetchPost('/fuel-cards', 'POST', { userId: pickDriver.value });
      if (toastError(responseIssueOfFuelCard)) {
        setSending(false);
        return;
      }
      setStep('success');
      setSending(false);
    } catch (e) {
      setSending(false);
      console.log(e);
    }
  };

  return (
    <>
      <ModalNew
        afterClose={() => {
          setStep('selectDriver');
          setPickDriver({ value: null, label: '' });
        }}
        openModal={openModalOfAddGSMCard}
        setOpenModal={setOpenModalOfAddGSMCard}
      >
        <StepsToAddCardGSM
          sending={sending}
          issueOfFuelCard={issueOfFuelCard}
          pickDriver={pickDriver}
          setPickDriver={setPickDriver}
          drivers={drivers}
          step={step}
          setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard}
        />
      </ModalNew>
    </>
  );
};

export default ModalOfAddGsmCard;
