import React, { useEffect, useRef } from 'react';
import styles from './FilterBox.module.scss';
import ChevronDownSvg from '../../UI/svg-icon/ChevronDownSvg';
import useClickOutside from 'src/CustomHooks/useClickOutside';
import ButtonNotFilled from '../ButtonNotFilled/ButtonNotFilled';

interface IProps {
  children: any;
  clearFilters: any;
  widthButton?: string;
  openFilter: boolean;
  setOpenFilter: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  label?: string;
  disabledLabel?: string;
  withoutShadow?: boolean;
  clearButtonText?: string;
}
const FilterBox = ({
  children,
  clearFilters,
  widthButton = '290px',
  openFilter,
  setOpenFilter,
  disabled,
  disabledLabel = 'Фильтры',
  label = 'Фильтры применены',
  withoutShadow = false,
  clearButtonText = 'Сбросить фильтры',
}: IProps) => {
  const filterRef = useRef(null);
  useClickOutside(filterRef, setOpenFilter);

  return (
    <div style={{ position: 'relative' }} ref={filterRef} onClick={e => e.stopPropagation}>
      <div style={{ width: widthButton }}>
        <div
          className={`${styles.buttonOpenFilter} ${openFilter ? styles.openedFilterButton : ''} ${disabled ? '' : styles.filteredFilterButton}`}
          style={{ width: widthButton }}
          onClick={() => setOpenFilter(!openFilter)}
        >
          {disabled ? disabledLabel : label}
          <ChevronDownSvg />
        </div>
      </div>
      {openFilter && (
        <div
          className={withoutShadow ? styles.hiddenFiltersContainerWithoutShadow : styles.hiddenFiltersContainer}
          style={{ width: widthButton }}
        >
          <div
            className={styles.hiddenFiltersContent}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {children}
          </div>
          <ButtonNotFilled
            disabled={disabled}
            text={clearButtonText}
            width={'100%'}
            sizeText="small"
            onClick={() => {
              clearFilters();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FilterBox;
