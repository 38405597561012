import React from 'react';
import styles from './GSMStatisticsPlot.module.scss';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { plotOptions } from '../../GSMconstants';
import { GSMPieChart } from './GSMPieChart/GSMPieChart';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);

const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
const numbers = [1, 2, 3, 4, 5, 6, 7];
const daysAndNumbers = daysOfWeek.map((day, index) => {
  return `${day}, ${numbers[index]}`;
});
const data = {
  labels: daysAndNumbers,
  datasets: [
    {
      data: [8000, 5000, 7000, 4500, 2000, 2000, 1500],
      borderColor: '#F4753A',
      pointBackgroundColor: '#F4753A',
      tension: 0.4,
      borderWidth: 2,
      pointRadius: 1,
    },
  ],
};

export const GSMStatisticsPlot = ({ type }: { type: 'line' | 'pie' }) => {
  return (
    <div className={styles.container}>
      {type === 'line' ? <Line data={data} options={plotOptions} /> : <GSMPieChart />}
    </div>
  );
};
