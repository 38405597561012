import { User } from './user/user.type';
import React from 'react';
import { tabsRole } from '../components/AuthRole';
import {fetchGet} from "./proxy-1C-fetch-auth";

const fetchLogout= async ()=> {
  try {
    await fetchGet('/auth/logout',{})
  }catch (e) {
    console.log(e);
  }
}
export const logout = async (user: User, setUser: React.Dispatch<User>) => {
  setUser({
    ...user,
    id: null,
    companyId: null,
    type: '',
    phone: '',
    isInn: false,
    isLogin:false,
    isChangeInput: false,
    isPhone: false,
    apiKey: null,
    active: false,
    activeProfile: false,
    error: false,
    synchronize: false,
    preLoaded: false,
  });

  for (const [, tab] of Object.entries(tabsRole)) {
    tab.active = false;
  }

  await fetchLogout();
  localStorage.clear();
};
