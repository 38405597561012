import { Dispatch, SetStateAction } from 'react';

export const uploadImage = (event: React.ChangeEvent<HTMLInputElement>, setImage: Dispatch<SetStateAction<string>>) => {
  const file = event.target.files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = e => {
      setImage(e.target?.result as string);
    };
    reader.readAsDataURL(file);
  }
};
