import React from 'react';
import styles from './QuarryLink.module.scss';
import { ArrowRightSvg } from 'src/components/UI/svg-icon/ArrowRightSvg';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';

type TQuarryLink = {
  onClick: () => void;
  quarryName: string;
};

export const QuarryLink = ({ onClick, quarryName }: TQuarryLink) => {
  return (
    <div
      className={styles.quarryLink}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <ButtonSimple
        text={quarryName}
        onClick={onClick}
        svg={<ArrowRightSvg />}
        isRightSvg={true}
        smallDesktopText={true}
      />
    </div>
  );
};
