import React, { useState } from 'react';
import { ClipSvg } from '../../UI/svg-icon/ClipSvg';
import { ImageContainer } from '../../ImageContainer';
import { ButtonClose } from '../../UI/buttons/buttonClose';
import { closePopUp, openPopUp } from '../../../common/open-close-popup.function';
import { loadingImages } from '../../../common/loading-image.function';
import { fetchPost } from '../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../common/toastError.helper';
import UploadImageContainer from '../../../Pages/SupplierQuarriesPages/components/UploadImageContainer';
import ImagesContainer from '../../../Pages/SupplierQuarriesPages/components/ImagesContainer';
import { toast } from 'react-toastify';
interface IProps {
  images: any /*то что пришло с бэка*/;
  setImages: any;
  uploadImages: any /*то что загрузил пользователь*/;
  setUploadImages: any;
  showUploadImages: any;
  setShowUploadImages: any;

  formData?: boolean;
  limitFile?: number;
  titleText?: string;
  subTitleText?: string;
}
const InputFile = ({
  titleText = 'Приложите файлы или документ',
  subTitleText = 'в формате PNG, JPEG, PDF',
  images,
  setImages,
  uploadImages,
  setUploadImages,
  showUploadImages,
  setShowUploadImages,
  formData = false,
  limitFile = 3,
}: IProps) => {
  const [showImage, setShowImage] = useState<string>('');
  const [popUpActiveImg, setPopUpActiveImg] = useState(false);
  const openPopupWithImg = src => {
    setShowImage(src);
    setPopUpActiveImg(openPopUp);
  };
  const handleChangeFile = (target: EventTarget & HTMLInputElement) => {
    if (images.length + uploadImages.length >= limitFile) {
      toast.error(`Максимальное количество файлов - ${limitFile}`);
      target.value = '';
      return;
    }
    if (target?.files[0].size / 1024 / 1024 > 10) {
      toast.error(`Файл не должен превышать ${10} мб`);
      return;
    }
    if (uploadImages.length + images.length <= limitFile) {
      setUploadImages(prevState => [...prevState, target?.files[0]]);
    }
    loadingImages(setShowUploadImages, showUploadImages, target, limitFile, 10);
  };
  const deleteFile = async (id: string, name: string, communicationCategory: string) => {
    try {
      const response = await fetchPost(`/files/${id}`, 'DELETE', {
        communicationCategory: communicationCategory,
        name: name,
        objectUuid: '',
      });
      const data = await response.json();
      if (toastError(data)) return;
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <div className="DriverPagePopup__inputItem">
        <div className="ProfilePageUploading">
          <input
            id="InputUploading"
            type={'file'}
            accept={'image/, .png,.jpg,.jpeg,.pdf,.heic'}
            onChange={({ target }) => {
              handleChangeFile(target);
            }}
          />
          <label className="ProfilePageUploading__label" htmlFor="InputUploading">
            <div className="ProfilePageUploading__title">
              <div className="ProfilePageUploading__svg">
                <ClipSvg />
              </div>
              <div className="ProfilePageUploading__text">
                {titleText && <span>{titleText}</span>}
                {subTitleText && <span>{subTitleText}</span>}
              </div>
            </div>
          </label>
        </div>
        <UploadImageContainer
          images={uploadImages}
          setImages={setUploadImages}
          showImages={showUploadImages}
          setShowImages={setShowUploadImages}
          openPopupFunc={openPopupWithImg}
          formData={formData}
        />
        <ImagesContainer
          images={images}
          setImages={setImages}
          openPopupFunc={openPopupWithImg}
          deleteFile={deleteFile}
        />
      </div>
      <div className={popUpActiveImg ? 'Popup active' : 'Popup'}>
        <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__white"></div>
        <div className="Popup__wrapper">
          <div>
            <div>
              <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__close"></div>
              <div className="Popup__content">
                <div className="Popup__box">
                  <img src={showImage} alt={'скан спецификации'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputFile;
