import React, { useEffect, useRef, useState } from 'react';
import styles from './CustomInput.module.scss';
import { CloseCircleNewSvg } from '../../UI/svg-icon/CloseCircleNewSvg';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import { HelpNewSvg } from '../../UI/svg-icon/HelpNewSvg';
import InfoSvg from 'src/components/UI/svg-icon/InfoSvg';

interface IProps {
  type?: string;
  value: any;
  placeholder: string;
  setValue?: React.Dispatch<any>;
  disabled: boolean;
  onChange?: any;
  name?: string;
  width?: string;
  height?: string;
  clearButton?: boolean;
  clearButtonFunction?: any;
  helpText?: string;
  widthSvg?: string;
  widthHelp?: string;
  error?: boolean;
  errorText?: string;
  widthError?: string;
  index?: number;
  suffix?: string;
}
const CustomInput = ({
  type,
  value,
  setValue,
  disabled,
  placeholder,
  onChange,
  name,
  width,
  clearButton,
  helpText,
  widthSvg,
  widthHelp,
  error,
  errorText = '',
  widthError = '',
  clearButtonFunction,
  index,
  suffix,
  height,
}: IProps) => {
  const [focus, setFocus] = useState<boolean>(false);
  const refInput = useRef();

  const suffixRef = useRef<HTMLSpanElement>(null);
  const suffixGap = height === '40px' ? 4 : 4.3;
  const [inputRightPadding, setInputRightPadding] = useState<number>(0);

  useEffect(() => {
    const suffixWidth = suffixRef.current?.offsetWidth;
    setInputRightPadding(suffix && suffixWidth ? suffixWidth + suffixGap : 16);
  }, [suffix]);
  return (
    <>
      <div
        style={{
          height: height,
          width: width,
          gap: (helpText || clearButton) && '16px',
          backgroundColor: disabled && '#E8EBF0',
          border: (error && '1px solid #DC2626') || (disabled && '1px solid #9BA1B4'),
          color: error && '#DC2626',
          paddingRight: suffix && inputRightPadding + 16,
          fontSize: height === '40px' ? '14px' : '16px',
          lineHeight: height === '40px' ? '20px' : '24px',
        }}
        className={focus ? styles.containerFocusByInput : styles.container}
        tabIndex={1}
      >
        <input
          ref={refInput}
          autoComplete={'off'}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          name={name}
          placeholder={suffix ? placeholder + ' ' + suffix : placeholder}
          className={styles.input}
          type={type}
          disabled={disabled}
          value={value || ''}
          onChange={onChange ? onChange : ({ target: { value } }) => setValue(String(value))}
        />

        <div className={styles.inputFakeValueWrapper}>
          <span style={{ paddingLeft: suffixGap }} className={styles.inputFakeValue}>
            {value}
          </span>
          <span
            style={{ visibility: value.length >= 1 ? 'visible' : 'hidden' }}
            ref={suffixRef}
            className={styles.suffix}
          >
            {suffix}
          </span>
        </div>

        <div className={styles.helpersButtonContainer}>
          {clearButton && (
            <div
              onClick={e => {
                clearButtonFunction ? clearButtonFunction(refInput, index) : setValue((value = ''));
                e.stopPropagation();
              }}
              className={styles.closeButton}
            >
              <CloseCircleNewSvg />
            </div>
          )}
          {helpText && (
            <CustomTooltip
              SideTooltip={'left'}
              MobileSideTooltip={'left'}
              svg={<HelpNewSvg color={'#1C274C'} />}
              text={helpText}
              widthSvg={'16px'}
              widthTooltip={widthHelp ? widthHelp : '311px'}
              positionTooltipRight={'-13px'}
              positionTooltipBottom={'28px'}
            />
          )}

          {error && (
            <CustomTooltip
              SideTooltip={'left'}
              MobileSideTooltip={'left'}
              svg={<InfoSvg color={'#DC2626'} />}
              text={errorText}
              widthSvg={'16px'}
              widthTooltip={widthError ? widthError : '311px'}
              positionTooltipRight={'-14px'}
              positionTooltipBottom={'20px'}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CustomInput;
