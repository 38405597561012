// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormReplenishmentsPage_title__n7RDd {\n  color: #1B1B1B;\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n}\n\n.FormReplenishmentsPage_backButtonAndTitleContainer__fbkjQ {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-bottom: 40px;\n}\n\n@media (max-width: 768px) {\n  .FormReplenishmentsPage_backButtonAndTitleContainer__fbkjQ {\n    margin-bottom: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/GSMNewPageCarrier/FormReplenishmentsPage/FormReplenishmentsPage.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AAEF;;AAAA;EACE;IACE,mBAAA;EAGF;AACF","sourcesContent":[".title{\n  color: #1B1B1B;\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n}\n.backButtonAndTitleContainer{\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-bottom: 40px;\n}\n@media (max-width: 768px){\n  .backButtonAndTitleContainer{\n    margin-bottom: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "FormReplenishmentsPage_title__n7RDd",
	"backButtonAndTitleContainer": "FormReplenishmentsPage_backButtonAndTitleContainer__fbkjQ"
};
export default ___CSS_LOADER_EXPORT___;
