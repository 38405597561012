import React from 'react';
import styles from './CatalogMaterialCategory.module.scss';
import { TCatalogMaterialCategoryProps } from '../../catalogTypes';
import { useMediaQuery } from 'react-responsive';

export const CatalogMaterialCategory = ({
  title,
  subtitle,
  image,
  textColor,
  subtextColor,
}: TCatalogMaterialCategoryProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div className={styles.container} style={{ backgroundImage: `url(${image})` }}>
      <h1
        className={styles.title}
        style={{ color: textColor, textShadow: title === 'ПГС' ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '' }}
      >
        {isMobile && (title === 'ЩПС' || title === 'ПГС') ? subtitle : title}
      </h1>
      {!isMobile && (
        <h2
          className={styles.subtitle}
          style={{
            color: subtextColor || textColor,
            textShadow: title !== 'Щебень' ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : '',
          }}
        >
          {subtitle}
        </h2>
      )}
    </div>
  );
};
