import React from 'react';

export const FavoriteSvg = ({ width = '24px' }: { width?: string; color?: string }) => {
  return (
    <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6193 5.41417L12 5.8612L12.3807 5.41417C13.3789 4.24197 14.9114 3.5 16.5 3.5C19.3039 3.5 21.5 5.69614 21.5 8.5C21.5 10.2258 20.7284 11.8307 19.2692 13.6147C17.8043 15.4057 15.6992 17.3155 13.1141 19.6596L13.1134 19.6603L11.9987 20.675L10.8863 19.67L10.8852 19.669L10.8735 19.6584C8.29415 17.3144 6.19348 15.4055 4.73088 13.6161C3.27162 11.8308 2.5 10.2258 2.5 8.5C2.5 5.69614 4.69614 3.5 7.5 3.5C9.08861 3.5 10.6211 4.24197 11.6193 5.41417ZM5.78512 13.3176C7.18867 14.9576 9.18798 16.7689 11.5552 18.9123L11.6464 19.0036L12 19.3571L12.3536 19.0036L12.4448 18.9123C14.812 16.7689 16.8113 14.9576 18.2149 13.3176C19.6154 11.6811 20.5 10.1296 20.5 8.5C20.5 6.22386 18.7761 4.5 16.5 4.5C14.8887 4.5 13.3081 5.45568 12.6133 6.86H11.3948C10.6926 5.45702 9.11294 4.5 7.5 4.5C5.22386 4.5 3.5 6.22386 3.5 8.5C3.5 10.1296 4.38462 11.6811 5.78512 13.3176Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};
