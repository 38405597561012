import React from 'react';
import styles from './ShowcaseCards.module.scss';
import { dataCardResponseType } from '../../type';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ShowcaseCard from './ShowcaseCard/ShowcaseCard';

interface IProps {
  dataCardResponse: dataCardResponseType[];
}
const ShowcaseCards = ({ dataCardResponse }: IProps) => {
  return (
    <div className={styles.cardContainer}>
      {dataCardResponse.map(showCase => (
        <ShowcaseCard showCase={showCase} />
      ))}
    </div>
  );
};

export default ShowcaseCards;
