// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalNew_closeButton__7s59e {\n  position: absolute;\n  top: -24px;\n  right: -104px;\n}", "",{"version":3,"sources":["webpack://./src/components/ModalNew/ModalNew.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,aAAA;AACF","sourcesContent":[".closeButton {\n  position: absolute;\n  top: -24px;\n  right: -104px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeButton": "ModalNew_closeButton__7s59e"
};
export default ___CSS_LOADER_EXPORT___;
