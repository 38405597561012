import React, { Dispatch, SetStateAction } from 'react';
import styles from './MaterialFields.module.scss';
import { IMaterialField } from '../../../type';
import SelectLk from '../../../../../components/newUI/SelectLk/SelectLk';
import { InputText } from '../../../../../components/UI/inputs/InputText';
import { useAppDispatch, useAppSelector } from '../../../../../CustomHooks/storeHooks';
import ImageShowContainer from '../ImageShowContainer/ImageShowContainer';
import ImageUploadInput from '../imageUploadInput/imageUploadInput';
import TextArea from 'antd/es/input/TextArea';
import { handleChangeFieldValue, selectCurrentMaterialProperties } from '../../../services/material/materialSlice';
import { Image } from '../../../../../common/image.type';
import { fetchPost } from '../../../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../../../common/toastError.helper';
import { useMediaQuery } from 'react-responsive';
interface IProps {
  passportMaterial: (File | Image)[];
  photoMaterial: (File | Image)[];
  setPassportMaterial: Dispatch<SetStateAction<(File | Image)[]>>;
  setPhotoMaterial: Dispatch<SetStateAction<(File | Image)[]>>;
}
const MaterialFields = ({ passportMaterial, photoMaterial, setPassportMaterial, setPhotoMaterial }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useAppDispatch();
  const currentMaterialProperties = useAppSelector(selectCurrentMaterialProperties);
  const descriptionField: IMaterialField = currentMaterialProperties?.[currentMaterialProperties?.length - 1];
  const handleValidateAndChangeInputField = ({
    value,
    index,
    fieldMask = '',
  }: {
    value: string;
    index: number;
    fieldMask?: any;
  }) => {
    if (fieldMask?.length && value?.length) {
      new RegExp(fieldMask.slice(1, -1)).test(value) && dispatch(handleChangeFieldValue({ value, index }));
      return;
    }
    dispatch(handleChangeFieldValue({ value, index }));
  };
  const deleteFile = async (id: string, name: string, communicationCategory: string) => {
    try {
      const response = await fetchPost(`/files/${id}`, 'DELETE', {
        communicationCategory: communicationCategory,
        name: name,
        objectUuid: '',
      });
      const data = await response.json();
      if (toastError(data)) return;
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div>
      <div className={styles.materialFieldsContainer}>
        {currentMaterialProperties?.map(
          (infoField: IMaterialField, index) =>
            infoField.fieldType !== 'textArea' && (
              <div key={currentMaterialProperties?.id + '_' + index} className={styles.fieldContainer}>
                {infoField.fieldType === 'select' && (
                  <div>
                    <p className={styles.materialTypeTitle}>
                      {index + 1}. {infoField.title}
                      {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                    </p>
                    <SelectLk
                      disabled={infoField.isDisabled}
                      isSearch={false}
                      value={infoField.value}
                      heightSelectWindow={'195px'}
                      options={infoField.properties}
                      placeholder={'Выберите из списка'}
                      setValue={value => {
                        dispatch(handleChangeFieldValue({ value, index }));
                      }}
                    />
                  </div>
                )}
                {infoField.fieldType === 'input' && (
                  <div>
                    <p className={styles.materialTypeTitle}>
                      {index + 1}. {infoField.title}
                      {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                    </p>
                    <InputText
                      isLabel={true}
                      error={false}
                      type={''}
                      value={infoField.value}
                      textError={''}
                      placeholder={'Введите значение'}
                      setValue={value => {
                        handleValidateAndChangeInputField({ value, index, fieldMask: infoField?.fieldMask });
                      }}
                      disabled={infoField.isDisabled}
                      helpText={''}
                    />
                  </div>
                )}
              </div>
            ),
        )}
      </div>
      {descriptionField?.fieldType === 'textArea' && (
        <div className={styles.fieldContainer} style={{ width: '100%' }}>
          <p className={styles.materialTypeTitle}>
            {currentMaterialProperties.length}. {descriptionField.title}
            {descriptionField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
          </p>
          <TextArea
            style={{ height: '116px', resize: 'none' }}
            value={descriptionField.value}
            className={styles.descriptionMaterialField}
            showCount
            maxLength={300}
            placeholder={'Краткое описание материала'}
            onChange={e =>
              dispatch(
                handleChangeFieldValue({
                  value: e.target.value,
                  index: currentMaterialProperties.length - 1,
                }),
              )
            }
            disabled={false}
          />
        </div>
      )}
      {currentMaterialProperties.length ? (
        <div className={styles.filesContainer}>
          <div className={styles.fieldContainer}>
            <p className={styles.materialTypeTitle}>
              {currentMaterialProperties.length + 1}. Паспорт материала
              <span style={{ color: 'var(--color-red600)' }}> *</span>
            </p>
            <ImageUploadInput setFiles={setPassportMaterial} disabled={passportMaterial?.length >= 2} />
            <ImageShowContainer files={passportMaterial} setFiles={setPassportMaterial} deleteFile={deleteFile} />
          </div>
          <div className={styles.fieldContainer}>
            <p className={styles.materialTypeTitle}>{currentMaterialProperties.length + 2}. Фотография материала</p>
            <ImageUploadInput setFiles={setPhotoMaterial} disabled={photoMaterial?.length >= 5} />
            <ImageShowContainer files={photoMaterial} setFiles={setPhotoMaterial} deleteFile={deleteFile} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MaterialFields;
