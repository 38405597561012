import React, { useEffect, useState } from 'react';
import styles from './GSMNewCardPage.module.scss';
import { useMediaQuery } from 'react-responsive';
import { PageWrapper } from 'src/components/PageWrapper';
import { GSMStatisticsCard } from 'src/Pages/GSMNewPage/GSMStatisticsCard/GSMStatisticsCard';
import { GSMOperationsHistoryTable } from 'src/Pages/GSMNewPage/GSMOperationsHistoryTable/GSMOperationsHistoryTable';
import { GSMCardPageCard } from './GSMCardPageCard/GSMCardPageCard';
import { useNavigate, useLocation } from 'react-router-dom';
import { TCardInfo, TGSMOperation, TOperationsFilter, TOperationsSort } from 'src/Pages/GSMNewPage/GSMtypes';
import { ModalNew } from '../../../components/ModalNew/ModalNew';
import { CloseSvg } from '../../../components/UI/svg-icon/CloseSvg';
import ButtonNotFilled from '../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import { formCardNumber } from '../../../common/formCardNumber.helper';
import { fetchPost } from '../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../common/toastError.helper';
import { toast } from 'react-toastify';
import { clearObject } from 'src/common/ClearObject.helper';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import CustomRangePicker from 'src/components/newUI/CustomRangePicker/CustomRangePicker';
import BackButton from '../../../components/newUI/BackButton/BackButton';
import { TimerGSM } from 'src/components/UI/svg-icon/TimerGSM';
import CustomTooltip from 'src/components/newUI/CustomTooltip/CustomTooltip';
import { declinationWord } from 'src/common/declinationWord.helper';
import { ILoadingPageGSMNewCardPage } from '../type';
import { initStateLoadingPageGSMNewCardPage } from '../constans';
import { isLeapYear, monthDays } from 'src/Pages/GSMNewPage/GSMHelpers';
import dayjs from 'dayjs';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';

export const GSMNewCardPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { pathname } = useLocation();
  const cardId = pathname.split('/')[2];
  const navigate = useNavigate();
  const [openModalOfCloseCard, setOpenModalOfCloseCard] = useState<boolean>(false);
  const [loadingDeleteOrRestore, setLoadingDeleteOrRestore] = useState<boolean>(false);
  const [plotData, setPlotData] = useState([]);
  const [cardInfo, setCardInfo] = useState<TCardInfo | null>(null);
  const [operationsHistoryInfo, setOperationsHistoryInfo] = useState<TGSMOperation[]>([]);
  const [sort, setSort] = useState<{ label: string; value: string }>({ value: 'По сумме', label: 'По сумме' });
  const [plotDates, setPlotDates] = useState<{ from: Date; to: Date }>({ from: null, to: null });
  const [dateOffset, setDateOffset] = useState<number>(0);
  const [operationsOffset, setOperationsOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoadingPage, setIsLoadingPage] = useState<ILoadingPageGSMNewCardPage>(initStateLoadingPageGSMNewCardPage);
  let checkLoadingPage = isLoadingPage.operationsHistory && isLoadingPage.plotInfo && isLoadingPage.cardInfo;
  const [dateRange, setDateRange] = useState<any>(['', '']);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  const [operationsSort, setOperationsSort] = useState<TOperationsSort>('lower-date');
  const [operationsFilter, setOperationsFilter] = useState<TOperationsFilter>({
    fuel: { value: 'Все', label: 'Все' },
    operation: { value: 'Все', label: 'Все' },
  });
  const closeTime: number = Math.floor(
    (new Date(cardInfo?.deletedAt).getTime() + 3 * 1000 * 60 * 60 - new Date().getTime()) / (1000 * 60 * 60) + 24,
  );

  const onClickButtonModalDeleteOrRestore = () => {
    if (cardInfo?.deletedAt) {
      restoreCard();
      return;
    }
    deleteCard();
  };
  useEffect(() => {
    getPlotInfo();
  }, [cardInfo, sort, plotDates]);
  useEffect(() => {
    !openModalOfCloseCard && getCardInfo();
  }, [openModalOfCloseCard]);

  useEffect(() => {
    changeDate();
  }, [dateOffset]);

  useEffect(() => {
    if (totalCount > operationsOffset && operationsHistoryInfo?.length) {
      getOperationsHistory(true);
    }
  }, [operationsOffset]);

  useEffect(() => {
    const today = new Date();
    if (!dateRange[0]) {
      //последний день месяца (в феврале - 28 или 29)
      const lastDay =
        today.getMonth() === 1
          ? isLeapYear(today.getFullYear())
            ? monthDays[1].to[1]
            : monthDays[1].to[0]
          : monthDays[today.getMonth()]?.to;

      setDateRange([
        dayjs(new Date(new Date(today.setDate(1)).setHours(0, 0, 0))),
        dayjs(new Date(new Date(today.setDate(lastDay)).setHours(23, 59, 59))),
      ]);
    }
    if (operationsOffset !== 0) setOperationsOffset(0);
    if (operationsHistoryInfo?.length) setOperationsHistoryInfo([]);
    getOperationsHistory(false);
  }, [cardInfo, operationsSort, dateRange, operationsFilter]);
  // @TODO вынести в отдельный хук
  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOperationsOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getCardInfo = async () => {
    setIsLoadingPage(prevState => ({ ...prevState, cardInfo: true }));
    try {
      const response = await fetchGet('/fuel-cards', { cardsId: cardId });
      if (response?.error) {
        return;
      }
      const cardData = response?.response[0];
      setCardInfo(
        clearObject({
          id: cardData?.id,
          cardNumber: cardData?.cardNumber,
          status: cardData?.status,
          limit: {
            id: cardData?.limit?.id,
            current: cardData?.limit?.current,
          },
          owner: cardData?.owner,
          deletedAt: cardData?.deletedAt || undefined,
        }),
      );
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingPage(prevState => ({ ...prevState, cardInfo: false }));
    }
  };

  const getPlotInfo = async () => {
    setIsLoadingPage(prevState => ({ ...prevState, plotInfo: true }));
    if (!cardInfo?.id || !plotDates?.from || !plotDates?.to) return;
    const dateFromISO = plotDates?.from?.toISOString();
    const dateToISO = plotDates?.to?.toISOString();
    try {
      const response = await fetchGet('/transactions/report/card', {
        cardId: Number(cardId),
        display: sort?.value === 'По сумме' ? 'money' : 'fuel',
        dateFrom: dateFromISO,
        dateTo: dateToISO,
      });
      if (response?.error) {
        return;
      }
      const plotInfoData = response?.response;
      setPlotData(plotInfoData?.result);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingPage(prevState => ({ ...prevState, plotInfo: false }));
    }
  };
  const getOperationsHistory = async (isPagination: boolean) => {
    setIsLoadingPage(prevState => ({ ...prevState, operationsHistory: true }));
    if (!cardInfo?.id || !dateRange[0] || !dateRange[1]) {
      return;
    }
    const dateFromISO = new Date(dateRange[0].$d.setHours(3, 0, 0)).toISOString();
    const dateToISO = new Date(dateRange[1].$d.setHours(26, 59, 59)).toISOString();
    try {
      const response = await fetchGet(
        '/transactions/card',
        clearObject({
          cardId: Number(cardId),
          limit: 10,
          offset: isPagination ? operationsOffset : 0,
          dateFrom: dateFromISO,
          dateTo: dateToISO,
          order: operationsSort,
          fuel:
            typeof operationsFilter?.fuel !== 'string' && operationsFilter?.fuel?.label !== 'Все'
              ? operationsFilter?.fuel?.value
              : undefined,
          operation:
            typeof operationsFilter?.operation !== 'string' && operationsFilter?.operation?.label !== 'Все'
              ? operationsFilter?.operation?.value
              : undefined,
        }),
      );
      if (response?.error) {
        return;
      }
      const operationsInfoData = response?.response?.transactions;
      setTotalCount(response?.response?.rows);
      if (isPagination && operationsOffset !== 0) {
        setOperationsHistoryInfo(prevState => [...prevState, ...operationsInfoData]);
      } else {
        setOperationsHistoryInfo(operationsInfoData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingPage(prevState => ({ ...prevState, operationsHistory: false }));
    }
  };

  const changeDate = () => {
    setPlotDates({
      from: new Date(new Date(new Date().setDate(new Date().getDate() + 1 + dateOffset - 7)).setHours(3, 0, 0)),
      to: new Date(new Date(new Date().setDate(new Date().getDate() + 1 + dateOffset)).setHours(2, 59, 59)),
    });
  };
  const deleteCard: () => Promise<void> = async () => {
    setLoadingDeleteOrRestore(true);
    try {
      const responseDelete = await fetchPost(`/fuel-cards/${cardId}`, 'DELETE', {});
      if (toastError(responseDelete)) {
        setLoadingDeleteOrRestore(false);
        return;
      }
      setLoadingDeleteOrRestore(false);
      setOpenModalOfCloseCard(false);
      toast.success('Вы успешно закрыли топливную карту');
    } catch (e) {
      setLoadingDeleteOrRestore(false);
      console.log(e);
    }
  };
  const restoreCard: () => Promise<void> = async () => {
    setLoadingDeleteOrRestore(true);
    try {
      const responseRestore = await fetchPost(`/fuel-cards/restore/${cardId}`, 'POST', {});
      if (toastError(responseRestore)) {
        setLoadingDeleteOrRestore(false);
        return;
      }
      setLoadingDeleteOrRestore(false);
      setOpenModalOfCloseCard(false);
      toast.success('Вы успешно отменили закрытие топливной карты');
    } catch (e) {
      setLoadingDeleteOrRestore(false);
      console.log(e);
    }
  };

  return (
    <PageWrapper
      loading={checkLoadingPage}
      style={{ backgroundColor: `${isMobile ? 'white' : 'var(--color-gray50)'}` }}
    >
      <div className={styles.topContainer}>
        <BackButton onClick={() => navigate('/gsm')} textButton={'К списку топливных карт'} />

        <div className={styles.buttonAndTooltip}>
          {cardInfo?.deletedAt && (
            <CustomTooltip
              SideTooltip={'up'}
              MobileSideTooltip={'up'}
              svg={<TimerGSM size={isMobile ? '20px' : '24px'} />}
              text={`Исчезнет через ${closeTime} ${declinationWord(closeTime, ['час', 'часа', 'часов'])}`}
              widthSvg={'24px'}
              widthTooltip={'200px'}
              positionTooltipBottom={'28px'}
              isRight={isMobile}
            />
          )}
          {!isMobile && (
            <ButtonSimple
              text={cardInfo?.deletedAt ? 'Отменить закрытие' : 'Закрыть карту'}
              disabled={cardInfo?.status === 'Выпускается'}
              onClick={() => setOpenModalOfCloseCard(true)}
            />
          )}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.priceAndPlotContainer}>
          <GSMCardPageCard cardInfo={cardInfo} setOpenModalOfCloseCard={setOpenModalOfCloseCard} />
          {plotDates?.from && plotDates?.to && (
            <GSMStatisticsCard
              plotData={plotData}
              sort={sort}
              setSort={setSort}
              plotDates={plotDates}
              offset={dateOffset}
              setOffset={setDateOffset}
            />
          )}
        </div>
        <div className={styles.operationsHistoryContainer}>
          <div className={styles.operationsHistoryHeader}>
            <span className={styles.subheader}>История операций</span>
            <CustomRangePicker
              width={'260px'}
              isPresets={true}
              open={openCalendar}
              dateRange={dateRange}
              setDateRange={setDateRange}
              setOpen={setOpenCalendar}
              placeholder={['Начало', 'Конец']}
              height="40px"
              isNewStyle={true}
            />
          </div>
          {isLoadingPage?.operationsHistory ? (
            <LoadingSpin />
          ) : (
            <>
              {dateRange[0] && dateRange[1] && (
                <GSMOperationsHistoryTable
                  operations={operationsHistoryInfo}
                  operationsSort={operationsSort}
                  setOperationsSort={setOperationsSort}
                  filter={operationsFilter}
                  setFilter={setOperationsFilter}
                />
              )}
            </>
          )}
        </div>
      </div>
      <ModalNew openModal={openModalOfCloseCard} setOpenModal={setOpenModalOfCloseCard}>
        <div className={styles.modalContainer}>
          <div className={styles.mobileCloseButtonAndTitle}>
            <p className={styles.modalTitle}>
              {cardInfo?.deletedAt ? 'Подтвердите отмену закрытия карты' : 'Подтвердите закрытие карты'}
            </p>
            {isMobile && (
              <div className={styles.closeButton} onClick={() => setOpenModalOfCloseCard(false)}>
                <CloseSvg />
              </div>
            )}
          </div>
          <div className={styles.modalSubtitle}>
            {cardInfo?.deletedAt
              ? 'Вы действительно хотите отменить закрытие карты'
              : 'Вы действительно хотите закрыть карту'}
            &nbsp;
            <span className={styles.modalCardText}>{formCardNumber(cardInfo?.cardNumber)}</span> ?
          </div>
          <div className={styles.modalButtonsContainer}>
            <ButtonNotFilled
              text="Отмена"
              onClick={() => {
                setOpenModalOfCloseCard(false);
              }}
            />
            <ButtonFilled
              disabled={loadingDeleteOrRestore}
              text="Подтвердить"
              onClick={() => {
                onClickButtonModalDeleteOrRestore();
              }}
            />
          </div>
        </div>
      </ModalNew>
    </PageWrapper>
  );
};
