import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import styles from './Catalog.module.scss';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { PageWrapper } from 'src/components/PageWrapper';
import SubTitleMain from 'src/components/UI/TitleAndSubtitle/SubTitleMain';
import TitleMain from 'src/components/UI/TitleAndSubtitle/TitleMain';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import useDebounce from '../../CustomHooks/useDebounce';
import { TFilter, TFilterItem } from './catalogTypes';
import { CatalogFilter } from './CatalogFilter/CatalogFilter';
import { defaultFilter, materialsFractionValues, materialsKindValues } from './catalogConstants';
import { CatalogMaterialCategories } from './CatalogMaterialCategories/CatalogMaterialCategories';
import { SelectCustom } from 'src/components/UI/select/SelectCustom';
import { PriceFilterInput } from './components/PriceFilterInput/PriceFilterInput';
import { formatNumber } from './utils';
import { FavoriteSvg } from 'src/components/UI/svg-icon/FavoriteSvg';
import { MaterialItem } from '../ShowcasesPage/components/MaterialCatalog/MaterialItem/MaterialItem';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import { IMaterials } from '../ShowcasesPage/type';
import { clearObject } from 'src/common/ClearObject.helper';
import { toastError } from 'src/common/toastError.helper';

export const Catalog = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [filter, setFilter] = useState<TFilter>(defaultFilter);
  const [sort, setSort] = useState<TFilterItem | ''>({ value: 'new', label: 'Новые' });
  const [isDisabledFilterButton, setIsDisabledFilterButton] = useState<boolean>(true);
  const [combinedFilter, setCombinedFilter] = useState<TFilterItem[]>([]);
  const [materials, setMaterials] = useState<IMaterials[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [materialsKind, setMaterialsKind] = useState<TFilterItem[]>([]);
  const [materialsFraction, setMaterialsFraction] = useState<TFilterItem[]>([]);
  const [isClearedFilters, setIsClearedFilters] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(filter.search, 500);

  const isEmptyFilters =
    !filter.objectPlace &&
    !filter.materialType.length &&
    !filter.materialFraction.length &&
    !filter.priceRange.to &&
    !filter.priceRange.from;

  useEffect(() => {
    for (const kind in materialsKindValues) {
      setMaterialsKind(prevState => [...prevState, ...materialsKindValues[kind]]);
    }
    getMaterials(false);
  }, []);

  useEffect(() => {
    setOffset(0);
    setTotalCount(0);
    if (!isEmptyFilters || (sort !== '' && sort?.value !== 'new') || filter.search) {
      setIsClearedFilters(true);
      setMaterials([]);
      getMaterials(false);
      return;
    }
    if (isClearedFilters) {
      setMaterials([]);
      getMaterials(false);
      setIsClearedFilters(false);
    }
  }, [filter.materialFraction, filter.materialType, filter.objectPlace, filter.priceRange, sort, debouncedSearchTerm]);

  useEffect(() => {
    setIsDisabledFilterButton(isEmptyFilters);
    setCombinedFilter([
      ...(!filter.objectPlace ? [] : [filter.objectPlace]),
      ...filter.materialType,
      ...filter.materialFraction,
      ...(filter.priceRange.from || filter.priceRange.to
        ? [
            {
              value: 'price',
              label: `От ${formatNumber(filter.priceRange.from) || '0'} до ${formatNumber(filter.priceRange.to) || '999 999'}`,
            },
          ]
        : []),
    ]);
  }, [filter]);

  useEffect(() => {
    if (!filter.materialType.length) {
      setMaterialsFraction([]);
      setFilter(prevState => ({ ...prevState, materialFraction: [] }));
      return;
    }
    setMaterialsFraction([]);
    filter.materialType.forEach(kind => {
      if (kind.label === 'Отсев дробления' || kind.label === 'Бетонный' || kind.label === 'Кирпичный бой') {
        setMaterialsFraction(prevState =>
          [...prevState, ...materialsFractionValues[kind.label]].filter(
            (item, index, self) => index === self.findIndex(i => i.value === item.value),
          ),
        );
        return;
      }

      for (const [key, values] of Object.entries(materialsKindValues)) {
        if (values.some(item => item.value === kind.value)) {
          if (key === 'Песок' || key === 'ЩПС') return;
          setMaterialsFraction(prevState =>
            [...prevState, ...materialsFractionValues[key]].filter(
              (item, index, self) => index === self.findIndex(i => i.value === item.value),
            ),
          );
        }
      }
    });
  }, [filter.materialType]);

  const clearFilters = () => {
    setIsClearedFilters(true);
    setOffset(0);
    setTotalCount(0);
    setMaterialsFraction([]);
    setFilter(prevState => ({
      ...prevState,
      objectPlace: null,
      materialType: [],
      materialFraction: [],
      priceRange: { from: '', to: '' },
    }));
  };

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 8);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (totalCount > offset) {
      getMaterials(true);
    }
  }, [offset]);

  const deleteFilter = (filterLabel: string, filterValue: string) => {
    setFilter(prevFilter => {
      const updatedFilter: TFilter = {
        ...prevFilter,
        objectPlace:
          !prevFilter.objectPlace || prevFilter.objectPlace.label === filterLabel ? null : prevFilter.objectPlace,
        materialType: prevFilter.materialType.filter(item => item.label !== filterLabel),
        materialFraction: prevFilter.materialFraction.filter(item => item.label !== filterLabel),
        priceRange: {
          from: filterValue === 'price' ? '' : prevFilter.priceRange.from,
          to: filterValue === 'price' ? '' : prevFilter.priceRange.to,
        },
      };
      return updatedFilter;
    });

    setCombinedFilter(prevCombinedValues => prevCombinedValues.filter(item => item.label !== filterLabel));
  };
  const getMaterials = async (isPagination: boolean) => {
    try {
      const response = await fetchGet(
        '/buyer/catalog',
        clearObject({
          limit: 8,
          offset: isPagination ? offset : 0,
          sort: !sort || sort.value === 'new' ? undefined : sort.value,
          fraction: filter.materialFraction.length
            ? filter.materialFraction.reduce((acc, item) => {
                return acc.concat(item.value);
              }, [])
            : undefined,
          kind: filter.materialType.length
            ? filter.materialType.reduce((acc, item) => {
                return acc.concat(item.value);
              }, [])
            : undefined,
          minPrice: !filter.priceRange.from ? undefined : Number(filter.priceRange.from),
          maxPrice: !filter.priceRange.to ? undefined : Number(filter.priceRange.to),
          searchString: filter.search || undefined,
        }),
      );
      setTotalCount(prevState => prevState + 9);
      if (toastError(response)) return;
      setMaterials(prevState => prevState.concat(response?.response?.rows));
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <PageWrapper>
      <div className={styles.container}>
        <div className={styles.container}>
          <div className={styles.titleAndSubtitleAndButtonContainer}>
            <div className={styles.titleAndButtonContainer}>
              <TitleMain title={'Каталог'} />
              {!isMobile && (
                <ButtonNotFilled
                  svg={<FavoriteSvg />}
                  widthSvg="24px"
                  width={'205px'}
                  sizeText={'small'}
                  text={'Перейти в избранное'}
                  onClick={() => navigate('favorites')}
                />
              )}
            </div>
            <div className={styles.subTitleContainer}>
              <SubTitleMain
                subTitle={
                  'Просматривайте материалы, оформляйте заказ и отслеживайте его статус в режиме реального времени'
                }
              />
            </div>
            {isMobile && (
              <div style={{ marginTop: '8px' }}>
                <ButtonNotFilled
                  svg={<FavoriteSvg />}
                  widthSvg="24px"
                  width={'100%'}
                  sizeText={'small'}
                  text={'Перейти в избранное'}
                  onClick={() => navigate('favorites')}
                />
              </div>
            )}
          </div>
          <CatalogFilter
            filter={filter}
            setFilter={setFilter}
            sort={sort}
            setSort={setSort}
            clearFilters={clearFilters}
            isDisabledFilterButton={isDisabledFilterButton}
            combinedFilter={combinedFilter}
            deleteFilter={deleteFilter}
          >
            <div className={styles.filtersContainer}>
              <h1 className={styles.filterHeader}>Фильтры</h1>
              <div className={styles.filtersContainer}>
                <div className={styles.filterWithHeader}>
                  <h2 className={styles.filterSubHeader}>Куда доставить</h2>
                  <SelectCustom
                    options={[]}
                    maxTagCount={1}
                    maxCount={1}
                    placeholder={'Выберите объект из списка'}
                    mode={null}
                    value={filter.objectPlace}
                    setValue={value => setFilter(prevState => ({ ...prevState, objectPlace: value }))}
                    disabled={false}
                    status={''}
                    errorText={''}
                  />
                </div>
                <div className={styles.filterWithHeader}>
                  <h2 className={styles.filterSubHeader}>Тип материала</h2>
                  <SelectCustom
                    options={materialsKind}
                    maxTagCount={1}
                    maxCount={materialsKind.length}
                    placeholder={'Выберите из списка'}
                    mode={'multiple'}
                    value={filter.materialType}
                    setValue={value => setFilter(prevState => ({ ...prevState, materialType: value }))}
                    disabled={false}
                    status={''}
                    errorText={''}
                  />
                </div>
                <div className={styles.filterWithHeader}>
                  <h2 className={styles.filterSubHeader}>Фракция материала</h2>
                  <SelectCustom
                    options={materialsFraction}
                    maxTagCount={2}
                    maxCount={materialsFraction.length}
                    placeholder={'Выберите из списка'}
                    mode={'multiple'}
                    value={filter.materialFraction}
                    setValue={value => setFilter(prevState => ({ ...prevState, materialFraction: value }))}
                    disabled={!filter.materialType.length || !materialsFraction.length}
                    status={''}
                    errorText={''}
                  />
                </div>
                <div className={styles.filterWithHeader}>
                  <h2 className={styles.filterSubHeader}>Диапазон цены, ₽</h2>
                  <div className={styles.priceFilter}>
                    <p>От</p>
                    <PriceFilterInput
                      value={filter.priceRange.from}
                      placeholder={'0'}
                      setValue={value =>
                        setFilter(prevState => ({
                          ...prevState,
                          priceRange: {
                            ...prevState.priceRange,
                            from: value,
                          },
                        }))
                      }
                    />
                    <p>до</p>
                    <PriceFilterInput
                      value={filter.priceRange.to}
                      placeholder={'999 999'}
                      setValue={value =>
                        setFilter(prevState => ({
                          ...prevState,
                          priceRange: {
                            ...prevState.priceRange,
                            to: value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </CatalogFilter>
          <CatalogMaterialCategories />
        </div>
        {loading ? (
          <LoadingSpin />
        ) : (
          <>
            <div className={styles.materialsContainer}>
              <div className={styles.materialContainerTitle}>
                <div className={isMobile ? 'textH5' : 'textH4 textH4_medium'}>Каталог материалов</div>
              </div>
              <div className={styles.cardsContainer}>
                {materials.map(material => (
                  <div key={material.id}>
                    <MaterialItem material={material} link={`/catalog/materials/show/${material.materialsId[0]}`} />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
};
