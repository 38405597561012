import React, { useContext, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import { useMediaQuery } from 'react-responsive';
import styles from './DocumentsPage.module.scss';
import { Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/context';
import { SpecificationsPage } from '../SpecificationPage/SpecificationsPage';
import AddContract from './Components/AddContract/AddContract';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import { Registry } from './Components/Registry/Registry';
import BuyerUpdAndRegistryPage from '../UpdAndRegistryPage/BuyerUpdAndRegistryPage';
import SupplierUpdAndRegistryPage from '../SupplierUpdAndRegistryPage/SupplierUpdAndRegistryPage';
import { DataResponseDocuments } from './type';
import { optionsOfActivityOfSpecifications } from '../SpecificationPage/utils';

export enum tabNames {
  CONTRACTSANDSPECIFICATIONS = 'contractsAndSpecifications',
  REGISTRYANDUP = 'registryAndUpd',
  OTHERDOCUMENTS = 'otherDocuments',
}

enum users {
  CARRIER = 'carrier',
  BUYER = 'buyer',
  SUPPLIER = 'supplier',
}

const DocumentsPage = () => {
  const [tab, setTab] = useState<string>(
    localStorage.getItem('tabOfDocumentsPage') || tabNames.CONTRACTSANDSPECIFICATIONS,
  );
  const [openAddContract, setOpenAddContract] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataResponse, setDataResponse] = useState<DataResponseDocuments[]>([]);
  const [activityOfSpecifications, setActivityOfSpecifications] = useState<
    { value: string; label: string } | undefined | null
  >(optionsOfActivityOfSpecifications[0]);
  const { user } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const getData = async () => {
    setLoading(true);
    try {
      const response = await fetchGet('/information/contracts', {
        status: activityOfSpecifications.value,
      });
      const responseTableData = response?.response?.map(item => {
        return {
          ...item,
          status: item?.status?.title,
        };
      });

      setDataResponse(responseTableData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  const onChange = (key: string) => {
    setDataResponse([]);
    setTab(key);
    localStorage.setItem('tabOfDocumentsPage', key);
  };

  const registryClick = () => {
    tab === tabNames.REGISTRYANDUP ? navigate('registry/registries') : setOpenAddContract(true);
  };

  const registryAndUpdByRole = {
    carrier: <Registry tab={tab} />,
    buyer: <BuyerUpdAndRegistryPage tab={tab} />,
    supplier: <SupplierUpdAndRegistryPage tab={tab} />,
  };

  const tabs: {
    key: string;
    label: string;
    children: JSX.Element;
  }[] = [
    {
      key: 'contractsAndSpecifications',
      label: 'Договоры и спецификации',
      children: (
        <>
          <SpecificationsPage
            activityOfSpecifications={activityOfSpecifications}
            setActivityOfSpecifications={setActivityOfSpecifications}
            dataResponse={dataResponse}
            setDataResponse={setDataResponse}
            loading={loading}
            setLoading={setLoading}
          />
        </>
      ),
    },
    {
      key: 'registryAndUpd',
      label: `${user.companyType === users.CARRIER ? 'Реестр' : 'УПД'}`,
      children: <>{registryAndUpdByRole[user.companyType]}</>,
    },
    user?.type === users.CARRIER && {
      key: 'otherDocuments',
      label: 'Другие',
      children: (
        <SpecificationsPage
          activityOfSpecifications={activityOfSpecifications}
          setActivityOfSpecifications={setActivityOfSpecifications}
          dataResponse={dataResponse}
          setDataResponse={setDataResponse}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
  ];
  return (
    <>
      <PageWrapper>
        <div className={styles.titleAndSubTitleContainer}>
          <div className={styles.titleAndAddButton}>
            <TitleMain title={'Документы'} />
            {isMobile && <SubTitleMain subTitle={`Общий список документов`} />}
            {tab !== tabNames.OTHERDOCUMENTS && (
              <ButtonFilled
                margin={isMobile ? '12px 0 0 0' : '0'}
                sizeText={'small'}
                svg={tab !== tabNames.REGISTRYANDUP && <NewPlusSvg />}
                width={isMobile ? '100%' : '185px'}
                text={tab === tabNames.REGISTRYANDUP ? 'Сформировать реестр' : 'Добавить договор'}
                onClick={registryClick}
              />
            )}
            <AddContract getData={getData} openAddContract={openAddContract} setOpenAddContract={setOpenAddContract} />
          </div>
          {!isMobile && <SubTitleMain subTitle={`Общий список документов`} />}
        </div>
        <Tabs defaultActiveKey={tab} items={tabs} onChange={onChange} />
      </PageWrapper>
    </>
  );
};

export default DocumentsPage;
