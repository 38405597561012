import { TOptionsForSelectCard } from './type';

export const initStateFilters = {
  search: '',
  grid: 'Сетка',
  dateAdded: 'lower-date',
};

export const optionsGrid = [
  { value: 'Сетка', label: 'Сетка' },
  { value: 'Список', label: 'Список' },
];
export const optionsDateAdded = [
  { value: 'lower-date', label: 'По дате добавления' },
  { value: 'higher-price', label: 'По возрастанию остатка' },
  { value: 'lower-price', label: 'По убыванию остатка' },
];

export const optionsPriceFilter = [
  { value: 'to1', label: 'до 1 000 ₽' },
  { value: 'from1to25', label: 'от 1 000 ₽ до 25 000 ₽' },
  { value: 'from25', label: 'от 25 000 ₽' },
];

export const initStateFiltersReplenishments = {
  search: '',
  grid: 'Сетка',
  dateAdded: 'lower-date',
  balance: { from: '', to: '' },
  balanceRadio: '',
};
export const InitStateInputsFormReplenishment = {
  transferTo: '',
  sum: '',
};
export const mockOptionsFormOfReplenishmentsCard: { value: string; label: string }[] = [
  { value: 'own', label: 'С общего баланса' },
  { value: '1', label: '3455 4562 7710 3507' },
  { value: '2', label: '5469 8765 7776 1646' },
  { value: '3', label: '8188 4562 3456 0987' },
];

export const mockOptionsForSelectCard: TOptionsForSelectCard = [
  { value: 'own', card: 'С общего баланса', money: '8944.47' },
  { value: '1', card: 'Брагин 3455 4562 7710 3507', money: '4.47' },
  { value: '2', card: 'Петров 5469 8765 7776 1646', money: '8944.47' },
  { value: '3', card: 'Иванов 8188 4562 3456 0987', money: '3404.56' },
];
export const initStateDataCards = [
  {
    cardNumber: '',
    id: null,
    limit: {
      id: null,
      current: null,
    },
    owner: '',
    status: '',
    checkActive: false,
  },
];

export const initStateOptionSelect = {
  value: 'own',
  card: 'С общего баланса',
  money: '0',
};

export const initStateOptionsFormOfReplenishmentsCard = { value: 'own', label: 'С общего баланса' };

export const initStateSwitcherState = {
  check: false,
  sending: false,
};
export const initStateLoadingPageGSMNewCardPage = {
  cardInfo: true,
  plotInfo: true,
  operationsHistory: true,
};
