import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './ModalOfAddGSMCard.module.scss';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import StepsToAddCardGSM from '../StepsToAddCardGSM/StepsToAddCardGSM';
import { useMediaQuery } from 'react-responsive';
import DrawerMobile from '../../../../components/newUI/DrawerMobile/DrawerMobile';

interface IProps {
  openModalOfAddGSMCard: boolean;
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
}

const ModalOfAddGsmCard = ({ openModalOfAddGSMCard, setOpenModalOfAddGSMCard }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [step, setStep] = useState<'selectDriver' | 'smsAuth' | 'success'>('selectDriver');
  const [codeValue, setCodeValue] = useState<string>('');

  useEffect(() => {
    if (codeValue.length === 5) {
      setStep('success');
    }
  }, [codeValue]);

  return (
    <>
      {isMobile ? (
        <DrawerMobile
          afterClose={() => {
            setStep('selectDriver');
            setCodeValue('');
          }}
          closeButton={false}
          heightDrawer={'fit-content'}
          open={openModalOfAddGSMCard}
          close={() => setOpenModalOfAddGSMCard(false)}
        >
          <StepsToAddCardGSM
            step={step}
            setStep={setStep}
            codeValue={codeValue}
            setCodeValue={setCodeValue}
            setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard}
          />
        </DrawerMobile>
      ) : (
        <Modal
          afterClose={() => {
            setStep('selectDriver');
            setCodeValue('');
          }}
          destroyOnClose={true}
          width={480}
          zIndex={9500}
          footer={null}
          closeIcon={false}
          open={openModalOfAddGSMCard}
          onCancel={() => setOpenModalOfAddGSMCard(false)}
          centered
        >
          <StepsToAddCardGSM
            step={step}
            setStep={setStep}
            codeValue={codeValue}
            setCodeValue={setCodeValue}
            setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard}
          />
          <div className={styles.closeButton}>
            <FrameModalSvg onClick={() => setOpenModalOfAddGSMCard(false)} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalOfAddGsmCard;
