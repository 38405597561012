import React from 'react';

export const ArrowRightSvg = ({ size = '16px' }: { size?: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.16699 3.33398L11.167 8.00065L7.16699 12.6673"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
