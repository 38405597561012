import React, { useEffect, useState } from 'react';
import styles from './CatalogFilter.module.scss';
import { InputSearch } from 'src/components/UI/inputs/InputSearch';
import FilterBox from 'src/components/newUI/FilterBox/FilterBox';
import { useMediaQuery } from 'react-responsive';
import { TCatalogFilter, TFilterItem } from '../catalogTypes';
import { sortValues } from '../catalogConstants';
import { SortBox } from 'src/components/SortBox/SortBox';
import { ShowedFilters } from '../components/ShowedFilters/ShowedFilters';

export const CatalogFilter = ({
  filter,
  setFilter,
  sort,
  setSort,
  clearFilters,
  isDisabledFilterButton,
  combinedFilter,
  deleteFilter,
  children,
}: TCatalogFilter) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openSort, setOpenSort] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <InputSearch
        placeholder={'Поиск'}
        onInput={({ currentTarget: { value: search } }) => setFilter(prevState => ({ ...prevState, search }))}
        value={filter.search}
      />
      <div className={styles.searchContainer}>
        <SortBox
          openSort={openSort}
          setOpenSort={setOpenSort}
          widthButton={isMobile ? '100%' : '242px'}
          sortOptions={sortValues}
          sort={sort}
          setSort={setSort}
        />
        <FilterBox
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          clearFilters={clearFilters}
          widthButton={isMobile ? '100%' : '290px'}
          disabled={isDisabledFilterButton}
          label="Фильтры"
          disabledLabel="Фильтры"
        >
          {children}
        </FilterBox>
        {!isDisabledFilterButton &&
          (!isMobile ? (
            <>
              <ShowedFilters combinedFilter={combinedFilter} deleteFilter={deleteFilter} clearFilters={clearFilters} />
            </>
          ) : (
            <div className={styles.showedFilters}>
              <ShowedFilters combinedFilter={combinedFilter} deleteFilter={deleteFilter} clearFilters={clearFilters} />
            </div>
          ))}
      </div>
    </div>
  );
};
