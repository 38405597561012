import React, { useRef, useState } from 'react';
import styles from './ShowMoreAdminTariffs.module.scss';
import { ShowMoreSVG } from '../../../../../components/UI/svg-icon/ShowMoreSvg';
import useClickOutsideNode from '../../../../../CustomHooks/useClickOutsideNode';
import { IArrayButtonsShowMoreAdminTariffs, IDataResponseTable } from '../../type';
import { fetchPost } from '../../../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../../../common/toastError.helper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
interface IProps {
  id: number;
  tariffId: number;
  stateTable: IDataResponseTable[];
  setStateTable: React.Dispatch<IDataResponseTable[]>;
}
const ShowMoreAdminTariffs = ({ id, tariffId, stateTable, setStateTable }: IProps) => {
  const [openWindow, setOpenWindow] = useState<boolean>(false);
  const navigate = useNavigate();
  const refContainer = useRef();
  useClickOutsideNode(refContainer, setOpenWindow);
  const deleteFromStateTariff = () => {
    let newArray = [...stateTable];
    let newFilteredArray = newArray.filter(obj => obj.id !== id);
    setStateTable(newFilteredArray);
  };
  const deleteTariffDetails = async () => {
    try {
      const responseDelete = await fetchPost(`/admin/tariffs/${id}`, 'DELETE', { tariffId: tariffId });
      if (toastError(responseDelete)) return;
      toast.success('Вы успешно удалили спецификацию');
      deleteFromStateTariff();
      setOpenWindow(false);
    } catch (e) {
      console.log(e);
    }
  };
  const arrayButtons: IArrayButtonsShowMoreAdminTariffs[] = [
    {
      title: 'Создать копию',
      onClick: () => console.log('Создать копию') /*здесь будут использоваться методы*/,
    },
    {
      title: 'Редактировать',
      onClick: () => navigate(`/tariffs/${tariffId}/edit/${id}`),
    },
    {
      title: 'Удалить',
      onClick: () => deleteTariffDetails(),
    },
  ];

  return (
    <div ref={refContainer}>
      <div
        onClick={e => {
          e.stopPropagation();
          setOpenWindow(!openWindow);
        }}
        className={styles.svgContainer}
      >
        <ShowMoreSVG />
      </div>
      {openWindow && (
        <div className={styles.windowContainer}>
          {arrayButtons.map(button => {
            return (
              <div key={button.title} onClick={button.onClick} className={styles.windowButtonContainer}>
                <p className={styles.windowButtonText}>{button.title}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ShowMoreAdminTariffs;
