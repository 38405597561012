import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './Filters.module.scss';
import { IFilters } from '../../type';
import { InputSearch } from '../../../../components/UI/inputs/InputSearch';
import { CustomSelect } from '../../../../components/UI/select/CustomSelect';
import { initStateFiltersReplenishments, optionsDateAdded, optionsGrid, optionsPriceFilter } from '../../constans';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import FilterBox from 'src/components/newUI/FilterBox/FilterBox';
import { PriceFilterInput } from 'src/Pages/Catalog/components/PriceFilterInput/PriceFilterInput';
import { Radio, RadioChangeEvent } from 'antd';
import useClickOutside from 'src/CustomHooks/useClickOutside';

interface IProps {
  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
}

const Filters = ({ filters, setFilters }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { pathname } = useLocation();
  const isReplenishmentsPage = pathname.indexOf('/replenishments') !== -1;
  const currentGridFromStorage = localStorage.getItem('currentFilterGrid');
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [disabledFilter, setDisabledFilter] = useState<boolean>(true);

  const onChangeRadioButton = (e: RadioChangeEvent) => {
    const corrObjBalance = {
      to1: { from: '', to: '1000' },
      from1to25: { from: '1000', to: '25000' },
      from25: { from: '25000', to: '' },
    };
    setFilters(prevState => ({ ...prevState, balanceRadio: e.target.value, balance: corrObjBalance[e.target.value] }));
  };

  useEffect(() => {
    const isEmptyBalanceFilter = !filters?.balanceRadio && !filters?.balance?.from && !filters?.balance?.to;
    if (isEmptyBalanceFilter) {
      setDisabledFilter(true);
      return;
    }
    setDisabledFilter(false);
  }, [filters?.balanceRadio, filters?.balance]);

  useLayoutEffect(() => {
    setFilters(prevState => ({ ...prevState, grid: currentGridFromStorage || 'Сетка' }));
  }, []);
   const handelChangeBalance = (typeValue:string,value) => {
     const regex = /^(?:0|[1-9][0-9]{0,5}|1000000)?$/;
     if(!regex.test(value)) return
     setFilters(prevState => ({
       ...prevState,
       balance: { ...prevState.balance, [typeValue]: value },
     }))
   }
  const clearFilters = () => {
    setFilters(initStateFiltersReplenishments);
  };
  return (
    <div className={styles.container}>
      <InputSearch
        height={isMobile && '40px'}
        placeholder="Поиск"
        onInput={({ currentTarget: { value: search } }) => setFilters(prevState => ({ ...prevState, search }))}
        value={filters.search}
      />
      <div className={styles.twoFiltersString}>
        <div style={{ width: isMobile ? '100%' : '242px' }}>
          <CustomSelect
            onMenuOpen={() => setOpenFilter(false)}
            height40px={isMobile}
            defaultValue={
              currentGridFromStorage !== null
                ? { value: currentGridFromStorage, label: currentGridFromStorage }
                : optionsGrid[0]
            }
            isDisabled={false}
            error={false}
            options={optionsGrid}
            setValue={e => {
              setFilters(prevState => ({ ...prevState, grid: e.value }));
              localStorage.setItem('currentFilterGrid', e.value);
            }}
            placeholder={''}
            isClearable={false}
            isSearchable={false}
            noOptionsMessage={'Нет вариантов'}
          />
        </div>
        <div style={{ width: isMobile ? '100%' : '242px' }}>
          <CustomSelect
            onMenuOpen={() => setOpenFilter(false)}
            height40px={isMobile}
            defaultValue={optionsDateAdded[0]}
            isDisabled={false}
            error={false}
            options={optionsDateAdded}
            setValue={e => {
              console.log(e);
              setFilters(prevState => ({ ...prevState, dateAdded: e.value }));
            }}
            placeholder={''}
            isClearable={false}
            isSearchable={false}
            noOptionsMessage={'Нет вариантов'}
          />
        </div>
        <div>
          {isReplenishmentsPage && (
            <div style={{ width: isMobile ? '100%' : '242px' }}>
              <FilterBox
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                clearFilters={clearFilters}
                label="Остаток баланса"
                disabled={disabledFilter}
                disabledLabel="Остаток баланса"
                clearButtonText={'Сбросить'}
                withoutShadow={true}
                widthButton={isMobile ? '100%' : '242px'}
              >
                <div className={styles.filterWithHeader}>
                  <h2 className={styles.filterSubHeader}>Остаток баланса</h2>
                  <div className={styles.priceFilter}>
                    <PriceFilterInput
                      value={filters?.balance?.from}
                      placeholder={'0'}
                      setValue={value =>
                          handelChangeBalance('from',value)
                      }
                    />
                    <PriceFilterInput
                      value={filters?.balance?.to}
                      placeholder={'999 999'}
                      setValue={value =>
                          handelChangeBalance('to',value)
                      }
                    />
                  </div>
                  <Radio.Group onChange={onChangeRadioButton} value={filters?.balanceRadio}>
                    <div className={styles.radioGroup}>
                      {optionsPriceFilter.map(option => {
                        return (
                          <Radio key={option.value} value={option.value}>
                            {option.label}
                          </Radio>
                        );
                      })}
                    </div>
                  </Radio.Group>
                </div>
              </FilterBox>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Filters;
